import { useState, useEffect } from 'react';
import { flow } from 'lodash';
import { ApolloProvider } from '@apollo/client';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { useGlobalContext } from 'src/GlobalContextProvider';
import { getAuthUrl, deleteAuthUrl } from 'src/Auth/common';
import { withFeatures } from 'src/components/Feature/Feature';
import AuthGuestModal from 'src/components/AuthGuestModal';
import ApolloUtil from './common/ApolloUtil';

import Auth from './Auth/Auth';

import { withAppSettings } from './AppSettings';
import { globalStore as reduxStore } from './store';

const AuthAndApolloProvider = props => {
  const {
    children,
    allowList,
    history,
    match,
    location,
    features,
    appSettings
  } = props;

  const { myOrganization } = useGlobalContext();
  const guestAuthEnabled = myOrganization?.guestAuthEnabled || false;
  const { organizationId, organizationDomainId } = appSettings;

  // these are essentially instance variables as this set state only gets called once...
  // using useRef didn't work as expected
  const [auth] = useState(() => {
    return Auth.initialize({
      allowList,
      appSettings,
      organizationId,
      organizationDomainId,
      features,
      history,
      match,
      location,
      guestAuthEnabled
    });
  });

  const [apolloClient] = useState(() => {
    return ApolloUtil.newClient({
      auth,
      appSettings,
      reduxStore
    });
  });

  useEffect(() => {
    // on first load check if their token is expired and if so, start login
    // expired tokens after inital load are handled by private route and apollo
    if (!Auth.isAuthenticated()) {
      Auth.login();
    } else {
      // if we are authenticated but haven't redirected yet we need to redirect
      const authUrl = getAuthUrl();
      if (authUrl) {
        deleteAuthUrl();
        window.location = authUrl;
      }
    }
  });

  return (
    <>
      {auth && apolloClient && (
        <ApolloProvider client={apolloClient}>
          <AuthGuestModal />
          {children}
        </ApolloProvider>
      )}
    </>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    allowList: ownProps.allowList
  };
}

export default flow(
  connect(mapStateToProps),
  withAppSettings,
  withFeatures,
  withRouter
)(AuthAndApolloProvider);
