import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { validateRequired } from 'src/common/validations';

export const cancelOrderInputs = [
  {
    name: 'orderId',
    displayName: 'Order ID',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'forceFullRefund',
    displayName: 'Force a Full Refund',
    displayMethodId: INPUT_TYPES.ANY_BOOLEAN,
    initialValue: false,
    reduxValidations: [validateRequired],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  }
];
