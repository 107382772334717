import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CardMedia from './DisplayCardMedia';
import { getDisplaySquareAdStyles } from './DisplaySquareAd';

const DisplayRectangleAd = props => {
  const { rectangleImageUrl, headline, description, businessName } = props;
  const theme = useTheme();
  const sxStyles = getDisplaySquareAdStyles({
    theme,
    vars: {
      hasUniformPreviewWidth: props.hasUniformPreviewWidth,
      isResponsive: props.isResponsive
    }
  });
  return (
    <Box sx={sxStyles.container}>
      <Box sx={sxStyles.wrapper}>
        <Box sx={sxStyles.cardContainer}>
          <Box sx={sxStyles.fakeTextContainer}>
            <Box sx={sxStyles.placeholderText} />
            <Box sx={sxStyles.placeholderText} />
            <Box sx={sxStyles.placeholderText} />
          </Box>
          <Box sx={sxStyles.profileContainer}>
            <Box sx={sxStyles.fakeImage} />
            <Box sx={sxStyles.placeholderTextShort} />
          </Box>
        </Box>

        <CardMedia
          headline={headline}
          description={description}
          businessName={businessName}
          image={rectangleImageUrl}
        />
      </Box>
    </Box>
  );
};

export default DisplayRectangleAd;
