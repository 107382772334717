import { flow, isEmpty } from 'lodash';
import { styled } from '@mui/material/styles';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { List, Typography } from '@mui/material';

import { formatBlueprintsForIcon } from 'src/common/blueprints';

import BlueprintDisplayListItem from './BlueprintDisplayListItem';

const PREFIX = 'BlueprintSelectorList';

const classes = {
  blueprintEmpty: `${PREFIX}-blueprintEmpty`,
  selectBlueprintsList: `${PREFIX}-selectBlueprintsList`,
  error: `${PREFIX}-error`,
  errorMessage: `${PREFIX}-errorMessage`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.blueprintEmpty}`]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '200px',
    minHeight: '100px'
  },

  [`& .${classes.selectBlueprintsList}`]: {
    maxHeight: '460px',
    minHeight: '460px',
    overflow: 'scroll'
  },

  [`& .${classes.error}`]: {
    color: theme.palette.error.main
  },

  [`& .${classes.errorMessage}`]: {
    margin: '0 auto'
  }
}));

const BlueprintSelectorList = props => {
  const {
    blueprints: { blueprints },
    handleSelectBlueprint,
    selectedBlueprint
  } = props;

  const formattedBlueprints = formatBlueprintsForIcon(blueprints);

  return (
    <Root>
      <List className={classes.selectBlueprintsList} data-cy="bp-selector-list">
        {isEmpty(formattedBlueprints) ? (
          <div className={classes.blueprintEmpty}>
            <Typography variant="body1">
              <Trans i18nKey="automatedProgramFilter:wizard.noBlueprints">
                I'm sorry it does not look like you have any blueprints we can
                automate.
              </Trans>
            </Typography>
          </div>
        ) : (
          formattedBlueprints.map(blueprint => {
            return (
              <BlueprintDisplayListItem
                key={`blueprint-${blueprint?.id}`}
                blueprint={blueprint}
                selected={selectedBlueprint?.id === blueprint?.id}
                onItemClick={() => handleSelectBlueprint(blueprint?.id)}
              />
            );
          })
        )}
      </List>
    </Root>
  );
};

export default flow(withRouter)(BlueprintSelectorList);
