import { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm } from 'redux-form';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import {
  Paper,
  Typography,
  Button,
  CircularProgress,
  Grid
} from '@mui/material';
import Alert from '@mui/material/Alert';

import PageTitle from 'src/components/PageTitle/PageTitle';
import { DynamicForm } from 'src/components/ReduxForm';
import { subscribeToTikTokLeadWebhooks } from './mutations';

const PREFIX = 'TikTokSettings';

const classes = {
  content: `${PREFIX}-content`,
  submitButton: `${PREFIX}-submitButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.content}`]: {
    padding: theme.spacing(2)
  },

  [`& .${classes.submitButton}`]: {
    marginTop: theme.spacing(2)
  }
}));

const getText = () => ({
  title: t('partner:tiktokSettings.title'),
  header: t('partner:tiktokSettings.header'),
  webhook: t('partner:tiktokSettings.webhook'),
  submit: t('partner:tiktokSettings.submit'),
  success: t('partner:tiktokSettings.success'),
  error: t('partner:tiktokSettings.error')
});

const TikTokSettings = ({
  handleSubmit,
  submitting,
  invalid,
  subscribeToTikTokLeadWebhooks
}) => {
  const text = getText();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const onSubmit = useCallback(async formValues => {
    try {
      const { data } = await subscribeToTikTokLeadWebhooks({
        variables: { advertiserId: formValues?.advertiserId }
      });

      if (data?.subscribeToTikTokLeadWebhooks?.success) {
        setSuccess(true);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  });

  const inputs = [
    {
      name: 'advertiserId',
      displayName: 'Your TikTok Advertiser Id',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: undefined,
      reduxValidations: [],
      isRequired: true,
      isHidden: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: 'This is the advertiser id you get from TikTok'
        }
      }
    }
  ];
  return (
    <Root>
      <PageTitle subPageTitle={text.title} />
      <Typography variant="h5">{text.title}</Typography>
      <Typography variant="subtitle2">{text.header}</Typography>
      <br />
      <Paper className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">{text.webhook}</Typography>
            {error && <Alert severity="error">{text.error}</Alert>}
            {success ? (
              <Alert severity="success">{text.success}</Alert>
            ) : (
              <form
                autoComplete="off"
                className={classes.form}
                onSubmit={handleSubmit(onSubmit)}
              >
                <DynamicForm disabled={false} inputs={inputs} />
                <br />
                <Trans i18nKey="partner:tiktokSettings.agreement">
                  By activating the TikTok leads webhook, you agree too{' '}
                  <a
                    href="https://ads.tiktok.com/i18n/official/policy/lead-gen-terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TikTok’s lead terms.
                  </a>
                </Trans>
                <br />
                <Button
                  color="primary"
                  disabled={submitting || invalid}
                  endIcon={submitting && <CircularProgress size={15} />}
                  className={classes.submitButton}
                  variant="contained"
                  type="submit"
                >
                  {text.submit}
                </Button>
              </form>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Root>
  );
};

export default flow(
  reduxForm({
    enableReinitialize: true,
    form: 'subscribeToTikTokLeadWebhooksForm',
    initialValues: {}
  }),
  graphql(subscribeToTikTokLeadWebhooks, {
    name: 'subscribeToTikTokLeadWebhooks'
  })
)(TikTokSettings);
