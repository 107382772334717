import { flow, isNull } from 'lodash';
import { styled } from '@mui/material/styles';
import { Trans } from 'react-i18next';

import { Grid, Typography } from '@mui/material';

import SelectBluePrintIcon from '@mui/icons-material/Directions';

import AdPreview from 'src/components/AdPreview';
import BlueprintSelectorList from '../BlueprintSelector/BlueprintSelectorList';

const PREFIX = 'AutomatedProgramChooseBlueprint';

const classes = {
  headline: `${PREFIX}-headline`,
  adPreviewNoData: `${PREFIX}-adPreviewNoData`,
  adPreviewNoDataText: `${PREFIX}-adPreviewNoDataText`,
  adPreviewNoDataIcon: `${PREFIX}-adPreviewNoDataIcon`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.headline}`]: {
    fontSize: '20px',
    margin: `${theme.spacing(2)} 0`,
    textAlign: 'center'
  },

  [`& .${classes.adPreviewNoData}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center'
  },

  [`& .${classes.adPreviewNoDataText}`]: {
    fontSize: theme.spacing(2),
    fontWeight: 'bold'
  },

  [`& .${classes.adPreviewNoDataIcon}`]: {
    height: theme.spacing(10),
    marginBottom: theme.spacing(2),
    transform: 'scaleX(-1)',
    width: theme.spacing(10)
  }
}));

const AutomatedProgramChooseBlueprint = props => {
  const {
    architecture,
    blueprints,
    formValues,
    selectedBlueprint,
    handleSelectBlueprint
  } = props;

  const previewData = {
    blueprint: selectedBlueprint,
    dynamicUserInputs: formValues?.dynamicUserInputs ?? {},
    businessObjects: []
  };

  return (
    <StyledGrid container spacing={3} data-cy="bp-step">
      <Grid item xs={12}>
        <Typography className={classes.headline} variant="h3">
          <Trans i18nKey="automatedProgramFilter:wizard.blueprintSelectionHeading">
            Choose a blueprint to determine the platform, targeting & layout
          </Trans>
        </Typography>
      </Grid>
      <Grid item sm={6}>
        <BlueprintSelectorList
          architectureName={architecture?.name}
          blueprints={blueprints}
          selectedBlueprint={selectedBlueprint}
          handleSelectBlueprint={handleSelectBlueprint}
        />
      </Grid>
      <Grid item sm={6} data-cy="ad-preview">
        {isNull(selectedBlueprint) ? (
          <div className={classes.adPreviewNoData}>
            <SelectBluePrintIcon className={classes.adPreviewNoDataIcon} />
            <Typography className={classes.adPreviewNoDataText} variant="h3">
              <Trans i18nKey="automatedProgramFilter:wizard.adPreviewNoData">
                Please select a blueprint to see previews
              </Trans>
            </Typography>
          </div>
        ) : (
          <AdPreview
            architecture={architecture}
            previewData={previewData}
            isAutomatedProgram
            showMessaging={false}
          />
        )}
      </Grid>
    </StyledGrid>
  );
};

export default flow()(AutomatedProgramChooseBlueprint);
