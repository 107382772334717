import Numeral from 'numeral';

import { styled } from '@mui/material/styles';

const PREFIX = 'SpendCell';

const classes = {
  spend: `${PREFIX}-spend`,
  discounted: `${PREFIX}-discounted`
};

const Root = styled('span')(() => ({
  [`& .${classes.spend}`]: {
    padding: '0 0 5px 0',
    fontWeight: 'bold',
    minWidth: '150px'
  },

  [`& .${classes.discounted}`]: {
    '& span:first-child': {
      textDecoration: 'line-through'
    },
    '& span:last-child': {
      fontSize: '10px'
    }
  }
}));

const SpendCell = props => {
  const { data } = props;

  return (
    <Root>
      {data?.priceBeforeDiscount && (
        <div className={classes.discounted}>
          <span>{Numeral(data?.priceBeforeDiscount).format('$0,0')}</span>{' '}
          <span>Promo applied</span>
        </div>
      )}
      <div className={classes.spend}>
        {Numeral(data?.amount).format('$0,0')}
      </div>
    </Root>
  );
};

export default SpendCell;
