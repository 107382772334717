import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { t } from 'i18next';

import { Typography } from '@mui/material';
import { Delete as DeleteIcon, Key as KeyIcon } from '@mui/icons-material';

import ConfirmationModal from 'src/components/Modal/ConfirmationModal';

const PREFIX = 'ConfirmModal';

const classes = {
  container: `${PREFIX}-container`
};

const StyledConfirmationModal = styled(ConfirmationModal)(({ theme }) => ({
  [`& .${classes.container}`]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1)
  }
}));

const pageText = () => ({
  deleteTitle: t('admin:clientKeys.confirmDeleteModalHeader'),
  createTitle: t('admin:clientKeys.confirmCreateModalHeader'),
  confirm: t('admin:clientKeys.confirmModalCommitButton'),
  cancel: t('admin:clientKeys.confirmModalCloseButton'),
  deleteBody: t('admin:clientKeys.confirmationDeleteWarning'),
  createBody: t('admin:clientKeys.confirmationCreateWarning')
});

const ConfirmModal = props => {
  const { modalOpen, handleConfirm, setModalOpen, isDelete } = props;

  const text = useMemo(() => pageText(), []);

  return (
    <StyledConfirmationModal
      icon={isDelete ? <DeleteIcon /> : <KeyIcon />}
      open={modalOpen}
      title={isDelete ? text.deleteTitle : text.createTitle}
      onClose={() => setModalOpen(false)}
      onConfirm={() => {
        handleConfirm();
        setModalOpen(false);
      }}
      confirmButtonText={text.confirm}
      cancelButtonText={text.cancel}
    >
      <div className={classes.container}>
        <Typography>{isDelete ? text.deleteBody : text.createBody}</Typography>
      </div>
    </StyledConfirmationModal>
  );
};

export default flow()(ConfirmModal);
