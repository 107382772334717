import { useState, useEffect, useCallback, useRef } from 'react';
import {
  isEmpty,
  isEqual,
  sortedUniq,
  isUndefined,
  isFunction,
  first,
  debounce,
  mapValues,
  trim,
  forEach,
  cloneDeep
} from 'lodash';
import { t } from 'i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, CircularProgress, Typography, Alert } from '@mui/material';

import { channelTypes, facebookCreativeTypes } from 'src/common/adChannels';
import { isArrayEqual } from 'src/common/utilities';
import { hasCatalog, contentSelectable } from 'src/common/blueprints';
import { filterOutConditionalInputs } from 'src/common/conditionals';
import Message from 'src/components/Containers/Message';

import { styled } from '@mui/system';
import { isSafari } from 'src/Auth/common';
import { createAdPreviews as createAdPreviewsMutation } from './mutations';

import { getAdPreviewSetForPreviewProgramId } from './queries';
import CreateFacebookPreview from './FacebookAdPreview/CreateFacebookPreview';
import CreateGooglePreview from './GoogleAdPreview/CreateGooglePreview';
import CreateTiktokPreview from './TiktokAdPreview/CreateTiktokAdPreview';
import AdPreviewSkeleton from './AdPreviewSkeleton';
import {
  MAX_POLLING_LIMIT,
  MAX_POLLING_INTERVAL,
  POLLING_RATE
} from './Constants';
import ChannelTabs from './ChannelTabs';
import PhoneMockAdPreviewWrapper from './PhoneMockAdPreviewWrapper';

const pageText = () => ({
  pollingError: t('adPreview:pollingError'),
  createError: t('adPreview:createError'),
  iframeTitle: t('adPreview:iframeTitle'),
  adBlockerWarning: t('adPreview:adBlockerWarning')
});

const AdBlockerWarning = ({ text }) => (
  <Typography
    sx={{
      position: 'absolute',
      top: '50%',
      width: '100%',
      textAlign: 'center'
    }}
  >
    {text.adBlockerWarning}
  </Typography>
);

const PreviewIframe = styled('iframe')(() => ({
  border: 'none',
  // Specifically choose 0.94 to scale things cleanly.
  // Other sizes don't scale the content enough or will
  // cause weird transformation artifacts on some elements like icons.
  transform: 'scale(0.94)'
}));

/**
 * Some browsers / browser extensions prevent us from showing the
 * iframe preview.
 * Don't use the iframe if this returns true!
 * ⚠️ Heads up, if we send the right information then the ad preview will
 * return an iframe and NOTHING else, meaning we'd have to re-query that
 * information. Make sure to send the ad preview creation request correctly!
 */
const canShowIframePreview = () => {
  return !isSafari;
};

export const generateAdPreviewFromResponse = ({
  adPreviewSet,
  blueprint,
  facebookPageName,
  architectureHasCatalog,
  businessObjects,
  isAutomatedProgram,
  showMessaging,
  contentName,
  disableResponsiveStyles,
  hideGoogleAdNavigationButtons,
  hasUniformPreviewWidth,
  displayAsPhoneMockUp,
  isResponsive,
  architecture,
  showIncludedButton
}) => {
  const creativeType = adPreviewSet?.creativeType;
  const text = pageText();
  const currentChannel = adPreviewSet?.channel;

  const previewDAREAlert = (
    <>
      {creativeType === facebookCreativeTypes.fbDareCarousel ? (
        <Alert
          variant="outlined"
          severity="info"
          sx={{
            marginBottom: theme => theme.spacing(2),
            marginRight: theme => theme.spacing(2),
            marginLeft: theme => theme.spacing(2)
          }}
        >
          {t('adPreview:facebookAdPreview.dareAdDisclaimer')}
        </Alert>
      ) : null}
    </>
  );

  // In the case of an iframe present, use it no matter what.
  // We won't have other parts like the creativeType or creativeModel
  if (adPreviewSet?.iframe) {
    // Our iframe should technically be the only thing in our iframe string,
    // but... let's be safe and parse it out.
    const iframeEle = new DOMParser()
      .parseFromString(adPreviewSet?.iframe, 'text/html')
      .querySelector('iframe');

    let defaultIframeHeight;
    let defaultIframeWidth;
    switch (currentChannel) {
      case channelTypes?.tiktok:
        defaultIframeHeight = 480;
        defaultIframeWidth = 300;
        break;
      case channelTypes?.facebook:
      default:
        // Google does not currently have an iframe preview
        defaultIframeHeight = 730;
        defaultIframeWidth = 540;
        break;
    }

    return (
      <>
        {previewDAREAlert}
        <PreviewIframe
          title={text.iframeTitle}
          src={iframeEle.src}
          width={defaultIframeWidth}
          height={defaultIframeHeight}
        />
      </>
    );
  }

  // Facebook
  if (currentChannel === channelTypes?.facebook) {
    return (
      <>
        {previewDAREAlert}
        <CreateFacebookPreview
          architectureHasCatalog={architectureHasCatalog}
          blueprint={blueprint}
          businessObjects={businessObjects}
          creativeType={creativeType}
          adPreviewSet={adPreviewSet}
          facebookPageName={facebookPageName}
          isAutomatedProgram={isAutomatedProgram}
          showMessaging={showMessaging}
          contentName={contentName}
          disableResponsiveStyles={disableResponsiveStyles}
          hasUniformPreviewWidth={hasUniformPreviewWidth}
          displayAsPhoneMockUp={displayAsPhoneMockUp}
          isResponsive={isResponsive}
          architecture={architecture}
          showIncludedButton={showIncludedButton}
        />
      </>
    );
  }

  // Google
  if (currentChannel === channelTypes?.google) {
    return (
      <CreateGooglePreview
        blueprint={blueprint}
        creativeType={creativeType}
        adPreviewSet={adPreviewSet}
        showMessaging={showMessaging}
        hideGoogleAdNavigationButtons={hideGoogleAdNavigationButtons}
        hasUniformPreviewWidth={hasUniformPreviewWidth}
        displayAsPhoneMockUp={displayAsPhoneMockUp}
        isResponsive={isResponsive}
        businessObjects={businessObjects}
        architecture={architecture}
        showIncludedButton={showIncludedButton}
      />
    );
  }

  // Tiktok
  if (currentChannel === channelTypes?.tiktok) {
    return (
      <CreateTiktokPreview
        architectureHasCatalog={architectureHasCatalog}
        blueprint={blueprint}
        businessObjects={businessObjects}
        creativeType={creativeType}
        adPreviewSet={adPreviewSet}
        showMessaging={showMessaging}
        hasUniformPreviewWidth={hasUniformPreviewWidth}
        displayAsPhoneMockUp={displayAsPhoneMockUp}
        isResponsive={isResponsive}
        architecture={architecture}
        showIncludedButton={showIncludedButton}
      />
    );
  }

  return t('adPreview:messages.unknownChannel');
};

const AdPreview = props => {
  const {
    customNoBlueprintMessage,
    previewData,
    architecture = {},
    isAutomatedProgram,
    facebookPageName,
    customNoBusinessObjectMessage,
    blueprintsLoading,
    showMessaging = true,
    showIndicator = true,
    previewProgramId,
    setIsLoadingAd,
    contentName = 'items',
    hideChannelTabs = false,
    disableResponsiveStyles, // This disabled the responsive breakpoint at the sm breakpoint.
    hideGoogleAdNavigationButtons,
    hasUniformPreviewWidth, // This prop gives all ad previews and ad preview skeletons the same width in px. This is used so that when we transform => scale the ad previews for quick programs, they all fit into the phone mock consistently.
    displayAsPhoneMockUp,
    isResponsive, // This is used to determine if the ad previews are fluidly responsive. They will fill the width of the component wrapping this one.
    conditionalInputsVisibility,
    handleAdContentChannelValidation,
    setIsPollingPreview,
    isPollingPreview: isPollingPreviewExternal,
    setIsValidatingCreative,
    clearUpdatedInputCreativeErrors,
    showIncludedButton = false
  } = props;

  const {
    blueprint,
    dynamicUserInputs,
    businessObjects,
    selectedBusinessObjects
  } = previewData;
  const isContentSelectable = contentSelectable(architecture, blueprint);
  const [selectedPreviewTab, setSelectedPreviewTab] = useState(0);
  const [hasLoadedFirstPreview, setHasLoadedFirstPreview] = useState(false);

  const abortController = useRef(new AbortController());
  const timeoutID = useRef(null);

  const [createAdPreviews] = useMutation(createAdPreviewsMutation);

  // For some use cases, we need to raise isPolling/setIsPolling state up to the parent component
  // this logic allows us to switch between internal isPolling and external isPolling
  // based on whether or not an external setter/state is present
  const [internalIsPollingPreview, internalSetIsPollingPreview] =
    useState(false);
  const pollingPreview = isPollingPreviewExternal || internalIsPollingPreview;
  const handleSetPollingPreview = useCallback(
    isPolling => {
      if (isFunction(setIsLoadingAd)) {
        setIsLoadingAd(isPolling);
      }
      if (setIsPollingPreview && isFunction(setIsPollingPreview)) {
        setIsPollingPreview(isPolling);
      } else {
        internalSetIsPollingPreview(isPolling);
      }
    },
    [setIsLoadingAd, setIsPollingPreview]
  );

  const text = pageText();

  const architectureHasCatalog = hasCatalog(architecture, blueprint);

  // to ensure we render the right preview we need to remove the conditionally rendered inputs
  // so the preview data that is returned is accurate to what we expect to see
  const filteredDynamicUserInputs = conditionalInputsVisibility
    ? filterOutConditionalInputs(
        conditionalInputsVisibility,
        dynamicUserInputs || []
      )
    : cloneDeep(dynamicUserInputs);

  const [
    fetchGeneratedAdPreview,
    { loading: loadingAdPreviewSet, refetch: refetchAdPreviewSet }
  ] = useLazyQuery(getAdPreviewSetForPreviewProgramId, {
    context: { fetchOptions: { signal: abortController?.current?.signal } }
  });

  const [adPreviews, setAdPreviews] = useState();
  const [adPreviewError, setAdPreviewError] = useState(null);

  const abortPollingQuery = useCallback(() => {
    abortController.current.abort();
    abortController.current = new AbortController();
  }, [abortController]);

  const onStopPolling = useCallback(() => {
    if (timeoutID.current) {
      clearTimeout(timeoutID.current);
      timeoutID.current = null;
    }

    handleSetPollingPreview(false);

    if (loadingAdPreviewSet) {
      abortPollingQuery();
    }
  }, [handleSetPollingPreview, abortPollingQuery, loadingAdPreviewSet]);

  const onPollingError = useCallback(() => {
    if (isFunction(setIsValidatingCreative)) {
      setIsValidatingCreative(false);
    }
    onStopPolling();
    setAdPreviewError(text.pollingError);
  }, [onStopPolling, text.pollingError, setIsValidatingCreative]);

  const hasBlueprint = !isUndefined(blueprint?.id);
  const hasDynamicUserInputs = !!dynamicUserInputs;

  const [lastMutationData, setLastMutationData] = useState(null);

  const selectedBusinessObjectIds = businessObjects.map(businessObject => {
    return businessObject?.id;
  });

  let businessObjectsHaveChanged = false;

  if (
    isContentSelectable &&
    !selectedBusinessObjects?.loading &&
    hasLoadedFirstPreview &&
    lastMutationData &&
    !isArrayEqual(
      lastMutationData?.selectedBusinessObjectIds,
      selectedBusinessObjectIds
    )
  ) {
    businessObjectsHaveChanged = true;
  }

  const skeletonChannel = first(blueprint?.blueprint?.channels);

  const [haveInputsChanged, setHaveInputsChanged] = useState(false);

  const checkForAdPreviewCompletion = useCallback(
    async (previewProgramId, pollingNum = 1) => {
      try {
        const status =
          pollingNum === 1
            ? await fetchGeneratedAdPreview({
                variables: { previewProgramId }
              })
            : await refetchAdPreviewSet();
        const adPreview = status?.data?.getAdPreviewSetForPreviewProgramId;

        if (adPreview?.status === 'complete') {
          setAdPreviews(adPreview);
          if (isFunction(handleAdContentChannelValidation)) {
            handleAdContentChannelValidation(adPreview);
          }
          if (!hasLoadedFirstPreview) {
            setHasLoadedFirstPreview(true);
          }
          onStopPolling();
          return;
        }

        if (adPreview?.status === 'error' || pollingNum >= MAX_POLLING_LIMIT) {
          onPollingError();
        } else {
          const pollingRate = POLLING_RATE + pollingNum * (POLLING_RATE / 4);
          timeoutID.current = setTimeout(
            () => checkForAdPreviewCompletion(previewProgramId, pollingNum + 1),
            pollingRate > MAX_POLLING_INTERVAL
              ? MAX_POLLING_INTERVAL
              : pollingRate
          );
        }
      } catch (error) {
        onPollingError();
      }
    },
    [
      fetchGeneratedAdPreview,
      handleAdContentChannelValidation,
      hasLoadedFirstPreview,
      onPollingError,
      onStopPolling,
      refetchAdPreviewSet
    ]
  );

  const createUpdateAdPreviews = useCallback(async () => {
    // Check if the method is currently executing, in this case we need to avoid calling createAdPreviews

    handleSetPollingPreview(true);

    setAdPreviewError(null);

    // there is a case when using conditionals to change from image -> video this changes the preview type
    // so if you send a existingPreviewProgramId the preview will be incorrect
    const haveConditionalsInputsChanged = !isEqual(
      lastMutationData?.conditionalInputsVisibility,
      conditionalInputsVisibility
    );

    const mutationParams = {
      variableValues: filteredDynamicUserInputs,
      productId: blueprint?.id,
      // If orderId is present on the request then it will create an iframe preview (if possible)
      // we only want to do this if we can show the iframe preview.
      orderId: canShowIframePreview() ? previewData.orderId : undefined,
      ...(!isEmpty(selectedBusinessObjectIds) &&
        architectureHasCatalog && {
          catalogId: architecture?.catalog?.id
        }),
      ...(!isEmpty(businessObjects) &&
        architectureHasCatalog && {
          catalogFilter: {
            id: {
              in: selectedBusinessObjectIds
            }
          }
        }),
      ...(!businessObjectsHaveChanged &&
        previewProgramId &&
        !haveConditionalsInputsChanged && {
          existingPreviewProgramId: previewProgramId
        })
    };

    // set current state of inputs so we know what was used in the most recent call
    setLastMutationData({
      inputs: filteredDynamicUserInputs,
      selectedBusinessObjectIds,
      blueprintId: blueprint?.id,
      conditionalInputsVisibility
    });
    setHaveInputsChanged(false);

    // create / update ad preview
    try {
      const response = await createAdPreviews({
        variables: {
          input: mutationParams
        }
      });

      // Clear the previous timeout if it exists
      if (timeoutID.current) {
        clearTimeout(timeoutID.current);
      }

      setAdPreviewError(null);
      // trigger first polling check polling funciton will call itself for subsequent checks
      timeoutID.current = setTimeout(
        programId => checkForAdPreviewCompletion(programId),
        POLLING_RATE,
        response?.data?.createAdPreviews?.previewProgramId
      );
    } catch (error) {
      onPollingError();
      setAdPreviewError(text.createError);
    }
  }, [
    architecture?.catalog?.id,
    architectureHasCatalog,
    blueprint?.id,
    businessObjects,
    checkForAdPreviewCompletion,
    conditionalInputsVisibility,
    createAdPreviews,
    filteredDynamicUserInputs,
    handleSetPollingPreview,
    lastMutationData?.conditionalInputsVisibility,
    previewData.orderId,
    selectedBusinessObjectIds,
    text.createError
  ]);

  // if we are manually passing in ad creation data we can look for the previewProgramId passed in
  useEffect(() => {
    if (previewProgramId) {
      checkForAdPreviewCompletion(previewProgramId);
    }
  }, [checkForAdPreviewCompletion, handleSetPollingPreview, previewProgramId]);

  // on mount
  useEffect(() => {
    if (
      hasBlueprint &&
      hasDynamicUserInputs &&
      !previewProgramId &&
      !hasLoadedFirstPreview &&
      !selectedBusinessObjects?.loading &&
      !timeoutID.current
    ) {
      createUpdateAdPreviews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // !!! if you put a dependency on createUpdateAdPreviews it will cause an infinite loop
    hasBlueprint,
    hasDynamicUserInputs,
    hasLoadedFirstPreview,
    previewProgramId,
    selectedBusinessObjects?.loading
  ]);

  const bouncedInputsChanged = useRef(
    debounce(() => {
      setHaveInputsChanged(true);
    }, 1000)
  );
  const trimmedMutationData = mapValues(lastMutationData?.inputs, trim);
  const trimmedDynamicUserInputs = mapValues(filteredDynamicUserInputs, trim);

  // update if the inputs have changed in a debounced manner
  if (
    lastMutationData &&
    !isEqual(trimmedMutationData, trimmedDynamicUserInputs)
  ) {
    if (!haveInputsChanged) {
      if (isFunction(setIsValidatingCreative)) {
        // Disables the submit button while the preview is created and validations are run
        setIsValidatingCreative(true);
      }
      bouncedInputsChanged.current();
    }
  }

  if (
    !haveInputsChanged &&
    // hasLoadedFirstPreview &&
    businessObjectsHaveChanged
  ) {
    // just call the inputs have changed function same diff
    setHaveInputsChanged(true);
  }

  useEffect(() => {
    let updatedInputName;

    if (haveInputsChanged) {
      // Find name of input that changed
      forEach(trimmedMutationData, (value, key) => {
        if (value !== trimmedDynamicUserInputs[key]) {
          updatedInputName = key;
        }
      });

      // Clear the creative validation error for that input
      if (updatedInputName && isFunction(setIsValidatingCreative)) {
        clearUpdatedInputCreativeErrors(updatedInputName);
      }

      if (pollingPreview) {
        // this stops polling and this effect will still fire net render cycle
        onStopPolling();
      } else {
        createUpdateAdPreviews();
      }
    }
  }, [
    createUpdateAdPreviews,
    haveInputsChanged,
    onStopPolling,
    pollingPreview
  ]);

  // sorting to keep the order consistent
  const channelTabs = sortedUniq(
    adPreviews?.previews.map(preview => {
      return preview?.channel;
    })
  );

  const adPreviewSkeleton = (
    <AdPreviewSkeleton
      channel={skeletonChannel}
      disableResponsiveStyles={disableResponsiveStyles}
      hasUniformPreviewWidth={hasUniformPreviewWidth}
      isResponsive={isResponsive}
    />
  );

  const adPreviewSkeletonToShow = displayAsPhoneMockUp ? (
    <PhoneMockAdPreviewWrapper>{adPreviewSkeleton}</PhoneMockAdPreviewWrapper>
  ) : (
    adPreviewSkeleton
  );

  const adPreviewSet = adPreviews?.previews?.[selectedPreviewTab];

  const previewToShow =
    (hasBlueprint || previewProgramId) && adPreviews?.previews
      ? generateAdPreviewFromResponse({
          adPreviewSet,
          blueprint,
          facebookPageName,
          architectureHasCatalog,
          businessObjects,
          isAutomatedProgram,
          showMessaging,
          contentName,
          disableResponsiveStyles,
          hideGoogleAdNavigationButtons,
          hasUniformPreviewWidth,
          displayAsPhoneMockUp,
          isResponsive,
          architecture,
          showIncludedButton
        })
      : adPreviewSkeletonToShow;

  // If they don't have a blueprint selected but the customNoBlueprintMessage is set show it
  if (
    !blueprintsLoading &&
    !hasBlueprint &&
    customNoBlueprintMessage &&
    !pollingPreview
  ) {
    return customNoBlueprintMessage;
  }

  if (hasBlueprint && !blueprintsLoading && !pollingPreview && adPreviewError) {
    return (
      <Message
        sx={
          !displayAsPhoneMockUp && {
            margin: '2px auto 18px auto',
            whiteSpace: 'normal !important'
          }
        }
        iconSize={displayAsPhoneMockUp ? 'small' : 'medium'}
        type="error"
      >
        {adPreviewError}
      </Message>
    );
  }

  return (
    <Box
      sx={
        displayAsPhoneMockUp
          ? {
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 3
            }
          : { width: '100%' }
      }
    >
      {((!displayAsPhoneMockUp && !isEmpty(channelTabs)) ||
        (displayAsPhoneMockUp && channelTabs?.length > 1)) &&
        !hideChannelTabs && (
          <ChannelTabs
            showIndicator={showIndicator}
            channelTabs={channelTabs}
            handleTabChange={setSelectedPreviewTab}
            selectedTab={selectedPreviewTab}
            loading={pollingPreview}
          />
        )}
      {!displayAsPhoneMockUp && <br />}
      <Box
        sx={{
          margin: '0 auto',
          overflow: 'hidden',
          padding: 1,
          ...(isResponsive ? { width: '100%' } : {})
        }}
      >
        {customNoBusinessObjectMessage}
        {adPreviews?.previews ? (
          <Box
            sx={{
              position: 'relative',
              whiteSpace: 'normal',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              minHeight: '50px',
              // If we're about to display an iframe adjust our alignment
              // that way we can cleanly scale the frame
              ...(adPreviews?.previews?.[selectedPreviewTab]?.iframe
                ? { justifyContent: 'start', alignItems: 'start' }
                : {}),
              ...(displayAsPhoneMockUp ? { gap: 3 } : {})
            }}
          >
            {pollingPreview && (
              <Box
                component="span"
                sx={{
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  zIndex: theme => theme.zIndex.drawer + 1
                }}
              >
                <CircularProgress data-cy="ad-preview-spinner" />
              </Box>
            )}
            {/* Only show the ad blocker warning if there is an iframe present */}
            {adPreviewSet?.iframe && <AdBlockerWarning text={text} />}
            {previewToShow}
          </Box>
        ) : (
          adPreviewSkeletonToShow
        )}
      </Box>
    </Box>
  );
};

export default AdPreview;
