import { Box } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

interface HelpTextProps {
  helpText: string;
}

const HelpText = ({ helpText }: HelpTextProps) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        color: 'grey.700',
        display: 'flex',
        fontSize: '0.8rem',
        mb: 1
      }}
    >
      <InfoIcon sx={{ mr: 1 }} />
      {helpText}
    </Box>
  );
};

export default HelpText;
