import { Box } from '@mui/material';

interface InputValueTranslatedProps {
  value: string;
}

const InputValueTranslated = ({ value }: InputValueTranslatedProps) => {
  return (
    <Box
      sx={{
        color: 'grey.700',
        fontSize: '0.8rem',
        mt: 0.5,
        ml: 1,
        whiteSpace: 'normal'
      }}
    >
      <strong>{value}</strong>
    </Box>
  );
};

export default InputValueTranslated;
