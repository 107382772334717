import { keys, sortBy, startCase } from 'lodash';

import {
  MIN_IMG_HEIGHT,
  MIN_IMG_WIDTH,
  MEDIA_TYPE_FILTER_VALUES
} from 'src/pages/Gallery/constants';
import {
  INPUT_TYPES,
  InputType
} from 'src/components/ReduxForm/DynamicForm/constants';
import { getCountryOptions } from 'src/common/countries';
import {
  validateArrayMaxLength,
  validationOptions
} from 'src/common/validations';
import {
  getPlacesAutocomplete,
  validateSelectedTypes
} from 'src/components/ReduxForm/RenderAddressAutocomplete/constants';

const DISPLAY_PARAMETERS = {
  renderValues: 'renderValues',
  isAutocomplete: 'isAutocomplete',
  allowGallery: 'allowGallery',
  internalOnly: 'internalOnly',
  isMultiSelect: 'isMultiSelect',
  showOptionCheckbox: 'showOptionCheckbox',
  showIntegerSlider: 'showIntegerSlider',
  showIntegerSliderInput: 'showIntegerSliderInput',
  sizeConstraint: 'sizeConstraint',
  allowAiGeneration: 'allowAiGeneration',
  allowTagCreationViaWebhook: 'allowTagCreationViaWebhook',
  addressComponentRestrictions: 'addressComponentRestrictions',
  types: 'types',
  helperText: 'helperText',
  tooltip: 'tooltip',
  columnWidth: 'columnWidth',
  mediaType: 'mediaType',
  allowContentSelection: 'allowContentSelection',
  rows: 'rows',
  startAdornment: 'startAdornment',
  endAdornment: 'endAdornment',
  extraValidationRules: 'extraValidationRules',
  actionButtonText: 'actionButtonText',
  datePickerProps: 'datePickerProps',
  orgCatalogSlug: 'orgCatalogSlug',
  valueColumn: 'valueColumn',
  friendlyNameColumn: 'friendlyNameColumn',
  children: 'children',
  options: 'options',
  filtering: 'filtering',
  showAdAccountSelector: 'showAdAccountSelector',
  eligibleForAiGenerationChatSupport: 'eligibleForAiGenerationChatSupport',
  value: 'value'
} as const;

type DisplayParameter =
  (typeof DISPLAY_PARAMETERS)[keyof typeof DISPLAY_PARAMETERS];

const genericDisplayParameterFields = [
  DISPLAY_PARAMETERS.tooltip,
  DISPLAY_PARAMETERS.columnWidth,
  DISPLAY_PARAMETERS.helperText,
  DISPLAY_PARAMETERS.extraValidationRules
];

const inputDisplayParamterFields = {
  [INPUT_TYPES.ADDRESS]: [
    DISPLAY_PARAMETERS.isMultiSelect,
    DISPLAY_PARAMETERS.addressComponentRestrictions,
    DISPLAY_PARAMETERS.types,
    DISPLAY_PARAMETERS.children
  ],
  [INPUT_TYPES.GEO_TARGETING_ADDRESS]: [
    DISPLAY_PARAMETERS.isMultiSelect,
    DISPLAY_PARAMETERS.addressComponentRestrictions,
    // DISPLAY_PARAMETERS.types,
    DISPLAY_PARAMETERS.children
  ],
  [INPUT_TYPES.ANY_NUMBER]: [
    DISPLAY_PARAMETERS.showIntegerSlider,
    DISPLAY_PARAMETERS.showIntegerSliderInput
  ],
  [INPUT_TYPES.CUSTOM_LINKS]: [DISPLAY_PARAMETERS.actionButtonText],
  [INPUT_TYPES.DATE_UTC]: [DISPLAY_PARAMETERS.datePickerProps],
  [INPUT_TYPES.FB_AD_ACCOUNT_ID]: [DISPLAY_PARAMETERS.showAdAccountSelector],
  [INPUT_TYPES.GALLERY_IMAGE_URL]: [
    DISPLAY_PARAMETERS.internalOnly,
    DISPLAY_PARAMETERS.sizeConstraint,
    DISPLAY_PARAMETERS.isMultiSelect
  ],
  [INPUT_TYPES.GALLERY_VIDEO_URL]: [
    DISPLAY_PARAMETERS.internalOnly,
    DISPLAY_PARAMETERS.sizeConstraint,
    DISPLAY_PARAMETERS.isMultiSelect
  ],
  [INPUT_TYPES.IMAGE_URL]: [
    DISPLAY_PARAMETERS.allowGallery,
    DISPLAY_PARAMETERS.sizeConstraint,
    DISPLAY_PARAMETERS.isMultiSelect
  ],
  [INPUT_TYPES.INT_SLIDER]: [
    DISPLAY_PARAMETERS.showIntegerSlider,
    DISPLAY_PARAMETERS.showIntegerSliderInput
  ],
  [INPUT_TYPES.MEDIA_ASSET]: [
    DISPLAY_PARAMETERS.allowGallery,
    DISPLAY_PARAMETERS.internalOnly,
    DISPLAY_PARAMETERS.sizeConstraint,
    DISPLAY_PARAMETERS.mediaType,
    DISPLAY_PARAMETERS.allowContentSelection,
    DISPLAY_PARAMETERS.isMultiSelect
  ],
  [INPUT_TYPES.MULTI_LINE_STRING]: [
    DISPLAY_PARAMETERS.allowAiGeneration,
    DISPLAY_PARAMETERS.rows,
    DISPLAY_PARAMETERS.startAdornment,
    DISPLAY_PARAMETERS.endAdornment,
    DISPLAY_PARAMETERS.eligibleForAiGenerationChatSupport
  ],
  [INPUT_TYPES.ORG_CONTENT_SELECTOR]: [
    DISPLAY_PARAMETERS.isMultiSelect,
    DISPLAY_PARAMETERS.orgCatalogSlug,
    DISPLAY_PARAMETERS.valueColumn,
    DISPLAY_PARAMETERS.friendlyNameColumn,
    DISPLAY_PARAMETERS.options
  ],
  [INPUT_TYPES.ORG_TAG_SELECTOR]: [
    DISPLAY_PARAMETERS.isMultiSelect,
    DISPLAY_PARAMETERS.orgCatalogSlug,
    DISPLAY_PARAMETERS.valueColumn,
    DISPLAY_PARAMETERS.friendlyNameColumn,
    DISPLAY_PARAMETERS.options,
    DISPLAY_PARAMETERS.allowTagCreationViaWebhook
  ],
  [INPUT_TYPES.POSITIVE_INT]: [
    DISPLAY_PARAMETERS.showIntegerSlider,
    DISPLAY_PARAMETERS.showIntegerSliderInput
  ],
  [INPUT_TYPES.POSITIVE_NUM]: [
    DISPLAY_PARAMETERS.showIntegerSlider,
    DISPLAY_PARAMETERS.showIntegerSliderInput
  ],
  [INPUT_TYPES.RADIO_SELECT]: [
    DISPLAY_PARAMETERS.renderValues,
    DISPLAY_PARAMETERS.options
  ],
  [INPUT_TYPES.SINGLE_LINE_STRING]: [
    DISPLAY_PARAMETERS.allowAiGeneration,
    DISPLAY_PARAMETERS.startAdornment,
    DISPLAY_PARAMETERS.endAdornment,
    DISPLAY_PARAMETERS.eligibleForAiGenerationChatSupport
  ],
  [INPUT_TYPES.SINGLE_SELECT]: [
    DISPLAY_PARAMETERS.isAutocomplete,
    DISPLAY_PARAMETERS.isMultiSelect,
    DISPLAY_PARAMETERS.showOptionCheckbox,
    DISPLAY_PARAMETERS.options
  ],
  [INPUT_TYPES.VIDEO_URL]: [DISPLAY_PARAMETERS.allowGallery],
  [INPUT_TYPES.ZIP]: [
    DISPLAY_PARAMETERS.isMultiSelect,
    DISPLAY_PARAMETERS.addressComponentRestrictions,
    DISPLAY_PARAMETERS.children
  ],
  [INPUT_TYPES.WORKATO_CONNECTION_LEGACY]: [DISPLAY_PARAMETERS.isMultiSelect],
  [INPUT_TYPES.WORKATO_CONNECTION]: [DISPLAY_PARAMETERS.isMultiSelect],
  [INPUT_TYPES.CUSTOM_HTML]: [DISPLAY_PARAMETERS.value]
};

type DisplayParamterInputType = keyof typeof inputDisplayParamterFields;

const getInputDisplayParameterFields = (
  inputType: InputType
): DisplayParameter[] => {
  const inputSpecificFields =
    inputDisplayParamterFields[inputType as DisplayParamterInputType] || [];
  return [...genericDisplayParameterFields, ...inputSpecificFields];
};

const displayParameterInputConfigs = [
  {
    name: DISPLAY_PARAMETERS.renderValues,
    displayName: 'Render Values',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Render the value below the label on radio selects'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.isAutocomplete,
    displayName: 'Use Filterable Dropdown',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'Only applicable for SINGLE_SELECT type inputs. Renders an advanced dropdown that includes extra features such as filtering, but otherwise behaves similar to a regular dropdown.'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.allowGallery,
    displayName: 'Allow Media Library',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Allows custom image upload on image select component'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.internalOnly,
    displayName: 'Internal Only',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,

    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Sets gallery to internal only'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.isMultiSelect,
    displayName: 'Multi Select',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,

    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Allow multiple selections in select input and zip code input'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.showOptionCheckbox,
    displayName: 'Show Select Checkbox',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Show checkboxes next to the select options in the dropdown.'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.showIntegerSlider,
    displayName: 'Show Slider',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Show slider on the integer slider'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.showIntegerSliderInput,
    displayName: 'Show Slider Input',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Show input on the integer slider'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.sizeConstraint,
    displayName: 'Image Size Validation',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: `When active we set a default image size validation of ${MIN_IMG_HEIGHT}px x ${MIN_IMG_WIDTH}px.`
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.allowAiGeneration,
    displayName: 'Allow AI Generation Within Input (Gen AI V2)',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'Whether or not to allow AI text generation for this input. Remember, this AND the org feature must ' +
          'be enabled in order to see AI suggestion buttons. Currently only functions for text inputs. See ' +
          'allowAiGeneratedContent in the feature settings.'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.eligibleForAiGenerationChatSupport,
    displayName: 'Eligible For AI Copywriter Chat Window (Gen AI V3)',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'When turned off, this input will not be included in the AI Copywriter chat window. ' +
          'This is useful for inputs that are not meant to be used for AI copywriting. ' +
          'When true, the input is eligible for AI copywriting and may or may not appear in the AI Copywriter chat window based on its configuration.'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.allowTagCreationViaWebhook,
    displayName: 'Allow Tag Creation Via Webhook',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'Allows users to create lead tags if the tag they are searching for is not found in the autocomplete dropdown. ' +
          'The only orgs that support this currently are Keller Williams and the Evocalize staging org.'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.addressComponentRestrictions,
    displayName: 'Address Component Restrictions',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    reduxValidations: [validateArrayMaxLength(5)],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip: 'Select a up to 5 countries to restric address autocomplete.',
        isMultiSelect: true,
        options: getCountryOptions(),
        isAutocomplete: true
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.types,
    displayName: 'Address Types',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    reduxValidations: [validateArrayMaxLength(5), validateSelectedTypes],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip:
          'Select a up to 5 address types to restric address autocomplete.',
        isMultiSelect: true,
        options: getPlacesAutocomplete(),
        isAutocomplete: true
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.helperText,
    displayName: 'Helper Text',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip: 'Text that shows below the input'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.tooltip,
    displayName: 'Tooltip',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip: 'Text that shows in the tooltip'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.columnWidth,
    displayName: 'Column Width',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip: 'Sets the width of the input can be 4,3,2,1'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.mediaType,
    displayName: 'Media Library Filter',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: MEDIA_TYPE_FILTER_VALUES.all,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'This will restrict the new media asset selector gallery to a type it defaults to All',
        options: keys(MEDIA_TYPE_FILTER_VALUES).map(key => {
          return {
            name: startCase(key),
            value:
              MEDIA_TYPE_FILTER_VALUES[
                key as keyof typeof MEDIA_TYPE_FILTER_VALUES
              ]
          };
        })
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.allowContentSelection,
    displayName: 'Media Library Allow Content Selection',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'This will allow content to be selected with the new media asset selector'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.rows,
    displayName: 'Rows',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip: 'How many lines show in the multi line string'
      }
    }
  },

  {
    name: DISPLAY_PARAMETERS.startAdornment,
    displayName: 'Start Adornment',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip: 'Adds a label to the beginning of the input (ex: YourWebsite/)'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.endAdornment,
    displayName: 'End Adornment',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip: 'Adds a label to the end of the input (ex: miles)'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.extraValidationRules,
    displayName: 'Extra Validation Rule',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: [],
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip: 'Adds extra validations (ex: ["NOT_URL"])',
        options: sortBy(validationOptions, ['name']),
        isMultiSelect: true,
        showOptionCheckbox: true
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.actionButtonText,
    displayName: 'Action Button Text',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip:
          'Text that shows up in component button currently used for custom_link'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.datePickerProps,
    displayName: 'Date Picker Props',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip:
          'Custom props for datepicker component (https://material-ui-pickers.dev/api/DatePicker)'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.orgCatalogSlug,
    displayName: 'Org Catalog Slug',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'Only applicable for org_content_selector inputs!\n' +
          'Selects the catalog for the content selector. This should ' +
          'be equal to the slug of a catalog. The catalog does not need ' +
          'to be linked to this blueprint to work.'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.valueColumn,
    displayName: 'Value Column',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 1,
        tooltip:
          'Only applicable for org_content_selector inputs!\n' +
          'Selects which column in the content will be used as the value when ' +
          'saving a selection. In other words, this is the "hidden" value that ' +
          'will be sent to the backend.'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.friendlyNameColumn,
    displayName: 'Friendly Name Column',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 1,
        tooltip:
          'Only applicable for org_content_selector inputs!\n' +
          'Selects which column in the content will be used as the visible text ' +
          'when selecting something from the content. In other words this is ' +
          'what column will be put into the label for each option in the dropdown.'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.value,
    displayName: 'Value (text or html)',
    displayMethodId: INPUT_TYPES.MULTI_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip:
          'This is for an input type that takes a value. In the case of custom text or html input this is where the text or html goes'
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.children,
    displayName: 'Children',
    displayMethodId: null,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,
    isMultiInput: true,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip: 'Props used for addressFields',
        groupInputs: [
          {
            name: 'name',
            displayName: 'Name',
            displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
            initialValue: '',
            reduxValidations: [],
            isRequired: false,
            isHidden: false,
            displaySortOrder: 1
          }
        ]
      }
    }
  },
  {
    name: DISPLAY_PARAMETERS.options,
    displayName: 'Option',
    displayMethodId: null,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,
    isMultiInput: true,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip: 'Select and radio options'
      }
    },
    childInputs: [
      {
        name: 'name',
        displayName: 'Name',
        displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
        initialValue: '',
        reduxValidations: [],
        isRequired: false,
        isHidden: false,
        displaySortOrder: 1
      },
      {
        name: 'value',
        displayName: 'Value',
        displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
        initialValue: '',
        reduxValidations: [],
        isRequired: false,
        isHidden: false,
        displaySortOrder: 1
      },
      {
        name: 'metadata.filtering.li_group_role.include',
        displayName: 'group roles allowed (blank for all)',
        displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
        initialValue: null,
        reduxValidations: [],
        isMultiInput: true,
        isRequired: false,
        isHidden: false,
        displaySortOrder: 1,
        displayParameters: {
          inputData: {
            columnWidth: 4,
            tooltip: 'roles that can see this option'
          }
        }
      }
    ]
  },
  // we dynamically populate fitlering based on if :point-up: li_group_role has values
  {
    name: DISPLAY_PARAMETERS.filtering,
    displayName: 'Filtering',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: null,
    reduxValidations: [],
    isHidden: true,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  }
];

export const displayParametersInputs = (displayMethodId: InputType) => {
  const displayParametersForDisplayMethodId = new Set(
    getInputDisplayParameterFields(displayMethodId)
  );

  return displayParameterInputConfigs.filter(inputConfig =>
    displayParametersForDisplayMethodId.has(inputConfig.name)
  );
};

export const displayParameterConditionalRender = (
  variableOptions: { name: string; value: string }[]
) => {
  return [
    {
      name: 'conditionalRender',
      displayName: 'Conditional Render',
      displayMethodId: null,
      initialValue: false,
      reduxValidations: [],
      isHidden: false,
      isMultiInput: true,
      displayParameters: {
        inputData: {
          columnWidth: 4
        }
      },
      childInputs: [
        {
          name: 'name',
          displayName: 'Name',
          displayMethodId: INPUT_TYPES.SINGLE_SELECT,
          initialValue: '',
          reduxValidations: [],
          isRequired: false,
          isHidden: false,
          displayParameters: {
            inputData: {
              options: [
                {
                  name: '** Set condition on this input **',
                  value: 'evSpecialInputConditional'
                },
                ...variableOptions
              ],
              tooltip: 'What input are we checking for a value?'
            }
          }
        },
        {
          name: 'value',
          displayName: 'Value',
          displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
          initialValue: '',
          reduxValidations: [],
          isRequired: false,
          isHidden: false,
          displayParameters: {
            inputData: {
              tooltip:
                'What is the value we are looking for to conditionally rendering this input?'
            }
          }
        }
      ]
    }
  ];
};

export const legacyDisplayParameterConditionalRender = [
  {
    name: 'conditionalRender.name',
    displayName: 'Conditional Render Name',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip: 'What input name are we checking the value?'
      }
    }
  },
  {
    name: 'conditionalRender.value',
    displayName: 'Conditional Render Value',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        tooltip:
          'What is the value we are looking for to conditionally rendering?'
      }
    }
  }
];
