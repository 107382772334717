import { useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import {
  reduxForm,
  FormSection,
  SubmissionError,
  getFormValues
} from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';

import { Typography, Button, CircularProgress } from '@mui/material';
import { withAppSettings } from 'src/AppSettings';

import Logger from 'src/common/Logger';

import { DynamicForm } from 'src/components/ReduxForm';
import ErrorMessage from 'src/components/Containers/ErrorMessage';

import {
  FACEBOOK_PAGE_LINK_BUTTON_FORM_NAME,
  facebookPageLinkButtonSettings,
  facebookPageLinkOverrideDashboard
} from './Constants';
import { createOrUpdateSkinSettings } from './mutations';

const PREFIX = 'FacebookPageLinkButtons';

const classes = {
  cardHeading: `${PREFIX}-cardHeading`,
  cardSubheading: `${PREFIX}-cardSubheading`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  errorContainer: `${PREFIX}-errorContainer`
};

const Root = styled('form')(({ theme }) => ({
  [`& .${classes.cardHeading}`]: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },

  [`& .${classes.cardSubheading}`]: {
    fontWeight: 'normal',
    fontSize: '14px',
    marginBottom: theme.spacing(4)
  },

  [`& .${classes.buttonContainer}`]: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  },

  [`& .${classes.errorContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    width: '100%'
  }
}));

const pageText = () => ({
  cardHeading: t('admin:facebookPageLinkButtons.cardHeading'),
  subHeading: t('admin:facebookPageLinkButtons.subHeading'),
  submitButton: t('admin:facebookPageLinkButtons.submitButton'),
  errorSnackMessage: t('admin:facebookPageLinkButtons.errorSnack'),
  errorMessage: t('admin:facebookPageLinkButtons.errorMessage')
});

const FacebookPageLinkButtons = props => {
  const {
    handleSubmit,
    dirty,
    submitting,
    createOrUpdateSkinSettings,
    submitFailed,
    overrideBackToDashboard = false
  } = props;
  const text = useMemo(() => pageText(), []);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(async data => {
    const mutationParams = {
      updateSettings: data,
      updateType: 'OVERWRITE'
    };

    return createOrUpdateSkinSettings({
      variables: {
        ...mutationParams
      }
    })
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        const message = text.errorSnackMessage;

        enqueueSnackbar(message, {
          variant: 'error'
        });

        Logger.error(
          'Error publishing skin settings FacebookPageLinkButtons',
          error
        );
        throw new SubmissionError(error);
      });
  });

  return (
    <Root autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Typography component="h2" className={classes.cardHeading}>
        {text.cardHeading}
      </Typography>
      <Typography variant="subtitle2" className={classes.cardSubheading}>
        {text.subHeading}
      </Typography>
      {submitFailed && (
        <div className={classes.errorContainer}>
          <ErrorMessage>{text.errorMessage}</ErrorMessage>
        </div>
      )}
      <FormSection name="evApp.facebook">
        <DynamicForm inputs={facebookPageLinkOverrideDashboard()} />
        {overrideBackToDashboard && (
          <DynamicForm inputs={facebookPageLinkButtonSettings()} />
        )}
      </FormSection>
      <br />
      <Button
        color="primary"
        disabled={!dirty || submitting}
        type="submit"
        variant="contained"
        endIcon={submitting && <CircularProgress size={15} />}
      >
        {text.submitButton}
      </Button>
    </Root>
  );
};

const mapStateToProps = (state, { appSettings }) => {
  const formValues = getFormValues(FACEBOOK_PAGE_LINK_BUTTON_FORM_NAME)(state);

  const officeTheme = appSettings?.rawSkinSettings || {};

  const initialValues = officeTheme;

  return {
    initialValues,
    overrideBackToDashboard:
      formValues?.evApp?.facebook?.overrideBackToDashboard || false
  };
};

export default flow(
  reduxForm({
    form: FACEBOOK_PAGE_LINK_BUTTON_FORM_NAME,
    destroyOnUnmount: true
  }),
  connect(mapStateToProps),
  graphql(createOrUpdateSkinSettings, {
    name: 'createOrUpdateSkinSettings'
  }),

  withAppSettings
)(FacebookPageLinkButtons);
