import { Step, StepLabel, Stepper, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ProgramStep from './ProgramStep';
import useProgram from '../utils/useProgram';
import { DRAWER_FULL_SCREEN_BREAKPOINT } from '../ProgramPreviewDrawer/constants';

// TODO: Replace with actual props and types
interface ProgramStepperProps {
  [any: string]: any;
}

const ProgramStepper = ({
  isMobile,
  programStepProps,
  handleStepChange
}: ProgramStepperProps) => {
  const theme = useTheme();
  const {
    programStepper: { currentStep, steps, stepRefs }
  } = useProgram();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(6)
      }}
    >
      <Stepper
        sx={{
          position: 'relative',
          background: 'inherit',
          padding: `0 ${theme.spacing(2)}`,
          maxWidth: '100%',
          minWidth: '500px',

          display: 'flex',
          justifyContent: 'space-between',

          [theme.breakpoints.down('md')]: {
            width: '100%'
          }
        }}
        orientation="horizontal"
        nonLinear
        activeStep={currentStep}
        {...(isMobile ? { connector: null } : {})}
      >
        {steps.map((step, i) => {
          return (
            <Step
              completed={currentStep > i}
              key={`createProgramStep-${step.label}`}
              id={`program-step-${i}`}
              ref={stepRefs[i]}
            >
              <StepLabel
                error={step.error}
                onClick={() => handleStepChange(i)}
                sx={{
                  cursor: 'pointer',
                  textTransform: 'uppercase',
                  [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    '& .MuiStepLabel-labelContainer': {
                      padding: 0
                    }
                  },
                  '& .MuiStepLabel-iconContainer': {
                    [theme.breakpoints.down('sm')]: {
                      paddingRight: 0,
                      paddingBottom: theme.spacing(1)
                    }
                  }
                }}
              >
                {step.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box
        sx={{
          minHeight: '650px',
          padding: 0,
          marginTop: 0,
          [theme.breakpoints.between(0, DRAWER_FULL_SCREEN_BREAKPOINT)]: {
            marginRight: 0
          },

          [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(0, 0)
          }
        }}
      >
        <ProgramStep
          component={steps[currentStep].component}
          {...programStepProps}
        />
      </Box>
    </Box>
  );
};

export default ProgramStepper;
