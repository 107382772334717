import { useState, useMemo, useCallback } from 'react';
import { values } from 'lodash';
import { FormSection, Field } from 'redux-form';
import { useQuery } from '@apollo/client';
import { t } from 'i18next';

import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Box
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { DynamicForm } from 'src/components/ReduxForm';
import { useSnackbar } from 'notistack';
import {
  getInitialValuesFromInputsConfig,
  configureInputs
} from 'src/components/ReduxForm/helpers';
import RenderOrgWebhookSwitch from './RenderOrgWebhookSwitch';
import { mapEnumeratedValues } from '../helpers';
import { getEnumeratedValues } from '../queries';
import { getVariablesInputs } from '../Constants';

const newVariable = {
  ...getInitialValuesFromInputsConfig(getVariablesInputs()),
  array: null
};

const enumInputs = {
  type: 'BLUEPRINT_VARIABLE_TYPES'
};

const pageText = ({ friendlyName }) => ({
  addButton: t('admin:blueprintBuilder.stepVariablesAddButton'),
  removeButton: t('admin:blueprintBuilder.stepVariablesRemoveButton'),
  undoButton: t('admin:blueprintBuilder.stepVariablesUndoButton'),
  removedSuccessMessage: t(
    'admin:blueprintBuilder.stepVariablesRemoveSuccessSnackMessage',
    {
      friendlyName
    }
  ),
  emptySelectionHeading: t(
    'admin:blueprintBuilder.stepVariablesEmptySelectionHeading'
  )
});

const RenderVariablesInputs = props => {
  const { fields, inModal } = props;
  const [selectedVariable, setSelectedVariable] = useState(null);
  const variablesInputs = getVariablesInputs();
  const { enqueueSnackbar } = useSnackbar();

  const { data: queryData } = useQuery(getEnumeratedValues, {
    variables: {
      request: { enumerations: values(enumInputs) }
    }
  });

  const { enumerationValues } = mapEnumeratedValues({
    getEnumeratedValues: queryData
  });

  const text = useMemo(
    () =>
      pageText({
        friendlyName:
          selectedVariable && fields.get(selectedVariable.index)?.friendlyName
      }),
    [selectedVariable, fields]
  );

  const updatedVariablesInputsUpdated = useMemo(() => {
    return configureInputs({
      inputs: variablesInputs,
      enumInputs,
      enumerationValues
    });
  }, [variablesInputs, enumerationValues]);

  const addNewVariable = useCallback(() => {
    fields.unshift(newVariable);
    setSelectedVariable({
      field: `${fields.name}[0]`,
      index: 0
    });
  }, [fields]);

  const removeVariable = useCallback(() => {
    const removedVariableData = fields.get(selectedVariable.index);
    const removedVariableSelect = selectedVariable;
    fields.remove(selectedVariable.index);
    setSelectedVariable(null);

    enqueueSnackbar(text.removedSuccessMessage, {
      variant: 'success',
      persist: false,
      action: (
        <>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              fields.push(removedVariableData);
              setSelectedVariable(removedVariableSelect);
            }}
          >
            {text.undoButton}
          </Button>
        </>
      )
    });
  }, [selectedVariable, fields]);

  return (
    <Box>
      <ListItem
        button
        onClick={addNewVariable}
        sx={theme => ({
          background: 'white',
          position: 'absolute',
          width: '240px',
          left: 0,
          zIndex: 1,
          '&:hover': {
            background: theme.palette.grey[100]
          }
        })}
      >
        <ListItemText
          sx={{
            '& span': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }
          }}
          primary={
            <>
              {text.addButton} <AddIcon />
            </>
          }
        />
      </ListItem>
      <Box
        sx={theme => ({
          display: 'flex',
          marginTop: theme.spacing(2),
          height: inModal ? '600px' : '770px',
          overflow: 'hidden'
        })}
      >
        <List
          sx={theme => ({
            borderRight: `1px solid ${theme.palette.grey[300]}`,
            height: '100%',
            overflowY: 'scroll',
            minWidth: '250px',
            paddingTop: '50px'
          })}
        >
          {fields.map((field, index) => {
            return (
              <ListItem
                key={field}
                button
                selected={selectedVariable?.field === field}
                onClick={() =>
                  selectedVariable?.field === field
                    ? setSelectedVariable(null)
                    : setSelectedVariable({ field, index })
                }
              >
                <ListItemText
                  primary={fields.get(index)?.friendlyName}
                  secondary={
                    <>
                      name: <strong>{fields.get(index)?.name}</strong>
                      <br />
                      type: <strong>{fields.get(index)?.type}</strong>
                    </>
                  }
                />
              </ListItem>
            );
          })}
        </List>
        {selectedVariable?.field ? (
          <Box
            sx={{
              marginLeft: '12px',
              height: '100%',
              overflowY: 'scroll'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%'
              }}
            >
              <IconButton
                onClick={() => setSelectedVariable(null)}
                size="small"
                color="primary"
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider sx={theme => ({ margin: theme.spacing(2, 0) })} />

            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                sx={theme => ({
                  mb: 2,
                  color: theme.palette.error.main,
                  borderColor: theme.palette.error.main,
                  '&:hover': {
                    color: theme.palette.error.main,
                    borderColor: theme.palette.error.main
                  }
                })}
                variant="outlined"
                onClick={removeVariable}
                startIcon={<RemoveIcon />}
              >
                {text.removeButton}
              </Button>
            </Box>

            <FormSection name={selectedVariable?.field}>
              <DynamicForm inputs={updatedVariablesInputsUpdated} />
              <Box mt={2}>
                <Field
                  name="includeInWebhooks"
                  component={RenderOrgWebhookSwitch}
                  label="Include In Webhooks"
                  fullWidth
                />
              </Box>
            </FormSection>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: theme => theme.spacing(2),
              width: '100%'
            }}
          >
            <Typography variant="h6" align="center">
              {text.emptySelectionHeading}
            </Typography>
            <br />
            <Button
              color="primary"
              onClick={addNewVariable}
              startIcon={<AddIcon />}
            >
              {text.addButton}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RenderVariablesInputs;
