import { noop } from 'lodash';
import { red } from '@mui/material/colors';
import { Box, Button } from '@mui/material';

import Instrumentation from 'src/instrumentation';
import { Trans } from 'react-i18next';
import { WrappedFieldProps } from 'redux-form';
import { GALLERY_TYPE } from './constants';
import TileWrapper from './Tiles/TileWrapper';
import { MediaAssetSelection } from './GalleryModal';

export interface GalleryModalProps {
  onClick: () => void;
  selection: MediaAssetSelection;
  galleryType: string;
  name: string;
  disabled: boolean;
  meta: WrappedFieldProps['meta'];
}

const GalleryModalButton = (props: GalleryModalProps) => {
  const {
    onClick: handleModalOpen,
    selection,
    galleryType,
    meta: { error, touched },
    name,
    disabled
  } = props;
  const selectionType =
    galleryType === GALLERY_TYPE.media ? (selection as any)?.type : galleryType; // using galleryType for asset type is legacy

  const triggerLogEvent = () => {
    const selectionIsArray = Array.isArray(selection);
    if (typeof selection === 'string' || selectionIsArray) {
      const eventMeta = {
        hasContent: !!selection,
        contentCount: selectionIsArray ? selection.length : +!!selection
      };

      Instrumentation.logEvent(
        Instrumentation.Events.MediaLibrarySelectFromGallery,
        eventMeta
      );
    }
  };

  const inputInError = error && touched;

  return (
    <div>
      <Button
        disabled={disabled}
        data-cy={`gallery-modal-button-${name.replace('.', '-')}`}
        color="primary"
        variant="outlined"
        sx={{
          width: '100%',
          minHeight: '56px', // input height
          ...(inputInError && { borderColor: red[500], color: red[500] }),
          '& .MuiButton-label': {
            display: 'flex',
            flexDirection: 'column'
          }
        }}
        onClick={() => {
          triggerLogEvent();
          handleModalOpen();
        }}
      >
        {selection && (
          <Box
            sx={{
              width: '100%',
              '& img': {
                maxHeight: '100px'
              }
            }}
          >
            <TileWrapper
              asset={selection}
              galleryType={galleryType}
              onClick={noop}
              type={selectionType}
              showThumbnail={galleryType === GALLERY_TYPE.video}
            />
          </Box>
        )}
        {selection && galleryType === GALLERY_TYPE.video ? null : (
          <Trans i18nKey="gallery:button.label" />
        )}
      </Button>
    </div>
  );
};

export default GalleryModalButton;
