import { debounce, isString, find, isEqual } from 'lodash';
import { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import numeral from 'numeral';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useFormContext, useWatch } from 'react-hook-form';

import { Grid, Typography, useMediaQuery, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { facebookCreativeTypes } from 'src/common/adChannels';
import { getTemplateData } from 'src/common/blueprints';
import {
  formatDailySpend,
  formatPriceWithOptionalCents
} from 'src/common/numbers';
import { dayjs } from 'src/common/dates';
import { useExperiment, EXPERIMENT_NAMES } from 'src/experiments';

import {
  scheduleTypes,
  PROGRAM_FORM_SECTION_SPEND_NAME
} from 'src/pages/Program/Constants';

import RenderTemplateField from 'src/components/ReduxForm/RenderTemplateField';
import { RenderSlider } from 'src/components/ReduxForm';
import Loading from 'src/components/Loading';
import HookFormWrapper from 'src/components/ReduxForm/DynamicForm/HookFormWrapper';

import SubscriptionSelector from './SubscriptionSelector';
import {
  computeMinimumSpend,
  computeResultsEstimation as computeResultsEstimationQuery
} from './queries';
import { getMinSpendInput, validateSpend } from './utilites';
import OneTimeSpendMessage from './OneTimeSpendMessage';
import ResultsEstimation from './ResultsEstimation';
import { SPEND_VALIDATION_MESSAGES } from './constants';

/*
  ! Attention: The use of `!important` seems rampant in this component. This is because
  ! of the need to override Grid item padding discretely, so that the padding added by the `spacing`
  ! prop in the container only adds space between columns and rows and not add spacing
  ! (padding) on the perimeter of the left and top-most Grid items.

  When this component is cleaned up, post-experiemnt, I think it could make sense to convert
  the use of the Mui Grid system to Box with flex styling. I chose to stick with Grid because
  it's what the control treatment is using.
*/

// TODO: I just made these numbers up
// these are just fallbacks in case the min and max is not defined
// prices are in dollars
const MIN_PRICE = 25;
const MAX_PRICE = 15000;
const PRICE_STEPS = 25;
// rough calculation of our default slider steps on this page
// may change drastically but just used for dividing the rounding nicely
const NUM_OF_SLIDER_STEPS = 300;

const getText = () => ({
  spendError: t('programCreate:spend.spendFieldError'),
  budgetLabel: t('programCreate:spend.budgetLabel'),
  budgetLabelMultiLocation: t('programCreate:spend.budgetLabelMultiLocation'),
  editSpendHeader: t('programEdit:spend.editHeader'),

  invalidEndDate: t('programEdit:spend.invalidBudgetIncreaseEndDate'),
  subscriptionPendingChangeDisabled: t(
    'programEdit:spend.subscriptionPendingTip'
  ),
  purchasePendingChangeDisabled: t('programEdit:spend.purchasePendingTip')
});

const SpendSelector = props => {
  const {
    isAutomated,
    paymentPlans: { purchaseOffers, subscriptionOffers },
    setHasMinSpendError,
    hasMinSpendError,
    isEdit,
    setMinSpendLoading,
    architecture,
    offerId,
    orderItemId,
    currentEndDate,
    currentStartDate,
    currentSpend,
    orderIsPending,
    selectedBlueprint,
    isMultiLocation = false,
    formSectionName = PROGRAM_FORM_SECTION_SPEND_NAME
  } = props;
  const { setValue, getValues } = useFormContext();
  const { value: resultsEstimationTreatmentFlag, experimentsLoaded } =
    useExperiment(EXPERIMENT_NAMES.RESULTS_ESTIMATION);
  const isResultsEstimationTreatment =
    resultsEstimationTreatmentFlag && experimentsLoaded;
  const formValues = getValues();

  // Used for values that can show up in the Edit Location modal form in MLP BPs
  // For MLP these will look like locationOverridesById.${locationId}.spendStep.${fieldName}
  // To see where formSectionName is defined, check the DefaultValuesForm component
  const getDynamicFieldName = fieldName => {
    return `${formSectionName}.${fieldName}`;
  };

  // Used for values that always use the root spend step values (default values for MLP BPs)
  const getSpendStepFieldName = fieldName => {
    return `${PROGRAM_FORM_SECTION_SPEND_NAME}.${fieldName}`;
  };

  const scheduleType = useWatch({
    name: getSpendStepFieldName('scheduleType'),
    defaultValue: ''
  });
  const isSubscription = scheduleType === scheduleTypes.subscription.value;
  const today = dayjs();
  const startDate = useWatch({
    name: getSpendStepFieldName('startDate'),
    defaultValue: ''
  });
  const endDate = useWatch({
    name: getSpendStepFieldName('endDate'),
    defaultValue: ''
  });

  const scheduleDays = useWatch({
    name: getDynamicFieldName('scheduleDays'),
    defaultValue: ''
  });
  const oneTimeSpend = useWatch({
    name: getDynamicFieldName('oneTimeSpend'),
    defaultValue: 0
  });
  const billingMethod = useWatch({
    name: getDynamicFieldName('billingMethod'),
    defaultValue: ''
  });

  const subscriptionSpend = useWatch({
    name: getDynamicFieldName('subscriptionSpend'),
    defaultValue: 0
  });

  const resultsEstimation = useWatch({
    name: getDynamicFieldName('resultsEstimation'),
    defaultValue: null
  });

  const minDays =
    getTemplateData(selectedBlueprint)?.creativeType ===
    facebookCreativeTypes.dynamicAdCreative
      ? 7 // DARE min 7 days
      : 2;

  const selectedPurchaseOffers = find(purchaseOffers, {
    billingMethod
  });

  const min = selectedPurchaseOffers?.purchasePriceUserSetMin || MIN_PRICE;
  const max = selectedPurchaseOffers?.purchasePriceUserSetMax || MAX_PRICE;

  // we cannot edit spend if we are within 24 hours of the end date
  const spendDisabled = !!(
    isEdit && dayjs(endDate || currentEndDate).diff(today, 'hours') < 24
  );

  const theme = useTheme();
  const text = getText();

  const gridContainerDesktopSpacing = 5;

  const minSpendInput = useMemo(
    () =>
      getMinSpendInput({
        architecture,
        formValues,
        endDate,
        startDate,
        isEdit,
        orderItemId,
        offerId
      }),
    [architecture, formValues, endDate, startDate, isEdit, orderItemId, offerId]
  );

  const { loading: minDailySpendLoading, data: minDailySpendData } = useQuery(
    computeMinimumSpend,
    {
      variables: {
        minSpendInput
      }
    }
  );

  const minDailySpend = minDailySpendData?.computeMinimumSpend?.minDailySpend;
  const totalMinDailySpendInflated =
    minDailySpendData?.computeMinimumSpend?.totalMinDailySpendInflated;

  const [resultKpi, setResultKpi] = useState(null);
  const [lowerBoundResultCount, setLowerBoundResultCount] = useState(0);
  const [upperBoundResultCount, setUpperBoundResultCount] = useState(0);

  const [
    computeResultsEstimation,
    {
      loading: resultsEstimationLoading,
      data: resultsEstimationData,
      error: resultsEstimationError
    }
  ] = useLazyQuery(computeResultsEstimationQuery);

  const debouncedCompute = useMemo(
    () =>
      debounce(amount => {
        if (amount && isResultsEstimationTreatment && !hasMinSpendError) {
          computeResultsEstimation({
            variables: {
              input: {
                budgetAmount: amount,
                productId: selectedBlueprint.id,
                offerId
              }
            }
          }).then(res => {
            /* 
              Setting all of this state separately, only when the values has changed,
              to avoid numbers animating unecessarily and to avoid resultKpi from being undefined while
              the query is loading 
            */

            const newResultKpi = res?.data?.resultsEstimation?.resultKpi;
            const newLowerBoundResultCount =
              res?.data?.resultsEstimation?.lowerBoundResultCount;
            const newUpperBoundResultCount =
              res?.data?.resultsEstimation?.upperBoundResultCount;
            const newPoolId = res?.data?.resultsEstimation?.poolId;

            if (newResultKpi !== resultKpi) {
              setResultKpi(newResultKpi);
            }
            if (newLowerBoundResultCount !== lowerBoundResultCount) {
              setLowerBoundResultCount(newLowerBoundResultCount);
            }
            if (newUpperBoundResultCount !== upperBoundResultCount) {
              setUpperBoundResultCount(newUpperBoundResultCount);
            }

            // Update the form values with the new results only if the values have changed
            // These will be passed to the place/edit order mutation
            // to log estimations and compare them against actual results
            const newResultsEstimation = {
              poolId: newPoolId,
              lowerBoundResultCount: newLowerBoundResultCount,
              upperBoundResultCount: newUpperBoundResultCount
            };
            if (!isEqual(newResultsEstimation, resultsEstimation)) {
              setValue(
                getDynamicFieldName('resultsEstimation'),
                newResultsEstimation
              );
            }
          });
        }
      }, 500),
    [computeResultsEstimation, selectedBlueprint?.id, hasMinSpendError]
  );

  // Compute results estimation on budget change
  useEffect(() => {
    debouncedCompute(isSubscription ? subscriptionSpend : oneTimeSpend);

    return () => {
      debouncedCompute.cancel();
    };
  }, [oneTimeSpend, subscriptionSpend, debouncedCompute, isSubscription]);

  // totalMinDailySpendInflated takes into account spend that has already happened in the edit case
  const minDailySpendAmount = isEdit
    ? totalMinDailySpendInflated
    : minDailySpend;

  const { dailySpend, message } = useMemo(
    () =>
      validateSpend({
        spend: oneTimeSpend,
        startDate,
        endDate,
        minDays,
        min,
        max,
        isAutomated,
        scheduleDays,
        minDailySpendAmount,
        performancePredictions: resultsEstimationData,
        isPredictionTreatment: isResultsEstimationTreatment,
        resultsEstimationError,
        isEdit,
        currentEndDate,
        currentSpend,
        currentStartDate
      }),
    [
      oneTimeSpend,
      startDate,
      endDate,
      minDays,
      min,
      max,
      isAutomated,
      scheduleDays,
      minDailySpendAmount,
      resultsEstimationData,
      isResultsEstimationTreatment,
      resultsEstimationError,
      isEdit,
      currentEndDate,
      currentSpend,
      currentStartDate
    ]
  );

  const spendFieldValidator = useRef();

  const roundingDivisor = useMemo(() => {
    const steps = [1, 5, 10, 25, 50, 100];
    const fullRange = max - min;
    const minStep = fullRange / NUM_OF_SLIDER_STEPS;
    return find(steps, (val, i) => {
      // always return the biggest if none other are selected
      if (i === steps.length - 1) {
        return true;
      }
      if (minStep < val) {
        return true;
      }
      return false;
    });
  }, [max, min]);

  const roundingFunction = useCallback(
    value => {
      const divisor = roundingDivisor;

      // allows lowest values to be unrounded eg: $99
      if (value <= min + Math.floor(divisor / 2)) {
        return value;
      }

      // closest divisor to value
      const remainder = value % divisor;

      const final =
        remainder <= Math.floor(divisor / 2)
          ? value - remainder
          : value + divisor - remainder;

      // don't allow values to go over max
      if (final > max) {
        return max;
      }

      return final;
    },
    [min, max, roundingDivisor]
  );

  useEffect(() => {
    // any time these requirements change update the spend field validator
    // otherwise use cached version
    spendFieldValidator.current = value => {
      const { validationFail, message } = validateSpend({
        spend: value,
        startDate,
        endDate,
        minDays,
        min,
        max,
        isAutomated,
        scheduleDays,
        minDailySpendAmount,
        performancePredictions: resultsEstimationData,
        isPredictionTreatment: isResultsEstimationTreatment,
        resultsEstimationError,
        isEdit,
        currentEndDate,
        currentSpend,
        currentStartDate
      });

      if (isResultsEstimationTreatment) {
        setHasMinSpendError(validationFail);
      }

      if (
        validationFail &&
        message === SPEND_VALIDATION_MESSAGES.increaseEndDate
      ) {
        return text.invalidEndDate;
      }

      if (validationFail) {
        return text.spendError;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oneTimeSpend,
    startDate,
    endDate,
    minDays,
    min,
    max,
    isAutomated,
    scheduleDays,
    minDailySpendAmount,
    resultsEstimationData,
    isResultsEstimationTreatment,
    resultsEstimationError,
    isEdit,
    currentEndDate,
    currentSpend,
    currentStartDate
  ]);

  useEffect(() => {
    if (setMinSpendLoading) {
      setMinSpendLoading(minDailySpendLoading);
    }
  }, [minDailySpendLoading]);

  const marks = [
    {
      value: min,
      label: formatPriceWithOptionalCents(min)
    },
    {
      value: max,
      label: formatPriceWithOptionalCents(max)
    }
  ];

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let oneTimeSpendLabel = text.budgetLabel;
  if (isMultiLocation) {
    oneTimeSpendLabel = text.budgetLabelMultiLocation;
  }

  const oneTimeSpendTextProps = {
    name: 'oneTimeSpend',
    formNamespace: formSectionName,
    label: oneTimeSpendLabel,
    component: RenderTemplateField,
    validate: {
      ...(spendFieldValidator.current && {
        spendValidation: spendFieldValidator.current
      })
    },
    disabled: spendDisabled || orderIsPending,
    extraProps: {
      startAdornment: (
        <span
          style={{
            position: 'relative',
            top: 1,
            color: theme.palette.text.primary,
            fontSize: '1.25rem'
          }}
        >
          $
        </span>
      ),
      normalize: value => numeral(value).value(),
      sx: {
        padding: '12.5px 10px'
      },
      inputProps: {
        thousandSeparator: true,
        fixedDecimalScale: true,
        decimalScale: 2,
        sx: {
          padding: '0',
          fontSize: '1.25rem'
        }
      }
    }
  };

  const oneTimeSpendSliderProps = {
    name: 'oneTimeSpend',
    formNamespace: formSectionName,
    component: RenderSlider,
    autoComplete: 'completely-off',
    disabled: spendDisabled || orderIsPending,
    // adding this validation specifically for hook form the validations don't work without it
    validate: {
      ...(spendFieldValidator.current && {
        spendValidation: spendFieldValidator.current
      })
    },

    extraProps: {
      roundingFunction,
      min,
      max,
      marks,
      step: PRICE_STEPS,
      sx: {
        margin: 0,
        '& .MuiSlider-markLabel[data-index="0"]': {
          left: '10px !important'
        },
        [theme.breakpoints.down('sm')]: {
          '& .MuiSlider-markLabel[data-index="1"]': {
            right: '-20px !important',
            left: 'unset !important'
          }
        }
      }
    }
  };

  return (
    <>
      {/* Grid Container For All Spend Inputs */}
      <Grid
        sx={{
          margin: 0,
          width: '100%'
        }}
        container
        {...(!isResultsEstimationTreatment && {
          spacing: isMobile ? 1 : gridContainerDesktopSpacing
        })}
        {...(isResultsEstimationTreatment && {
          rowGap: 2.5,
          columnGap: 7
        })}
      >
        {/* Subscription Input With Conditionals For Both Control and Treatment */}
        {isSubscription && (
          <>
            <Grid item xs={12} sx={{ pl: '0 !important', pt: '0 !important' }}>
              <SubscriptionSelector
                subscriptionOffers={subscriptionOffers}
                billingMethod={billingMethod}
                disabled={orderIsPending}
                formNamespace={formSectionName}
              />
            </Grid>

            {/* Subscription Results Estimation Treatment */}
            {isResultsEstimationTreatment && (
              <Grid item>
                <ResultsEstimation
                  isSubscription
                  hasValidationError={hasMinSpendError}
                  hasApiError={resultsEstimationError}
                  lowerBoundResultCount={lowerBoundResultCount}
                  upperBoundResultCount={upperBoundResultCount}
                  resultKpi={resultKpi}
                  isLoading={resultsEstimationLoading}
                  isAutomated={isAutomated}
                  isMlp={isMultiLocation}
                />
              </Grid>
            )}
          </>
        )}

        {/* One Time Purchase Input With Conditionals For Both Control and Treatment */}
        {!isSubscription && (
          <>
            <Grid
              item
              xs={12}
              {...(!isResultsEstimationTreatment && {
                sm: 3
              })}
              {...(isResultsEstimationTreatment && {
                md: 3,
                sm: 6
              })}
              sx={{ pl: '0 !important', pt: '0 !important' }}
            >
              <HookFormWrapper {...oneTimeSpendTextProps} />
              {isResultsEstimationTreatment && (
                <Typography
                  variant="caption"
                  sx={{
                    ml: 1,
                    ...(hasMinSpendError && {
                      position: 'relative',
                      top: -6
                    })
                  }}
                >
                  <Trans
                    i18nKey="programCreate:spend.dailySpend"
                    values={{
                      amount: isString(dailySpend)
                        ? dailySpend
                        : formatDailySpend(dailySpend)
                    }}
                  />
                </Typography>
              )}

              <Box
                sx={{
                  pt: 1,
                  pb: 2
                }}
              >
                <HookFormWrapper {...oneTimeSpendSliderProps} />
              </Box>
            </Grid>
          </>
        )}

        {/* One Time Purchase Control Treatment */}
        {!isSubscription && !isResultsEstimationTreatment && (
          <Grid
            item
            xs={isMobile ? 12 : 9}
            sx={{
              pt: isResultsEstimationTreatment
                ? '0 !important'
                : `11px !important`,
              pl: isMobile && '0 !important'
            }}
          >
            {minDailySpendLoading ? (
              <Loading />
            ) : (
              <>
                {!isResultsEstimationTreatment && (
                  <Typography>
                    <Trans
                      i18nKey="programCreate:spend.dailySpend"
                      values={{
                        amount: isString(dailySpend)
                          ? dailySpend
                          : formatDailySpend(dailySpend)
                      }}
                    />
                  </Typography>
                )}

                {resultsEstimationLoading ? (
                  <Loading />
                ) : (
                  <>
                    {!isResultsEstimationTreatment && (
                      <OneTimeSpendMessage
                        performancePredictions={resultsEstimationData}
                        message={message}
                        min={min}
                        max={max}
                        minDays={minDays}
                        currentEndDate={currentEndDate}
                        setHasMinSpendError={setHasMinSpendError}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        )}

        {/* One Time Purchase Results Estimation Treatment */}
        {isResultsEstimationTreatment && !isSubscription && (
          <Grid item sm={12} md={6}>
            <ResultsEstimation
              hasValidationError={hasMinSpendError}
              hasApiError={resultsEstimationError}
              lowerBoundResultCount={lowerBoundResultCount}
              upperBoundResultCount={upperBoundResultCount}
              resultKpi={resultKpi}
              isLoading={resultsEstimationLoading}
              isAutomated={isAutomated}
              isMlp={isMultiLocation}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SpendSelector;
