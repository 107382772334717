import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { Button } from '@mui/material';
import { Trans } from 'react-i18next';
import Modal from 'src/components/Modal';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import { deactivateSupervisorOrder } from './mutations';

const PREFIX = 'StopAutomationButton';

const classes = {
  button: `${PREFIX}-button`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.button}`]: {
    marginLeft: theme.spacing(1),
    display: 'inline-block'
  }
}));

const StopAutomationButton = ({
  isActive,
  id,
  refetch,
  deactivateSupervisorOrder
}) => {
  const [deactivating, setDeactivating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleStopProgram = async () => {
    setDeleting(true);
    try {
      await deactivateSupervisorOrder({
        variables: {
          supervisorOrderId: id
        }
      });

      enqueueSnackbar(t('automatedDetails:delete.Snacksuccess'), {
        variant: 'success'
      });
    } catch (e) {
      enqueueSnackbar(t('automatedDetails:delete.snackError'), {
        variant: 'error'
      });
    }

    setDeactivating(false);
    setDeleting(false);
    refetch();
  };

  return (
    <Root>
      {isActive && (
        <Button
          onClick={() => {
            setDeactivating(true);
          }}
          variant="contained"
          color="secondary"
          className={classes.button}
        >
          <Trans i18nKey="automatedDetails:stopProgram">
            Deactivate Automation
          </Trans>
        </Button>
      )}
      {deactivating && (
        <Modal
          maxWidth="sm"
          onClose={() => setDeactivating(false)}
          open={deactivating}
          headerText={t('automatedDetails:delete.header')}
          FooterComponent={() => (
            <>
              <Button onClick={() => setDeactivating(false)}>
                <Trans i18nKey="automatedDetails:delete.cancel">Cancel</Trans>
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={deleting}
                onClick={handleStopProgram}
              >
                <Trans i18nKey="automatedDetails:delete.confirmButton">
                  Deactivate
                </Trans>
              </Button>
            </>
          )}
        >
          {t('automatedDetails:delete.confirmText')}
        </Modal>
      )}
    </Root>
  );
};

export default flow(
  graphql(deactivateSupervisorOrder, {
    name: 'deactivateSupervisorOrder'
  })
)(StopAutomationButton);
