import { flow } from 'lodash';

import { styled } from '@mui/material/styles';

import { Card, Grid, Theme } from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';

const PREFIX = 'ErrorMessage';

const classes = {
  errorContainer: `${PREFIX}-errorContainer`,
  errorIcon: `${PREFIX}-errorIcon`
};

const StyledCard = styled(Card)(({ theme }: { theme: Theme }) => ({
  [`& .${classes.errorContainer}`]: {
    maxWidth: '450px',
    padding: theme.spacing(2),
    color: theme.palette.error.dark
  },

  [`& .${classes.errorIcon}`]: {
    width: '50px',
    height: '50px',
    color: theme.palette.error.dark
  }
}));

interface ErrorMessageProps {
  className?: string;
  children: JSX.Element | string;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const { className, children, ...restProps } = props;

  let containerClassName = classes.errorContainer;

  if (className) {
    containerClassName = `${className} ${containerClassName}`;
  }

  return (
    <StyledCard {...restProps} className={containerClassName}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <ErrorIcon className={classes.errorIcon} />
        </Grid>
        <Grid item xs={10}>
          {children}
        </Grid>
      </Grid>
    </StyledCard>
  );
};

export default flow()(ErrorMessage);
