import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';

interface SellingPointProps {
  children: string;
}

interface SellingPointsListProps {
  sellingPoints: string[];
}

const List = styled(Box)(({ theme }) => ({
  padding: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  whiteSpace: 'normal'
}));

const SellingPoint = ({ children }: SellingPointProps) => {
  return (
    <Box
      component="li"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2
      }}
    >
      <CheckIcon sx={{ color: 'text.primary', fontSize: 20 }} />
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};

const SellingPointsList = ({ sellingPoints }: SellingPointsListProps) => {
  // Description items will be dynamically set in the BPB, for now, this is the placeholder text
  return (
    <List>
      {sellingPoints.map(sellingPoint => (
        <SellingPoint key={sellingPoint}>{sellingPoint}</SellingPoint>
      ))}
    </List>
  );
};

export default SellingPointsList;
