import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import ReactDiffViewer from 'react-diff-viewer';
import { t } from 'i18next';

import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Difference as DifferenceIcon } from '@mui/icons-material';

import Modal, { ModalHeader } from 'src/components/Modal';

import { sortKeys } from '../helpers';
import BlueprintBuilderWarnings from '../BlueprintBuilderWarnings';

const PREFIX = 'ConfirmModal';

const classes = {
  publishButtonContainer: `${PREFIX}-publishButtonContainer`
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.publishButtonContainer}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1)
  }
}));

const pageText = () => ({
  modalHeader: t('admin:blueprintBuilder.confirmModalHeader'),
  commit: t('admin:blueprintBuilder.confirmModalCommitButton'),
  saveDraft: t('admin:blueprintBuilder.confirmModalSaveDraftButton'),
  close: t('admin:blueprintBuilder.confirmModalCloseButton'),
  leftTitle: t('admin:blueprintBuilder.confirmModalLeftTitle'),
  rightTitle: t('admin:blueprintBuilder.confirmModalRightTitle')
});

const ConfirmModal = props => {
  const {
    submitModalOpen,
    formValues = {},
    setSubmitModalClose,
    onPublish,
    isSubmitting,
    currentDocument
  } = props;
  const text = useMemo(() => pageText(), []);

  // Note: Sorting these keys so that the diff works correctly redux form and the
  //       object that the we compare can have properties out of order
  const currentDocumentState = currentDocument ? sortKeys(currentDocument) : {};
  const formDocumentState = formValues ? sortKeys(formValues) : {};

  // we don't need to see these in the diff importDocument is the imported file
  // it gets removed before submitting the form
  delete currentDocumentState.importDocument;
  delete formDocumentState.importDocument;

  return (
    <StyledModal
      open={submitModalOpen}
      HeaderComponent={() => (
        <ModalHeader icon={<DifferenceIcon />}>{text.modalHeader}</ModalHeader>
      )}
      fullWidth
      maxWidth="lg"
      onClose={setSubmitModalClose}
      FooterComponent={() => (
        <div className={classes.publishButtonContainer}>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={() => onPublish({ isDraft: false })}
            loading={isSubmitting}
          >
            {text.commit}
          </LoadingButton>

          <Button
            color="primary"
            variant="outlined"
            onClick={() => onPublish({ isDraft: true })}
            disabled={isSubmitting}
          >
            {text.saveDraft}
          </Button>
          <Button onClick={setSubmitModalClose}>{text.close}</Button>
        </div>
      )}
    >
      <div>
        <ReactDiffViewer
          oldValue={JSON.stringify(currentDocumentState, null, 2)}
          newValue={JSON.stringify(formDocumentState, null, 2)}
          splitView
          disableWordDiff
          leftTitle={text.leftTitle}
          rightTitle={text.rightTitle}
        />

        <BlueprintBuilderWarnings formValues={formValues} />
      </div>
    </StyledModal>
  );
};

export default ConfirmModal;
