import { useState } from 'react';
import { uniq, keys, omit, flatMap, difference } from 'lodash';

import { unparse } from 'papaparse';
import { useLazyQuery, LazyQueryExecFunction } from '@apollo/client';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';

import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { Button, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import DownloadIcon from '@mui/icons-material/Download';

import {
  UserLeadsByProgramIdQuery,
  UserLeadsByProgramIdQueryVariables,
  LeadConnectionEdge
} from 'src/generated/gql/graphql';
import { Row } from 'src/pages/Leads/LeadsTable';
import { rowDataFieldsToExclude, formatRowDataEdge } from './helpers';
import { getLeads } from './queries';

const downloadFile = (filename: string, data: BlobPart) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const elem = window.document.createElement('a');
  elem.href = window.URL.createObjectURL(blob);
  elem.download = filename;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

const fixedFields = ['name', 'email', 'phone', 'date'];
const extractUniqueKeys = (data: Row[]) => {
  return uniq(flatMap(data, item => keys(omit(item, fixedFields))));
};

const prepareCSVData = (rows: Row[]) => {
  const explodedRows = rows.map(row => {
    const explodedRow = { ...row };
    delete explodedRow.leadQuestions;
    row?.leadQuestions?.forEach(leadQuestion => {
      // Type assertion to allow string indexing
      explodedRow[leadQuestion.name] = leadQuestion.value;
    });
    return explodedRow;
  });
  const uniqueKeys = extractUniqueKeys(explodedRows);
  const excludedFieldsArray = [...rowDataFieldsToExclude, 'leadQuestions'];
  const questionColumns = difference(uniqueKeys, excludedFieldsArray);
  const columns = [...fixedFields, ...questionColumns];
  return { data: explodedRows, columns };
};

const MAX_LEADS = 10000;

const makeCSVfromAll = async ({
  setLoading,
  programId,
  onError,
  fetchUserLeads
}: {
  setLoading: (loading: boolean) => void;
  programId?: string;
  onError: () => void;
  fetchUserLeads: LazyQueryExecFunction<
    UserLeadsByProgramIdQuery,
    UserLeadsByProgramIdQueryVariables
  >;
}) => {
  setLoading(true);
  try {
    const { data } = await fetchUserLeads({
      variables: {
        ...(programId && { programId }),
        first: MAX_LEADS
      }
    });

    const edges = data?.userLeads?.edges || [];
    // format data to be like the rows in the table so we are consistent
    const formattedData = formatRowDataEdge(edges as LeadConnectionEdge[]);
    const { data: csvData, columns } = prepareCSVData(formattedData);

    downloadFile('leads.csv', unparse(csvData, { columns }));
  } catch (error) {
    onError();
  } finally {
    setLoading(false);
  }
};

const makeCSVfromRows = (
  rows: Row[],
  selected: GridRowSelectionModel,
  setLoading: (loading: boolean) => void
) => {
  setLoading(true);
  // find selected rows
  const selectedRows = rows.filter(row => selected.includes(row.key));
  const { data: csvData, columns } = prepareCSVData(selectedRows);

  downloadFile('leads.csv', unparse(csvData, { columns }));
  setLoading(false);
};

interface LeadsExportProps {
  programId?: string;
  rows?: Row[];
  selected?: GridRowSelectionModel;
}

const LeadsExport = ({ programId, rows, selected }: LeadsExportProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const hasSelection = selected && selected.length > 0;

  const [fetchUserLeads] = useLazyQuery(getLeads);

  const onError = () => {
    enqueueSnackbar(t('leads:leadsTable.exportError'), { variant: 'error' });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        p: 2,
        borderBottom: `1px solid ${grey[300]}`
      }}
    >
      <Button
        data-amp-click-download-contacts
        disabled={loading}
        size="small"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() =>
          hasSelection && rows && rows.length > 0
            ? makeCSVfromRows(rows, selected, setLoading)
            : makeCSVfromAll({ setLoading, programId, onError, fetchUserLeads })
        }
        startIcon={<DownloadIcon />}
      >
        {hasSelection
          ? t('leads:leadsTable.exportCount', { count: selected.length })
          : t('leads:leadsTable.exportAll')}
      </Button>
    </Box>
  );
};

export default LeadsExport;
