import { SvgIcon, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ThemeAugmentations } from 'src/AppThemeWrapper';

interface QuickAutomationProps {
  fill?: string;
}

const QuickAutomation = ({ fill }: QuickAutomationProps) => {
  const theme = useTheme();
  const htmlColor =
    fill || (theme as Theme & ThemeAugmentations).palette.text.primary;

  return (
    <SvgIcon htmlColor={htmlColor} viewBox="0 0 250 250">
      <path
        d="M125,20C69,20,23,64.1,20.1,119.5c-0.4,5.5,3.7,10.3,9.2,10.7s10.3-3.7,10.7-9.2c0-0.1,0-0.3,0-0.4
	C42.4,75.6,79.4,40,125,40c28.3,0,53.3,13.8,68.7,35H190c-5.5-0.1-10.1,4.3-10.1,9.9c-0.1,5.5,4.3,10.1,9.9,10.1c0.1,0,0.2,0,0.3,0
	h40V55c0.1-5.5-4.3-10.1-9.9-10.1c-0.1,0-0.2,0-0.3,0c-5.5,0.1-9.9,4.6-9.9,10.1v8.3C190.9,37.1,159.9,20,125,20z M114.5,70L112,86
	c-1.5,0.5-3.5,1.5-5,2l-13.5-9.5l-15,15l9,12.5c-1,1.5-1.5,3.5-2,5.5L70,114v21l15.5,2.5c0.5,1.5,1.5,3.5,2,5l-9,13.5l15,15l13.5-9
	c2,1,3.5,1.5,5.5,2l2,16h21l2.5-16c1.5-0.5,3.5-1.5,5-2l13.5,9l15-15l-9.5-13c1-1.5,1.5-3.5,2-5l16-3v-21l-16-2.5
	c-0.5-1.5-1.5-3.5-2-5l9-13.5l-14.5-14.5l-13,9.5c-1.5-1-3.5-1.5-5-2l-3-16H114.5z M125,110c8.5,0,15,6.5,15,15s-6.5,15-15,15
	s-15-6.5-15-15S116.5,110,125,110z M220.2,119.9c-5.5-0.2-10.2,4.1-10.4,9.6c-2.3,44.9-39.3,80.5-84.9,80.5
	c-28.3,0-53.3-13.8-68.7-35H60c5.5,0.1,10.1-4.3,10.1-9.9s-4.3-10.1-9.9-10.1c-0.1,0-0.2,0-0.3,0l-40,0v40
	c-0.1,5.5,4.3,10.1,9.9,10.1s10.1-4.3,10.1-9.9c0-0.1,0-0.2,0-0.3v-8.3c19.1,26.3,50.1,43.3,85,43.3c56,0,102-44.1,104.9-99.5
	c0.4-5.5-3.8-10.3-9.3-10.6C220.4,119.9,220.3,119.9,220.2,119.9z"
      />
    </SvgIcon>
  );
};

export default QuickAutomation;
