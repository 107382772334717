import { useMemo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { FormSection } from 'redux-form';
import { t } from 'i18next';

import { Button, Divider } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/DeleteForever';

import { DynamicForm } from 'src/components/ReduxForm';
import {
  getInitialValuesFromInputsConfig,
  configureInputs
} from 'src/components/ReduxForm/helpers';

import { isDeveloper } from 'src/Auth/common';

import { getContentSetInputs } from '../Constants';

const PREFIX = 'RenderContentInputs';

const classes = {
  heading: `${PREFIX}-heading`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  divider: `${PREFIX}-divider`,
  removeButton: `${PREFIX}-removeButton`,
  alert: `${PREFIX}-alert`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.heading}`]: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold'
  },

  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: theme.spacing(2)
  },

  [`& .${classes.divider}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  [`& .${classes.removeButton}`]: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main
    }
  },

  [`& .${classes.alert}`]: {
    marginBottom: theme.spacing(2)
  }
}));

const pageText = () => ({
  addButton: t('admin:blueprintBuilder.stepContentAddButton'),
  removeButton: t('admin:blueprintBuilder.stepContentRemoveButton'),
  alertTitle: t('admin:blueprintBuilder.stepContentDevOnlyAlertTitle')
});

const isDev = isDeveloper();
const devOnlyInputs = new Set([]);

const newVariable = getInitialValuesFromInputsConfig(getContentSetInputs());

const RenderContentInputs = props => {
  const { fields } = props;
  const text = useMemo(() => pageText(), []);
  const contentSetInputs = getContentSetInputs();

  const updatedContentSetInputs = useMemo(() => {
    const disabledInputs = isDev ? new Set() : devOnlyInputs;

    return configureInputs({
      inputs: contentSetInputs,
      disabledInputs
    });
  }, [contentSetInputs, devOnlyInputs]);

  const addNewContentSet = useCallback(() => {
    fields.push(newVariable);
  }, [fields]);

  const removeContentSet = useCallback(
    index => {
      fields.remove(index);
    },
    [fields]
  );

  return (
    <Root>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={addNewContentSet}
          startIcon={<AddIcon />}
        >
          {text.addButton}
        </Button>
      </div>
      {fields.map((field, index) => {
        return (
          <div key={field}>
            <Divider className={classes.divider} />
            <div className={classes.buttonContainer}>
              <Button
                className={classes.removeButton}
                variant="outlined"
                onClick={() => removeContentSet(index)}
                startIcon={<RemoveIcon />}
              >
                {text.removeButton}
              </Button>
            </div>

            <FormSection name={field}>
              <DynamicForm inputs={updatedContentSetInputs} />
            </FormSection>
          </div>
        );
      })}
    </Root>
  );
};

export default RenderContentInputs;
