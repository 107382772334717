import { Field, FormSection } from 'redux-form';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';

import { Button, Grid, IconButton, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteForever';

import {
  validateRequired,
  validateUrlWithProtocol
} from 'src/common/validations';

import RenderTextField from 'src/components/ReduxForm/RenderTextField';
import { RenderLinkUrl } from 'src/components/ReduxForm/RenderLinkUrl';
import RenderSwitch from 'src/components/ReduxForm/RenderSwitch';
import { Trans } from 'react-i18next';

const PREFIX = 'RenderCustomLinks';

const classes = {
  headingContainer: `${PREFIX}-headingContainer`
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.headingContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const RenderCustomLinks = props => {
  const { actionButtonText, previewUrl, fields, disabled = false } = props;

  return (
    <StyledGrid container spacing={3}>
      {fields.map((field, index) => {
        return (
          <Grid item xs={12} md={6} key={field}>
            <div className={classes.headingContainer}>
              <Typography variant="subtitle1">
                <Trans
                  i18nKey="renderCustomLinks:subtitle"
                  defaults="Link {index}"
                  values={{ index: index + 1 }}
                />
              </Typography>
              <IconButton
                onClick={() => {
                  fields.remove(index);
                }}
                size="small"
                disabled={disabled}
              >
                <DeleteIcon />
              </IconButton>
            </div>

            <Field
              component={RenderLinkUrl}
              name={`${field}.link`}
              label={t('renderCustomLinks:inputs.link.label', 'Link')}
              props={{ previewUrl }}
              disabled={disabled}
              validate={[validateRequired, validateUrlWithProtocol]}
            />

            <FormSection key="text" name={`${field}.text`}>
              <Field
                component={RenderTextField}
                name="en"
                label={t('renderCustomLinks:inputs.text.label', 'Text')}
                validate={[validateRequired]}
                disabled={disabled}
              />
            </FormSection>

            <Field
              component={RenderSwitch}
              name={`${field}.newTab`}
              label={t('renderCustomLinks:inputs.newTab', 'Open in new tab')}
              defaultValue={false}
              defaultChecked={false}
              disabled={disabled}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Button
          endIcon={<AddIcon />}
          variant="outlined"
          disabled={disabled}
          onClick={() => {
            fields.push({
              text: {
                en: ''
              },
              link: ''
            });
          }}
        >
          {actionButtonText}
        </Button>
      </Grid>
    </StyledGrid>
  );
};

export default RenderCustomLinks;
