import { Grid, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

import LocationOnIcon from '@mui/icons-material/LocationOn';

const PREFIX = 'AddressRenderOptions';

const classes = {
  icon: `${PREFIX}-icon`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
}));

const AddressRenderOptions = props => {
  const { option } = props;

  return (
    <StyledGrid container alignItems="center">
      <Grid item>
        <LocationOnIcon className={classes.icon} />
      </Grid>
      <Grid item xs>
        <Typography variant="body1" color="textPrimary">
          {option?.description || '-'}
        </Typography>
      </Grid>
    </StyledGrid>
  );
};

export default AddressRenderOptions;
