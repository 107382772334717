import { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';
import { flow, isEmpty } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';

import { Fab, Paper, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import TableEmptyState from 'src/components/EmptyStates/TableEmptyState';
import Loading from 'src/components/Loading';
import Table from 'src/components/Table';

import ResourceCollectionForm from './ResourceCollectionForm';
import { getResourceCollections } from './queries';
import { getResourceCollectionTableColumnSchema } from './constants';
import ResourceItemsManager from './ResourceItemsManager';

const PREFIX = 'ResourceCollectionsManager';

const classes = {
  collectionsContent: `${PREFIX}-collectionsContent`,
  collectionsTable: `${PREFIX}-collectionsTable`,
  collectionsHeader: `${PREFIX}-collectionsHeader`,
  collectionsForm: `${PREFIX}-collectionsForm`,
  itemsManager: `${PREFIX}-itemsManager`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.collectionsContent}`]: {
    display: 'flex'
  },

  [`& .${classes.collectionsTable}`]: {
    width: '50%'
  },

  [`& .${classes.collectionsHeader}`]: {
    width: '50%',
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between'
  },

  [`& .${classes.collectionsForm}`]: {
    width: '50%',
    paddingLeft: theme.spacing(2)
  },

  [`& .${classes.itemsManager}`]: {
    marginTop: theme.spacing(3)
  }
}));

const pageText = () => ({
  title: t('adminResourceLibrary:collections.title'),
  emptyTableMessage: t('adminResourceLibrary:collections.empty')
});

const ResourceCollectionsManager = ({
  resourceItemTypeSlug,
  isEditItemValuesOnly,
  resourceCollections,
  resourceCollectionsMeta: {
    resourceCollectionsLoading,
    resourceCollectionsError,
    refetchResourceCollections
  }
}) => {
  const text = useMemo(() => pageText(), []);

  const [selectedCollection, setSelectedCollection] = useState();
  const [isFormOpen, setIsFormOpen] = useState(false);

  // anytime we have a new resourceItemTypeSlug, ensure that we don't
  // have anything selected and the form is not open.
  useEffect(() => {
    setSelectedCollection();
    setIsFormOpen(false);
  }, [resourceItemTypeSlug]);

  if (resourceCollectionsLoading || resourceCollectionsError) {
    return <Loading error={resourceCollectionsError} />;
  }

  return (
    <Root>
      <div className={classes.collectionsHeader}>
        <Typography component="h2" variant="h5">
          {text.title}
        </Typography>
        {!isEditItemValuesOnly && (
          <Fab
            size="small"
            color="primary"
            onClick={() => {
              setSelectedCollection();
              setIsFormOpen(true);
            }}
          >
            <AddIcon />
          </Fab>
        )}
      </div>
      <div className={classes.collectionsContent}>
        <div className={classes.collectionsTable}>
          <Paper>
            {isEmpty(resourceCollections) && (
              <TableEmptyState
                emptyMessage={text.emptyTableMessage}
                loading={resourceCollectionsLoading}
              />
            )}
            {!isEmpty(resourceCollections) && (
              <Table
                columnSchema={getResourceCollectionTableColumnSchema()}
                rows={resourceCollections}
                onClickBodyRow={resourceCollection => {
                  setSelectedCollection(resourceCollection);
                  setIsFormOpen(true);
                }}
                loading={resourceCollectionsLoading}
                hasRowCheckbox
                // just need something here, the work is done by onClickBodyRow
                onCheckboxClick={() => {}}
                singleSelect
                selectedRows={
                  selectedCollection && {
                    [selectedCollection.id]: selectedCollection
                  }
                }
              />
            )}
          </Paper>
        </div>
        <div className={classes.collectionsForm}>
          {!isEditItemValuesOnly && isFormOpen && (
            <ResourceCollectionForm
              closeForm={() => {
                setSelectedCollection();
                setIsFormOpen(false);
              }}
              initialValues={
                selectedCollection ?? {
                  type: resourceItemTypeSlug
                }
              }
              isUpdate={!!selectedCollection}
              isEditItemValuesOnly={isEditItemValuesOnly}
              onAfterSuccess={() => {
                setSelectedCollection();
                setIsFormOpen(false);
                refetchResourceCollections();
              }}
            />
          )}
        </div>
      </div>
      {selectedCollection && (
        <div className={classes.itemsManager}>
          <ResourceItemsManager
            isEditItemValuesOnly={isEditItemValuesOnly}
            selectedResourceCollection={selectedCollection}
          />
        </div>
      )}
    </Root>
  );
};

export default flow(
  graphql(getResourceCollections, {
    name: 'getResourceCollections',
    options: props => ({
      variables: {
        type: props.resourceItemTypeSlug
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }),
    props: ({
      getResourceCollections: { error, loading, refetch, resourceCollections }
    }) => {
      return {
        resourceCollections,
        resourceCollectionsMeta: {
          resourceCollectionsLoading: loading,
          resourceCollectionsError: error,
          refetchResourceCollections: refetch
        }
      };
    }
  })
)(ResourceCollectionsManager);
