import { Box, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { getFacebookAdPreviewStyles } from './FacebookAdPreview';
import { getFacebookAdPreviewCardStyles } from './FacebookAdPreviewCard';

const FacebookAdPreviewSkeleton = props => {
  const theme = useTheme();
  const vars = {
    hasUniformPreviewWidth: props.hasUniformPreviewWidth,
    isResponsive: props.isResponsive,
    disableResponsiveStyles: props.disableResponsiveStyles
  };
  const adPreviewSxStyles = getFacebookAdPreviewStyles({ theme, vars });
  const adPreviewCardSxStyles = getFacebookAdPreviewCardStyles();

  const sxStyles = {
    ...adPreviewSxStyles,
    ...adPreviewCardSxStyles
  };

  return (
    <>
      <Box sx={sxStyles.preview}>
        <Box sx={sxStyles.previewHeader} style={{ gridArea: '1 / 1' }}>
          <Skeleton width={50} />
        </Box>
        <Box sx={sxStyles.companyRow} style={{ gridArea: '2 / 1' }}>
          <Box sx={sxStyles.companyNameContainer} style={{ gridArea: '1 / 1' }}>
            <Box style={{ display: 'inline-block' }}>
              <Skeleton variant="rectangular" width={25} height={25} />
            </Box>
            <Box sx={sxStyles.companyNameInline} style={{ marginLeft: '10px' }}>
              <Box sx={sxStyles.companyName}>
                <Skeleton width={50} />
              </Box>
              <Skeleton width={50} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={sxStyles.bodyText}
          style={{ gridArea: '3 / 1', marginTop: '10px' }}
        >
          <Skeleton width={200} />
        </Box>
        <Box sx={sxStyles.previewCardsWrap}>
          <Box sx={sxStyles.previewCards}>
            <Box style={{ width: '100%' }}>
              <Skeleton variant="rectangular" width={361} height={271} />
              <Box sx={sxStyles.previewCardFooter}>
                <Box sx={sxStyles.previewCardLeadAdText}>
                  <Skeleton />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FacebookAdPreviewSkeleton;
