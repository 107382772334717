import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import {
  validateRequired,
  validateNotBlank,
  validateSlug,
  validateIsNumber
} from 'src/common/validations';

export const FORM_NAME_CREATE_PLAN = 'pricingPlanCreate';
export const FORM_NAME_UPDATE_PLAN = 'pricingPlanUpdate';
export const FORM_NAME_TIERING = 'tierSettings';

export const MODES = {
  CREATE: 0,
  UPDATE: 1
};

const getPricingInputs = mode => [
  {
    name: 'name',
    displayName: 'Name',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: !mode ? '' : undefined,
    reduxValidations: [validateRequired, validateNotBlank()],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'currencyCode',
    displayName: 'Currency Code',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired, validateNotBlank()],
    isRequired: true,
    disabled: !!mode,
    displaySortOrder: 1
  },
  {
    name: 'slug',
    displayName: 'Slug',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    disabled: !!mode,
    reduxValidations: [validateRequired, validateNotBlank(), validateSlug()],
    isRequired: true
  },
  {
    name: 'description',
    displayName: 'Description',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired, validateNotBlank()],
    isRequired: true,
    isHidden: false
  },
  {
    name: 'intervalUnit',
    displayName: 'Interval Unit',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    disabled: !!mode,
    reduxValidations: [validateRequired, validateNotBlank()],
    isRequired: true
  },
  {
    name: 'intervalLength',
    displayName: 'Interval Length',
    disabled: !!mode,
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired, validateNotBlank(), validateIsNumber],
    isRequired: true
  },
  {
    name: 'isActive',
    displayName: 'Is Active',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [validateRequired, validateNotBlank()],
    isRequired: true,
    isHidden: false
  }
];

const getTierInputs = mode => [
  {
    name: 'name',
    displayName: 'Name',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired, validateNotBlank()],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'slug',
    displayName: 'Slug',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired, validateNotBlank(), validateSlug()],
    disabled: !!mode,
    isRequired: true,
    isHidden: false
  },
  {
    name: 'isActive',
    displayName: 'Is Active',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [validateRequired, validateNotBlank()],
    isRequired: true,
    isHidden: false
  },
  {
    name: 'amount',
    displayName: 'Amount',
    displayMethodId: INPUT_TYPES.NUMBER,
    initialValue: 0,
    disabled: !!mode,
    reduxValidations: [validateRequired, validateNotBlank(), validateIsNumber],
    isRequired: true,
    isHidden: false
  }
];

export const createPricingConfigInputs = getPricingInputs(MODES.CREATE);
export const createTierConfigInputs = getTierInputs(MODES.CREATE);
export const updateTierConfigInputs = getTierInputs(MODES.UPDATE);
export const updatePricingConfigInputs = getPricingInputs(MODES.UPDATE);
