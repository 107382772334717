import { useState } from 'react';
import { t } from 'i18next';

import { Collapse, Typography, IconButton, Box, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

interface ExpandableTextProps {
  text: string;
  maxLength?: number;
  hasAccepted: boolean;
  fullName?: string;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({
  text,
  maxLength = 100,
  hasAccepted = true,
  fullName = t('leads:detailPanel.lead')
}) => {
  const [expanded, setExpanded] = useState(false);
  const isTextLong = text.length > maxLength;
  const truncatedText = `${text.substring(0, maxLength)}...`;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'grey.100',
        p: 2,
        borderRadius: 1,
        mb: 1
      }}
    >
      <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ mr: 2 }}>
          {/* Show Truncated Text Only When Collapsed */}
          {!expanded && (
            <Typography variant="body2">{truncatedText}</Typography>
          )}

          {/* Show Full Text Only When Expanded */}
          <Collapse in={expanded} timeout="auto">
            <Typography variant="body2">{text}</Typography>
          </Collapse>
        </Box>

        <Tooltip
          title={
            hasAccepted
              ? t('leads:detailPanel.accepted', { name: fullName })
              : t('leads:detailPanel.notAccepted', { name: fullName })
          }
        >
          {hasAccepted ? (
            <CheckCircleOutlineIcon sx={{ color: 'success.main' }} />
          ) : (
            <DoNotDisturbIcon sx={{ color: 'error.main' }} />
          )}
        </Tooltip>
      </Box>

      {/* Expand/Collapse Button */}
      {isTextLong && (
        <IconButton
          onClick={() => setExpanded(!expanded)}
          sx={{
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: theme =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest
              })
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default ExpandableText;
