import { t } from 'i18next';
import { styled } from '@mui/material/styles';
import { Trans } from 'react-i18next';

import { Button, Typography } from '@mui/material';

import WarningIcon from '@mui/icons-material/Warning';

import Modal from 'src/components/Modal';

const PREFIX = 'AdminBillingSettingsSubmitModal';

const classes = {
  container: `${PREFIX}-container`,
  warning: `${PREFIX}-warning`,
  warningIcon: `${PREFIX}-warningIcon`,
  warningExtra: `${PREFIX}-warningExtra`
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.container}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },

  [`& .${classes.warning}`]: {
    alignItems: 'center',
    color: theme.palette.warning[600],
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },

  [`& .${classes.warningIcon}`]: {
    margin: `0 ${theme.spacing(2)}`
  },

  [`& .${classes.warningExtra}`]: {
    color: theme.palette.warning[600],
    textAlign: 'center'
  }
}));

const AdminBillingSettingsSubmitModal = props => {
  const { isOpen, onClose, onSubmit, isUpdatingSecretKey } = props;

  return (
    <StyledModal // `disableBackdropClick` is removed by codemod.
      // You can find more details about this breaking change in [the migration guide](https://mui.com/material-ui/migration/v5-component-changes/#modal)

      fullWidth
      open={isOpen}
      headerText={t('admin:billingSettings.modalTitle')}
      showClose={false}
      FooterComponent={() => (
        <>
          <Button onClick={onClose}>
            <Trans i18nKey="admin:billingSettings.closeModal">Close</Trans>
          </Button>
          <Button color="primary" onClick={onSubmit}>
            <Trans i18nKey="admin:billingSettings.publishModal">Publish</Trans>
          </Button>
        </>
      )}
    >
      <div className={classes.container}>
        <Typography variant="h5" className={classes.warning}>
          <WarningIcon className={classes.warningIcon} />
          <Trans i18nKey="admin:billingSettings.confirmation">
            Are you sure you want to do this?
          </Trans>
          <WarningIcon className={classes.warningIcon} />
        </Typography>
        {isUpdatingSecretKey && (
          <Typography className={classes.warningExtra}>
            <Trans i18nKey="admin:billingSettings.updateSecretKeyWarning">
              You are updating the secret key. This can break our ability to
              bill users.
            </Trans>
          </Typography>
        )}
      </div>
    </StyledModal>
  );
};

export default AdminBillingSettingsSubmitModal;
