import { t } from 'i18next';
import { styled } from '@mui/material/styles';
import { Trans } from 'react-i18next';

import { Button, Typography } from '@mui/material';

import WarningIcon from '@mui/icons-material/Warning';

import Modal from 'src/components/Modal';
import Loading from 'src/components/Loading';

const PREFIX = 'SkinPublishModal';

const classes = {
  diffContainer: `${PREFIX}-diffContainer`,
  diffWarning: `${PREFIX}-diffWarning`,
  diffWarningIcon: `${PREFIX}-diffWarningIcon`,
  diffWarningExtra: `${PREFIX}-diffWarningExtra`
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.diffContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },

  [`& .${classes.diffWarning}`]: {
    alignItems: 'center',
    color: theme.palette.warning[600],
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },

  [`& .${classes.diffWarningIcon}`]: {
    margin: `0 ${theme.spacing(2)}`
  },

  [`& .${classes.diffWarningExtra}`]: {
    color: theme.palette.warning[600],
    textAlign: 'center'
  }
}));

const AdminSkinPublishModal = props => {
  const { close, error, loading, open, publishError, submit } = props;

  const env = (() => {
    switch (import.meta.env.EVOCALIZE_ENV) {
      case 'production':
        return t('adminSkinSettings:modal.productionEnv');
      case 'staging':
        return t('adminSkinSettings:modal.stageEnv');
      default:
        return t('adminSkinSettings:modal.developmentEnv');
    }
  })();

  return (
    <StyledModal // `disableBackdropClick` is removed by codemod.
      // You can find more details about this breaking change in [the migration guide](https://mui.com/material-ui/migration/v5-component-changes/#modal)

      fullWidth
      open={open}
      headerText={t('adminSkinSettings:modal.publishHeader')}
      showClose={false}
      FooterComponent={() => (
        <>
          <Button data-cy="skin-publish-button-close" onClick={close}>
            <Trans i18nKey="adminSkinSettings:modal.close">Close</Trans>
          </Button>
          <Button
            data-cy="skin-publish-button-confirm"
            color="primary"
            onClick={submit}
            disabled={loading}
          >
            <Trans i18nKey="adminSkinSettings:modal.publish">Publish</Trans>
          </Button>
        </>
      )}
    >
      <div className={classes.diffContainer}>
        <Typography variant="h5" className={classes.diffWarning}>
          <WarningIcon className={classes.diffWarningIcon} />
          <span>
            {loading ? (
              <Trans i18nKey="adminSkinSettings:modal.publishing">
                Publishing your changes to
              </Trans>
            ) : (
              <Trans i18nKey="adminSkinSettings:modal.publishWarning">
                Please confirm your changes in
              </Trans>
            )}
            <br />
            <strong>{env}</strong>
          </span>
          <WarningIcon className={classes.diffWarningIcon} />
        </Typography>
        <br />
        {loading ? (
          <Loading />
        ) : (
          <Typography variant="subtitle1" className={classes.diffWarningExtra}>
            <Trans i18nKey="adminSkinSettings:modal.publishWarningExtra">
              All of your changes will affect the whole organization and will go
              live when you publish.
            </Trans>
          </Typography>
        )}

        {(error || publishError) && (
          <Typography color="error">
            {error}
            {publishError}
          </Typography>
        )}
      </div>
    </StyledModal>
  );
};

export default AdminSkinPublishModal;
