import { Typography, Grid, Paper } from '@mui/material';

import { styled } from '@mui/material/styles';

import Sandbox from './Sandbox';

const PREFIX = 'Playground';

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: '100%',
    width: '100%'
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2)
  }
}));

const Playground = () => {
  return (
    <Root>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h4">
            <span aria-label="eyes" role="img">
              👀 🏝 Sandbox 🏖 👀
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Sandbox />
          </Paper>
        </Grid>
      </Grid>
    </Root>
  );
};

export default Playground;
