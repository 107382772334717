import { Box, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { getSx } from 'src/components/ReduxForm/helpers';

interface MessageBackgroundProps {
  children: React.ReactNode;
  bgcolor?: string;
  sx?: SxProps;
}

const defaultBgColor = 'grey.100';

const MessageBackground = ({
  bgcolor = defaultBgColor,
  children,
  sx,
  ...rest
}: MessageBackgroundProps) => {
  const theme = useTheme();
  const sxStyles = getSx(sx, theme);
  return (
    <Box
      {...rest}
      sx={{
        bgcolor,
        px: 2.5,
        py: 1,
        borderRadius: 2,
        ...sxStyles
      }}
    >
      {children}
    </Box>
  );
};

export default MessageBackground;
