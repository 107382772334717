import { SnackbarProvider } from 'notistack';
import { styled } from '@mui/material/styles';

import { Theme } from '@mui/system';
import { ReactNode, CSSProperties } from 'react';

const PREFIX = 'AdmiralSnackbarProvider';

const classes = {
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  warning: `${PREFIX}-warning`,
  info: `${PREFIX}-info`,
  containerRoot: `${PREFIX}-containerRoot`
};

const StyledSnackbarProvider = styled(SnackbarProvider)(
  ({ theme }: { theme: Theme }) => ({
    [`& .${classes.success}`]: {
      background: 'white',
      border: `3px solid ${theme.palette.success[500]}`,
      color: theme.palette.success[500]
    },

    [`& .${classes.error}`]: {
      background: 'white',
      border: `3px solid ${theme.palette.error.main}`,
      color: theme.palette.error.main
    },

    [`& .${classes.warning}`]: {
      background: 'white',
      border: `3px solid ${theme.palette.warning[500]}`,
      color: theme.palette.warning[500]
    },

    [`& .${classes.info}`]: {
      background: 'white',
      border: `3px solid ${theme.palette.grey[500]}`,
      color: theme.palette.grey[500]
    },

    [`& .${classes.containerRoot}`]: {
      '& .SnackbarContent-root': {
        flexWrap: 'nowrap' as CSSProperties['flexWrap'],
        maxWidth: '680px'
      }
    }
  })
);

export interface AdmiralSnackBarProviderProps {
  children: ReactNode;
}

const AdmiralSnackBarProvider = ({
  children
}: AdmiralSnackBarProviderProps) => {
  return (
    <StyledSnackbarProvider
      autoHideDuration={4000}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      classes={{
        containerRoot: classes.containerRoot
      }}
      SnackbarProps={{
        // @ts-expect-error data-cy is a valid attribute but doesn't exist in the list of HTML attributes
        'data-cy': 'admiral-snack-bar-snack'
      }}
    >
      {children}
    </StyledSnackbarProvider>
  );
};

export default AdmiralSnackBarProvider;
