import { Trans } from 'react-i18next';

import { Typography, LinearProgress, Box } from '@mui/material';

const UploadingProgress = ({ totalFileCount, completedFileCount }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
      }}
      data-cy="upload-progress-tile"
    >
      <Typography variant="h6">
        <Trans
          i18nKey="gallery:progress.uploadingFiles"
          values={{ completedFileCount, totalFileCount }}
        />
      </Typography>
      <LinearProgress
        sx={{ width: '100%' }}
        color="secondary"
        variant="query"
      />
    </Box>
  );
};

export default UploadingProgress;
