import { useMemo } from 'react';
import { flow, get } from 'lodash';
import { getFormValues, change, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';

import { Typography, Box } from '@mui/material';

import { fieldArrayFixDumbReduxFormError } from 'src/common/validations';

import { BLUEPRINT_BUILDER_FORM_NAME } from '../Constants';
import RenderConfigureUserInputs from './RenderConfigureUserInputs';
import BlueprintBuilderStepFooter from './BlueprintBuilderStepFooter';

const pageText = () => ({
  stepHeading: t('admin:blueprintBuilder.stepConfigureInputsStepHeading'),
  stepSubHeading: t('admin:blueprintBuilder.stepConfigureInputsStepSubHeading')
});

const StepUserInput = () => {
  const text = useMemo(() => pageText(), []);

  return (
    <Box>
      <Typography variant="h6">{text.stepHeading}</Typography>
      <Typography variant="subtitle2">{text.stepSubHeading}</Typography>
      <br />
      <FieldArray
        component={RenderConfigureUserInputs}
        name="document.inputSections"
        validate={[fieldArrayFixDumbReduxFormError]}
      />
      <BlueprintBuilderStepFooter />
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    sections: get(
      getFormValues(BLUEPRINT_BUILDER_FORM_NAME)(state),
      'configureUserInputs.userInputSections'
    )
  };
};

export default flow(connect(mapStateToProps, { change }))(StepUserInput);
