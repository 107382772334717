import { useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { graphql } from '@apollo/client/react/hoc';
import { useSnackbar } from 'notistack';
import { reduxForm } from 'redux-form';
import { flow } from 'lodash';
import { t } from 'i18next';

import { CircularProgress, Paper, Button } from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';

import { DynamicForm } from 'src/components/ReduxForm';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';

import {
  buildCreateOrUpdateInput,
  getResourceCollectionFormInputs
} from './constants';
import {
  createResourceCollection,
  updateResourceCollection,
  deleteResourceCollection
} from './queries';

const PREFIX = 'ResourceCollectionForm';

const classes = {
  formContainer: `${PREFIX}-formContainer`,
  deleteButton: `${PREFIX}-deleteButton`
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.formContainer}`]: {
    padding: theme.spacing(2)
  },

  [`& .${classes.deleteButton}`]: {
    background: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.dark
    }
  }
}));

const pageText = () => ({
  errorMessage: t('adminResourceLibrary:collections.form.errorMessage'),
  successMessage: t('adminResourceLibrary:collections.form.successMessage'),
  updateButton: t('adminResourceLibrary:collections.form.updateButton'),
  createButton: t('adminResourceLibrary:collections.form.createButton'),
  cancelButton: t('adminResourceLibrary:collections.form.cancelButton'),
  resetButton: t('adminResourceLibrary:collections.form.resetButton'),
  deleteButton: t('adminResourceLibrary:collections.form.deleteButton'),
  deleteWarningHeader: t(
    'adminResourceLibrary:collections.form.deleteWarningHeader'
  ),
  deleteWarningBody: t(
    'adminResourceLibrary:collections.form.deleteWarningBody'
  ),
  deleteSuccessMessage: t(
    'adminResourceLibrary:collections.form.deleteSuccessMessage'
  ),
  deleteErrorMessage: t(
    'adminResourceLibrary:collections.form.deleteErrorMessage'
  )
});

const ResourceCollectionForm = ({
  isUpdate,
  initialValues,
  onAfterSuccess,
  reset,
  handleSubmit,
  createResourceCollection,
  updateResourceCollection,
  deleteResourceCollection,
  submitting,
  dirty,
  invalid,
  closeForm
}) => {
  const text = useMemo(() => pageText(), []);
  const inputs = useMemo(
    () => getResourceCollectionFormInputs(isUpdate),
    [isUpdate]
  );
  const { enqueueSnackbar } = useSnackbar();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onSubmit = useCallback(
    async data => {
      const input = buildCreateOrUpdateInput(isUpdate, data, initialValues);

      const request = {
        variables: { input }
      };

      try {
        await (isUpdate
          ? updateResourceCollection(request)
          : createResourceCollection(request));
      } catch (error) {
        enqueueSnackbar(text.errorMessage, {
          variant: 'error'
        });

        reset();
        return;
      }

      enqueueSnackbar(text.successMessage, {
        variant: 'success'
      });

      reset();
      onAfterSuccess();
    },
    [isUpdate, initialValues, reset, onAfterSuccess]
  );

  const onDelete = useCallback(async () => {
    const request = {
      variables: { id: initialValues.id }
    };

    try {
      await deleteResourceCollection(request);
    } catch (error) {
      enqueueSnackbar(text.deleteErrorMessage, {
        variant: 'error'
      });

      reset();
      return;
    }

    enqueueSnackbar(text.deleteSuccessMessage, {
      variant: 'success'
    });

    reset();
    onAfterSuccess();
  }, [isUpdate, initialValues, reset, onAfterSuccess]);

  return (
    <StyledPaper className={classes.formContainer}>
      <form
        autoComplete="off"
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Use the key prop here to ensure we don't keep old form data around
                    when switching between update and create. */}
        <DynamicForm key={isUpdate} disabled={false} inputs={inputs} />
        <br />
        <Button
          color="primary"
          disabled={submitting || !dirty || invalid}
          endIcon={submitting && <CircularProgress size={15} />}
          variant="contained"
          type="submit"
        >
          {isUpdate ? text.updateButton : text.createButton}
        </Button>{' '}
        {isUpdate && (
          <>
            <Button
              className={classes.deleteButton}
              variant="outlined"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              {text.deleteButton}
            </Button>{' '}
          </>
        )}
        <Button color="secondary" variant="outlined" onClick={() => reset()}>
          {text.resetButton}
        </Button>{' '}
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            reset();
            closeForm();
          }}
        >
          {text.cancelButton}
        </Button>
        <ConfirmationModal
          icon={<WarningIcon />}
          cancelButtonText={text.cancelButton}
          confirmButtonText={text.deleteButton}
          title={text.deleteWarningHeader}
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={onDelete}
        >
          {text.deleteWarningBody}
        </ConfirmationModal>
      </form>
    </StyledPaper>
  );
};

export default flow(
  reduxForm({
    enableReinitialize: true,
    form: 'resourceCollectionForm'
  }),
  graphql(createResourceCollection, {
    name: 'createResourceCollection'
  }),
  graphql(updateResourceCollection, {
    name: 'updateResourceCollection'
  }),
  graphql(deleteResourceCollection, {
    name: 'deleteResourceCollection'
  })
)(ResourceCollectionForm);
