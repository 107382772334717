import { isNumber } from 'lodash';

import { Box } from '@mui/material';

import { s3BaseUrl } from 'src/components/AdPreview/S3Image';

const PREVIEW_DRAWER_IMAGE = 'phone-mock-up.svg';
const PHONE_MOCK_HEIGHT = 210;
const PHONE_MOCK_WIDTH = 195;
const DEFAULT_CONTAINER_WIDTH = '240px';
const WIDTH_UNIT = 'px';

const PhoneMockUp = ({ children, width = DEFAULT_CONTAINER_WIDTH }) => {
  // If width is a number, the height calculation will still work.
  const formattedWidth = isNumber(width) ? `${width}${WIDTH_UNIT}` : width;

  return (
    <Box
      sx={{
        overflow: 'hidden',
        width: formattedWidth,
        height: `calc((${PHONE_MOCK_HEIGHT}/${PHONE_MOCK_WIDTH}) * ${formattedWidth})`,
        backgroundImage: `url("${s3BaseUrl}${PREVIEW_DRAWER_IMAGE}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        pt: 2,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      {children && children}
    </Box>
  );
};

export default PhoneMockUp;
