// import { useState, ChangeEvent } from 'react';
import { t } from 'i18next';

import {
  Box,
  Typography,
  // Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Divider
} from '@mui/material';
import TextFieldsIcon from '@mui/icons-material/TextFields';

import {
  MetaField,
  GoogleField
} from 'src/components/AdPreview/IncludedButton/IncludedButton';
import ChipText from 'src/components/AdPreview/IncludedButton/ChipText';

interface TextCarouselProps {
  textData: {
    meta: MetaField[];
    google: GoogleField[];
  };
}

const TextCarousel = ({ textData }: TextCarouselProps) => {
  // turning off pagination for now
  // const [currentIndex, setCurrentIndex] = useState<number>(0);
  // const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
  //   setCurrentIndex(value - 1); // Pagination is 1-based, so adjust to 0-based index
  // };

  const currentIndex = 0;

  const combinedData: (MetaField | GoogleField)[] = [
    ...textData.meta,
    ...textData.google
  ];
  const totalItems: number = combinedData.length;

  if (totalItems === 0) {
    return <Typography>No text data available.</Typography>;
  }

  const currentItem = combinedData[currentIndex] as MetaField;
  const { headline, description, body } = currentItem;

  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 1,
        border: theme => `1px solid ${theme.palette.grey[300]}`,
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        variant="body1"
        sx={{ p: 1, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
      >
        <TextFieldsIcon
          fontSize="small"
          sx={{ mr: 1, color: theme => theme.palette.grey[700] }}
        />
        {t('common:plg.whatsIncluded.modal.textExample')}
      </Typography>
      <Divider />

      <Box
        sx={{
          p: 1
        }}
      >
        <Table sx={{ mb: 3 }}>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', width: '30%' }}>
                Headline
              </TableCell>
              <TableCell>
                <ChipText text={headline} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
              <TableCell>
                <ChipText text={description} />
              </TableCell>
            </TableRow>
            {body && (
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Body</TableCell>
                <TableCell>
                  <ChipText text={body} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>

      {/* turning this off for now i have a feeling they will want it back on */}
      {/* <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignSelf: 'flex-end',
          position: 'absolute',
          bottom: theme => theme.spacing(2)
        }}
      >
        <Pagination
          count={totalItems}
          page={currentIndex + 1}
          onChange={handlePageChange}
        />
      </Box> */}
    </Box>
  );
};

export default TextCarousel;
