import { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { flow, pickBy } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { useSnackbar } from 'notistack';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import {
  Paper,
  Typography,
  Button,
  CircularProgress,
  Grid
} from '@mui/material';

import PageTitle from 'src/components/PageTitle/PageTitle';
import Loading from 'src/components/Loading';
import { DynamicForm } from 'src/components/ReduxForm';
import { formatDate, dayjs } from 'src/common/dates';

import { updatePromotion } from './mutations';
import { getPromotion } from './queries';
import { getPromoInputs } from './constants';

const PREFIX = 'PromotionDetails';

const classes = {
  content: `${PREFIX}-content`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.content}`]: {
    padding: theme.spacing(2)
  }
}));

const AdminPromotionDetails = ({
  reset,
  handleSubmit,
  submitting,
  invalid,
  promotions: { loading, error, refetch },
  initialValues,
  updatePromotion
}) => {
  const title = t('adminPromotions:edit.title');
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async data => {
      const onlyChanged = pickBy(
        data,
        (val, key) => val !== initialValues?.[key]
      );

      // This value should never change since the input will be disasbled in the
      // edit form. However, if it does change somehow, we don't want to send it with the mutation
      if (onlyChanged?.platformFeeCalculation) {
        delete onlyChanged.platformFeeCalculation;
      }

      const cleanData = {
        ...onlyChanged,
        // id required
        promotionId: data.id,
        ...(onlyChanged?.startDate && {
          startDate: formatDate({
            date: dayjs.utc(data.startDate),
            format: 'YYYY-MM-DD'
          })
        }),
        ...(onlyChanged?.endDate && {
          endDate: formatDate({
            date: dayjs.utc(data.endDate),
            format: 'YYYY-MM-DD'
          })
        })
      };

      // We don't allow updating of supportType and the input is disabled. However the
      // input is still in the form so users can see supportType
      delete cleanData.supportType;

      try {
        await updatePromotion({
          variables: { updateRequest: cleanData }
        });
      } catch (error) {
        enqueueSnackbar(
          t('adminPromotions:edit.errorDescription', {
            promoCode: data.promoCode,
            error: error.toString()
          }),
          {
            variant: 'error'
          }
        );
        await refetch();
        reset();
        return;
      }

      enqueueSnackbar(
        t('adminPromotions:edit.successDescription', {
          promoCode: data.promoCode
        }),
        {
          variant: 'success'
        }
      );

      await refetch();
      reset();
    },
    [initialValues, reset, refetch]
  );

  const inputs = getPromoInputs(true);

  if (loading || error) {
    return <Loading error={error} />;
  }

  return (
    <Root>
      <PageTitle subPageTitle={title} />
      <Typography variant="h5">{title}</Typography>
      <Typography variant="subtitle2">
        <Trans i18nKey="adminPromotions:edit.subtitle">Promotion Details</Trans>
      </Typography>
      <Paper className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <form
              autoComplete="off"
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <DynamicForm disabled={false} inputs={inputs} />
              <br />
              <Button
                color="primary"
                disabled={submitting || invalid}
                endIcon={submitting && <CircularProgress size={15} />}
                variant="contained"
                type="submit"
              >
                <Trans i18nKey="adminPromotions:edit.submit">
                  Update Promo
                </Trans>
              </Button>{' '}
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => reset()}
              >
                <Trans i18nKey="adminPromotions:edit.reset">Reset Form</Trans>
              </Button>
            </form>
          </Grid>
        </Grid>
      </Paper>
    </Root>
  );
};

export default flow(
  reduxForm({
    enableReinitialize: true,
    form: 'updatePromotionForm'
  }),
  graphql(updatePromotion, {
    name: 'updatePromotion'
  }),
  graphql(getPromotion, {
    name: 'getPromotion',
    options: ({ match }) => ({
      fetchPolicy: 'no-cache',
      variables: {
        promotionId: match?.params?.promoId
      }
    }),
    props: ({ getPromotion: { error, loading, myOrganization, refetch } }) => {
      const promotions = myOrganization?.promotions || [];
      const promotion = promotions[0] || {};

      return {
        promotions: {
          loading,
          error,
          refetch,
          promotion
        },
        initialValues: promotion
      };
    }
  }),
  withRouter
)(AdminPromotionDetails);
