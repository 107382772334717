import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import Papa from 'papaparse';

import { Box, Typography, Button, CircularProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { isEmpty } from 'lodash';

interface BulkUploadResourceItemsProps {
  bulkUploadResourceItems: (
    items: Array<{ key: string; value: string; description: string }>
  ) => Promise<void>;
}

interface ParsedItem {
  key: string;
  value: string;
  description: string;
}

const pageText = () => ({
  dropzoneText: t('adminResourceLibrary:items.bulkUpload.dropzoneText'),
  uploadButton: t('adminResourceLibrary:items.bulkUpload.uploadButton'),
  successMessage: t('adminResourceLibrary:items.bulkUpload.successMessage'),
  errorMessage: t('adminResourceLibrary:items.bulkUpload.errorMessage'),
  invalidFileType: t('adminResourceLibrary:items.bulkUpload.invalidFileType'),
  invalidFormat: t('adminResourceLibrary:items.bulkUpload.invalidFormat'),
  resourceItems: t('adminResourceLibrary:items.bulkUpload.resourceItems'),
  missingHeaders: t('adminResourceLibrary:items.bulkUpload.missingHeaders')
});

const BulkUploadResourceItems = ({
  bulkUploadResourceItems
}: BulkUploadResourceItemsProps) => {
  const text = pageText();
  const { enqueueSnackbar } = useSnackbar();
  const [isUploading, setIsUploading] = useState(false);
  const [parsedItems, setParsedItems] = useState<ParsedItem[] | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const validateCsvData = (
    data: any[]
  ): { isValid: boolean; missingColumns: string[] } => {
    if (!data || data.length === 0)
      return { isValid: false, missingColumns: [] };

    const requiredColumns = ['Key', 'Value', 'Description'];
    const headers = Object.keys(data[0]);

    const missingColumns = requiredColumns.filter(
      col => !headers.some(header => header.toLowerCase() === col.toLowerCase())
    );

    return {
      isValid: missingColumns.length === 0,
      missingColumns
    };
  };

  const parseFile = (file: File) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: results => {
        const validation = validateCsvData(results.data);

        if (!validation.isValid) {
          const missingColumnsStr = validation.missingColumns.join(', ');
          enqueueSnackbar(`${text.missingHeaders}: ${missingColumnsStr}`, {
            variant: 'error',
            autoHideDuration: 6000
          });
          setParsedItems(null);
          setSelectedFile(null);
          return;
        }

        const errors = results?.errors;

        if (!isEmpty(errors)) {
          errors.forEach(error => {
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 6000
            });
          });
          setParsedItems(null);
          setSelectedFile(null);
          return;
        }

        // Filter out any extra columns, only keep required fields
        const items = results.data.map((row: any) => ({
          key: row.Key || row.key,
          value: row.Value || row.value,
          description: row.Description || row.description
        }));

        setParsedItems(items);
        setSelectedFile(file);
        enqueueSnackbar(text.successMessage, { variant: 'success' });
      },
      error: () => {
        enqueueSnackbar(text.errorMessage, {
          variant: 'error',
          autoHideDuration: 6000
        });
        setParsedItems(null);
        setSelectedFile(null);
      }
    });
  };

  const handleUpload = async () => {
    if (!parsedItems) return;

    setIsUploading(true);
    try {
      await bulkUploadResourceItems(parsedItems);
      // Reset state after successful upload
      setParsedItems(null);
      setSelectedFile(null);
    } catch (error) {
      enqueueSnackbar(text.errorMessage, {
        variant: 'error',
        autoHideDuration: 6000
      });
    } finally {
      setIsUploading(false);
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;
    const file = acceptedFiles[0];
    parseFile(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv',
    maxFiles: 1,
    multiple: false
  });

  return (
    <Box>
      <Box
        {...getRootProps()}
        sx={{
          border: '2px dashed',
          borderColor: 'divider',
          borderRadius: 1,
          p: 3,
          mb: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          minHeight: 200,
          bgcolor: isDragActive ? 'action.hover' : 'background.paper'
        }}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon
          sx={{ fontSize: 48, mb: 2, color: 'text.secondary' }}
        />
        <Typography variant="h6" color="text.secondary" align="center">
          {selectedFile
            ? `${selectedFile.name} (${parsedItems?.length ?? 0} ${text.resourceItems})`
            : text.dropzoneText}
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        disabled={isUploading || !parsedItems}
        endIcon={isUploading && <CircularProgress size={20} />}
        fullWidth
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handleUpload}
      >
        {text.uploadButton}
      </Button>
    </Box>
  );
};

export default BulkUploadResourceItems;
