import { useState, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { flow, noop } from 'lodash';
import { reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';

import { Button, Typography, CircularProgress } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

import { DynamicForm } from 'src/components/ReduxForm';
import ErrorMessage from 'src/components/Containers/ErrorMessage';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';

import { UPDATE_FORM_NAME, getInputs } from './Constants';

const PREFIX = 'UpdateWebhook';

const classes = {
  cardHeading: `${PREFIX}-cardHeading`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  errorContainer: `${PREFIX}-errorContainer`
};

const Root = styled('form')(({ theme }) => ({
  [`& .${classes.cardHeading}`]: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },

  [`& .${classes.buttonContainer}`]: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  },

  [`& .${classes.errorContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
}));

const pageText = () => ({
  cardHeading: t('admin:updateWebhook.cardHeading'),
  cancelButton: t('admin:updateWebhook.cancelButton'),
  errorMessage: t('admin:updateWebhook.errorMessage'),
  modalHeading: t('admin:updateWebhook.modalHeading'),
  submitButton: t('admin:updateWebhook.submitButton'),
  removeBody: t('admin:updateWebhook.removeBody'),
  removeButton: t('admin:updateWebhook.removeButton')
});

const UpdateWebhook = props => {
  const { dirty, handleSubmit, submitFailed, onSubmit, onRemove, submitting } =
    props;

  const updateWebhookSubscriptionInputs = getInputs(true);

  const text = useMemo(() => pageText(), []);

  const [removeModal, setRemoveModal] = useState(false);
  const toggleModal = useCallback(() => {
    setRemoveModal(!removeModal);
  }, [removeModal]);

  return (
    <Root
      autoComplete="off"
      onSubmit={handleSubmit(data => onSubmit(data, UPDATE_FORM_NAME, noop))}
    >
      <Typography component="h2" className={classes.cardHeading}>
        {text.cardHeading}
      </Typography>
      {submitFailed && (
        <div className={classes.errorContainer}>
          <ErrorMessage>{text.errorMessage}</ErrorMessage>
        </div>
      )}
      <DynamicForm inputs={updateWebhookSubscriptionInputs} />
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          disabled={!dirty}
          type="submit"
          variant="contained"
          endIcon={submitting && <CircularProgress size={15} />}
        >
          {text.submitButton}
        </Button>

        <Button onClick={toggleModal}>{text.removeButton}</Button>
      </div>
      <ConfirmationModal
        icon={<DeleteIcon />}
        cancelButtonText={text.cancelButton}
        confirmButtonText={text.removeButton}
        title={text.modalHeading}
        open={removeModal}
        onClose={toggleModal}
        onConfirm={() => onRemove()}
      >
        {text.removeBody}
      </ConfirmationModal>
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { webhookSubscription } = ownProps;
  const initialValues = {
    url: webhookSubscription?.url
  };

  if (webhookSubscription?.hasAuthenticationParameters) {
    initialValues.providers =
      webhookSubscription?.authenticationParameters?.providers.map(provider => {
        return { name: provider?.value?.name };
      });
  }

  return {
    initialValues
  };
};

export default flow(
  reduxForm({
    form: UPDATE_FORM_NAME,
    destroyOnUnmount: true
  }),
  connect(mapStateToProps, { SubmissionError })
)(UpdateWebhook);
