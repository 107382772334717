import {
  get,
  includes,
  cloneDeep,
  isString,
  isObject,
  isEmpty,
  set,
  has,
  cloneDeepWith,
  isArray,
  keys,
  find,
  reduce,
  isFunction
} from 'lodash';
import { getObjectKeysAsPath } from 'src/common/utilities';

export const headerInErrorState = ({
  formErrors,
  formMeta,
  submitFailed,
  key
}) => {
  // NOTE: In order to validate the the form for the headers we need to compare
  //       formErrors, formMeta, and submitFailed.
  //
  //       formErrors: - will show all fields in error even on initial load
  //                   - redux-form selector: formErrors: getFormSyncErrors(FORM_NAME)(state)
  //
  //       formMeta: - will show what fields have been touched
  //                 - redux-form selector: formMeta: getFormMeta(FORM_NAME)(state)
  //
  //       submitFailed: will tell us if the submit failed so we can now show all errors

  // check if section key is even in error object
  if (get(formErrors, key)) {
    // if !!formErrors[key] && submitFailed then this section has errors
    if (submitFailed) {
      return true;
    }
    // get the key/path from the error object
    const errorFields = getObjectKeysAsPath(formErrors, 'ignoreArrays');

    for (let i = 0; i < errorFields.length; i++) {
      const errorField = errorFields[i];

      // check to see if the error is in this section key && has been touched
      if (includes(errorField, key) && get(formMeta, `${errorField}.touched`)) {
        return true;
      }
    }
  }

  return false;
};

export const getInitialValuesFromInputsConfig = (inputs, sectionName) => {
  const initialValues = {};

  inputs.forEach(input => {
    // has initial value
    if (input?.initialValue || input?.initialValue !== undefined) {
      // has section name
      if (sectionName) {
        // set initial value
        set(initialValues, `${input.name}`, input.initialValue);
        return;
      }
      // set initial value
      set(initialValues, input.name, input.initialValue);
    }
  });

  return initialValues;
};

/**
 * Replaces all empty arrays in the given set of values with null.
 * If onlyKeysMatching is provided, then only keys matching something in the
 * set are considered for replacement.
 * @param formValues {any}
 * @param onlyKeysMatching {Set<string>?}
 * @return {any}
 */
export const replaceEmptyArraysWithNull = (formValues, onlyKeysMatching) => {
  return cloneDeepWith(formValues, (value, key) => {
    if (
      isArray(value) &&
      isEmpty(value) &&
      (onlyKeysMatching == null || onlyKeysMatching.has(key))
    ) {
      return null;
    }
  });
};

export const nullEmptyStringValues = formValues => {
  const newValues = cloneDeep(formValues);

  if (isEmpty(newValues)) {
    return newValues;
  }

  // change empty strings to null values
  return JSON.parse(JSON.stringify(newValues), (key, value) => {
    if (isString(value) && isEmpty(value)) {
      return null;
    }

    return value;
  });
};

export const omitDeep = (collection, excludeKeys) => {
  const omitFn = value => {
    if (value && typeof value === 'object') {
      excludeKeys.forEach(key => {
        // eslint-disable-next-line no-param-reassign
        delete value[key];
      });
    }
  };

  return cloneDeepWith(collection, omitFn);
};

export const configureInputs = ({
  inputs, // array of inputs
  disabledInputs, // set with input names as the array values ex: new Set(['slug', 'available'])
  hiddenInputs, // set with input names as the array values ex: new Set(['slug', 'available'])
  enumInputs, // key/value object with key as input name and value being enumeration to look for ex: {objectiveSlug: 'OBJECTIVE_SLUGS'}
  enumerationValues // from api call ... listEnumeratedValues?.enumerationValues
}) => {
  const newInputs = cloneDeep(inputs);

  const inputEnumsByName = keys(enumInputs).reduce((accum, inputName) => {
    let options;
    if (isArray(enumerationValues)) {
      options =
        find(enumerationValues, {
          enumeration: enumInputs[inputName]
        })?.values || null;
    } else {
      options = enumerationValues?.[enumInputs?.[inputName]];
    }

    if (options) {
      return { ...accum, [inputName]: options };
    }
    return accum;
  }, {});

  const updatedInputs = newInputs.map(input => {
    const updatedInput = cloneDeep(input);

    // set disabled inputs
    if (disabledInputs && disabledInputs.has(input?.name)) {
      updatedInput.disabled = true;
    }

    // set hidden inputs
    if (hiddenInputs && hiddenInputs.has(input?.name)) {
      updatedInput.isHidden = true;
    }

    // add options to inputs
    if (inputEnumsByName[input?.name]) {
      const options = inputEnumsByName[input?.name].map(option => {
        // format options to have name & value keys for select component
        if (option.key) {
          // enums have key
          return { ...option, value: option.key };
        }
        // for some we are passing raw options like { name: 'foo', value: 'bar' }
        return { ...option };
      });

      updatedInput.displayParameters.inputData.options = options;
    }

    return updatedInput;
  });

  return updatedInputs;
};

// flatten our error object keys into an array like:
// { subform: { field: 'error' }, field2 } => ['subform.field', 'field2']
export const formErrorMapper = (deepObject, parent) =>
  reduce(
    deepObject,
    (arr, value, key) => {
      // error object looks like { type: 'errorType', message: 'error message' }
      if (isObject(value) && !(has(value, 'message') && has(value, 'type'))) {
        return [
          ...arr,
          ...formErrorMapper(value, parent ? `${parent}.${key}` : key)
        ];
      }

      return [...arr, parent ? `${parent}.${key}` : key];
    },
    []
  );

export const getSx = (sx, value) => {
  return isFunction(sx) ? sx(value) : sx;
};

/**
 * Formats a value for a select-like dropdown based on if it is multiselect
 * or not.
 *
 * This mostly deals with arrays and casting so that you don't have to.
 */
export const formatValue = (value, isMultiSelect) => {
  if (isMultiSelect) {
    // when input is multi select the value must be an array or it crashes the app
    if (isArray(value)) {
      return value;
    }
    return isEmpty(value) ? [] : [value];
  }
  return value;
};

export const getSxStyles = ({ sx, theme }) => {
  let sxStyles = sx;

  if (isFunction(sx)) {
    sxStyles = sx(theme);
  }

  return sxStyles;
};
