import { useState, ChangeEvent } from 'react';
import { t } from 'i18next';

import { Box, Divider, Pagination, Typography } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';

interface ImageCarouselProps {
  images: string[];
}

const ImageCarousel = ({ images }: ImageCarouselProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentImageIndex(value - 1);
  };

  if (images.length === 0) {
    return (
      <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
        No images available for this theme.
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        border: theme => `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        p: 1,
        position: 'relative'
      }}
    >
      <Typography
        variant="body1"
        sx={{ p: 1, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
      >
        <ImageIcon
          fontSize="small"
          sx={{ mr: 1, color: theme => theme.palette.grey[700] }}
        />
        {t('common:plg.whatsIncluded.modal.imageExample')}
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <Box
        sx={{
          height: '400px',
          p: 1,
          position: 'relative',
          textAlign: 'center'
        }}
      >
        <Box
          component="img"
          src={images[currentImageIndex]}
          alt={`Theme Image ${currentImageIndex + 1}`}
          sx={{
            height: 'auto',
            maxHeight: '300px',
            maxWidth: '100%'
          }}
        />
      </Box>

      <Box
        sx={{
          alignSelf: 'flex-end',
          bottom: theme => theme.spacing(2),
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          width: '100%'
        }}
      >
        <Pagination
          count={images.length}
          page={currentImageIndex + 1}
          onChange={handlePageChange}
        />
      </Box>
    </Box>
  );
};

export default ImageCarousel;
