import { useCallback, useRef, useState, useEffect } from 'react';
import { flow, shuffle, times, trimEnd, trim } from 'lodash';

import { IconButton, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { googlePublisherFacets } from '../Constants';
import { STYLES } from './GoogleAdPreviewConstants';

const searchAdColors = {
  adBadge: '#202124'
};

export const getSearchAdPreviewStyles = ({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: STYLES.fontFamily.roboto,
    margin: `${theme.spacing(2)} 0`,
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1)
  },
  urlSection: {
    color: searchAdColors.adBadge,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center'
  },
  adBadge: {
    flexShrink: 0,
    fontWeight: 700
  },
  interPunctuation: {
    padding: '0 5px'
  },
  displayUrl: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  infoArrow: {
    borderColor: ' #70757a transparent',
    borderStyle: 'solid',
    borderWidth: '5px 4px 0',
    margin: ' 0 3px 0 7px'
  },
  headlineSection: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '20px'
  },
  headlineGroup: {
    color: '#1a0dab',
    display: 'flex',
    fontSize: '18px',
    justifyContent: 'space-between',
    lineHeight: '24px',
    margin: '3px 0',
    wordBreak: 'break-word'
  },
  headline: {
    display: 'inline',
    whiteSpace: 'break-spaces'
  },
  descriptionSection: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  descriptionGroup: {
    color: '#4d5156',
    wordBreak: 'break-word',
    whiteSpace: 'break-spaces'
  }
});

const getHeadline = headlines => {
  // Creates a shuffled string value for the headline
  const shuffledHeadlines = shuffle(headlines);

  // Note
  //      - Ellipses happens at 59 characters total
  //      - Max of 3 headlines shown at once
  let str = '';
  times(3, i => {
    if (shuffledHeadlines[i]) {
      str = `${str}${i > 0 ? ' | ' : ''}${shuffledHeadlines[i]}`;
    }
  });

  if (str.length > 60) {
    return `${trimEnd(str.substring(0, 60), '| ')}...`;
  }

  return str;
};

const getDescription = descriptions => {
  // Creates a shuffled string value for the description
  const shuffledDescriptions = shuffle(descriptions);

  // Note
  //      - Max of 2 descriptions show at once
  let str = '';
  times(2, i => {
    if (shuffledDescriptions[i]) {
      str = `${str}${i > 0 ? ' ' : ''}${shuffledDescriptions[i]}`;
    }
  });

  return trim(str);
};

const GoogleSearchAdPreview = props => {
  const { adData, hideGoogleAdNavigationButtons } = props;

  const headlines = adData?.[googlePublisherFacets.headlines] || [];
  const descriptions = adData?.[googlePublisherFacets.descriptions] || [];

  // We only need to take the first one at this time the preview only shows one
  const finalUrl =
    adData?.[googlePublisherFacets.finalUrls]?.[0] ||
    adData?.[googlePublisherFacets.finalUrl];

  // Extract hostname from finalUrl. In the case that this value is coming
  // from a user input, the call to construct the URL object will fail until
  // the url is complete, so catch any errors here so the user can finish
  // typing.
  let baseDisplayPath = '';
  try {
    baseDisplayPath = new URL(finalUrl)?.hostname;
  } catch (error) {
    baseDisplayPath = '';
  }
  const displayPath1 = adData?.[googlePublisherFacets.displayPath1];
  const displayPath2 = adData?.[googlePublisherFacets.displayPath2];

  const [headline, setHeadline] = useState([]);
  const handleSetHeadline = useCallback(newHeadline => {
    setHeadline(newHeadline);
  });
  useEffect(() => {
    handleSetHeadline(getHeadline(headlines));
  }, [headlines]);

  const [description, setDescription] = useState([]);
  const handleSetDescription = useCallback(newDescription => {
    setDescription(newDescription);
  });
  useEffect(() => {
    handleSetDescription(getDescription(descriptions));
  }, [descriptions]);

  const [position, setPosition] = useState(0);
  const history = useRef(new Set());

  const pageForward = useCallback(() => {
    const newHeadline = getHeadline(headlines);
    const newDescription = getDescription(descriptions);

    if (headline === newHeadline && newDescription === description) {
      // if they match generate new permutation
      return pageForward();
    }

    const historySize = history.current.size;
    const newPosition = position + 1;

    if (historySize === position || newPosition === historySize) {
      // you are at the head of the list add a new item to the history
      handleSetHeadline(newHeadline);
      handleSetDescription(newDescription);
      setPosition(newPosition);
      return history.current.add({ headline, description });
    }

    const historyArray = Array.from(history.current);

    handleSetHeadline(historyArray?.[newPosition]?.headline);
    handleSetDescription(historyArray?.[newPosition]?.description);
    return setPosition(newPosition);
  }, [headlines, descriptions, history, position]);

  const pageBackward = useCallback(() => {
    if (position === 0) {
      return pageForward();
    }

    const historyArray = Array.from(history.current);
    const newPosition = position - 1;

    handleSetHeadline(historyArray?.[newPosition]?.headline);
    handleSetDescription(historyArray?.[newPosition]?.description);
    return setPosition(newPosition);
  }, [headlines, descriptions, history, position]);

  const theme = useTheme();
  const sxStyles = getSearchAdPreviewStyles({ theme });

  return (
    <Box sx={sxStyles.container} className="notranslate">
      {!hideGoogleAdNavigationButtons && (
        <Box sx={sxStyles.buttonContainer}>
          <IconButton onClick={pageBackward} size="large">
            <ArrowLeftIcon />
          </IconButton>
          <IconButton onClick={pageForward} size="large">
            <ArrowRightIcon />
          </IconButton>
        </Box>
      )}
      <Box sx={sxStyles.urlSection}>
        <Box sx={sxStyles.adBadge}>AD</Box>
        <Box sx={sxStyles.interPunctuation}>·</Box>
        <Box sx={sxStyles.displayUrl}>
          {baseDisplayPath}
          {displayPath1 && `/${displayPath1}`}
          {displayPath1 && displayPath2 && `/${displayPath2}`}
        </Box>
        <Box sx={sxStyles.infoArrow} />
      </Box>
      <Box sx={sxStyles.headlineSection}>
        <Box sx={sxStyles.headlineGroup}>
          <Box sx={sxStyles.headline}>{headline}</Box>
        </Box>
      </Box>
      <Box sx={sxStyles.descriptionSection}>
        <Box sx={sxStyles.descriptionGroup}>{description}</Box>
      </Box>
    </Box>
  );
};

export default flow()(GoogleSearchAdPreview);
