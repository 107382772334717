import { flow } from 'lodash';

import { Grid } from '@mui/material';

import { UNASSOCIATED_KEY } from '../Constants';
import GridViewCard from './GridViewCard';

const BlueprintGridView = props => {
  const { architectures, productsByArch } = props;

  const allProducts = architectures.reduce((accum, architecture) => {
    return [
      ...accum,
      ...(productsByArch[architecture.id] || []),
      ...(productsByArch[architecture.slug] || [])
    ];
  }, []);

  return (
    <Grid container spacing={3} data-cy="product-list">
      {allProducts.map(product => {
        return (
          <Grid item key={product.id}>
            <GridViewCard product={product} />
          </Grid>
        );
      })}
      {/* if we have products with no architecture list them here: */}
      {productsByArch[UNASSOCIATED_KEY] && (
        <Grid item key={UNASSOCIATED_KEY}>
          <GridViewCard product={productsByArch[UNASSOCIATED_KEY]} />
        </Grid>
      )}
    </Grid>
  );
};
export default flow()(BlueprintGridView);
