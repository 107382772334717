import {
  ListItemIcon,
  ListItemText,
  Typography,
  ListItemButton
} from '@mui/material';

import { styled } from '@mui/material/styles';

import AccountCircle from '@mui/icons-material/AccountCircle';

const PREFIX = 'UserListItem';

const classes = {
  listItem: `${PREFIX}-listItem`
};

const StyledListItem = styled(ListItemButton)(() => ({
  [`&.${classes.listItem}`]: {
    padding: '4px 16px'
  }
}));

const UserListItem = props => {
  const { handleClick, user, selected } = props;

  return (
    <StyledListItem
      selected={selected}
      onClick={() => handleClick(user)}
      sx={{
        padding: '4px 16px'
      }}
    >
      <ListItemIcon>
        <AccountCircle />
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Typography variant="body2" display="block">
            {user.name}
          </Typography>
        }
        secondary={
          <Typography variant="body2" color="textSecondary" display="block">
            {user.email}
          </Typography>
        }
      />
    </StyledListItem>
  );
};

export default UserListItem;
