import { useCallback, useState, useMemo, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { clone, isString, isArray } from 'lodash';
import { t } from 'i18next';

import {
  TextField,
  IconButton,
  Button,
  FormControlLabel,
  Switch
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/Close';

const PREFIX = 'RenderSubParameterArray';

const classes = {
  outerContainer: `${PREFIX}-outerContainer`,
  outerContainerVariable: `${PREFIX}-outerContainerVariable`,
  container: `${PREFIX}-container`,
  switch: `${PREFIX}-switch`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.outerContainer}`]: {
    width: '100%'
  },

  [`& .${classes.outerContainerVariable}`]: {
    display: 'flex',
    width: '100%'
  },

  [`& .${classes.container}`]: {
    alignItems: 'center',
    display: 'flex',
    margin: `${theme.spacing(1)} 0`,
    flexGrow: '1'
  },

  [`& .${classes.switch}`]: {
    margin: `0 ${theme.spacing(1)}`,
    width: '190px'
  }
}));

const pageText = () => ({
  addButton: t('admin:blueprintBuilder.facetSubParameterAddButton'),
  useVariable: t('admin:blueprintBuilder.facetSubParameterUseVariable')
});

const ParameterInput = props => {
  // move text input to its own component so each key stroke does not cause a rerender
  const { value, index, onUpdateParameter } = props;

  const [paramValue, setParamValue] = useState(value);

  const onBlur = () => {
    onUpdateParameter(paramValue, index);
  };

  return (
    <TextField
      multiline
      maxRows={8}
      fullWidth
      variant="outlined"
      value={paramValue}
      onChange={({ target: { value } }) => {
        setParamValue(value);
      }}
      onBlur={onBlur}
    />
  );
};

const RenderSubParameterArray = props => {
  const { parameterValue, onSubValueChange, currentKey, onBlur } = props;

  const text = useMemo(() => pageText(), []);

  const [variableRef, setVariableRef] = useState(false);

  useEffect(() => {
    setVariableRef(isString(parameterValue) && !isArray(parameterValue));
  }, [parameterValue]);

  const [parameterValues, setParameterValues] = useState(variableRef ? '' : []);

  useEffect(() => {
    setParameterValues(parameterValue ? clone(parameterValue) : []);
  }, [parameterValue]);

  const onRemoveParameter = useCallback(
    parameterIndex => {
      const updatedValues = clone(parameterValues);
      updatedValues.splice(parameterIndex, 1);

      onSubValueChange(updatedValues);
    },
    [parameterValue, parameterValues, setParameterValues, onSubValueChange]
  );

  const addParameter = useCallback(() => {
    const updatedValues = clone(parameterValues);
    updatedValues.push('');

    onSubValueChange(updatedValues);
  }, [parameterValue, parameterValues, setParameterValues, onSubValueChange]);

  const onUpdateParameter = useCallback(
    (value, index) => {
      const updatedValues = clone(parameterValues);
      updatedValues[index] = value;

      setParameterValues(updatedValues);
      onSubValueChange(updatedValues);
    },
    [parameterValue, parameterValues, setParameterValues, onSubValueChange]
  );

  const onUpdateParameterVariable = useCallback(
    value => {
      setParameterValues(value);
      onSubValueChange(value);
    },
    [parameterValue, parameterValues, setParameterValues, onSubValueChange]
  );

  const toggleVariableRef = useCallback(() => {
    const updatedVariableRef = !variableRef;
    // Note: The parameterValues are also set via the useEffect above when the
    //       data comes in. Setting it here ensures things are consistent.
    setVariableRef(!variableRef);
    setParameterValues(updatedVariableRef ? '' : ['']);

    onSubValueChange(updatedVariableRef ? '' : ['']);
  }, [variableRef, onSubValueChange]);

  return (
    <Root>
      {variableRef ? (
        <div className={classes.outerContainerVariable}>
          <TextField
            multiline
            maxRows={8}
            fullWidth
            variant="outlined"
            value={parameterValues}
            onChange={({ target: { value } }) => {
              onUpdateParameterVariable(value);
            }}
            onBlur={onBlur}
          />

          <FormControlLabel
            className={classes.switch}
            control={
              <Switch checked={variableRef} onChange={toggleVariableRef} />
            }
            label={text.useVariable}
          />
        </div>
      ) : (
        <div className={classes.outerContainer}>
          {parameterValues.map((value, index) => {
            return (
              <div
                key={`${currentKey}-parameterValue-${value}`}
                className={classes.container}
              >
                <ParameterInput
                  value={value}
                  index={index}
                  onUpdateParameter={onUpdateParameter}
                />
                <IconButton
                  onClick={() => {
                    onRemoveParameter(index);
                  }}
                  size="large"
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            );
          })}
          <Button onClick={addParameter}>{text.addButton}</Button>

          <FormControlLabel
            control={
              <Switch checked={variableRef} onChange={toggleVariableRef} />
            }
            label={text.useVariable}
          />
        </div>
      )}
    </Root>
  );
};

export default RenderSubParameterArray;
