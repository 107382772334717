import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { flow, some } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';

import { Button, Typography } from '@mui/material';

import withTheme from '@mui/styles/withTheme';

import Instrumentation from 'src/instrumentation';
import { withGlobalContext } from 'src/GlobalContextProvider';

import {
  AUTOMATION_ADOPTION_MODAL,
  AUTOMATION_ADOPTION_MODAL_REMIND
} from 'src/common/userSettings';
import { isCorporateFacebookPage } from 'src/common/FacebookUtil';
import { dayjs } from 'src/common/dates';

import { paths } from 'src/routes/paths';
import { generateLinkPathWithQueryParams } from 'src/routes/RouteUtil';
import { withFeatures } from 'src/components/Feature';
import Modal from 'src/components/Modal';

import { setDismissModal } from './mutations';
import { getFacebookPagesV2 } from './queries';

const PREFIX = 'AutomationAdoptionModal';

const classes = {
  contentContainer: `${PREFIX}-contentContainer`,
  image: `${PREFIX}-image`,
  headline: `${PREFIX}-headline`,
  body: `${PREFIX}-body`,
  buttonContainer: `${PREFIX}-buttonContainer`
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.contentContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: `${theme.spacing(2)} 0`
  },

  [`& .${classes.image}`]: {
    margin: `0 10% ${theme.spacing(2)} 10% `,
    maxWidth: '700px',
    maxHeight: '400px'
  },

  [`& .${classes.headline}`]: {
    fontSize: '35px',
    fontWeight: 'bold',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center'
  },

  [`& .${classes.body}`]: {
    color: theme.palette.grey[500],
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    margin: `${theme.spacing(2)} 5% ${theme.spacing(3)} 5% `
  },

  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    '& button': {
      marginRight: theme.spacing(2)
    },
    '& button:last-child': {
      marginRight: 0
    }
  }
}));

const dismissType = {
  dismiss: 'dismiss',
  remindMe: 'remindMe',
  tryMe: 'tryMe'
};

const AutomationAdoptionModal = props => {
  const {
    setDismissModal,
    history,
    location,
    features: { automations, automationAdoptionModal },
    globalContext,
    facebookPages,
    theme
  } = props;

  const userSettings = globalContext?.me?.settings;

  const [modalOpen, setModalOpen] = useState(true);

  const {
    architectureId,
    body,
    callToActionText,
    dismissText,
    filters,
    headline,
    mainImage,
    modalHeader,
    productId,
    promoCodes,
    remindInterval,
    remindText,
    startDate
  } = theme?.app?.featureSettings?.automationAdoptionConfig || {};

  const dismissedAt = userSettings?.[AUTOMATION_ADOPTION_MODAL] || null; // setting to null b/c dayjs treats undefined as now
  const remindAt = userSettings?.[AUTOMATION_ADOPTION_MODAL_REMIND] || null; // setting to null b/c dayjs treats undefined as now

  const facebookPagesData = facebookPages?.facebookPagesV2;

  // don't render if:
  if (
    // automation feature turned off
    !automations ||
    // adoption modal feature turned off
    !automationAdoptionModal ||
    // not on the dashboard
    !location.pathname.includes('dashboard') ||
    !modalOpen ||
    // check that user has linked facebook page this prevents overlap with the FacebookLinkModal and helps ensure the user can place an order
    !some(facebookPagesData, page => !isCorporateFacebookPage(page)) ||
    // start time is after now
    dayjs(startDate).isSameOrAfter(dayjs()) ||
    // dismiss time is after start time
    dayjs(dismissedAt).isSameOrAfter(dayjs(startDate)) ||
    // remind me time is after now
    dayjs(remindAt)
      .add(remindInterval ?? 12, 'hours')
      .isSameOrAfter(dayjs()) ||
    // does not have an architecture id
    !architectureId
  ) {
    return null;
  }

  const handleDismiss = type => {
    const updateSettings = {};

    if (type === dismissType?.tryMe || type === dismissType?.dismiss) {
      updateSettings[AUTOMATION_ADOPTION_MODAL] = dayjs().valueOf();
    }

    if (type === dismissType?.remindMe) {
      updateSettings[AUTOMATION_ADOPTION_MODAL_REMIND] = dayjs().valueOf();
    }

    setDismissModal({
      variables: {
        updateSettings,
        updateType: 'DEEP_MERGE'
      }
    })
      .then(() => {
        globalContext.refetch();
        setModalOpen(false);

        if (type === dismissType?.tryMe) {
          const link = generateLinkPathWithQueryParams(
            paths.architecture.automatedProgramCreate,
            { ...(architectureId && { architectureId }) },
            {
              ...(productId && { productIds: productId }),
              ...(promoCodes && { promoCodes }),
              ...(filters && { filters }),
              showWizard: false
            }
          );

          history.push(link);
        }
      })
      .catch(() => {
        setModalOpen(false);
      });
  };

  return (
    <StyledModal // `disableBackdropClick` is removed by codemod.
      // You can find more details about this breaking change in [the migration guide](https://mui.com/material-ui/migration/v5-component-changes/#modal)

      open={modalOpen}
      fullWidth
      maxWidth="lg"
      headerText={modalHeader}
      onClose={() => {
        Instrumentation.logEvent(
          Instrumentation.Events.AutomationGrowthExperimentClose,
          {
            architectureId,
            productId,
            promoCodes
          }
        );
        setModalOpen(false);
      }}
      showClose
    >
      <div className={classes.contentContainer}>
        {mainImage && (
          <img
            className={classes.image}
            src={mainImage}
            alt="Try Automations"
          />
        )}

        {headline && (
          <Typography className={classes.headline} variant="h2">
            {headline}
          </Typography>
        )}

        {body && (
          <Typography className={classes.body} variant="h3">
            {body}
          </Typography>
        )}

        <div className={classes.buttonContainer}>
          <Button
            data-amp-click-automation-growth-experiment-call-to-action={JSON.stringify(
              {
                architectureId,
                productId,
                promoCodes
              }
            )}
            color="primary"
            variant="contained"
            onClick={() => handleDismiss(dismissType?.tryMe)}
            size="large"
          >
            {callToActionText}
          </Button>

          <Button
            data-amp-click-automation-growth-experiment-remind-later={JSON.stringify(
              {
                architectureId,
                productId,
                promoCodes
              }
            )}
            variant="outlined"
            size="large"
            onClick={() => handleDismiss(dismissType?.remindMe)}
          >
            {remindText}
          </Button>

          <Button
            data-amp-click-automation-growth-experiment-dismiss={JSON.stringify(
              {
                architectureId,
                productId,
                promoCodes
              }
            )}
            variant="outlined"
            size="large"
            onClick={() => handleDismiss(dismissType?.dismiss)}
          >
            {dismissText}
          </Button>
        </div>
      </div>
    </StyledModal>
  );
};

export default flow(
  withRouter,
  withFeatures,
  graphql(setDismissModal, { name: 'setDismissModal' }),
  withGlobalContext,

  withTheme,
  graphql(getFacebookPagesV2, {
    name: 'facebookPages',
    options: {
      // Who you gonna call? CACHE BUSTERS!
      variables: {
        antiCache: +new Date()
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  })
)(AutomationAdoptionModal);
