import { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';
import { flow, some } from 'lodash';

import { Button, Typography } from '@mui/material';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import FailureIcon from '@mui/icons-material/Report';

import Loading from 'src/components/Loading';
import { useInvokableQuery } from 'src/hooks/apollo/queryHooks';

import { getBulkOperationReportErrors } from '../queries';

const PREFIX = 'StepViewResults';

const classes = {
  downloadButton: `${PREFIX}-downloadButton`,
  resultIconContainer: `${PREFIX}-resultIconContainer`,
  closeButton: `${PREFIX}-closeButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.downloadButton}`]: {
    width: '100%',
    textAlign: 'center',
    paddingTop: theme.spacing(2)
  },

  [`& .${classes.resultIconContainer}`]: {
    textAlign: 'center'
  },

  [`& .${classes.closeButton}`]: {
    paddingTop: theme.spacing(2)
  }
}));

const pageText = () => ({
  successMessage: t('adminContentDataManager:uploadModal.successMessage'),
  errorMessage: t('adminContentDataManager:uploadModal.errorMessage'),
  downloadErrors: t('adminContentDataManager:uploadModal.downloadErrors'),
  close: t('adminContentDataManager:uploadModal.close')
});

const StepViewResults = ({ contentSetSlug, reportIds, handleClose }) => {
  const text = useMemo(() => pageText(), []);

  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  const getReportErrors = useInvokableQuery(getBulkOperationReportErrors);

  useEffect(() => {
    const fetchErrorTasks = async () => {
      const errorTasks = reportIds.map(async reportId => {
        const response = await getReportErrors({
          reportId
        });

        return response.data.getBulkOperationReportErrors;
      });

      const errorReports = await Promise.all(errorTasks);

      setErrors(errorReports.flatMap(report => report.errors));
      setIsLoading(false);
    };

    fetchErrorTasks();
  }, [reportIds]);

  const downloadErrorReport = content => {
    const a = document.createElement('a');
    const file = new Blob([JSON.stringify(content, null, 2)], {
      type: 'json'
    });
    a.href = URL.createObjectURL(file);
    a.download = `error-report-${contentSetSlug}.json`;
    a.click();
  };

  const hasErrors = some(errors);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Root>
      <div className={classes.resultIconContainer}>
        {hasErrors ? (
          <FailureIcon color="error" fontSize="large" />
        ) : (
          <SuccessIcon
            color="primary"
            fontSize="large"
            data-cy="content-data-manager-upload-success-icon"
          />
        )}
      </div>
      <Typography>
        {hasErrors ? text.errorMessage : text.successMessage}
      </Typography>
      {hasErrors && (
        <div className={classes.downloadButton}>
          <Button
            variant="contained"
            onClick={() => downloadErrorReport(errors)}
          >
            {text.downloadErrors}
          </Button>
        </div>
      )}
      <div className={classes.closeButton}>
        <Button
          variant="outlined"
          onClick={handleClose}
          data-cy="content-data-manager-upload-close"
        >
          {text.close}
        </Button>
      </div>
    </Root>
  );
};

export default flow()(StepViewResults);
