import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { withRouter } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import { t } from 'i18next';
import Alert from '@mui/material/Alert';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography
} from '@mui/material';

import PageTitle from 'src/components/PageTitle/PageTitle';
import Loading from 'src/components/Loading/Loading';
import { getStripeConnectedAccountDetails } from 'src/pages/Partner/Onboarding/queries';
import { parse } from 'qs';
import {
  initiateStripeOnBoardingOauth,
  processStripeConnectOauthCode
} from './mutations';

const PREFIX = 'StripeConnect';

const classes = {
  content: `${PREFIX}-content`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.content}`]: {
    padding: theme.spacing(2),
    maxWidth: '700px'
  }
}));

const STRIPE_CONNECTED_STATUS = {
  not_connected: 'not_connected',
  in_progress: 'in_progress',
  completed: 'completed',
  requires_update: 'requires_update'
};

const PAGE_STATES = {
  loading: 'loading',
  initial: 'initial',
  processing: 'processing',
  success: 'success'
};

const getText = () => ({
  title: t('partner:stripeOnboarding.title'),
  subtite: t('partner:stripeOnboarding.subtitle'),
  initiate: t('partner:stripeOnboarding.initiate'),
  instructions: t('partner:stripeOnboarding.instructions'),
  continue: t('partner:stripeOnboarding.continue'),
  continue2: t('partner:stripeOnboarding.continue2'),
  linkDescription: t('partner:stripeOnboarding.linkDescription'),
  linkText: t('partner:stripeOnboarding.linkText'),
  success: t('partner:stripeOnboarding.success'),
  error: t('partner:stripeOnboarding.error')
});

const StripeConnect = ({
  initiateStripeOnBoardingOauth,
  processStripeConnectOauthCode,
  getStripeConnectedAccountDetails: {
    loading: orgLoading,
    error: orgError,
    myOrganization = {}
  },
  location
}) => {
  const text = getText();
  const query = parse(location.search, { ignoreQueryPrefix: true });
  // if they clicked the button to initiate the Stripe onboarding we need to make an async call so it takes a second
  const [loading, setLoading] = useState(false);
  const [onboardingLink, setOnboardingLink] = useState(null);
  const [error, setError] = useState(null);
  const [currentState, setCurrentState] = useState(PAGE_STATES.loading);
  const [checkingOauthStatus, setCheckingOauthStatus] = useState(false);
  const isStripe0Auth = !!query.stripe_connect_auth_code;

  const stateFromStripeConnectAccountDetails = stripeConnectAccountDetails => {
    if (
      stripeConnectAccountDetails?.connectedStatus ===
        STRIPE_CONNECTED_STATUS.requires_update ||
      stripeConnectAccountDetails?.connectedStatus ===
        STRIPE_CONNECTED_STATUS.in_progress
    ) {
      return PAGE_STATES.processing;
    }
    if (
      stripeConnectAccountDetails?.connectedStatus ===
      STRIPE_CONNECTED_STATUS.completed
    ) {
      return PAGE_STATES.success;
    }
    return PAGE_STATES.initial;
  };

  const handleOauthCode = async () => {
    try {
      const { data } = await processStripeConnectOauthCode({
        variables: {
          stripeOauthCode: query.stripe_connect_auth_code
        }
      });
      if (data) {
        setCurrentState(
          stateFromStripeConnectAccountDetails(
            data.processStripeConnectOauthCode
          )
        );
        setCheckingOauthStatus(false);
      }
    } catch (error) {
      setCurrentState(PAGE_STATES.initial);
      setCheckingOauthStatus(false);
      setError(error);
    }
  };

  const handleInitiate = async () => {
    setLoading(true);
    try {
      const { data } = await initiateStripeOnBoardingOauth();
      if (data?.initiateStripeOnBoardingOauth?.link) {
        window.location.assign(data.initiateStripeOnBoardingOauth.link);
        // this is a backup in case the user is not redirected
        setOnboardingLink(data.initiateStripeOnBoardingOauth.link);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    if (orgError) {
      setError(orgError);
    }

    // check ouath for stripe connected account details if 0Auth
    if (
      currentState === PAGE_STATES.loading &&
      isStripe0Auth &&
      !checkingOauthStatus
    ) {
      setCheckingOauthStatus(true);
      handleOauthCode();
      return;
    }
    // otherwise wait to check org for stripe connected account details if not 0Auth
    if (!orgLoading && !isStripe0Auth) {
      setCurrentState(
        stateFromStripeConnectAccountDetails(
          myOrganization?.stripeConnectedAccountDetails
        )
      );
    }
  }, [orgLoading, orgError, myOrganization]);

  if (orgLoading || currentState === PAGE_STATES.loading) {
    return <Loading />;
  }

  return (
    <Root>
      <PageTitle subPageTitle={text.title} />
      <Typography variant="h5">{text.title}</Typography>
      <Typography variant="subtitle2">{text.subtitle}</Typography>
      <br />
      <Paper className={classes.content}>
        <Grid container spacing={3}>
          {error && (
            <Alert severity="error">
              {text.error}: {error}
            </Alert>
          )}
          {currentState === PAGE_STATES.initial && (
            <>
              <Alert severity="info">{text.instructions}</Alert>
            </>
          )}
          {currentState === PAGE_STATES.processing && (
            <>
              <Alert severity="warning">
                {text.continue}
                <br />
                <br />
                {text.continue2}
              </Alert>
            </>
          )}
          {currentState === PAGE_STATES.success && (
            <>
              <Alert severity="success">{text.success}</Alert>
            </>
          )}
          <br />
          {currentState === PAGE_STATES.initial && (
            <>
              {onboardingLink ? (
                <p>
                  {text.linkDescription}{' '}
                  <a href={onboardingLink}>{text.linkText}</a>
                </p>
              ) : (
                <Button
                  onClick={handleInitiate}
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  startIcon={loading && <CircularProgress size={20} />}
                >
                  {text.initiate}
                </Button>
              )}
            </>
          )}
        </Grid>
      </Paper>
    </Root>
  );
};

export default flow(
  graphql(initiateStripeOnBoardingOauth, {
    name: 'initiateStripeOnBoardingOauth'
  }),
  graphql(processStripeConnectOauthCode, {
    name: 'processStripeConnectOauthCode'
  }),
  graphql(getStripeConnectedAccountDetails, {
    name: 'getStripeConnectedAccountDetails',
    options: {
      // Who you gonna call? CACHE BUSTERS!
      variables: {
        antiCache: +new Date()
      },
      fetchPolicy: 'no-cache'
    }
  }),

  withRouter
)(StripeConnect);
