// import Instrumentation from './index';

/**
 * A helper function for instrumenting standard site loading events. A nice
 * timeline of the events can be seen here:
 * {@link https://w3c.github.io/navigation-timing/#processing-model}.
 * This code should be called after the `loadEventEnd` point in  time.
 */
export const instrumentDomLoadingEvents = () => {
  // we aren't really using these and it's been noisy in amplitude. keeping in case we want to re-add it.
  // const perfEntries = performance.getEntriesByType('navigation');
  // if (perfEntries && perfEntries.length > 0) {
  //   const {
  //     domContentLoadedEventStart,
  //     domContentLoadedEventEnd,
  //     domComplete,
  //     loadEventStart,
  //     loadEventEnd,
  //     type,
  //     redirectCount
  //   } = perfEntries[0];
  // // https://stackoverflow.com/a/11832950
  // const round = n => Math.round((n + Number.EPSILON) * 100) / 100;
  // Instrumentation.logEvent(Instrumentation.Events.PageLoaded, {
  //   domContentLoadedEventStart: round(domContentLoadedEventStart),
  //   domContentLoadedEventEnd: round(domContentLoadedEventEnd),
  //   domComplete: round(domComplete),
  //   loadEventStart: round(loadEventStart),
  //   loadEventEnd: round(loadEventEnd),
  //   type,
  //   redirectCount
  // });
  // }
};
