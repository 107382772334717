import { t } from 'i18next';
import { useFeatures } from 'src/components/Feature';
import { useState } from 'react';
import { Box } from '@mui/material';

import { GenericPlaceholder } from 'src/components/Placeholders';

import AudienceUpload from './AudienceUpload';
import AudiencesTable from './AudiencesTable';
import Heading from '../../components/PageElements/Heading';

const pageText = () => ({
  title: t('audiences:title'),
  subTitle: t('audiences:subTitle'),
  errorMessage: t('audiences:error.failedToLoad')
});

const Audiences = () => {
  const features = useFeatures();
  const audienceUpload = features?.audienceUpload;

  const text = pageText();

  /**
   * We want to re-fetch the audiences when a new audience is uploaded.
   * However, the data table the audiences table uses does not have a way to
   * re-fetch the data. So we use a unique key to force the table to re-fetch.
   *
   * The actual data in this key doesn't matter, as long as it's unique.
   */
  const [audienceUniqueKey, setAudienceUniqueKey] = useState(Date.now());

  const onAudienceUploaded = () => {
    setAudienceUniqueKey(Date.now());
  };

  return (
    <Box
      data-cy="audiences-wrapper"
      sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
    >
      <Heading
        title={text.title}
        subTitle={text.subTitle}
        pageTitle={text.title}
        divider
      />
      {audienceUpload && (
        <Box>
          <AudienceUpload onAudienceUploaded={onAudienceUploaded} />
        </Box>
      )}
      <AudiencesTable
        key={audienceUniqueKey}
        noRowsPlaceholderComponent={
          <GenericPlaceholder
            variant="noAudiences"
            title={t('audiences:placeholders.noAudiences.title')}
            subtitle={t('audiences:placeholders.noAudiences.subtitle')}
            {...(audienceUpload && {
              buttons: [
                <AudienceUpload
                  onAudienceUploaded={onAudienceUploaded}
                  key="create-audience"
                />
              ]
            })}
          />
        }
      />
    </Box>
  );
};

export default Audiences;
