import GalleryModal, {
  MediaAssetSelection
} from 'src/pages/Gallery/GalleryModal';
import { ReactNode } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { isNil, filter, reject, isArray, sortBy, reduce } from 'lodash';
import { MediaAsset } from 'src/generated/gql/graphql';
import { stripTemplateWhitespace } from 'src/common/templateTranslator';

import { GALLERY_TYPE } from 'src/pages/Gallery/constants';

export interface RenderGallerySelectorProps extends WrappedFieldProps {
  galleryType: string;
  mediaType?: string;
  showFilter?: boolean;
  sizeConstraint?: boolean;
  children?: ReactNode;
  tooltip?: string;
  helperText?: string;
  type?: string;
  label: string;
  isMultiSelect?: boolean;
  allowContentSelection?: boolean;
  arrayMinMax?: { min?: number; max?: number };
  businessObjects: any;
  dynamicValues: {
    displayMethodId: string;
    displaySortOrder: number;
    fieldName: string;
    isHidden: boolean;
    label: string;
    value: string;
  }[];
  missingColumns: Set<string>;
  extraProps: any;
  contentName: string;
}

export type ContentMediaItem = {
  value: string;
  link: string;
};

const RenderGallerySelector = (props: RenderGallerySelectorProps) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    children,
    input: { onChange, value, name },
    galleryType,
    mediaType,
    showFilter,
    sizeConstraint,
    isMultiSelect = false,
    arrayMinMax,
    allowContentSelection = false,
    businessObjects,
    dynamicValues,
    missingColumns,
    extraProps,
    contentName,
    ...rest
  } = props;

  // selection values are treated as an array internally for consistency, even if it's not multiSelect
  // we convert it here depending on multiSelectState
  let selection: any[];
  if (isNil(value) || value === '') {
    selection = [];
  } else if (isMultiSelect) {
    if (value && !isArray(value)) {
      // this is the case where we have a default value that is not an array
      // usually a legacy single select item we convert to an array
      if (value.trim().startsWith('{{')) {
        // we were selecting a default because it was a "select" input before
        // it's gallery select now so start clean and handle like urls instead of template tags
        onChange([]);
      } else {
        onChange([value]);
      }
    }
    selection = isArray(value) ? value : [value];
  } else {
    selection = [value];
  }

  let contentItems: ContentMediaItem[] = [];
  if (allowContentSelection) {
    contentItems = sortBy(
      reduce(
        dynamicValues,
        (accum, item) => {
          if (
            item?.displayMethodId === extraProps?.displayMethodId &&
            !missingColumns.has(item?.fieldName)
          ) {
            const urlString =
              businessObjects?.[0]?.[
                stripTemplateWhitespace(item?.value)
                  .replace('{{', '')
                  .replace('}}', '')
              ];
            return [
              ...accum,
              ...(urlString ? [{ value: item?.value, link: urlString }] : [])
            ];
          }
          return accum;
        },
        [] as ContentMediaItem[]
      ),
      ['displaySortOrder', 'label']
    );
  }

  return (
    <GalleryModal
      name={name}
      selection={selection}
      setSelectedGalleryItem={(selectedAsset: MediaAsset) => {
        // new media uses asset object and ID old media uses string link url
        const asset: any =
          galleryType === GALLERY_TYPE.media
            ? {
                id: selectedAsset?.id,
                type: selectedAsset?.type
              }
            : selectedAsset?.link; // legacy

        if (isMultiSelect) {
          // filter asset if it's already in the selection
          const filteredSelection =
            galleryType === GALLERY_TYPE.media
              ? reject(selection, { id: asset?.id })
              : filter(selection, v => v !== asset); // legacy

          if (filteredSelection.length < selection.length) {
            onChange(filteredSelection);
            return;
          }
          if (arrayMinMax?.max && filteredSelection.length >= arrayMinMax.max) {
            return;
          }
          onChange([...(selection as MediaAssetSelection[]), asset]);
        } else {
          const alreadySelected =
            galleryType === GALLERY_TYPE.media
              ? asset?.id === value?.id
              : asset === value; // legacy
          onChange(isNil(asset) || alreadySelected ? '' : asset);
        }
      }}
      onChange={onChange}
      reset={() => {
        onChange(isMultiSelect ? [] : '');
      }}
      galleryType={galleryType}
      sizeConstraint={sizeConstraint}
      mediaType={mediaType}
      showFilter={showFilter}
      isMultiSelect={isMultiSelect}
      allowContentSelection={allowContentSelection}
      businessObjects={businessObjects}
      contentName={contentName}
      contentItems={contentItems}
      arrayMinMax={arrayMinMax}
      {...rest}
    />
  );
};

export default RenderGallerySelector;
