import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { isEmpty, debounce } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArchitectureType } from 'src/pages/Architecture/ArchitectureProvider';
import ContentCount from './ContentCount';
import ProgramFilterSelector from './ProgramFilterSelector';
import { AutomatedProgramFilterType } from './constants';
import {
  trimFilterRuleValues,
  convertFiltersArrayToJSON,
  ContentSetFieldMetadata
} from './helpers';

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 'bold',
  margin: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
  textAlign: 'center'
}));

type AutomatedProgramFilterProps = {
  architecture: ArchitectureType;
  contentNameLowerCase: string;
  contentMeta: ContentSetFieldMetadata[];
  formNamespace: string;
};

const formatTableFilters = (filters: AutomatedProgramFilterType[]) => {
  const filtersWithValue = filters.reduce<AutomatedProgramFilterType[]>(
    (accum, filter) => {
      accum.push(trimFilterRuleValues(filter));
      return accum;
    },
    []
  );
  if (isEmpty(filtersWithValue)) {
    return {};
  }
  return convertFiltersArrayToJSON(filtersWithValue);
};

const handleTestRules = debounce(fn => fn(), 1000);

const AutomatedProgramFilter = (props: AutomatedProgramFilterProps) => {
  const { architecture, contentNameLowerCase, contentMeta, formNamespace } =
    props;

  const filters = useWatch({
    name: `${formNamespace}.filters`,
    defaultValue: []
  });

  const currentFilters = formatTableFilters(filters);
  const hasFilters = !isEmpty(filters);

  const [tableFilters, setTableFilters] = useState(currentFilters);
  const [showContent, toggleShowContent] = useState(false);

  const testRules = () => {
    setTableFilters(currentFilters);
  };

  // Check filters to see if we need to re-query the table
  if (JSON.stringify(tableFilters) !== JSON.stringify(currentFilters)) {
    handleTestRules(testRules);
  }

  return (
    <div data-cy="filter-step">
      {hasFilters ? (
        <StyledHeading variant="h3">
          {t('automatedProgramFilter:Rules.body', {
            contentNameLowerCase
          })}
        </StyledHeading>
      ) : (
        <>
          <StyledHeading variant="h3">
            {t('automatedProgramFilter:Rules.header', {
              contentNameLowerCase
            })}
          </StyledHeading>

          <Typography
            variant="h4"
            sx={{
              fontSize: '18px',
              textAlign: 'center',
              mb: 2
            }}
          >
            <Trans i18nKey="automatedProgramFilter:RulesEmpty.body">
              Here are some suggestions based on your data:
            </Trans>
          </Typography>
        </>
      )}
      <div data-amp-program-automation-wizard-filter-selector-click>
        <ProgramFilterSelector
          contentMeta={contentMeta}
          formNamespace={formNamespace}
        />
      </div>

      {!hasFilters && (
        <div>
          <Typography
            variant="h4"
            sx={{
              fontSize: '18px',
              textAlign: 'center',
              margin: theme => `${theme.spacing(7)} 0 ${theme.spacing(2)} 0`
            }}
          >
            {t('automatedProgramFilter:rulesEmpty.autoGenerateAll', {
              contentNameLowerCase
            })}
          </Typography>
        </div>
      )}

      {hasFilters && (
        <>
          <ContentCount
            architectureId={architecture?.id}
            tableFilters={tableFilters}
            displayCollapseKey={
              architecture?.catalog?.displayCollapseKey ?? 'id'
            }
            toggleShowContent={toggleShowContent}
            showContent={showContent}
            contentNameLowerCase={contentNameLowerCase}
          />
        </>
      )}
    </div>
  );
};

export default AutomatedProgramFilter;
