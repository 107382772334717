import { useMemo, useRef, useState, useEffect } from 'react';
import { find, flow, has, isArray, uniqBy } from 'lodash';
import { useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { change, getFormValues, reduxForm } from 'redux-form';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

import { LoadingButton } from '@mui/lab';
import { Add as AddIcon } from '@mui/icons-material';

import { useGlobalContext } from 'src/GlobalContextProvider';
import { formatBOs } from 'src/common/businessObjects';
import Logger from 'src/common/Logger';
import SentryUtil from 'src/common/SentryUtil';

import useOnMount from 'src/hooks/useOnMount';
import { useInvokableQuery } from 'src/hooks/apollo/queryHooks';
import { getChangeFormValue } from 'src/common/utilities/inputConversionHelpers';

import { getOrgContent } from './queries';
import { RenderAutocomplete } from '../RenderAutocomplete';
import { attemptCmpUseLeadTagsWebhookDelivery } from './mutations';

const NEW_LEAD_TAGS_FORM_NAME = 'newLeadTags';

const getText = () => ({
  errorFetchingOptions: t('orgContentSelector:errorFetchingOptions'),
  createNewTagButton: t('orgContentSelector:button.creatNewTag'),
  creatingTagButton: t('orgContentSelector:button.creatingTag'),
  errorAddingTag: t('orgContentSelector:errorAddingTag')
});

const extractOrgContentResponseData = ({
  orgContentData,
  friendlyNameColumn,
  valueColumn
}) => {
  const selectedCatalog =
    orgContentData?.myOrganization?.architectures?.[0]?.catalog;

  const catalogOptions = selectedCatalog?.content?.edges?.reduce(
    (accum, curr) => {
      // format data like a business object so it's easier to work with
      const businessObject = formatBOs(curr);

      // add select option
      if (
        has(businessObject, friendlyNameColumn) &&
        has(businessObject, valueColumn)
      ) {
        accum.push({
          name: businessObject[friendlyNameColumn],
          value: businessObject[valueColumn]
        });
      }

      return accum;
    },
    []
  );

  return {
    catalogOptions: catalogOptions || [],
    hasNextPage: selectedCatalog?.content?.pageInfo?.hasNextPage,
    nextCursor: selectedCatalog?.content?.pageInfo?.endCursor
  };
};

const RenderOrgContentSelector = props => {
  const {
    orgCatalogSlug,
    friendlyNameColumn,
    valueColumn,
    selectedCatalog,
    extraSelectOptions = [],
    allowTagCreationViaWebhook,
    createNewTagOptions: reduxCreateNewTagOptions,
    fieldNameSanitized,
    change: reduxChange,
    hookFormContext,
    ...rest
  } = props;

  const hookFormValues = hookFormContext?.watch();
  const hookFormCreateNewTagOptions =
    hookFormValues?.[fieldNameSanitized] || [];

  const createNewTagOptions = hookFormContext
    ? hookFormCreateNewTagOptions
    : reduxCreateNewTagOptions;

  const change = getChangeFormValue({
    reduxChange,
    hookSetValue: hookFormContext?.setValue
  });

  const inputValue = rest.input.value;
  const text = useMemo(() => getText(), []);
  const { enqueueSnackbar } = useSnackbar();
  const getCatalogOptions = useInvokableQuery(getOrgContent);

  const [catalogOptions, setCatalogOptions] = useState([]);
  const [selectedCatalogOptions, setSelectedCatalogOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [nextCursor, setNextCursor] = useState(null);
  const [textInputValue, setTextInputValue] = useState('');
  const queryFilter = useRef(null);
  const [attemptLeadTagsWebhookDelivery, { loading: webhookDeliveryLoading }] =
    useMutation(attemptCmpUseLeadTagsWebhookDelivery);
  const globalContext = useGlobalContext();
  const groupId = globalContext?.office?.id;
  const userId = globalContext?.me?.id;

  // combine options with extra along with any selected options
  // but make sure we don't have duplicates based on the value column!
  const options = uniqBy(
    [
      ...createNewTagOptions,
      ...extraSelectOptions,
      ...catalogOptions,
      ...selectedCatalogOptions
    ],
    'value'
  );

  // we have to fetch the selected options so they are always in the selector
  useEffect(() => {
    const existingValues = [];
    if (isArray(inputValue)) {
      existingValues.push(...inputValue);
    } else if (inputValue) {
      existingValues.push(inputValue);
    }

    if (existingValues.length) {
      setIsLoading(true);
      try {
        (async () => {
          const result = await getCatalogOptions({
            filter: {
              [valueColumn]: {
                in: existingValues
              }
            },
            // If we're running a new filter always ignore our next cursor
            // (also helps with stale react state)
            after: null,
            orderBy: friendlyNameColumn,
            catalogSlug: orgCatalogSlug
          });

          const extractedData = extractOrgContentResponseData({
            orgContentData: result.data,
            friendlyNameColumn,
            valueColumn
          });

          setSelectedCatalogOptions(extractedData.catalogOptions);
        })();
      } catch (error) {
        SentryUtil.addBreadcrumb({
          message:
            'Error getting selected catalog options for org content or tag selector'
        });
        SentryUtil.captureException(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setSelectedCatalogOptions([]);
    }
  }, [
    friendlyNameColumn,
    getCatalogOptions,
    orgCatalogSlug,
    valueColumn,
    inputValue
  ]);

  const fetchCatalogOptions = async ({ hasNewFilter } = {}) => {
    try {
      // We're only loading a "next" page if we aren't loading our first page
      if (nextCursor != null) {
        setIsNextPageLoading(true);
      }
      const result = await getCatalogOptions({
        filter: queryFilter.current,
        // If we're running a new filter always ignore our next cursor
        // (also helps with stale react state)
        after: hasNewFilter ? null : nextCursor,
        orderBy: friendlyNameColumn,
        catalogSlug: orgCatalogSlug
      });

      const extractedData = extractOrgContentResponseData({
        orgContentData: result.data,
        friendlyNameColumn,
        valueColumn
      });

      if (hasNewFilter) {
        // If we have a new filter it must replace all previous results with those
        // new filtered results
        setCatalogOptions(extractedData.catalogOptions);
      } else {
        // Make sure to append our catalog options on the end so that we remember
        // the old values
        setCatalogOptions([...catalogOptions, ...extractedData.catalogOptions]);
      }

      setNextCursor(extractedData.nextCursor);
      setHasNextPage(extractedData.hasNextPage);
    } finally {
      setIsLoading(false);
      setIsNextPageLoading(false);
    }
  };

  const handleFilterChange = newFilter => {
    if (newFilter === '') {
      // User cleared the filter! Don't apply any filter then
      queryFilter.current = null;
    } else {
      // Otherwise set up our new filter criteria.
      // right now this is just a contains call on the friendly name column,
      // but in the future this should really be a better full-text search system.
      queryFilter.current = {
        [friendlyNameColumn]: {
          contains: newFilter
        }
      };
    }

    // No matter what we must persist our existing selected value options
    // Then fetch new options, resetting a lot of our stuff
    setIsLoading(true);
    setNextCursor(null);
    setHasNextPage(false);

    fetchCatalogOptions({ hasNewFilter: true }).catch(ex => {
      SentryUtil.addBreadcrumb({
        message:
          'Tried fetching catalog options for org content selector after filtering'
      });
      SentryUtil.captureException(ex);
      enqueueSnackbar(text.errorFetchingOptions, {
        variant: 'error'
      });
      Logger.error(ex);
    });
  };

  useOnMount(() => {
    // On mount fetch our first page
    fetchCatalogOptions().catch(ex => {
      SentryUtil.addBreadcrumb({
        message:
          'Tried fetching catalog options for org content selector on mount'
      });
      SentryUtil.captureException(ex);
      enqueueSnackbar(text.errorFetchingOptions, {
        variant: 'error'
      });
      Logger.error(ex);
    });
  });

  const handleAddNewTag = async ({ selectedOptions, onChange }) => {
    try {
      // Call webhook delivery mutation
      const result = await attemptLeadTagsWebhookDelivery({
        variables: {
          input: {
            tags: [textInputValue],
            targetUser: {
              userIdOrExternalId: userId,
              groupIdOrExternalId: groupId
            },
            waitForWebhookResponse: true
          }
        }
      });

      const tags = result?.data?.attemptCmpUserLeadTagsWebhookDelivery?.tags;

      if (tags?.length && tags[0]?.[valueColumn] && tags[0]?.name) {
        const [newTag] = tags;

        // Add new option to auxiliary LeadTags form to persist these values when navigating between steps
        change(NEW_LEAD_TAGS_FORM_NAME, fieldNameSanitized, [
          ...createNewTagOptions,
          { value: newTag?.[valueColumn], name: newTag.name }
        ]);

        // Set new autocomplete value
        onChange([...selectedOptions, newTag?.[valueColumn]]);
      } else {
        enqueueSnackbar(text.errorAddingTag, { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar(text.errorAddingTag, { variant: 'error' });
    }
  };

  // Case insensitive search for matching option
  const matchingOption = !!find(
    options.map(option => ({
      ...option,
      name: option?.name?.toLowerCase() || ''
    })),
    { name: textInputValue?.toLowerCase() }
  );

  // New tag can't match any other option and can not be an empty string
  const isValidNewTag = !matchingOption && !!textInputValue.trim();

  const renderCreateNewButton = ({ selectedOptions, onChange }) => {
    return (
      <LoadingButton
        loading={webhookDeliveryLoading}
        startIcon={<AddIcon />}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          pl: theme => theme.spacing(2)
        }}
        loadingPosition="start"
        variant="text"
        disabled={!isValidNewTag || webhookDeliveryLoading}
        onClick={() => {
          handleAddNewTag({ selectedOptions, onChange });
        }}
      >
        {webhookDeliveryLoading
          ? text.creatingTagButton
          : text.createNewTagButton}
      </LoadingButton>
    );
  };

  const storeTextInputValue = value => {
    setTextInputValue(value);
  };

  return (
    <RenderAutocomplete
      hasNextPage={hasNextPage}
      // We only loaded all pages when there's no more pages AND there's no filter
      // any presence of a filter means we need to be loading locally
      hasLoadedAllPages={
        !isLoading && !hasNextPage && queryFilter.current == null
      }
      isNextPageLoading={isNextPageLoading}
      loadNextPage={fetchCatalogOptions}
      loading={isLoading}
      applyFilter={handleFilterChange}
      renderListButton={allowTagCreationViaWebhook && renderCreateNewButton}
      preventCloseOnListButtonClick
      onInputChange={allowTagCreationViaWebhook && storeTextInputValue}
      {...rest}
      options={options}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const fieldNameSanitized = ownProps.input.name.replace('.', '_');
  const formValues = getFormValues(NEW_LEAD_TAGS_FORM_NAME)(state);
  const createNewTagOptions = formValues?.[fieldNameSanitized] || [];
  return {
    createNewTagOptions,
    fieldNameSanitized,
    change
  };
};

export default flow(
  reduxForm({
    // This form stores all new tag option values so that they persist between form steps
    form: NEW_LEAD_TAGS_FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: false
  }),
  connect(mapStateToProps)
)(RenderOrgContentSelector);
