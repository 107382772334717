import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { FieldArray } from 'redux-form';
import { t } from 'i18next';

import { Typography } from '@mui/material';

import { fieldArrayFixDumbReduxFormError } from 'src/common/validations';

import RenderOffersInputs from './RenderOffersInputs';
import BlueprintBuilderStepFooter from '../BlueprintBuilderStepFooter';

const PREFIX = 'StepOffers';

const classes = {
  subHeading: `${PREFIX}-subHeading`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.subHeading}`]: {
    marginBottom: theme.spacing(2)
  }
}));

const pageText = () => ({
  stepHeading: t('admin:blueprintBuilder.stepOffersStepHeading'),
  stepSubHeading: t('admin:blueprintBuilder.stepOffersStepSubHeading')
});

const StepOffers = () => {
  const text = useMemo(() => pageText(), []);

  return (
    <Root>
      <Typography variant="h6">{text.stepHeading}</Typography>
      <Typography className={classes.subHeading} variant="subtitle2">
        {text.stepSubHeading}
      </Typography>
      <FieldArray
        component={RenderOffersInputs}
        name="document.offers"
        validate={[fieldArrayFixDumbReduxFormError]}
      />
      <BlueprintBuilderStepFooter />
    </Root>
  );
};

export default StepOffers;
