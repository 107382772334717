import { flow } from 'lodash';

import { cld } from 'src/common/cloudinaryUtils';
import { trim } from '@cloudinary/url-gen/actions/videoEdit';
import { AdvancedImage } from '@cloudinary/react';

const getCloudinaryVideoThumbnail = sourceUrl => {
  return cld
    .video(sourceUrl)
    .videoEdit(trim().startOffset(5.0))
    .setAssetType('video')
    .setDeliveryType('fetch')
    .format('jpg');
};

const VideoThumbnail = props => {
  const { asset, sourceUrl } = props;

  return (
    <>
      <AdvancedImage
        alt={asset?.id || asset}
        style={{ height: '100%', width: '100%', objectFit: 'cover' }}
        cldImg={getCloudinaryVideoThumbnail(sourceUrl)}
        data-cy={`gallery-asset-${asset?.id || asset}`}
      />
    </>
  );
};

export default flow()(VideoThumbnail);
