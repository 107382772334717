/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState, useCallback, ReactElement, SetStateAction } from 'react';
import { get, flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import { useSnackbar } from 'notistack';
import Loading from 'src/components/Loading';
import {
  paymentErrorByBackendDisplayCode,
  genericCardDeclinedError
} from 'src/common/paymentUtils';
import useSetStripeElementLocale from 'src/hooks/useSetStripeElementLocale';

import { useGlobalContext } from 'src/GlobalContextProvider';
import { programErrorTypes } from 'src/pages/Program/Constants';

import {
  AddPaymentMethodMutation,
  AddPaymentMethodMutationVariables
} from 'src/generated/gql/graphql';
import SentryUtil from 'src/common/SentryUtil';
import { StripeCardElement } from '@stripe/stripe-js';
import { MutationFunction } from '@apollo/client';
import useGetPaymentMethods from './useGetPaymentMethods';
import { addPaymentMethod } from './mutations';

export interface AddCardElementInjectedProps {
  addPaymentMutation: MutationFunction<
    AddPaymentMethodMutation,
    AddPaymentMethodMutationVariables
  >;
}

const AddCardElement = (props: AddCardElementInjectedProps) => {
  const { addPaymentMutation } = props;

  const location = useLocation();
  const globalContext = useGlobalContext();
  const isTeamsEnabled = globalContext?.office?.isTeamsEnabled;
  const { enqueueSnackbar } = useSnackbar();

  const captureCardError = (error: any, context: string) => {
    // Capture the error in Sentry if the URL contains the debug flag.
    if (location.search.includes('sentry-stripe-debug')) {
      SentryUtil.captureException(error, {
        extra: {
          message: context || ''
        }
      });
    }
  };

  const { refetch: refetchPaymentMethods } = useGetPaymentMethods({
    isTeamsEnabled
  });

  // useRef does not allow us to set the ref onReady so we need todo it via useCallback
  const [cardElementRef, setCardEl] = useState<StripeCardElement | null>(null);
  const setCardElementRef = useCallback(newRef => {
    setCardEl(newRef);
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  useSetStripeElementLocale({ elements });

  const [disableAddCard, setDisableAddCard] = useState(true);
  const [stripeError, setStripeError] = useState(null);
  const [updatingPayment, setUpdatingPayment] = useState(false);

  const cardFailed = () => {
    setDisableAddCard(true);
  };

  const cardSuccess = () => {
    setDisableAddCard(false);
  };

  const handleAddCard = async () => {
    setStripeError(null);
    setUpdatingPayment(true);

    const cardElement = elements!.getElement(CardElement);
    let stripeSource;

    // 1. Call Stripe directly to create the source.
    try {
      stripeSource = await stripe!.createSource(cardElement!, {
        type: 'card'
      });
    } catch (error) {
      enqueueSnackbar(genericCardDeclinedError(), {
        variant: 'error'
      });

      captureCardError(error, 'Failed while creating stripe source');

      setUpdatingPayment(false);
      setStripeError(genericCardDeclinedError() as SetStateAction<any>);

      return stripeSource;
    }

    // This will catch when the stripe.createSource call doesn't throw an
    // exception but still has an error.
    if (get(stripeSource, 'error')) {
      const message = genericCardDeclinedError();

      // Show the Stripe-generated message to the user with the Admiral.
      enqueueSnackbar(message, {
        variant: 'error'
      });

      captureCardError(
        stripeSource.error,
        'Stripe source error after creating source'
      );

      setUpdatingPayment(false);
      setStripeError(message as SetStateAction<any>);

      // Return the stripeSource (containing the error) so
      // handleNextWithValidation can check the value to determine if it
      // should allow the user to go to the next stage.
      return stripeSource;
    }

    const stripeSourceId = stripeSource?.source?.id || '';

    // 2. Now make the request to our API to add the credit card to the
    //    user's account.
    try {
      await addPaymentMutation({
        variables: {
          stripeSourceId
        }
      });
    } catch (error: any) {
      const errorName = error?.graphQLErrors[0]?.extensions?.errorName;
      const errorDisplayCode =
        error?.graphQLErrors[0]?.extensions?.additionalExceptionDetails
          ?.displayCode;

      captureCardError(
        error,
        `Failed while adding payment method. error:${errorName} - displaycode: ${errorDisplayCode}`
      );

      let errorMessage = genericCardDeclinedError();

      // if card declined / billing error display our specific error message
      if (
        errorName === programErrorTypes.billingException ||
        errorName === programErrorTypes.paymentAuthorizationException
      ) {
        errorMessage =
          paymentErrorByBackendDisplayCode(errorDisplayCode) ||
          genericCardDeclinedError();

        enqueueSnackbar(<span>{errorMessage}</span>, {
          variant: 'error'
        });
      }

      setUpdatingPayment(false);
      setStripeError(errorMessage as SetStateAction<any>);

      return error;
    }

    // 3. Get the new card data to keep the UI in sync with the db.
    await refetchPaymentMethods();

    // 4. Finally, unset loading state now that we've succeeded.
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await setUpdatingPayment(false);

    // 5. Clear the card element
    return cardElementRef!.clear();
  };

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          width: '100%'
        }}
      >
        {updatingPayment && <Loading size={20} />}

        <Box
          sx={{
            maxWidth: '400px',
            p: 2,
            width: '100%'
          }}
        >
          <CardElement
            // Note: This allows us to clear the element with cardElementRef.clear()
            //       after the card has been added.
            onReady={c => {
              setCardElementRef(c);
            }}
            onChange={({ complete }) => {
              return complete ? cardSuccess() : cardFailed();
            }}
            options={{ disableLink: true }}
          />
        </Box>
        <Button
          color="primary"
          onClick={handleAddCard}
          size="small"
          variant="outlined"
          disabled={disableAddCard || updatingPayment}
          data-cy="add-new-cc-button"
        >
          <Trans i18nKey="billingDetails.addNewCC">Add New Card</Trans>
        </Button>
      </Box>
      {stripeError && (
        <Typography
          sx={{
            color: 'error.main'
          }}
        >
          {stripeError}
        </Typography>
      )}
    </>
  );
};

export default flow(
  graphql(addPaymentMethod, {
    name: 'addPaymentMutation'
  })
)(AddCardElement as () => ReactElement);
