import { head } from 'lodash';

import { LeadConnection, LeadField } from 'src/generated/gql/graphql';
import { LeadFieldFlattened, Row } from 'src/pages/Leads/LeadsTable';

export type customDisclaimerResponse = {
  isChecked: string;
  checkboxKey: string;
  text: string;
};

const underscoreRegExp = /_/g;
const firstLetterRegExp = /^\w/;

export const rowDataFieldsToExclude = new Set([
  'name',
  'first_name',
  'last_name',
  'email',
  'phoneNumber',
  'leadId',
  'key',
  'architectureId',
  'programId',
  'orderId',
  'date',
  'phone_number',
  'phone',
  'programName',
  'customDisclaimerResponses'
]);

// Helper function to clean the consent text for rebeliQ leads
const cleanRiqLeadConsentText = (consentText: string) => {
  let cleanText = consentText || '';

  cleanText = cleanText.replace(/&quot;/g, '"');
  cleanText = cleanText.replace(/&amp;/g, '&');
  cleanText = cleanText.replace(/&nbsp;/g, ' ');
  cleanText = cleanText.replace(/EV - /g, '');

  return cleanText;
};

export const formatRowDataEdge = (data: LeadConnection['edges']): Row[] => {
  if (!data) {
    return [];
  }

  return data.reduce((accum: Row[], edge) => {
    if (!edge?.node) {
      return accum;
    }

    const { node } = edge;

    let customDisclaimerResponses = [];

    // Facebook Leads
    if (node?.data?.lead?.custom_disclaimer_responses) {
      customDisclaimerResponses = (
        node?.data?.lead?.custom_disclaimer_responses || []
      ).map((response: { is_checked: string; checkbox_key: string }) => {
        return {
          isChecked: response.is_checked,
          checkboxKey: response.checkbox_key,
          text: node?.data?.lead_form?.legal_content?.custom_disclaimer?.checkboxes.find(
            (checkbox: { key: string }) =>
              checkbox.key === response.checkbox_key
          )?.text
        };
      }, []);
    } else if (node?.data?.content?.leadInfo?.systemFields) {
      // rebeliQ Leads
      //  * Note that Facebook leads have isChecked as a string literal '1'
      //    for some reason but rebeliQ uses 'ACCEPTED'. To force it to match, we simply
      //    set it to '1' for 'ACCEPTED', '0' otherwise.
      customDisclaimerResponses = [
        {
          isChecked:
            node?.data?.content?.leadInfo?.systemFields?.consent_status ===
            'ACCEPTED'
              ? '1'
              : '0',
          checkboxKey: 'consent_status',
          text: cleanRiqLeadConsentText(
            node?.data?.content?.leadInfo?.systemFields?.consent_text
          )
        }
      ];
    }

    const baseRow: Row = {
      leadId: node.id,
      key: node.id,
      architectureId: node.architectureId,
      programId: node.programId,
      orderId: node.orderId,
      date: node.submittedAt,
      programName: node.programName
    };

    // flatten fields
    const fieldData = node.fields.reduce(
      (fieldAccum: LeadFieldFlattened, field: LeadField): any => {
        const firstValue = head(field.values);

        // field specific data
        return {
          ...fieldAccum,
          [field.name]: firstValue,
          ...(field.name === 'first_name' && {
            name: `${firstValue} ${fieldAccum.name || ''}`.trim()
          }),
          ...(field.name === 'last_name' && {
            name: `${fieldAccum.name || ''} ${firstValue}`.trim()
          }),
          ...(field.name === 'name' && {
            name: firstValue
          }),
          ...((field.name === 'phone_number' || field.name === 'phone') && {
            phone: firstValue
          })
        };
      },
      {}
    );
    // Split fields into standard, custom disclaimers, and additional
    const { standardFields, leadQuestions } = Object.entries(fieldData).reduce(
      (
        acc: {
          standardFields: { [key: string]: string };
          leadQuestions: { [key: string]: string };
        },
        [key, value]
      ) => {
        if (rowDataFieldsToExclude.has(key)) {
          return {
            ...acc,
            standardFields: { ...acc.standardFields, [key]: value }
          };
        }
        return {
          ...acc,
          leadQuestions: { ...acc.leadQuestions, [key]: value }
        };
      },
      { standardFields: {}, leadQuestions: {} }
    );

    return [
      ...accum,

      {
        ...baseRow,
        ...standardFields,
        customDisclaimerResponses,
        leadQuestions: Object.entries(leadQuestions).map(([key, value]) => ({
          name: key
            .replace(underscoreRegExp, ' ')
            .replace(firstLetterRegExp, (c: string) => c.toUpperCase()),
          value: value?.replace(underscoreRegExp, ' ') || ''
        }))
      }
    ];
  }, []);
};
