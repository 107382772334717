import { useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { FieldArray, Field } from 'redux-form';
import { t } from 'i18next';

import { AppBar, Tabs, Tab } from '@mui/material';

import { fieldArrayFixDumbReduxFormError } from 'src/common/validations';

import RenderVariablesInputs from './RenderVariablesInputs';
import RenderDefaultVariableValues from './RenderDefaultVariableValues';

const PREFIX = 'Variables';

const classes = {
  container: `${PREFIX}-container`,
  subHeading: `${PREFIX}-subHeading`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.container}`]: {
    position: 'relative'
  },

  [`& .${classes.subHeading}`]: {
    marginBottom: theme.spacing(2)
  }
}));

const pageText = () => ({
  stepHeading: t('admin:blueprintBuilder.stepVariablesStepHeading'),
  stepSubHeading: t('admin:blueprintBuilder.stepVariablesStepSubHeading')
});

const TabContent = [
  {
    label: 'Variables',
    key: 'variables',
    content: () => {
      return (
        <Root>
          <FieldArray
            component={RenderVariablesInputs}
            name="document.blueprint.variables"
            validate={[fieldArrayFixDumbReduxFormError]}
          />
        </Root>
      );
    }
  },
  {
    label: 'Default Variable Values',
    key: 'export',
    content: () => {
      return (
        <Field
          component={RenderDefaultVariableValues}
          name="document.defaultVariableValues"
        />
      );
    }
  }
];

const Variables = ({ inModal }) => {
  const text = useMemo(() => pageText(), []);

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const SelectedTabContent = TabContent[selectedTab].content;

  return (
    <div className={classes.container}>
      <AppBar position="static" color="secondary">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="inherit"
        >
          {TabContent.map(tab => {
            return <Tab key={tab.key} label={tab.label} />;
          })}
        </Tabs>
      </AppBar>

      <SelectedTabContent classes={classes} text={text} inModal={inModal} />
    </div>
  );
};

export default Variables;
