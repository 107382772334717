import { useRef, useState } from 'react';
import { initial, last } from 'lodash';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { PageInfo } from 'src/generated/gql/graphql';

type PaginationOptions = {
  edges: any[];
  resultsPerPage: number;
  refetchCallback: (options: any) => Promise<any>;
  refetchOptions?: any;
};

export const usePagination = ({
  edges = [],
  resultsPerPage,
  refetchCallback: refetch,
  refetchOptions
}: PaginationOptions) => {
  // pagination
  // we save an array of cursors so we can go backwards
  // use ref instead of state because state get's wonky when we call it
  // from the child component.
  const previousCursors = useRef<string[]>([]);

  const navigateNext = () => {
    if (!edges.length) {
      return;
    }
    previousCursors.current = [...previousCursors.current, last(edges).cursor];

    refetch({
      ...refetchOptions,
      first: resultsPerPage,
      after: last(edges).cursor
    })
      .then(() => {})
      .catch(() => {});
  };

  const navigatePrev = () => {
    // remove the last cursor first!
    previousCursors.current = initial(previousCursors.current);

    refetch({
      ...refetchOptions,
      first: resultsPerPage,
      after:
        previousCursors.current.length > 0
          ? last(previousCursors.current)
          : null
    })
      .then(() => {})
      .catch(() => {});
  };

  const resetPagination = () => {
    previousCursors.current = [];
  };

  return {
    navigateNext,
    navigatePrev,
    resetPagination,
    page: previousCursors.current.length + 1,
    previousCursors: previousCursors.current
  };
};

type DataTablePaginationOptions = {
  pageInfo: PageInfo | undefined | null;
  resultsPerPage: number;
  resultsLength?: number;
  refetch: (options: any) => Promise<any>;
  refetchOptions?: any;
  postRefetchCallback?: (...args: any[]) => void;
  refetchErrorCallback?: (...args: any[]) => void;
};

// note: the new version of the datagrid has a different pagination model
// which makes the fuzzy math for page numbers a little better
// we can update this when we update the datagrid
export const useDataTablePagination = ({
  pageInfo,
  resultsPerPage,
  resultsLength = 0,
  refetch,
  refetchOptions = {},
  postRefetchCallback = () => {},
  refetchErrorCallback = () => {}
}: DataTablePaginationOptions) => {
  const [cursors, setCursors] = useState<string[]>([]);
  const pageNum = cursors.length;

  const handlePaginationModelChange = (
    paginationModel: GridPaginationModel
  ) => {
    const { page } = paginationModel;

    // going down
    if (page < pageNum) {
      refetch({
        ...refetchOptions,
        first: resultsPerPage,
        after: cursors.length > 1 ? cursors[cursors.length - 2] : null
      })
        .then(postRefetchCallback)
        .catch(refetchErrorCallback);
      setCursors(initial(cursors)); // remove the last cursor
    }
    // going up
    if (page > pageNum) {
      refetch({
        ...refetchOptions,
        first: resultsPerPage,
        ...(pageInfo?.endCursor && { after: pageInfo?.endCursor })
      })
        .then(postRefetchCallback)
        .catch(refetchErrorCallback);
      setCursors(
        pageInfo?.endCursor ? [...cursors, pageInfo?.endCursor] : cursors
      );
    }
  };

  // fuzzy row count until we update the datagrid (new version)
  // we don't have a real total count so we just assume there's more if there's a next page
  const rowCount = pageInfo?.hasNextPage
    ? resultsPerPage * pageNum + resultsPerPage + resultsPerPage
    : resultsPerPage * pageNum + resultsLength;

  return {
    paginationModel: {
      page: pageNum,
      pageSize: resultsPerPage
    },
    rowCount,
    handlePaginationModelChange
  };
};
