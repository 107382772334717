import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { Trans } from 'react-i18next';

import Auth from 'src/Auth/Auth';

import { paths } from 'src/routes/paths';
import { Link as RouterLink } from 'react-router-dom';
import { CircularProgress, Grid, Link, Paper, Typography } from '@mui/material';

import { withAppSettings } from 'src/AppSettings';

import PageTitle from 'src/components/PageTitle';

const PREFIX = 'Logout';

const classes = {
  root: `${PREFIX}-root`,
  subTitle: `${PREFIX}-subTitle`,
  progress: `${PREFIX}-progress`,
  centeredWrap: `${PREFIX}-centeredWrap`,
  logoContainer: `${PREFIX}-logoContainer`,
  companyLogo: `${PREFIX}-companyLogo`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(5)
  },

  [`& .${classes.subTitle}`]: {
    marginTop: theme.spacing(2)
  },

  [`& .${classes.progress}`]: {
    margin: theme.spacing(2)
  },

  [`& .${classes.centeredWrap}`]: {
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: '500px',
    minWidth: '300px'
  },

  [`& .${classes.logoContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },

  [`& .${classes.companyLogo}`]: {
    width: `${theme.evSizes.navigationWidth}px`
  }
}));

const Logout = props => {
  const { appSettings } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Auth.logout().then(() => {
      if (appSettings?.logoutUrl) {
        window.location = appSettings.logoutUrl;
      }
      setLoading(false);
    });
  }, []);

  const showLoginBackLink = appSettings?.app?.features?.loginBackLink;

  return (
    <StyledGrid className={classes.root} container spacing={3}>
      <PageTitle subPageTitle="Logout" />
      <Paper className={classes.centeredWrap}>
        <div className={classes.logoContainer}>
          <img
            alt={`${appSettings?.app.general.baseTitle} Logo`}
            className={classes.companyLogo}
            src={appSettings?.evAssets?.logoUrl}
          />
        </div>
        {loading ? (
          <Grid item xs>
            <Typography component="h1" variant="h4">
              <Trans i18nKey="login:title.loggingOut">Logging out</Trans>
            </Typography>
            <Typography className={classes.subTitle} variant="subtitle1">
              <Trans i18nKey="login:subTitle.standby">Please standby</Trans>
            </Typography>
            <CircularProgress className={classes.progress} />
          </Grid>
        ) : (
          <Grid item xs>
            <Typography component="h1" variant="h4">
              <Trans i18nKey="login:title.loggedOut">Logged out</Trans>
            </Typography>
            <Typography className={classes.subTitle} variant="subtitle1">
              <Trans i18nKey="login:subTitle.loggedOut">
                You have been logged out.
              </Trans>

              {showLoginBackLink && (
                <Trans
                  i18nKey="login:subTitle.signInAgain"
                  components={[
                    <Link component={RouterLink} to={paths.auth.login} />
                  ]}
                />
              )}
            </Typography>
          </Grid>
        )}
      </Paper>
    </StyledGrid>
  );
};

export default flow(withAppSettings)(Logout);
