import { t } from 'i18next';

import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/system';
// import InfoIcon from '@mui/icons-material/Info';

// import ExpandableText from 'src/pages/Leads/ExpandableText';
// import { Row } from 'src/pages/Leads/LeadsTable';

import { customDisclaimerResponse } from './helpers';
import ExpandableText from './ExpandableText';

const LeftColumnTypography = styled(Typography)(props => ({
  color: props.theme.palette.grey[600]
}));

const TableCellStyled = styled(TableCell)(props => ({
  border: '1px solid',
  borderColor: props.theme.palette.grey[300]
}));

interface LeadQuestion {
  name: string;
  value: string;
}

export interface DetailLeadField {
  [key: string]:
    | string
    | customDisclaimerResponse[]
    | LeadQuestion[]
    | undefined;
  customDisclaimerResponses?: customDisclaimerResponse[];
  leadQuestions?: LeadQuestion[];
  fullName?: string;
  key?: string;
}

const LeadTableDetailPanel = ({ row }: { row: DetailLeadField }) => {
  const leadQuestions = row.leadQuestions || [];
  const customDisclaimerResponses = row.customDisclaimerResponses || [];

  return (
    <Box sx={{ p: 4, pb: 2 }}>
      <Grid
        container
        spacing={2}
        sx={{ border: '1px solid', borderColor: 'grey.300', p: 2 }}
      >
        {leadQuestions.length > 0 && (
          <Grid
            item
            // sm={6}
            sx={{
              pt: '0 !important',
              pl: '0 !important',
              pr: 2,
              borderRight: '1px solid',
              borderColor: 'grey.300',
              width: '50%'
            }}
          >
            <Typography variant="body1" sx={{ mb: 2, fontWeight: 'bold' }}>
              {t('leads:detailPanel.additionalInfo')}
            </Typography>
            <Table>
              <TableBody>
                {leadQuestions.map(({ name, value }) => {
                  return (
                    <TableRow key={`${row.key}-${name}-${value}`}>
                      <TableCellStyled>
                        <LeftColumnTypography variant="body2">
                          {name}
                        </LeftColumnTypography>
                      </TableCellStyled>
                      <TableCellStyled>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          {value}
                        </Typography>
                      </TableCellStyled>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        )}
        {customDisclaimerResponses.length > 0 && (
          <Grid
            item
            sm={6}
            sx={{
              pt: '0 !important',
              width: '50%'
            }}
          >
            <Typography
              variant="body1"
              sx={{
                mb: 2,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {t('leads:detailPanel.disclaimerConsent')}
              <Tooltip title={t('leads:detailPanel.disclaimerTip')}>
                <InfoIcon sx={{ ml: 1, fontSize: 16 }} />
              </Tooltip>
            </Typography>
            {customDisclaimerResponses.map(
              ({ isChecked, checkboxKey, text }) => {
                return (
                  <ExpandableText
                    key={checkboxKey}
                    text={text || checkboxKey}
                    hasAccepted={isChecked === '1' || isChecked === 'true'}
                    fullName={row.fullName}
                  />
                );
              }
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default LeadTableDetailPanel;
