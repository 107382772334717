import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { t } from 'i18next';

import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

import { useAppSettings } from 'src/AppSettings';
import PageTitle from 'src/components/PageTitle';
import Loading from 'src/components/Loading';

const GuestAuthLanding = () => {
  const appSettings = useAppSettings();
  const location = useLocation();
  const [error, setError] = useState(true);

  // TODO: remove this once we have the token exchange logic
  // eslint-disable-next-line @typescript-eslint/require-await
  const handleTokenExchange = async (token: string) => {
    try {
      // TODO: Implement your token exchange logic here
      // eslint-disable-next-line no-console
      console.log('exchange this token:', token);
    } catch (error) {
      setError(true);
      // eslint-disable-next-line no-console
      console.error('Error exchanging token:', error);
    }
  };

  useEffect(() => {
    const { token } = queryString.parse(location.search);

    if (token && typeof token === 'string') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleTokenExchange(token);
    }
  }, [location]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        gap: 3,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        p: 1
      }}
    >
      <PageTitle subPageTitle={t('guestAuth.authenticate.pageTitle')} />
      {appSettings?.evAssets?.logoUrl && (
        <Box
          component="img"
          alt={`${appSettings?.app.general.baseTitle} Logo`}
          src={appSettings?.evAssets?.logoUrl}
          sx={{
            maxWidth: '300px'
          }}
        />
      )}
      {!error && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Loading />
          <Typography variant="h5">
            {t('guestAuth.authenticate.loggingIn')}
          </Typography>
        </Box>
      )}
      {error && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <ErrorIcon color="error" />
          <Typography variant="h5" color="error" sx={{ textAlign: 'center' }}>
            {t('guestAuth.authenticate.error')}
          </Typography>

          <Typography
            variant="body1"
            color="error"
            sx={{ textAlign: 'center', mt: 2 }}
          >
            {t('guestAuth.authenticate.errorMessage')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default GuestAuthLanding;
