import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import {
  flow,
  throttle,
  isObject,
  isEmpty,
  clone,
  isString,
  isArray
} from 'lodash';
import { change, touch } from 'redux-form';
import { connect } from 'react-redux';
import { Loader } from '@googlemaps/js-api-loader';
import { t } from 'i18next';

import { TextField, FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { getChangeFormValue } from 'src/common/utilities/inputConversionHelpers';
import { HelperTextFooter } from 'src/components/ReduxForm/HelperTextFooter';
import { ErrorFooter } from 'src/components/ReduxForm/ErrorFooter';
import { InputTooltip } from 'src/components/ReduxForm/InputTooltip';

import { googleAddressMappings } from '../constants';

import { keyByAddressPart } from './helpers';
import AddressRenderOptions from './AddressRenderOptions';

const loader = new Loader({
  apiKey: import.meta.env.EVOCALIZE_GOOGLE_MAPS_API_KEY,
  // This version is pinned because we were seeing errors in the google maps library on 3.59
  // which was the current weekly version at the time of pinning.
  // Update with caution, the error code we were seeing was: b/369845599 which comes from a
  // catch block in the maps library.
  // https://developers.google.com/maps/documentation/javascript/versions#choosing-a-version-number
  version: '3.58',
  libraries: ['places']
});

let autocompleteService = null;
let autocompleteSessionToken = null;
let placesService = null;

const RenderAddressAutocomplete = props => {
  const {
    change: reduxChange,
    touch,
    setDynamicValidation,
    addressFields,
    isMultiSelect,
    input: { onChange, value, name },
    meta: { touched, error, form },
    addressTypes,
    tooltip,
    label,
    helperText,
    readOnly = false,
    disabled = false,
    addressComponentRestrictions,
    hookFormContext,
    isHookForm
  } = props;
  const [mapsLoaded, setMapsLoaded] = useState(false);
  const inputRef = useRef(null);

  const [inputValue, setInputValue] = useState(value || '');
  const [options, setOptions] = useState([]);
  const [inputErrors, setInputErrors] = useState([]);

  const change = getChangeFormValue({
    reduxChange,
    hookSetValue: hookFormContext?.setValue
  });

  useEffect(() => {
    const load = async () => {
      // prevent multiple google loads
      if (!window?.google?.maps) {
        await loader.load();
      }
      // setup autocomplete service
      autocompleteService = new window.google.maps.places.AutocompleteService();
      // setup session token
      autocompleteSessionToken =
        new window.google.maps.places.AutocompleteSessionToken();
      // setup place service this is used to get the address details once the user has selected a location
      placesService = new window.google.maps.places.PlacesService(
        inputRef.current // required to pass a container but this does not render anything
      );
      return setMapsLoaded(true);
    };
    load();
  }, []); // only load once

  const fetchPredictions = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  // when the input value changes we want to fetch predictions
  useEffect(() => {
    // can't do anything without the services
    if (!autocompleteService || !autocompleteSessionToken || !placesService) {
      return undefined;
    }

    // no need to fetch without input text
    if (inputValue === '') {
      return undefined;
    }

    fetchPredictions(
      {
        input: inputValue,
        sessionToken: autocompleteSessionToken,
        ...(addressTypes && {
          types: isArray(addressTypes) ? addressTypes : [addressTypes]
        }),
        ...(addressComponentRestrictions?.length && {
          componentRestrictions: {
            country: isString(addressComponentRestrictions)
              ? addressComponentRestrictions
              : [...addressComponentRestrictions]
          }
        })
      },
      results => {
        if (isMultiSelect) {
          // we want to have the current values in the options to prevent material warnings
          let extraValues = value || [];
          if (isString(value) && !isEmpty(value)) {
            extraValues = [value];
          }

          return setOptions([...extraValues, ...(results || [])]);
        }

        // for single select just add results to the options no need for the current value in this case
        return setOptions(results || []);
      }
    );
  }, [inputValue, fetchPredictions, mapsLoaded, value]);

  const getDetails = useCallback(
    value => {
      return new Promise((resolve, reject) => {
        try {
          placesService.getDetails(
            {
              sessionToken: autocompleteSessionToken,
              placeId: value.place_id,
              fields: ['address_components', 'geometry']
            },
            details => {
              const detailsMap = keyByAddressPart(details);
              const updatedInputItem = {
                description: value.description,
                place_id: value.place_id,
                // guaranteed to always return a string starting with the most specific detail
                // eg. "Seattle, WA" returns "Seattle" but "King County, WA" returns "King County"
                safePlaceName:
                  // terms is an array of locations strings with decreasing specificity
                  // eg. ["Ballard", "Seattle", "King County", "Washington", "US"]
                  value?.terms?.[0]?.value ||
                  value?.structured_formatting?.main_text ||
                  value?.description ||
                  ''
              };

              const errors = [];

              if (!isEmpty(addressFields)) {
                addressFields.forEach(({ name }) => {
                  // map each hidden form name to google name
                  const nameValue = googleAddressMappings[name]
                    ? googleAddressMappings[name](detailsMap)
                    : null;

                  if (!nameValue) {
                    errors.push(name);
                    setInputErrors([...inputErrors, name]);
                  }
                  updatedInputItem[name] = nameValue;
                });
              }

              if (errors.length) {
                // this address does not contain all our required fields.
                setDynamicValidation({
                  validation: () =>
                    t('renderTemplateStringTextField:addressFieldsError')
                });
              } else {
                // reset local errors to none
                setDynamicValidation({ validation: () => {} });
              }

              return resolve(updatedInputItem);
            }
          );
        } catch (error) {
          return reject(error);
        }
      });
    },
    [value, autocompleteSessionToken]
  );

  const handleOnChange = async newValue => {
    // need to account for formSections
    //  - check input name to see if it is nested in a section
    const inputNameSplit = name.split('.');
    // remove the current inputs name
    inputNameSplit.pop();

    let newValueWithDetails;
    if (isMultiSelect) {
      // multi select
      if (isEmpty(newValue)) {
        return onChange([]);
      }
      // Handle case where an item was removed
      if (isArray(value) && value.length > newValue.length) {
        // If items were removed, return just the new value array
        return onChange(newValue);
      }

      // Filter out items that already exist in value based on place_id
      const existingPlaceIds = new Set(
        isArray(value) ? value.map(v => v.place_id) : []
      );
      const filteredNewValue = newValue.filter(
        v => !existingPlaceIds.has(v.place_id)
      );

      newValueWithDetails = await Promise.all(
        filteredNewValue.map(async value => {
          const updatedInputItem = await getDetails(value);
          return updatedInputItem;
        })
      );

      return onChange([...(value || []), ...newValueWithDetails]);
    }

    // single select
    // no need for detail if no value / accounts for clearing field
    if (newValue) {
      newValueWithDetails = await getDetails(newValue);
    }

    if (!isEmpty(addressFields)) {
      // update each extra hidden field
      addressFields.forEach(({ name }) => {
        const formSection = clone(inputNameSplit);
        formSection.push(name);

        change(
          form,
          formSection.join('.'),
          newValueWithDetails ? newValueWithDetails[name] : null
        );
      });
    }

    return onChange(newValueWithDetails?.description || null);
  };

  const inputInError = error && touched;

  const fallbackValue = isMultiSelect ? [] : null;

  return (
    <FormControl fullWidth>
      <Autocomplete
        autoSelect
        autoHighlight
        clearOnBlur={false}
        options={options}
        includeInputInList
        multiple={isMultiSelect}
        value={value || fallbackValue}
        disabled={readOnly || disabled}
        filterOptions={options => {
          // an empty value gets pulled in so we need to filter it out
          const filteredOptions = options.filter(
            option => option !== '' && option !== null
          );

          return filteredOptions;
        }}
        isOptionEqualToValue={(option, value) => {
          // multiSelect
          if (isObject(value)) {
            // what we store / use differs from what fetchPredictions returns
            // so we need to compare ourselves to avoid the material-ui warnings

            // eslint-disable-next-line camelcase
            return option?.place_id === value?.place_id;
          }
          // single select
          // fixes some of the warning with the value and options
          // not having a match when value is ''
          if (isEmpty(value)) {
            return false;
          }

          return option?.description === value;
        }}
        getOptionLabel={option => {
          if (option?.description) {
            return option.description;
          }
          return isEmpty(option) ? '' : option;
        }}
        renderInput={params => {
          return (
            <>
              <TextField
                {...params}
                sx={{
                  '& .MuiAutocomplete-input': {
                    // setting this b/c it was causing the internal input to add an extra line
                    minWidth: '0px !important', // default is 30px
                    padding: '7.5px 0 !important' // need to keep top and bottom padding to ensure empty input looks correct
                  }
                }}
                error={inputInError}
                label={label}
                variant={readOnly ? 'standard' : 'outlined'}
                fullWidth
                inputRef={inputRef}
                type="text"
                inputProps={{
                  ...params.inputProps,
                  name,
                  autoComplete: 'watTheWatStayOff' // only way to turn off the autocomplete on this input it would seem
                }}
                // Not actually a duplicate prop
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  // we are pulling out endAdornment so we need to spread the rest of the props in
                  ...params?.InputProps,
                  ...(readOnly && {
                    readOnly: true,
                    disableUnderline: true
                  }),
                  endAdornment: (
                    <>
                      {/* this component already has endAdornment passed down to it so we need to add our tooltip to it */}
                      {params.InputProps.endAdornment}
                      {tooltip && <InputTooltip tooltipText={tooltip} />}
                    </>
                  )
                }}
              />
              {inputInError && <ErrorFooter touched={touched} error={error} />}
              {helperText && (
                <HelperTextFooter
                  helperText={helperText}
                  stacked={inputInError}
                />
              )}
            </>
          );
        }}
        renderOption={(props, option) => {
          const { key } = props;
          return (
            <li key={key} {...props}>
              <AddressRenderOptions option={option} />
            </li>
          );
        }}
        onChange={(event, newValue) => {
          return handleOnChange(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);

          if (!isHookForm) {
            return touch(form, name);
          }
        }}
      />
    </FormControl>
  );
};

export default flow(connect(null, { change, touch }))(
  RenderAddressAutocomplete
);
