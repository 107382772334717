import { t } from 'i18next';

import { Box, Theme } from '@mui/system';
import { useTheme } from '@mui/material/styles';

import Tooltip from '@mui/material/Tooltip';

import {
  OrderForProgramPerformanceQuery,
  MultiLocationProgramStatusCode
} from 'src/generated/gql/graphql';

import {
  programOrderStatusCode,
  mlpParentOrderStatusCode,
  statusType,
  getStatusColor
} from './Constants';

// In some cases the user doesn't need to know the exact status
// so some things like placement_job_running or placement_job_unknown_error will be shown simply as "Pending"

const getMlpParentStatus = (
  theme: Theme,
  statusCode?: MultiLocationProgramStatusCode
) => {
  const status = {
    text: t('programs:mlpMessages.pending'),
    type: statusType.pending,
    color: getStatusColor({
      isProgramActive: true,
      orderStatus: statusCode ?? '',
      theme
    })
  };

  switch (statusCode) {
    case mlpParentOrderStatusCode.cancellationUnknownError:
    case mlpParentOrderStatusCode.cancelled:
    case mlpParentOrderStatusCode.cancelling:
      status.text = t('programs:mlpMessages.cancelled');
      status.type = statusType.stopped;
      break;

    case mlpParentOrderStatusCode.active:
      status.text = t('programs:mlpMessages.active');
      status.type = statusType.running;
      break;

    case mlpParentOrderStatusCode.editUnknownError:
    case mlpParentOrderStatusCode.editInProgress:
      status.text = t('programs:mlpMessages.editInProgress');
      status.type = statusType.pending;
      break;

    default:
      break;
  }

  return status;
};

const getProgramStatus = (
  theme: Theme,
  order?: OrderForProgramPerformanceQuery['order'] | null
) => {
  const orderStatus = order?.orderItem?.status ?? '';
  const isProgramActive = order?.isProgramActive ?? false;

  const status = {
    text: t('programs:messages.orderPending'),
    type: statusType.pending,
    color: getStatusColor({
      isProgramActive,
      orderStatus,
      theme
    })
  };

  switch (orderStatus) {
    case programOrderStatusCode.fulfilled:
      if (isProgramActive) {
        status.text = t('programs:messages.orderRunning');
        status.type = statusType.running;
      } else if (isProgramActive === false) {
        status.text = t('programs:messages.orderStopped');
        status.type = statusType.stopped;
      }
      break;
    case programOrderStatusCode.cancelled:
      status.text = t('programs:messages.orderCancelled');
      status.type = statusType.stopped;

      break;
    case programOrderStatusCode.error:
      status.text = t('programs:messages.orderError');
      status.type = statusType.error;

      break;
    default:
      // keep default above Pending
      break;
  }

  return status;
};

interface OrderStatusProps {
  mlpParentOrderStatusCode?: MultiLocationProgramStatusCode;
  order?: OrderForProgramPerformanceQuery['order'] | null;
  renderTextStatus?: boolean;
}

const OrderStatus = ({
  order = null,
  renderTextStatus = false,
  mlpParentOrderStatusCode
}: OrderStatusProps) => {
  const theme = useTheme();
  const { text, color } = mlpParentOrderStatusCode
    ? getMlpParentStatus(theme, mlpParentOrderStatusCode)
    : getProgramStatus(theme, order);

  return (
    <>
      <Tooltip title={renderTextStatus ? '' : text}>
        <Box
          component="span"
          sx={{
            fontSize: '20px',
            cursor: 'default',
            color
          }}
        >
          ●{' '}
          {renderTextStatus && (
            <Box component="span" sx={{ fontSize: '14px' }}>
              ({text})
            </Box>
          )}
        </Box>
      </Tooltip>
    </>
  );
};

export default OrderStatus;
