import React from 'react';
import { findIndex, indexOf } from 'lodash';
import { red } from '@mui/material/colors';
import { Box, Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';
import { WrappedFieldProps } from 'redux-form';
import { InjectedStyledClasses } from 'src/common/Style';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable';

import { GALLERY_TYPE } from './constants';
import GalleryModalMediaListItem from './GalleryModalMediaListItem';
import { MediaAssetSelection } from './GalleryModal';

const styles = () =>
  ({
    container: {
      width: '100%',
      minHeight: '56px' // input height
    },
    label: {
      display: 'flex',
      flexDirection: 'column'
    },
    error: {
      color: red[500],
      borderColor: red[500]
    }
  }) as const;

export interface GalleryModalProps extends WrappedFieldProps {
  onClick: () => void;
  onChange: (selection: MediaAssetSelection[]) => void;
  selectedGalleryItems: MediaAssetSelection[];
  galleryType: string;
  name: string;
  disabled: boolean;
}

const GalleryMediaList = (
  props: GalleryModalProps & InjectedStyledClasses<typeof styles>
) => {
  const { selectedGalleryItems, galleryType, onChange } = props;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex =
        galleryType === GALLERY_TYPE.media
          ? findIndex(selectedGalleryItems as any, { id: active.id })
          : indexOf(selectedGalleryItems, active.id); // legacy;
      const newIndex =
        galleryType === GALLERY_TYPE.media
          ? findIndex(selectedGalleryItems as any, { id: over.id })
          : indexOf(selectedGalleryItems, over.id); // legacy;
      const newArray = arrayMove(selectedGalleryItems, oldIndex, newIndex);
      onChange(newArray);
    }
  };

  return (
    <Box>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={selectedGalleryItems}>
          {selectedGalleryItems?.length > 0 && (
            <Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
              {selectedGalleryItems.map(item => {
                const selectionType =
                  galleryType === GALLERY_TYPE.media
                    ? (item as any)?.type
                    : galleryType; // using galleryType for asset type is legacy
                return (
                  <GalleryModalMediaListItem
                    key={(item as any)?.id || item}
                    id={(item as any)?.id || item}
                    item={item as any}
                    galleryType={galleryType}
                    selectionType={selectionType}
                  />
                );
              })}
            </Grid>
          )}
        </SortableContext>
      </DndContext>
    </Box>
  );
};

export default withStyles(styles)(GalleryMediaList);
