/* eslint-disable jsx-a11y/media-has-caption */
import { Box } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import TiktokPlayIcon from 'src/images/tiktok/tiktok-play-button.svg';

const TiktokVideoPlayer = ({ url, poster, width, height }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const videoRef = useRef();

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const canPlayHandler = () => {
      setIsReady(true);
    };

    const waitingHandler = () => {
      setIsReady(false);
    };

    const playHandler = () => {
      setIsPlaying(true);
    };

    const pauseHandler = () => {
      setIsPlaying(false);
    };

    const loadedmetadataHandler = () => {
      setIsPlaying(false);
    };

    const videoElement = videoRef.current;

    videoElement.addEventListener('waiting', waitingHandler);
    videoElement.addEventListener('canplay', canPlayHandler);
    videoElement.addEventListener('play', playHandler);
    videoElement.addEventListener('pause', pauseHandler);
    videoElement.addEventListener('loadedmetadata', loadedmetadataHandler);

    // clean up
    return () => {
      videoElement.removeEventListener('waiting', waitingHandler);
      videoElement.removeEventListener('canplay', canPlayHandler);
      videoElement.removeEventListener('play', playHandler);
      videoElement.removeEventListener('pause', pauseHandler);
      videoElement.removeEventListener('loadedmetadata', loadedmetadataHandler);
    };
  }, [url]);

  const handlePlayPauseClick = () => {
    if (videoRef.current) {
      if (!isReady) {
        return;
      }
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  };

  return (
    <Box
      onClick={handlePlayPauseClick}
      style={{ position: 'relative' }}
      role="button"
      tabIndex={0}
    >
      <video
        ref={videoRef}
        src={url}
        poster={poster}
        width={width}
        height={height}
      />
      {url && isReady && !isPlaying && (
        <button
          type="button"
          onClick={handlePlayPauseClick}
          style={{
            borderRadius: '50%',
            width: '70px',
            height: '70px',
            background: 'rgba(0,0,0,.38)',
            overflow: 'hidden',
            textAlign: 'center',
            lineHeight: '70px',
            verticalAlign: 'middle',
            position: 'absolute',
            left: '50%',
            top: '50%',
            zIndex: '115',
            margin: '-35px auto auto -35px',
            cursor: 'pointer',
            border: 0,
            opacity: 1,
            '&:hover': {
              opacity: 0.8
            }
          }}
        >
          <img src={TiktokPlayIcon} alt="Play" />
        </button>
      )}
    </Box>
  );
};

export default TiktokVideoPlayer;
