import { Skeleton, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  getTikTokAdPreviewStyles,
  TIKTOK_PREVIEW_WIDTH
} from './TiktokAdPreview';
import { getPreviewWidth } from '../helpers';

const TiktokAdPreviewSkeleton = props => {
  const theme = useTheme();
  const sxStyles = getTikTokAdPreviewStyles({
    theme,
    vars: {
      hasUniformPreviewWidth: props.hasUniformPreviewWidth,
      isResponsive: props.isResponsive
    }
  });

  return (
    <Box sx={sxStyles.container} className="notranslate">
      <Box
        sx={{
          width: getPreviewWidth(
            props.hasUniformPreviewWidth,
            TIKTOK_PREVIEW_WIDTH,
            props.isResponsive
          ),
          height: '460px',
          overflow: 'hidden',
          position: 'relative',
          borderRadius: '12px',
          boxSizing: 'content-box',
          backgroundColor: '#ffffff',
          border: '1px solid #e5e5e5'
        }}
      >
        <Box sx={sxStyles.infoOverlay}>
          <Box sx={sxStyles.topBanner}>
            <Skeleton width={100} />
          </Box>
          <Box sx={sxStyles.rightBar}>
            <Box sx={sxStyles.avatar}>
              <Skeleton variant="circular" width={40} height={40} />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2.5}
              alignItems="center"
            >
              <Skeleton width={25} />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2}
              alignItems="center"
            >
              <Skeleton width={25} />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2}
              alignItems="center"
            >
              <Skeleton width={25} />
            </Box>
          </Box>
          <Box sx={sxStyles.infoContainer}>
            <Skeleton width={160} />
            <Box sx={sxStyles.infoLabelContainer}>
              <Skeleton width={110} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TiktokAdPreviewSkeleton;
