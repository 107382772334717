import { useState, useRef } from 'react';
import { flow } from 'lodash';
import {
  ImageListItem,
  ImageList,
  Slide,
  ImageListItemBar,
  Button,
  Typography,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { graphql } from '@apollo/client/react/hoc';

import { t } from 'i18next';

import { usePagination } from 'src/components/Pagination/hooks';

import Loading from 'src/components/Loading';
import PaginationControls from 'src/components/Pagination/PaginationControls';

import { shutterstockQuery } from './queries';
import { MEDIA_TYPE, IMAGE_GALLERY_HEIGHT } from './constants';
import ThirdPartyMediaTile from './Tiles/ThirdPartyMediaTile';
import MediaPurchaseModal from './MediaPurchaseModal';

const GalleryContentWrapper = styled(Box)(() => ({
  position: 'relative',
  minHeight: `${IMAGE_GALLERY_HEIGHT}px`
}));

const RESULTS_PER_PAGE = 12;

const getText = () => ({
  purchaseButton: t('gallery:shutterstock.purchaseButton'),
  noResults: t('gallery:shutterstock.noResults')
});

const GalleryShutterstock = ({
  shutterstockResult = {},
  searchQuery,
  returnToGallery,
  galleryRefetch,
  setIsAddingMedia,
  isAddingMedia
}) => {
  // we will replace placeholder images with this.
  const { shutterstockSearchImages } = shutterstockResult;
  const images =
    shutterstockSearchImages?.media?.edges.map(edge => edge.node) || [];

  const text = getText();

  const containerRef = useRef(null);
  const [hoveredId, setHoveredId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };
  const setImageSelected = image => {
    setModalOpen(true);
    setSelectedImage(image);
  };

  const { navigateNext, navigatePrev } = usePagination({
    edges: shutterstockSearchImages?.media?.edges,
    resultsPerPage: RESULTS_PER_PAGE,
    refetchCallback: shutterstockResult.refetch,
    refetchOptions: {
      last: RESULTS_PER_PAGE,
      input: {
        query: searchQuery
      }
    }
  });

  if (shutterstockResult?.loading || shutterstockResult?.error) {
    return (
      <Box>
        <GalleryContentWrapper>
          <Loading
            error={
              shutterstockResult?.error &&
              t('gallery:shutterstock.loadingError')
            }
          />
        </GalleryContentWrapper>
      </Box>
    );
  }

  return (
    <Box>
      <GalleryContentWrapper>
        {images.length === 0 && searchQuery && searchQuery?.length > 0 && (
          <Typography
            variant="h4"
            sx={{ textAlign: 'center', marginTop: '20px' }}
          >
            {text.noResults}
          </Typography>
        )}
        {images.length > 0 && (
          <ImageList rowHeight={180} cols={4}>
            {images.map(image => (
              <ImageListItem
                key={image.id}
                cols={1}
                onMouseEnter={() => setHoveredId(image.id)}
                onMouseLeave={() => setHoveredId(null)}
                onClick={() => setImageSelected(image)}
                data-cy="shutterstock-image"
                sx={{ position: 'relative', overflow: 'hidden' }}
                ref={containerRef}
              >
                <ThirdPartyMediaTile
                  media={image}
                  mediaType={MEDIA_TYPE.image}
                  clipBottom="12px"
                />
                <Slide
                  direction="up"
                  in={hoveredId === image.id}
                  container={containerRef.current}
                >
                  <ImageListItemBar
                    onClick={() => {}}
                    position="bottom"
                    title={
                      image?.offer?.price ? `$${image?.offer?.price}` : 'Free'
                    }
                    actionIcon={
                      <Button
                        aria-label="delete"
                        size="small"
                        variant="contained"
                        disableElevation
                        sx={{ marginRight: '10px' }}
                        data-amp-media-library-shutterstock-select-click
                        onClick={() => setImageSelected(image)}
                      >
                        {text.purchaseButton}
                      </Button>
                    }
                  />
                </Slide>
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </GalleryContentWrapper>
      <PaginationControls
        pageInfo={shutterstockSearchImages?.media?.pageInfo}
        navigateNext={navigateNext}
        navigatePrev={navigatePrev}
        variant="icon"
      />
      {selectedImage && (
        <MediaPurchaseModal
          media={selectedImage}
          modalOpen={modalOpen}
          selectedImage={selectedImage}
          closeModal={closeModal}
          returnToGallery={returnToGallery}
          galleryRefetch={galleryRefetch}
          setIsAddingMedia={setIsAddingMedia}
          isAddingMedia={isAddingMedia}
        />
      )}
      <br />
    </Box>
  );
};

export default flow(
  graphql(shutterstockQuery, {
    name: 'shutterstockResult',
    skip: props => !props.searchQuery,
    options: ({ searchQuery }) => {
      return {
        variables: {
          input: {
            query: searchQuery
          },
          first: RESULTS_PER_PAGE,
          last: RESULTS_PER_PAGE // what does this mean?
        }
      };
    }
  })
)(GalleryShutterstock);
