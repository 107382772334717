import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { flow, isEmpty, isFunction, isNil, isUndefined } from 'lodash';
import classnames from 'classnames';
import pluralize from 'pluralize';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import {
  Button,
  IconButton,
  Box,
  Divider,
  InputLabel,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { useAccordionState } from 'src/pages/Admin/BlueprintBuilder/BlueprintBuilderSteps/AccordionList/useAccordionState';
import { AccordionListHeading } from 'src/pages/Admin/BlueprintBuilder/BlueprintBuilderSteps/AccordionList';
import MaybeAccordionWrapper from './MaybeAccordionWrapper';
import FormInput from './FormInput';
import { sortAndFilterFormInputs } from './helpers';

const PREFIX = 'MultiInput';

const classes = {
  inputContainer: `${PREFIX}-inputContainer`,
  requiredText: `${PREFIX}-requiredText`,
  error: `${PREFIX}-error`,
  addButton: `${PREFIX}-addButton`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.inputContainer}`]: {
    marginTop: theme.spacing(2)
  },

  [`& .${classes.requiredText}`]: {
    fontStyle: 'italic'
  },

  [`& .${classes.error}`]: {
    color: theme.palette.error.main
  },

  [`& .${classes.addButton}`]: {
    marginTop: theme.spacing(2)
  }
}));

// Example input metadata
//
// MultiInput
// {
//     name: 'email',
//     displayName: 'Emails', // will show as group heading
//     displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING, // type for all added inputs
//     isMultiInput: true,
//     ...
//     displayParameters: {
//         inputData: {
//             ...
//         }
//     }
// }
//
// MultiInputGroup
// {
//     name: 'providers',
//     displayName: 'Custom Heading', // will show as group heading
//     displayMethodId: null, // not needed for MultiInputGroup as inputs are defined in groupInputs
//     isMultiInput: true,
//     ...
//     displayParameters: {
//         inputData: {
//             // Note: groupInputs can be a function that returns an array or an array so we can conditionally set things
//             //       based on the inputs being new like disabling fields on update but not when adding new ones
//             groupInputs: (isNew) => [
//                 {
//                     name: 'name',
//                     displayName: 'Header Name',
//                     displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
//                     ...
//                 },
//                 {
//                     name: 'value',
//                     displayName: 'Header Value',
//                     displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
//                     ...
//                 },
//                 ...
//             ],
//             ...
//         }
//     }
// }

// Redux output
//
// MultiInput
// {
//     fields: ['', ''];
// }
//
// MultiInputGroup
// {
//     providers: [
//         {
//             name: '',
//             value: ''
//         }
//     ]
// }

const getEmptyFields = groupInputs => {
  // If group inputs we need to create an empty object with the correct keys
  if (groupInputs) {
    const getGroupInputs = isFunction(groupInputs)
      ? groupInputs()
      : groupInputs;

    return getGroupInputs.reduce(
      (accum, field) => {
        const updated = accum;
        updated[field.name] = isNil(field.initialValue)
          ? ''
          : field.initialValue;
        return updated;
      },
      // Note: When group inputs are a function we need to know if the recently added fields are
      //       new to do conditional logic within groupInputs. Like disabling or changing the validations
      //       on the inputs. isNew will be passed along to the group input function.
      { ...(isFunction(groupInputs) && { isNew: true }) }
    );
  }
  return '';
};

const MultiInput = props => {
  const {
    fields,
    metadata,
    meta = {},
    DynamicForm,
    hookFormFieldArrayMethods,
    name,
    hookFormMethods,
    ...rest
  } = props;
  const { displayName, isRequired } = metadata;
  const { error, dirty, submitFailed } = meta;

  const showError = (error && dirty) || (error && submitFailed);
  const groupInputs = metadata?.displayParameters?.inputData?.groupInputs;
  const childInputs = metadata?.childInputs;
  const maxInputsLimit = metadata?.blueprintVariable?.arrayMaxItems;
  const shouldShowAddButton =
    (!maxInputsLimit && maxInputsLimit !== 0) ||
    fields?.length < maxInputsLimit;
  // Collapseable config is:
  // {
  //   numFieldsTillCollapse: number | undefined,
  //   headerItemText: ((index) => string) | undefined,
  // }
  const isCollapsable =
    metadata?.displayParameters?.inputData?.collapseConfig != null;

  const numFieldsTillCollapse =
    metadata?.displayParameters?.inputData?.collapseConfig
      ?.numFieldsTillCollapse ?? 3;

  const headerItemText =
    metadata?.displayParameters?.inputData?.collapseConfig?.headerItemText ??
    (index => t('multiInput:collapse.item', { index }));

  const shouldUseAccordions =
    isCollapsable && fields?.length > numFieldsTillCollapse;

  const { openIndexes, handleAccordionToggleAll, handleAccordionToggle } =
    useAccordionState({ maxNumItems: fields?.length });

  const allowDeleteMetaValue =
    metadata?.displayParameters?.inputData?.allowDelete;
  const allowDelete = isUndefined(allowDeleteMetaValue)
    ? true
    : allowDeleteMetaValue; // defaults to true when nothing provided

  const emptyFields = useMemo(() => {
    if (childInputs) {
      return getEmptyFields(childInputs);
    }
    if (groupInputs) {
      return getEmptyFields(groupInputs);
    }
  }, [groupInputs, childInputs]);

  const headerText = (
    <>
      {pluralize(displayName)}
      {isRequired && (
        <span className={classes.requiredText}>
          {' '}
          {t('common:input.labels.required')}
        </span>
      )}
    </>
  );
  return (
    <Root className={classes.inputContainer}>
      {!shouldUseAccordions && (
        <InputLabel
          shrink
          className={classnames({ [classes.error]: showError })}
          sx={{ display: 'inline-block' }}
        >
          {headerText}
        </InputLabel>
      )}
      {shouldUseAccordions && (
        <AccordionListHeading
          title={headerText}
          openIndexes={openIndexes}
          handleToggleAll={handleAccordionToggleAll}
        />
      )}
      <Divider sx={{ mb: 2 }} />
      {fields.map((field, index) => {
        const updatedProps = {
          ...rest,
          metadata: {
            ...metadata,
            name: hookFormFieldArrayMethods ? `${name}[${index}]` : field,
            isMultiInput: false, // don't want children to be multi inputs
            blueprintVariable: {} // if this is not cleared then the parent variable validations will be put on the inputs
          }
        };

        const isNew = hookFormFieldArrayMethods
          ? !!fields[index]?.isNew
          : !!fields.get(index)?.isNew;
        // Note: When group inputs are a function we need to know if the recently added fields are
        //       new to do conditional logic within groupInputs. Like disabling or changing the validations
        //       on the inputs.
        const getGroupInputs = isFunction(groupInputs)
          ? groupInputs(isNew)
          : groupInputs;

        let updatedChildInputs = [];
        if (childInputs) {
          updatedChildInputs = childInputs.map(input => ({
            ...input,
            name: `${field}.${input.name}`
          }));
        }

        return (
          <MaybeAccordionWrapper
            index={index}
            key={hookFormFieldArrayMethods ? field.id : `container.${field}`}
            shouldUseAccordions={shouldUseAccordions}
            openIndexes={openIndexes}
            handleAccordionToggle={handleAccordionToggle}
            headerItemText={headerItemText}
          >
            <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
              <Box style={{ width: '100%' }} flexGrow={1}>
                {/* new and sexy */}
                {childInputs && (
                  // TODO revisit and convert to hook form
                  <DynamicForm
                    key={`dynamicForm.${field}`}
                    inputs={updatedChildInputs}
                  />
                )}
                {/* old and ssweaty */}
                {groupInputs &&
                  sortAndFilterFormInputs(getGroupInputs).map(input => {
                    // TODO revisit and convert to hook form
                    return (
                      <FormInput
                        key={`formInput.${field}-${input?.name}`}
                        {...rest}
                        disabled={input.disabled} // this is normally handled by DynamicForm so we need to pass it
                        metadata={{
                          ...input,
                          name: `${field}.${input.name}`
                        }}
                      />
                    );
                  })}
                {!groupInputs && !childInputs && (
                  <FormInput
                    isHookForm={!!hookFormFieldArrayMethods}
                    {...updatedProps}
                  />
                )}
              </Box>
              {allowDelete && (
                <Box alignItems="center">
                  <IconButton
                    onClick={() => {
                      let remove;

                      if (hookFormFieldArrayMethods) {
                        remove = hookFormFieldArrayMethods.remove;
                      } else {
                        remove = fields.remove;
                      }

                      remove(index);
                    }}
                    className={classes.delete}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          </MaybeAccordionWrapper>
        );
      })}
      {shouldShowAddButton && (
        <Button
          variant="outlined"
          fullWidth
          onClick={() => {
            const previousInputIsEmpty =
              fields.length > 0 &&
              isEmpty(
                hookFormMethods?.getValues(`${name}[${fields.length - 1}]`)
              );

            if (hookFormFieldArrayMethods && !previousInputIsEmpty) {
              hookFormFieldArrayMethods.append(emptyFields);
            } else if (hookFormFieldArrayMethods && previousInputIsEmpty) {
              hookFormMethods.setError(
                `${name}[${fields.length - 1}]`,
                {
                  type: 'requiredToAdd',
                  message: t('validations:multiInputRequiredToAdd')
                },
                { shouldFocus: true }
              );
            } else {
              fields.push(emptyFields);
            }
          }}
          className={classes.addButton}
          size="small"
        >
          <AddIcon /> <Trans i18nKey="multiInput:button.add" />
        </Button>
      )}
      {showError && (
        <Typography variant="body1" className={classes.error}>
          {error}
        </Typography>
      )}
    </Root>
  );
};

export default flow()(MultiInput);
