import { flow, isEmpty } from 'lodash';
import { styled } from '@mui/material/styles';
import { Link, withRouter } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import { Button, Paper } from '@mui/material';
import { t } from 'i18next';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

import TableEmptyState from 'src/components/EmptyStates/TableEmptyState';
import Loading from 'src/components/Loading';
import Table from 'src/components/Table';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import DateCell from 'src/components/Table/DateCell';

import { getPromotions } from './queries';

const PREFIX = 'PromotionsTable';

const classes = {
  root: `${PREFIX}-root`,
  highlight: `${PREFIX}-highlight`,
  tableTitle: `${PREFIX}-tableTitle`,
  icon: `${PREFIX}-icon`,
  create: `${PREFIX}-create`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%'
  },

  [`& .${classes.highlight}`]: {
    background: theme.palette.success[50]
  },

  [`& .${classes.tableTitle}`]: {
    flexGrow: 1
  },

  [`& .${classes.icon}`]: {
    width: '50px'
  },

  [`& .${classes.create}`]: {
    position: 'absolute',
    right: '0px',
    transform: 'translate(0, -100%)',
    marginTop: theme.spacing(-2)
  }
}));

const PromotionsTable = props => {
  const { history, loading, promotions } = props;

  const handleRowClick = ({ id }) => {
    const linkPath = generateLinkPath(paths.admin.settings.promotionDetails, {
      promoId: id
    });

    return history.push(linkPath);
  };

  if (loading) {
    return <Loading />;
  }

  const columnSchema = [
    {
      columnName: t('adminPromotions:promotionsTable.promotionId'),
      accessor: 'id',
      key: 'id',
      type: 'ID'
    },
    {
      columnName: t('adminPromotions:promotionsTable.promoCode'),
      accessor: 'promoCode',
      key: 'promoCode',
      type: 'single_line_string'
    },
    {
      columnName: t('adminPromotions:promotionsTable.description'),
      accessor: 'description',
      key: 'description',
      type: 'single_line_string'
    },
    {
      columnName: t('adminPromotions:promotionsTable.startDate'),
      accessor: 'startDate',
      key: 'startDate',
      type: 'datetime_utc',
      CellComponent: props => <DateCell {...props} size="medium" />
    },
    {
      columnName: t('adminPromotions:promotionsTable.endDate'),
      accessor: 'endDate',
      key: 'endDate',
      type: 'datetime_utc',
      CellComponent: props => <DateCell {...props} size="medium" />
    },
    {
      columnName: t('adminPromotions:promotionsTable.percentOff'),
      accessor: r => {
        const percentageDiscount = r?.percentageDiscount;
        if (!percentageDiscount) {
          return t('adminPromotions:promotionsTable.notApplicable');
        }

        return percentageDiscount;
      },
      key: 'percentOff',
      type: INPUT_TYPES.PERCENTAGE_DECIMAL
    },
    {
      columnName: t('adminPromotions:promotionsTable.minimumSpend'),
      accessor: 'minimumSpend',
      key: 'minimumSpend',
      type: 'price_decimal'
    },
    {
      columnName: t('adminPromotions:promotionsTable.totalDollarDiscount'),
      accessor: 'totalDollarDiscount',
      key: 'totalDollarDiscount',
      type: 'price_decimal'
    },
    {
      columnName: t('adminPromotions:promotionsTable.maxDiscountPerUse'),
      accessor: 'maxDollarDiscountPerUse',
      key: 'maxDollarDiscountPerUse',
      type: 'price_decimal'
    },
    {
      columnName: t('adminPromotions:promotionsTable.poolSize'),
      accessor: 'totalNumberOfUsesAllowed',
      key: 'totalNumberOfUsesAllowed',
      type: 'positive_integer'
    },
    {
      columnName: t('adminPromotions:promotionsTable.maxClaimsPerUser'),
      accessor: 'totalNumberOfUsesAllowedPerUser',
      key: 'totalNumberOfUsesAllowedPerUser',
      type: 'positive_integer'
    },
    {
      columnName: t('adminPromotions:promotionsTable.isDeleted'),
      accessor: 'isDeleted',
      key: 'isDeleted'
    },
    {
      columnName: t('adminPromotions:promotionsTable.createdAt'),
      accessor: 'createdAt',
      key: 'createdAt',
      type: 'datetime_utc',
      CellComponent: props => <DateCell {...props} size="medium" />
    }
  ];

  const buttonText = t('adminPromotions:promotionsTable.createProgram');

  const buttonTo = generateLinkPath(paths.admin.settings.promotionCreate);
  return !loading && isEmpty(promotions) ? (
    <TableEmptyState
      buttonText={buttonText}
      buttonTo={buttonTo}
      emptyMessage={t('adminPromotions:promotionsTable.empty')}
    />
  ) : (
    <Root>
      <div style={{ position: 'relative' }}>
        <Button
          className={classes.create}
          color="primary"
          component={Link}
          size="large"
          to={buttonTo}
          variant="outlined"
        >
          {buttonText}
        </Button>
      </div>
      <Paper className={classes.root}>
        <Table
          columnSchema={columnSchema}
          rows={promotions}
          onClickBodyRow={handleRowClick}
          loading={loading}
        />
      </Paper>
    </Root>
  );
};

export default flow(
  graphql(getPromotions, {
    name: 'getPromotions',
    options: () => ({
      fetchPolicy: 'no-cache'
    }),
    props: ({ getPromotions: { error, loading, myOrganization } }) => {
      const promotions = myOrganization?.promotions || [];

      return {
        loading,
        error,
        promotions
      };
    }
  }),
  withRouter
)(PromotionsTable);
