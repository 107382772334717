import { useMemo } from 'react';
import { FieldArray } from 'redux-form';
import { t } from 'i18next';

import { Box, Typography } from '@mui/material';

import { fieldArrayFixDumbReduxFormError } from 'src/common/validations';

import RenderPublishersInputs from './RenderPublishersInputs';
import BlueprintBuilderStepFooter from '../BlueprintBuilderStepFooter';

const pageText = () => ({
  stepHeading: t('admin:blueprintBuilder.stepPublishersStepHeading')
});

const StepPublishers = () => {
  const text = useMemo(() => pageText(), []);

  return (
    <Box>
      <Typography variant="h6">{text.stepHeading}</Typography>
      <br />
      <FieldArray
        component={RenderPublishersInputs}
        name="document.blueprint.publishers"
        validate={[fieldArrayFixDumbReduxFormError]}
      />
      <BlueprintBuilderStepFooter />
    </Box>
  );
};

export default StepPublishers;
