import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';

import { Typography } from '@mui/material';

import Variables from './Variables';
import BlueprintBuilderStepFooter from './BlueprintBuilderStepFooter';

const PREFIX = 'StepVariables';

const classes = {
  subHeading: `${PREFIX}-subHeading`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.subHeading}`]: {
    marginBottom: theme.spacing(2)
  }
}));

const pageText = () => ({
  stepHeading: t('admin:blueprintBuilder.stepVariablesStepHeading'),
  stepSubHeading: t('admin:blueprintBuilder.stepVariablesStepSubHeading')
});

const StepVariables = () => {
  const text = useMemo(() => pageText(), []);

  return (
    <Root>
      <Typography variant="h6">{text.stepHeading}</Typography>
      <Typography className={classes.subHeading} variant="subtitle2">
        {text.stepSubHeading}
      </Typography>
      <Variables />
      <BlueprintBuilderStepFooter />
    </Root>
  );
};

export default StepVariables;
