import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';

import { graphql } from '@apollo/client/react/hoc';
import { AUTOMATED_PROGRAMS_WIZARD_MODAL_DISMISS } from 'src/common/userSettings';
import { updateUserSettings } from 'src/pages/Programs/mutations';

const PREFIX = 'AutomatedProgramGetStarted';

const classes = {
  container: `${PREFIX}-container`,
  heading: `${PREFIX}-heading`,
  subHeading: `${PREFIX}-subHeading`,
  listHeading: `${PREFIX}-listHeading`,
  list: `${PREFIX}-list`,
  dismissHelpLabel: `${PREFIX}-dismissHelpLabel`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },

  [`& .${classes.heading}`]: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: `${theme.spacing(2)} 0`,
    maxWidth: '700px',
    textAlign: 'center'
  },

  [`& .${classes.subHeading}`]: {
    fontSize: '18px',
    maxWidth: '700px',
    textAlign: 'center'
  },

  [`& .${classes.listHeading}`]: {
    marginTop: theme.spacing(2),
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: 'bold'
  },

  [`& .${classes.list}`]: {
    counterRest: 'item',
    fontSize: '16px',
    listStyle: 'none',

    '& li': {
      counterIncrement: 'item',
      marginBottom: theme.spacing(1)
    },

    '& li:before': {
      background: theme.palette.primary.main,
      borderRadius: '100%',
      color: theme.palette.primary.contrastText,
      content: 'counter(item)',
      display: 'inline-block',
      marginRight: theme.spacing(2),
      textAlign: 'center',
      width: '23px'
    }
  },

  [`& .${classes.dismissHelpLabel}`]: {
    fontSize: '12px'
  }
}));

const AutomatedProgramGetStarted = props => {
  const { contentNameLowerCase, dismissHelpUserSetting, updateUserSettings } =
    props;

  const [dismissHelp, setDismissHelp] = useState(dismissHelpUserSetting);

  const handelDismissHelp = async () => {
    setDismissHelp(!dismissHelp);
    if (dismissHelp) {
      // remove dissmiss
      await updateUserSettings({
        variables: {
          updateSettings: {
            [AUTOMATED_PROGRAMS_WIZARD_MODAL_DISMISS]: false
          },
          updateType: 'DEEP_MERGE'
        }
      });
      return;
    }
    // add dismiss
    await updateUserSettings({
      variables: {
        updateSettings: {
          [AUTOMATED_PROGRAMS_WIZARD_MODAL_DISMISS]: true
        },
        updateType: 'DEEP_MERGE'
      }
    });
  };

  const listItems = [
    t('automatedProgramFilter:wizard.overviewListItem1', {
      contentNameLowerCase
    }),
    t('automatedProgramFilter:wizard.overviewListItem2'),
    t('automatedProgramFilter:wizard.overviewListItem3'),
    t('automatedProgramFilter:wizard.overviewListItem4'),
    t('automatedProgramFilter:wizard.overviewListItem5')
  ];

  return (
    <Root className={classes.container}>
      <Typography className={classes.heading} variant="h3">
        <Trans i18nKey="automatedProgramFilter:wizard.stepOneHeading">
          Welcome to Automations
        </Trans>
      </Typography>
      <Typography className={classes.subHeading} variant="h4">
        {t('automatedProgramFilter:wizard.stepOneSubHeading', {
          contentNameLowerCase
        })}
      </Typography>
      <Typography className={classes.listHeading} variant="h5">
        <Trans i18nKey="automatedProgramFilter:wizard.stepOneListHeading">
          How to setup an automation:
        </Trans>
      </Typography>
      <ol className={classes.list}>
        {listItems.map(item => (
          <li key={item}>{item}</li>
        ))}
      </ol>
      <FormControlLabel
        data-amp-program-automation-wizard-dismiss-click
        control={
          <Checkbox
            checked={!!dismissHelp}
            onChange={handelDismissHelp}
            name="dismissHelp"
          />
        }
        label={
          <Typography className={classes.dismissHelpLabel}>
            <Trans i18nKey="automatedProgramFilter:wizard.stepOneDismissHelp">
              Thanks, I've got it and would like to skip this step in the
              future.
            </Trans>
          </Typography>
        }
      />
    </Root>
  );
};

export default flow(
  graphql(updateUserSettings, { name: 'updateUserSettings' })
)(AutomatedProgramGetStarted);
