import { Trans } from 'react-i18next';

import { styled } from '@mui/material/styles';

import { Divider, List, Typography } from '@mui/material';

import BusinessObjectBulkListItem from './BusinessObjectBulkListItem';

const PREFIX = 'BusinessObjectBulkSelector';

const classes = {
  list: `${PREFIX}-list`
};

const Root = styled('div')(() => ({
  [`& .${classes.list}`]: {
    maxHeight: '200px',
    minHeight: '200px',
    overflow: 'scroll'
  }
}));

const BusinessObjectBulkSelector = props => {
  const { contentName, hasCatalog } = props;
  const contentNameLowercase = contentName
    ? contentName.toLowerCase()
    : 'content';

  let title = (
    <Trans
      i18nKey="businessObjectSelector:businessObjectBulkSelector.title"
      values={{ contentName: contentNameLowercase }}
    />
  );

  if (!hasCatalog) {
    title = (
      <Trans i18nKey="businessObjectSelector:businessObjectBulkSelector.noContentTitle" />
    );
  }

  return (
    <Root>
      <Typography variant="body2">{title}</Typography>
      <Divider />
      <List className={classes.list}>
        <BusinessObjectBulkListItem
          contentName={contentNameLowercase}
          hasCatalog={hasCatalog}
        />
      </List>
    </Root>
  );
};

export default BusinessObjectBulkSelector;
