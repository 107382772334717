import { Tooltip, Button } from '@mui/material';

const TooltipButton = props => {
  const { displayTooltip, tooltip, children, ...rest } = props;

  const baseButton = <Button {...rest}>{children}</Button>;
  // The button is wrapoped in a span element so the tooltip can display when the button is disabled
  // https://mui.com/material-ui/react-tooltip/#disabled-elements
  if (displayTooltip)
    return (
      <Tooltip title={tooltip}>
        <span>{baseButton}</span>
      </Tooltip>
    );

  return baseButton;
};

export default TooltipButton;
