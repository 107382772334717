import { useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

import useCopyToClipboard from 'src/hooks/useCopyToClipboard';

import CopyToClipboardButton from './CopyToClipboardButton';

const getStyles = theme => {
  return {
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      gap: theme.spacing(2)
    }
  };
};

const CopyToClipboard = props => {
  const { text } = props;
  const theme = useTheme();
  const { copied, copy, error, isLoading } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();

  const styles = getStyles(theme);

  useEffect(() => {
    if (error && !isLoading) enqueueSnackbar(error, { variant: 'error' });
    if (copied && !isLoading)
      enqueueSnackbar(t('copyToClipboard:snackbar.success'), {
        variant: 'success'
      });
  }, [error, isLoading, copied, enqueueSnackbar]);

  const handleClick = useCallback(async () => {
    await copy(text);
  }, [copy, text]);

  return (
    <Box sx={styles.container}>
      <Typography variant="body1">{text}</Typography>
      <CopyToClipboardButton onClick={handleClick} isCopied={copied} />
    </Box>
  );
};

export const CopyToClipboardContainer = props => {
  const { children, ...restProps } = props;
  const { copied, error, isLoading } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error && !isLoading) enqueueSnackbar(error, { variant: 'error' });
    if (copied && !isLoading)
      enqueueSnackbar(t('copyToClipboard:snackbar.success'), {
        variant: 'success'
      });
  }, [error, isLoading, copied, enqueueSnackbar]);

  return <div {...restProps}>{children}</div>;
};

export const CopyToClipboardActionButton = props => {
  const { text, variant, label, copiedLabel, iconColor, color, ...restProps } =
    props;
  const { copy, copied } = useCopyToClipboard();

  const handleClick = useCallback(async () => {
    await copy(text);
  }, [copy, text]);

  return (
    <CopyToClipboardButton
      label={label}
      onClick={handleClick}
      isCopied={copied}
      copiedLabel={copiedLabel}
      variant={variant}
      color={color}
      iconColor={iconColor}
      {...restProps}
    />
  );
};

export default CopyToClipboard;
