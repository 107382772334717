import { flow, noop } from 'lodash';
import { generatePath, matchPath } from 'react-router';
import { withRouter } from 'react-router-dom';

import { Box, Divider, Drawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { withAppSettings } from 'src/AppSettings';
import { useAppState } from 'src/AppStateProvider';

import { paths } from 'src/routes/paths';
import { isGuest } from 'src/Auth/common';
import { withFeatures } from 'src/components/Feature';

import NavigationList from './NavigationList';
import OfficeSelector from './OfficeSelector';
import AdminNavigationList from './AdminNavigationList';
import NavigationAccountInfo from './NavigationAccountInfo';
// This function is designed to determine if the logo link url is overridden
// to go to an external site. If so, we use window.location. Otherwise we send
// the user to the dashboard.
const handleLogoClick = (history, linkBackTo) => {
  const linkBackUrl = linkBackTo;

  if (linkBackUrl) {
    window.location = linkBackUrl;
    return;
  }
  // Default case if no settings exist in the skin to override it.
  history.push(generatePath(paths.dashboard.base));
};

const ChromeDrawer = props => {
  const { loading, features, history, location, appSettings } = props;
  const { sideNavOpen } = useAppState();

  const isAdminPath = !!matchPath(location.pathname, {
    path: paths.admin.base
  });
  const linkBackTo = appSettings?.app?.general?.linkBack?.url;

  const theme = useTheme();

  const isGuestUser = isGuest();

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        whiteSpace: 'nowrap',
        width: `${theme.evSizes.navigationWidth}px`,
        ...(sideNavOpen && {
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
          }),
          width: `${theme.evSizes.navigationWidth}px`
        }),
        ...(!sideNavOpen && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          }),
          width: 0
        }),
        '& .MuiDrawer-paper': {
          ...(sideNavOpen && {
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen
            }),
            width: `${theme.evSizes.navigationWidth}px`
          }),
          ...(!sideNavOpen && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen
            }),
            width: 0
          })
        }
      }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      open={sideNavOpen}
      variant="permanent"
    >
      <Box
        sx={{
          width: `${theme.evSizes.navigationWidth}px`,
          padding: theme.spacing(2)
        }}
      >
        <Box
          component="img"
          alt={`${appSettings?.app.general.baseTitle} Logo`}
          sx={{ width: '100%', cursor: 'pointer' }}
          src={appSettings?.evAssets.logoUrl}
          onClick={() =>
            features?.logoClick ? handleLogoClick(history, linkBackTo) : noop
          }
        />
      </Box>
      <Divider />
      {!isGuestUser && <OfficeSelector />}
      {isGuestUser && <NavigationAccountInfo />}
      <Divider />
      {!loading && (
        <>{isAdminPath ? <AdminNavigationList /> : <NavigationList />}</>
      )}
    </Drawer>
  );
};

export default flow(withAppSettings, withRouter, withFeatures)(ChromeDrawer);
