import jwtDecode from 'jwt-decode';
import { get } from 'lodash';
import Logger from '../common/Logger';

export const EV_ORGANIZATION_ID = '110';

export const AUTH_PROVIDER_TYPES = {
  auth0: 'auth0',
  auth0_sso: 'auth0_sso',
  internal: 'internal',
  okta_direct_sso: 'okta_direct_sso'
};

export const GUEST_AUTH_MODAL_PROVIDER_TYPES = [
  AUTH_PROVIDER_TYPES.internal,
  AUTH_PROVIDER_TYPES.okta_direct_sso
];

export const EV_ROLES = {
  GROUP: 'li_group_role',
  ORG: 'li_organization_role'
};

export const OFFICE_USER_GROUP_ADMIN_ROLES = new Set(['group_admin']);

export const OFFICE_USER_ORG_ADMIN_ROLES = new Set(['admin']);

export const AUTH0_KEYS = {
  ACCESS_TOKEN: 'access_token',
  ID_TOKEN: 'id_token',
  EXPIRES_AT: 'expires_at',
  EV_ACCESS_TOKEN: 'ev_access_token'
};

export const AUTH0_SUPER_ADMIN_ACCESSOR =
  'https://office.evocalize.com/evocalize_role';

export const AUTH0_SUPER_ADMIN_TYPES = {
  superAdmin: 'super_admin'
};

const AUTH0_IS_DEVELOPER_ACCESSOR = 'https://office.evocalize.com/is_developer';

export const AUTH_URL_KEY = 'authUrl';

export const getCurrentLocation = () => `${window.location}`;

export const getAuthUrl = () => localStorage.getItem(AUTH_URL_KEY);

export const setAuthUrl = url => {
  const currentLocation = url || getCurrentLocation();

  // Only store the redirect URL if this isn't an authentication
  // redirect.
  if (
    currentLocation.match(/access_token=/) !== null ||
    currentLocation.match(/code=/) !== null ||
    currentLocation.match(/token=/) !== null ||
    currentLocation.match(/iss=/) !== null
  ) {
    return;
  }

  Logger.debug('Setting authUrl to: ', currentLocation);

  localStorage.setItem(AUTH_URL_KEY, currentLocation);
};

export const deleteAuthUrl = () => {
  localStorage.removeItem(AUTH_URL_KEY);
};

export const getAccessToken = () => {
  return localStorage.getItem(AUTH0_KEYS.ACCESS_TOKEN);
};

export const setAccessToken = accessToken => {
  return localStorage.setItem(AUTH0_KEYS.ACCESS_TOKEN, accessToken);
};

export const getEvAccessToken = () => {
  return localStorage.getItem(AUTH0_KEYS.EV_ACCESS_TOKEN);
};

export const getAccessTokenExpiration = () => {
  return localStorage.getItem(AUTH0_KEYS.EXPIRES_AT);
};

export const setAccessTokenExpiration = exp => {
  return localStorage.setItem(AUTH0_KEYS.EXPIRES_AT, exp);
};

export const setEvAccessToken = accessToken => {
  localStorage.setItem(AUTH0_KEYS.EV_ACCESS_TOKEN, accessToken);
};

// Office helpers.
export const getJwtAttribute = attributeName => {
  const evAccessToken = getEvAccessToken();
  let attribute = null;

  try {
    const decoded = jwtDecode(evAccessToken);
    attribute = get(decoded, attributeName);
  } catch (e) {
    // Do nothing.
  }

  return attribute;
};

export const getUserGroupRole = () => {
  return getJwtAttribute(EV_ROLES.GROUP);
};

export const isGroupAdmin = () => {
  const userGroupRole = getUserGroupRole();

  return OFFICE_USER_GROUP_ADMIN_ROLES.has(userGroupRole);
};

export const getUserOrgRole = () => {
  return getJwtAttribute(EV_ROLES.ORG);
};

export const isOrgAdmin = () => {
  const userOrgRole = getUserOrgRole();

  return OFFICE_USER_ORG_ADMIN_ROLES.has(userOrgRole);
};

export const isPartnerAdmin = () => {
  return getUserOrgRole() === 'partner_admin';
};

export const isGuest = () => {
  return getUserOrgRole() === 'guest';
};

export const AGENT_PROFILE_ID_KEY = 'ev_agent_profile_id';

export const getAgentProfileId = () => {
  return localStorage.getItem(AGENT_PROFILE_ID_KEY);
};

export const setAgentProfileId = agentProfileId => {
  return localStorage.setItem(AGENT_PROFILE_ID_KEY, agentProfileId);
};

export const removeAgentProfileId = () => {
  return localStorage.removeItem(AGENT_PROFILE_ID_KEY);
};

export const isSuperAdmin = () => {
  let superAdmin = false;

  try {
    const decodedToken = jwtDecode(getAccessToken());
    superAdmin =
      get(decodedToken, AUTH0_SUPER_ADMIN_ACCESSOR) ===
      AUTH0_SUPER_ADMIN_TYPES.superAdmin;
  } catch (e) {
    // Do nothing.
  }

  return superAdmin;
};

export const isDeveloper = () => {
  let isDev = false;

  try {
    const decodedToken = jwtDecode(getAccessToken());
    isDev = get(decodedToken, AUTH0_IS_DEVELOPER_ACCESSOR);
  } catch (e) {
    // Do nothing.
  }

  return isDev;
};

export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (p => {
    return p.toString() === '[object SafariRemoteNotification]';
  })(
    !get(window, 'safari') ||
      // Note: safari is defined in the browser
      // eslint-disable-next-line no-undef
      (typeof safari !== 'undefined' && safari.pushNotification)
  );

export const padUserId = userId => {
  if (userId && userId.length < 5) {
    return `${userId}_evocalize`;
  }

  return userId;
};
