import { createRef, Component } from 'react';
import { styled } from '@mui/material/styles';
import { find, remove } from 'lodash';
import {
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Chip,
  InputAdornment,
  Tooltip,
  MenuItem,
  Checkbox
} from '@mui/material';

import HelpIcon from '@mui/icons-material/HelpOutline';
import { formatValue } from 'src/components/ReduxForm/helpers';

const PREFIX = 'RenderMultiSelect';

const classes = {
  chips: `${PREFIX}-chips`,
  chip: `${PREFIX}-chip`,
  helpTip: `${PREFIX}-helpTip`,
  helpIcon: `${PREFIX}-helpIcon`
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.chips}`]: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  [`& .${classes.chip}`]: {
    margin: `1px ${theme.spacing(0.25)}`
  },

  [`& .${classes.helpTip}`]: {
    color: theme.palette.grey[500],
    position: 'absolute',
    top: '1px',
    right: '3px'
  },

  [`& .${classes.helpIcon}`]: {
    width: '20px'
  }
}));

const getChipName = (value, menuItems) => {
  const menuItem = find(menuItems, { value });

  return menuItem?.content;
};

class RenderMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.inputRef = createRef();
  }

  handelOnDelete = deleteValue => {
    const {
      input: { value, onChange }
    } = this.props;

    const updatedValue = remove(value, item => {
      return item !== deleteValue;
    });

    // Note: this puts focus back on the input after removing an item so that
    //       when you click off of the input it deselects and so that keyboard commands
    //       work after delete as well.
    this.inputRef.current.focus();

    onChange(updatedValue);
  };

  render() {
    const {
      menuItems,
      className,
      input,
      label,
      meta: { touched, error },
      placeholder,
      variant = 'outlined',
      multiple,
      tooltip,
      readOnly,
      autoComplete,
      helperText
    } = this.props;

    const {
      input: { value }
    } = this.props;

    return (
      <StyledFormControl
        className={className}
        error={touched && !!error}
        fullWidth
        variant={variant}
      >
        <InputLabel>{label || placeholder}</InputLabel>
        <Select
          readOnly={readOnly}
          autoComplete={autoComplete}
          input={
            <OutlinedInput
              inputRef={this.inputRef}
              label={label || placeholder}
              endAdornment={
                tooltip && (
                  <InputAdornment className={classes.helpTip} position="end">
                    <Tooltip arrow title={tooltip}>
                      <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                  </InputAdornment>
                )
              }
            />
          }
          multiple={multiple}
          renderValue={selected => {
            return multiple ? (
              <div className={classes.chips}>
                {selected.map(value => {
                  return (
                    <Chip
                      key={value}
                      label={getChipName(value, menuItems)}
                      className={classes.chip}
                      onDelete={() => this.handelOnDelete(value)}
                      onMouseDown={event => {
                        // NOTE: Select component intercepts mousedown event so we need
                        //       to prevent that from happening on a chip click
                        event.stopPropagation();
                      }}
                    />
                  );
                })}
              </div>
            ) : (
              getChipName(selected, menuItems)
            );
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            PaperProps: {
              style: {
                // if we can get it to position below we will want to set a max height
                // maxHeight: '300px'
              }
            }
          }}
          {...input}
          value={formatValue(value, multiple)}
        >
          {menuItems &&
            menuItems.map(item => (
              <MenuItem
                key={item.key}
                value={item.value}
                disabled={item?.disabled || false}
              >
                {!item.disabled && multiple && (
                  <Checkbox
                    checked={
                      input.value ? input.value.indexOf(item.value) > -1 : false
                    }
                  />
                )}
                {item.content}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText
          sx={{ mt: 0.5, ml: 1, whiteSpace: 'pre-line' }}
          error={touched && error}
          variant
        >
          {(touched && error) || helperText}
        </FormHelperText>
      </StyledFormControl>
    );
  }
}

export default RenderMultiSelect;
