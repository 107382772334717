import { useMediaQuery } from '@mui/material';
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { ArrowDropDown } from '@mui/icons-material';

import { convertToDayjs } from 'src/common/dates';

const FORMAT = 'MM/DD/YYYY';
const MASK = '';

const RenderCalendarPicker = props => {
  const {
    meta: { error },
    input,
    readOnly = false,
    ...rest
  } = props;

  // All dates passed into MUI date pickers must be a Dayjs object or null
  const { value } = input;
  // convertToDayjs will wrap the value in a Dayjs object if it is not already
  const wrappedValue = convertToDayjs(value);

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const sharedInputProps = { error: !!error, helperText: error };
  const restProps = { ...rest, ...input, value: wrappedValue };

  if (isMobile) {
    return (
      <MobileDatePicker
        {...restProps}
        format={FORMAT}
        mask={MASK}
        slotProps={{
          textField: {
            ...sharedInputProps,
            InputProps: {
              endAdornment: <ArrowDropDown />
            }
          }
        }}
        disabled={readOnly}
      />
    );
  }
  return (
    <DesktopDatePicker
      {...restProps}
      format={FORMAT}
      mask={MASK}
      disabled={readOnly}
      slotProps={{
        textField: { ...sharedInputProps }
      }}
    />
  );
};

export default RenderCalendarPicker;
