import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { debounce } from 'lodash';

import {
  Box,
  IconButton,
  Stack,
  Slide,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
  styled
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import useProgram from 'src/pages/Program/utils/useProgram';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import { getContentSelectorByArchitectureId } from 'src/components/ContentTable/queries';
import ContentThemeSelectorCard from 'src/components/BusinessObjectSelector/ContentThemeSelectorCard';
import ContentThemeSelectorSkeleton from 'src/components/BusinessObjectSelector/ContentThemeSelectorSkeleton';

interface ContentThemeSelectorProps {
  onSelect: (id: string) => void;
  selected: string[];
}

const PAGE_SIZE = 25;
export const CARD_WIDTH = 140;

// Calculates how many cards fit horizontally in the container.
const calculateItemsPerPage = (
  containerWidth: number,
  totalItems: number
): number => {
  const totalWidthPerCard = CARD_WIDTH;
  const rawItems = containerWidth / totalWidthPerCard;

  // I've found that we can squeeze thing a bit more so we can calculate the itemsPerPage like this:

  // If the fractional part of rawItems is >= 0.8, round up to the next integer (e.g., 2.88 → 3).
  // Otherwise, round down (e.g., 2.56 → 2).
  const roundedItems =
    rawItems % 1 >= 0.85 ? Math.ceil(rawItems) : Math.floor(rawItems);

  // Clamp itemsPerPage to totalItems to avoid issues
  return Math.min(roundedItems, totalItems);
};

// example : getPageStartIndices(7, 3);
// Output: [0, 2, 4]
const getPageStartIndices = (
  totalItems: number,
  itemsPerPage: number
): number[] => {
  // Return an empty array if itemsPerPage or totalItems is invalid
  if (itemsPerPage <= 0 || totalItems <= 0) return [];

  const startIndices: number[] = [];

  // Calculate indices in steps of itemsPerPage
  for (let i = 0; i + itemsPerPage <= totalItems; i += itemsPerPage) {
    startIndices.push(i);
  }

  // Ensure the last page is included if it's partially filled
  if (totalItems % itemsPerPage !== 0) {
    startIndices.push(totalItems - itemsPerPage);
  }

  // Remove duplicate if the last page was already covered
  return [...new Set(startIndices)].sort((a, b) => a - b);
};

// Styled arrows
const StyledIconButton = styled(IconButton)(() => ({
  backgroundColor: 'transparent',
  position: 'absolute',
  zIndex: 2,
  borderRadius: '4px',
  height: '100%',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, .1)'
  }
}));

const ContentThemeSelector = ({
  onSelect,
  selected
}: ContentThemeSelectorProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const architecture = useArchitecture();
  const { catalogCollapseKey } = useProgram();

  // Determine which catalog fields to use if none are set
  const catalogFieldMetadata = architecture?.catalog?.fieldMetadata ?? [];
  const fallbackImageFieldName = catalogFieldMetadata.find(
    item => item.displayMethodId === 'image_url'
  )?.fieldName;
  const fallbackImageTemplate = `{${fallbackImageFieldName}}`;
  const fallbackNameTemplate = `{${catalogFieldMetadata[0]?.fieldName}}`;

  const displayImageTemplate =
    architecture?.catalog?.displayImageTemplate || fallbackImageTemplate;
  const displayNameTemplate =
    architecture?.catalog?.displayNameTemplate || fallbackNameTemplate;

  // Fetch content items
  const [fetchContent, { data, loading }] = useLazyQuery(
    getContentSelectorByArchitectureId
  );

  useEffect(() => {
    if (architecture?.id) {
      fetchContent({
        variables: {
          architectureId: architecture.id,
          first: PAGE_SIZE,
          groupKey: catalogCollapseKey,
          alwaysInclude: { id: { in: selected } }
        }
      }).catch(() => {
        // TODO snack
      });
    }
  }, [architecture?.id, catalogCollapseKey, fetchContent]);

  const content = useMemo(() => {
    return (data?.architecture?.catalog?.contentV2?.edges ?? [])
      .flatMap(edge => edge?.node?.items ?? [])
      .filter(item => item?.id);
  }, [data]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [currentPage, setCurrentPage] = useState(0);

  const totalItems = content.length;

  const handleResize = useCallback(
    debounce(() => {
      // no need to resize if there are no items
      if (totalItems === 0) return;

      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const updatedItems = calculateItemsPerPage(containerWidth, totalItems);
        setItemsPerPage(updatedItems);
        setCurrentPage(0);
      }
    }, 200),
    [totalItems]
  );

  // Resize observer to update itemsPerPage
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => handleResize());
    if (containerRef.current) resizeObserver.observe(containerRef.current);

    window.addEventListener('resize', handleResize);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  // Select the first item if none are selected
  useEffect(() => {
    if (content.length > 0 && selected.length === 0) {
      // Ensure we call onSelect with a slight delay to allow form state to initialize
      const timer = setTimeout(() => {
        onSelect(content[0].id);
      }, 20);
      return () => clearTimeout(timer);
    }
  }, [content, selected, onSelect]);

  const pageStartIndices = useMemo(
    () =>
      isSmallScreen
        ? []
        : (getPageStartIndices(totalItems, itemsPerPage) ?? []),
    [totalItems, itemsPerPage]
  );

  const currentPageItems = useMemo(() => {
    const startIndex = pageStartIndices[currentPage];
    return content.slice(startIndex, startIndex + itemsPerPage);
  }, [content, pageStartIndices, currentPage, itemsPerPage]);

  const handleNext = useCallback(() => {
    setCurrentPage(prevPage => (prevPage + 1) % pageStartIndices.length);
  }, [pageStartIndices]);

  const handlePrev = useCallback(() => {
    setCurrentPage(
      prevPage =>
        (prevPage + pageStartIndices.length - 1) % pageStartIndices.length
    );
  }, [pageStartIndices]);

  if (loading) {
    return <ContentThemeSelectorSkeleton />;
  }

  // Render vertical list on small screens
  if (isSmallScreen) {
    return (
      <List sx={{ padding: 0, width: '100%' }}>
        {content.map(item => (
          <ListItem key={item.id} sx={{ padding: 0.5, width: '100%' }}>
            <ContentThemeSelectorCard
              contentData={item}
              displayImageTemplate={displayImageTemplate}
              displayNameTemplate={displayNameTemplate}
              isSelected={selected.includes(item.id)}
              onSelect={() => onSelect(item.id)}
              isListView
            />
          </ListItem>
        ))}
      </List>
    );
  }

  // Render horizontal carousel for larger screens
  return (
    <Box
      ref={containerRef}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        margin: 'auto',
        maxWidth: '100%',
        width: 'calc(100% - 60px)'
      }}
    >
      {pageStartIndices.length > 1 && (
        <StyledIconButton onClick={handlePrev} sx={{ left: '-48px' }}>
          <ArrowBackIosIcon />
        </StyledIconButton>
      )}

      <Stack
        direction="row"
        spacing={2}
        sx={{
          position: 'relative',
          width: '100%',
          justifyContent: 'center'
        }}
      >
        {currentPageItems.map(item => (
          <Slide key={item.id} direction="left" in mountOnEnter unmountOnExit>
            <Box
              sx={{
                flex: '1 1 auto',
                width: CARD_WIDTH,
                maxWidth: CARD_WIDTH,
                minWidth: CARD_WIDTH
              }}
            >
              <ContentThemeSelectorCard
                contentData={item}
                displayImageTemplate={displayImageTemplate}
                displayNameTemplate={displayNameTemplate}
                isSelected={selected.includes(item.id)}
                onSelect={() => onSelect(item.id)}
              />
            </Box>
          </Slide>
        ))}
      </Stack>

      {pageStartIndices.length > 1 && (
        <StyledIconButton onClick={handleNext} sx={{ right: '-48px' }}>
          <ArrowForwardIosIcon />
        </StyledIconButton>
      )}
    </Box>
  );
};

export default ContentThemeSelector;
