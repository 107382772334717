import { useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { orderBy, head, isEmpty, first } from 'lodash';
import { t } from 'i18next';
import { useQuery } from '@apollo/client';

import {
  Alert,
  Tooltip,
  Drawer,
  IconButton,
  FormControlLabel,
  Switch,
  Button,
  Fab
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/LayersClear';
import CopyIcon from '@mui/icons-material/FileCopy';
import CompareIcon from '@mui/icons-material/Compare';

import { sortKeys } from '../helpers';
import DiffViewer from './DiffViewer';
import DocumentVersionSelector from '../DocumentVersionSelector';
import { getProductByProductId } from '../queries';

const PREFIX = 'DiffDrawer';

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  container: `${PREFIX}-container`,
  headerContainer: `${PREFIX}-headerContainer`,
  headerButtonContainer: `${PREFIX}-headerButtonContainer`,
  diffSelect: `${PREFIX}-diffSelect`,
  bodyContainer: `${PREFIX}-bodyContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.drawer}`]: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    height: theme.evSizes.navigationWidth
  },

  [`& .${classes.drawerOpen}`]: {
    height: '600px'
  },

  [`& .${classes.container}`]: {
    position: 'relative',
    width: '100%'
  },

  [`& .${classes.headerContainer}`]: {
    alignItems: 'center',
    background: 'white',
    borderBottom: `5px solid ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    position: 'fixed',
    width: '100%',
    zIndex: '1'
  },

  [`& .${classes.headerButtonContainer}`]: {
    '& button': {
      marginRight: theme.spacing(1)
    }
  },

  [`& .${classes.diffSelect}`]: {
    width: '455px'
  },

  [`& .${classes.bodyContainer}`]: {
    marginTop: '80px'
  }
}));

const pageText = () => ({
  close: t('admin:blueprintBuilder.fabDiffer.close'),
  clear: t('admin:blueprintBuilder.fabDiffer.clearTip'),
  leftTitle: t('admin:blueprintBuilder.fabDiffer.leftTitle'),
  rightTitle: t('admin:blueprintBuilder.fabDiffer.rightTitle'),
  toggleDiff: t('admin:blueprintBuilder.fabDiffer.toggleDiff'),
  copyOld: t('admin:blueprintBuilder.fabDiffer.copyOldButton'),
  copyNew: t('admin:blueprintBuilder.fabDiffer.copyNewButton'),
  copyOldTip: t('admin:blueprintBuilder.fabDiffer.copyOldButtonTip'),
  copyNewTip: t('admin:blueprintBuilder.fabDiffer.copyNewButtonTip'),
  tooltip: t('admin:blueprintBuilder.fabDiffer.tooltip'),
  selectedDocumentError: t(
    'admin:blueprintBuilder.fabDiffer.selectedDocumentError'
  )
});

const getLastClosedId = allProductVersions => {
  const sortedVersions = orderBy(
    allProductVersions,
    'versionTimestamp',
    'desc'
  );

  return head(
    sortedVersions.filter(version => {
      return version.status !== 'open';
    })
  )?.id;
};

const DiffDrawer = props => {
  const { formValues = {}, allProductVersions = [], currentDocument } = props;
  const text = useMemo(() => pageText(), []);
  const [drawerOpen, onSetDrawerState] = useState(false);

  const [showFullDiff, setShowDiffOnly] = useState(false);
  const handleShowFullDiffToggle = () => {
    setShowDiffOnly(!showFullDiff);
  };

  const [selectedVersionId, setSelectedVersionId] = useState(
    currentDocument?.id
  );
  const hasSelectedId = !isEmpty(selectedVersionId);

  const handleSetSelectVersion = id => {
    setSelectedVersionId(id);
  };

  const handleOpenDrawer = () => {
    onSetDrawerState(true);
    if (formValues?.status === 'open') {
      handleSetSelectVersion(
        getLastClosedId(allProductVersions) // if open compare against the last closed version
      );
    }
  };
  const handleCloseDrawer = () => {
    onSetDrawerState(false);
    // reset to initial state
    handleSetSelectVersion(currentDocument?.id);
  };

  const {
    loading: selectedDocumentLoading,
    data,
    error: selectedDocumentError
  } = useQuery(getProductByProductId, {
    skip: !hasSelectedId,
    fetchPolicy: 'no-cache',
    variables: {
      documentId: selectedVersionId,
      productId: currentDocument?.productId,
      limit: 1
    }
  });

  const selectedDocument = data?.productDocumentVersions
    ? first(data?.productDocumentVersions)
    : {};

  // Note: Sorting these keys so that the diff works correctly redux form and the
  //       object that the we compare can have properties out of order
  const currentDocumentState = selectedDocument
    ? sortKeys(selectedDocument)
    : {};
  const formDocumentState = formValues ? sortKeys(formValues) : {};

  // we don't need to see these in the diff importDocument is the imported file
  // it gets removed before submitting the form
  delete currentDocumentState.importDocument;
  delete formDocumentState.importDocument;

  return (
    <Root>
      <Tooltip arrow placement="left" title={text.tooltip}>
        <Fab
          sx={{
            position: 'fixed',
            bottom: '160px',
            right: '20px',
            zIndex: theme => theme.zIndex.modal
          }}
          color="primary"
          aria-label="viewDiff"
          onClick={drawerOpen ? handleCloseDrawer : handleOpenDrawer}
        >
          <CompareIcon />
        </Fab>
      </Tooltip>
      {drawerOpen && (
        <Drawer
          anchor="bottom"
          className={classes.drawerOpen}
          classes={{
            paper: classes.drawerOpen
          }}
          ModalProps={{
            hideBackdrop: true,
            disableScrollLock: true,
            disableBackdropClick: true
          }}
          open
          variant="persistent"
        >
          <div className={classes.container}>
            <div className={classes.headerContainer}>
              <div>
                <DocumentVersionSelector
                  productId={currentDocument?.productId}
                  currentDocumentId={currentDocument?.id}
                  setSelected={id => handleSetSelectVersion(id)}
                  initialValue={selectedVersionId}
                />

                <Tooltip title={text.clear}>
                  <IconButton
                    onClick={() => {
                      // reset selection to current
                      handleSetSelectVersion(currentDocument?.id);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </div>

              <div className={classes.headerButtonContainer}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showFullDiff}
                      onChange={handleShowFullDiffToggle}
                    />
                  }
                  label={text.toggleDiff}
                />

                <Tooltip title={text.copyOldTip}>
                  <Button
                    endIcon={<CopyIcon />}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        JSON.stringify(currentDocumentState, null, 2)
                      );
                    }}
                  >
                    {text.copyOld}
                  </Button>
                </Tooltip>

                <Tooltip title={text.copyNewTip}>
                  <Button
                    endIcon={<CopyIcon />}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        JSON.stringify(formDocumentState, null, 2)
                      );
                    }}
                  >
                    {text.copyNew}
                  </Button>
                </Tooltip>
              </div>

              <IconButton onClick={handleCloseDrawer} size="large">
                <CloseIcon />
              </IconButton>
            </div>

            <div className={classes.bodyContainer}>
              {selectedDocumentError && (
                <Alert
                  severity="error"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {text.selectedDocumentError}
                </Alert>
              )}

              {!isEmpty(currentDocumentState) && (
                <DiffViewer
                  selectedDocumentLoading={selectedDocumentLoading}
                  oldValue={currentDocumentState}
                  newValue={formDocumentState}
                  leftTitle={text.leftTitle}
                  rightTitle={text.rightTitle}
                  showDiffOnly={!showFullDiff}
                />
              )}
            </div>
          </div>
        </Drawer>
      )}
    </Root>
  );
};

export default DiffDrawer;
