import {
  ListItem,
  ListItemIcon,
  Avatar,
  ListItemText,
  List
} from '@mui/material';
import { isGuest } from 'src/Auth/common';
import { useGlobalContext } from 'src/GlobalContextProvider';

// Setup to be dynamic instead of hard coded for future use
const getRole = () => {
  const isGuestUser = !isGuest();
  if (isGuestUser) {
    return 'Guest Account';
  }
  return 'Account';
};

const AVATAR_SIZE = 32;

const NavigationAccountInfo = () => {
  const globalContext = useGlobalContext();
  const role = getRole();

  return (
    <List>
      <ListItem sx={{ pt: 1.5, pb: 2.5, gap: 0.2, pl: 3.5 }}>
        <ListItemIcon>
          <Avatar
            src="https://i.pravatar.cc/150?img=13"
            sx={{
              height: AVATAR_SIZE,
              width: AVATAR_SIZE,
              position: 'relative',
              top: -9
            }}
          />
        </ListItemIcon>
        <ListItemText
          primary={role}
          secondary={globalContext?.me?.name}
          sx={{
            '& .MuiListItemText-primary': {
              fontWeight: 500
            },
            '& .MuiListItemText-secondary': { color: 'text.primary', mt: 0.5 }
          }}
        />
      </ListItem>
    </List>
  );
};

export default NavigationAccountInfo;
