import { Route, Redirect } from 'react-router-dom';

import Account from 'src/pages/Account';
import { Activate, Password } from 'src/pages/Activation';
import Audiences from 'src/pages/Audiences';
import Content from 'src/pages/Content';
import Dashboard from 'src/pages/Dashboard';
import FacebookPages from 'src/pages/FacebookPages';
import { FACEBOOK_LINK_MODES, FacebookLink } from 'src/pages/FacebookLink';
import Gallery from 'src/pages/Gallery';
import Leads from 'src/pages/Leads';
import { Login, Logout, Sso } from 'src/pages/Auth';
import {
  ProgramsByArchitecture,
  Programs,
  AutomatedPrograms,
  Automations
} from 'src/pages/Programs';
import { ProgramCreate, ProgramEdit } from 'src/pages/Program';
import {
  ProgramPerformance,
  AutomatedProgramDetails
} from 'src/pages/ProgramPerformance';
import MLProgramParent from 'src/pages/ProgramPerformance/MLProgramParent';
import Settings from 'src/pages/Settings';
import Playground from 'src/pages/Playground';
import Admin from 'src/pages/Admin';
import AdminImpersonate from 'src/pages/Admin/Impersonate/Impersonate';
import AdminImpersonateLanding from 'src/pages/Admin/Impersonate/ImpersonateLanding';
import AdminCloudinaryLanding from 'src/pages/Admin/CloudinaryTemplateBuilder/CloudinaryLanding';
import AdminBlueprintBuilderLanding from 'src/pages/Admin/BlueprintBuilder/BlueprintBuilderLanding';
import AdminSkinSettings from 'src/pages/Admin/SkinSettings/SkinSettings';
import AdminFacebookSupportTools from 'src/pages/Admin/FacebookSupportTools';
import PartnerPortalLink from 'src/pages/Partner/PartnerPortalLink/PartnerPortalLink';
import AdminClientKeys from 'src/pages/Admin/ClientKeys';
import AdminHandlebarMapper from 'src/pages/Admin/HandlebarMapper';
import AdminCreateOrganization from 'src/pages/Admin/CreateOrganization';
import AdminCreateUser from 'src/pages/Admin/CreateUser';
import AdminCancelOrder from 'src/pages/Admin/CancelOrder';
import AdminArchitectureSettings from 'src/pages/Admin/ArchitectureSettings';
import AdminAuthenticationSettings from 'src/pages/Admin/AuthenticationSettings';
import AdminBillingSettings from 'src/pages/Admin/BillingSettings';
import AdminCatalogContentSettingsLanding from 'src/pages/Admin/CatalogContentSettings/CatalogContentSettingsLanding';
import AdminCatalogContentSettings from 'src/pages/Admin/CatalogContentSettings';
import AdminContentTablesSettings from 'src/pages/Admin/ContentTablesSettings';
import AdminContentTablesLanding from 'src/pages/Admin/ContentTablesSettings/ContentTablesLanding';
import AdminFacebookSettings from 'src/pages/Admin/FacebookSettings';
import {
  ManageQuickstarts as AdminManageQuickstartsTools,
  EditQuickstart as AdminEditQuickstartTool
} from 'src/pages/Admin/ManageQuickstartsTools';
import AdminWebhookSettings from 'src/pages/Admin/WebhookSettings';
import AdminExperimentSettings from 'src/pages/Admin/ExperimentSettings';
import AdminManuallyLinkOrders from 'src/pages/Admin/ManuallyLinkOrders';
import AdminPromotions from 'src/pages/Admin/Promotions/Promotions';
import AdminPromotionCreate from 'src/pages/Admin/Promotions/PromotionCreate';
import AdminPromotionDetails from 'src/pages/Admin/Promotions/PromotionDetails';
import AdminGalleries from 'src/pages/Admin/Galleries/AdminGalleries';
import AdminGalleryForm from 'src/pages/Admin/Galleries/AdminGalleryForm';
import AdminAudience from 'src/pages/Admin/AudienceTools/AudienceTool';
import TestingPlayground from 'src/pages/Playground/Testing';
import InputComponentTesting from 'src/pages/Playground/Testing/InputComponentTesting';
import ResourceLibraryManager from 'src/pages/Admin/ResourceLibrary/ResourceLibraryManager';
import GoogleSearchSettings from 'src/pages/Admin/GoogleSearch/GoogleSearchSettings';
import ContentDataManager from 'src/pages/Admin/ContentDataManager/ContentDataManager';
import PartnerLanding from 'src/pages/Partner/PartnerLanding';
import PartnerOnboardingStripe from 'src/pages/Partner/Onboarding/StripeConnect';
import PartnerOnboardingTikTok from 'src/pages/Partner/Onboarding/TikTok';
import PartnerOnboardingOkta from 'src/pages/Partner/Onboarding/Okta';
import PartnerTikTokSettings from 'src/pages/Partner/TikTok/TikTokSettings';
import PartnerEmailNotificationSettings from 'src/pages/Partner/PartnerEmailNotificationSettings/SettingsLanding';
import NotificationPreferences from 'src/pages/Admin/NotificationPreferences/NotificationPreferences';
import Unsubscribe from 'src/pages/Unsubscribe';
import {
  GuestPrograms,
  GuestLeads,
  GuestAccount,
  GuestAuthLanding
} from 'src/pages/Guest';

// plg
import SignUp from 'src/pages/Plg/SignUp/SignUp';

import { paths } from './paths';
import PrivateRoute from './PrivateRoute';
import SuperAdminRoute from './SuperAdminRoute';
import PartnerRoute from './PartnerRoute';
import PublicRoute from './PublicRoute';
import GuestRoute from './GuestRoute';
import ChannelSpendAllocationSettings from '../pages/Admin/ChannelSpendAllocationSettings/ChannelSpendAllocationSettings';
import PricingSettings from '../pages/Admin/PricingSettings';
import { programActions } from '../pages/Program/Constants';

// Note: If you would like the route to be authenticated use PrivateRoute
//       otherwise just use the normal Route from react-route-dom
export const getRoutes = () => {
  return [
    // Note: the order here matters because this will be the order
    //       we render the routes
    {
      name: 'Dashboard',
      getComponent: () => (
        <PrivateRoute
          component={Dashboard}
          exact
          key="dashboard"
          path={paths.dashboard.base}
        />
      )
    },
    {
      name: 'Gallery',
      getComponent: () => (
        <PrivateRoute
          component={Gallery}
          exact
          key="gallery"
          path={paths.dashboard.gallery}
        />
      )
    },
    {
      name: 'Audiences',
      getComponent: () => (
        <PrivateRoute
          component={Audiences}
          exact
          key="audiences"
          path={paths.dashboard.audiences}
        />
      )
    },
    {
      name: 'All Programs',
      getComponent: () => (
        <PrivateRoute
          component={Programs}
          exact
          key="programs"
          path={paths.programs.base}
        />
      )
    },
    {
      name: 'Programs',
      getComponent: () => (
        <PrivateRoute
          component={ProgramsByArchitecture}
          exact
          key="programs"
          path={paths.architecture.programs}
        />
      )
    },
    {
      name: 'Create Program',
      getComponent: () => (
        <PrivateRoute
          component={ProgramCreate}
          exact
          key="programCreate"
          path={paths.architecture.programCreate}
        />
      )
    },
    {
      name: 'Edit Program',
      getComponent: () => (
        <PrivateRoute
          component={ProgramCreate}
          componentProps={{ isEditing: true }}
          exact
          key="programEdit"
          path={paths.architecture.programEdit}
        />
      )
    },
    {
      name: 'Edit Multi Location Program',
      getComponent: () => (
        <PrivateRoute
          component={ProgramEdit}
          componentProps={{ type: programActions.multiLocationEdit }}
          exact
          key="MLProgramEdit"
          path={paths.architecture.multiLocationProgramEdit}
        />
      )
    },
    {
      name: 'Program Performance',
      getComponent: () => (
        <PrivateRoute
          component={ProgramPerformance}
          exact
          key="ProgramPerformance"
          path={paths.architecture.program}
        />
      )
    },
    {
      name: 'Multi Location Program Performance',
      getComponent: () => (
        <PrivateRoute
          component={MLProgramParent}
          exact
          key="MLProgramParent"
          path={paths.architecture.multiLocationProgram}
        />
      )
    },
    {
      name: 'Automations',
      getComponent: () => (
        <PrivateRoute
          component={AutomatedProgramDetails}
          exact
          key="AutomatedProgram"
          path={paths.architecture.automatedProgram}
        />
      )
    },
    {
      name: 'Automation Create',
      getComponent: () => (
        <PrivateRoute
          component={ProgramCreate}
          componentProps={{
            type: programActions.automatedCreate,
            isEditing: false
          }}
          exact
          key="ProgramCreateAutomated"
          path={paths.architecture.automatedProgramCreate}
        />
      )
    },
    {
      name: 'Automation Edit',
      getComponent: () => (
        <PrivateRoute
          component={ProgramCreate}
          componentProps={{
            type: programActions.automatedEdit,
            isEditing: true
          }}
          exact
          key="ProgramEditAutomated"
          path={paths.architecture.automatedProgramEdit}
        />
      )
    },
    {
      name: 'Automations',
      getComponent: () => (
        <PrivateRoute
          component={Automations}
          exact
          key="AutomatedPrograms"
          path={paths.automations.base}
        />
      )
    },
    {
      name: 'Automations',
      getComponent: () => (
        <PrivateRoute
          component={AutomatedPrograms}
          exact
          key="AutomatedPrograms"
          path={paths.architecture.automatedPrograms} // Legacy
        />
      )
    },
    {
      name: 'Content',
      getComponent: () => (
        <PrivateRoute
          component={Content}
          exact
          key="content"
          path={paths.architecture.content}
        />
      )
    },
    {
      name: 'Leads',
      getComponent: () => (
        <PrivateRoute
          component={Leads}
          exact
          key="leads"
          path={paths.dashboard.leads}
        />
      )
    },
    {
      name: 'Settings',
      getComponent: () => (
        <PrivateRoute
          component={Settings}
          exact
          key="architectureSettings"
          path={paths.architecture.settings}
        />
      )
    },
    {
      name: 'Account',
      getComponent: () => (
        <PrivateRoute
          component={Account}
          key="account"
          path={paths.account.base}
        />
      )
    },
    {
      name: 'Facebook: New Page',
      getComponent: () => (
        <PrivateRoute
          component={FacebookLink}
          componentProps={{ mode: FACEBOOK_LINK_MODES.NEW }}
          exact
          key="facebookLink"
          path={paths.facebook.link}
        />
      )
    },

    {
      name: 'Facebook: View Pages',
      getComponent: () => (
        <PrivateRoute
          component={FacebookPages}
          exact
          key="facebookPages"
          path={paths.facebook.pages}
        />
      )
    },
    // Auth
    {
      name: 'Login',
      getComponent: () => (
        <Route component={Login} exact key="login" path={paths.auth.login} />
      )
    },
    {
      name: 'SSO',
      getComponent: () => (
        <Route component={Sso} exact key="Sso" path={paths.auth.sso} />
      )
    },
    {
      name: 'Logout',
      getComponent: () => (
        <Route component={Logout} exact key="logout" path={paths.auth.logout} />
      )
    },
    {
      name: 'AuthCallback',
      getComponent: () => (
        <Route
          component={Login}
          exact
          key="authCallback"
          path={paths.auth.callBack}
        />
      )
    },
    {
      name: 'Activate Check',
      getComponent: () => (
        <Route
          component={Activate}
          exact
          key="activateCheck"
          path={paths.auth.activate.check}
        />
      )
    },
    {
      name: 'Activate Confirm',
      getComponent: () => (
        <Route
          component={Password}
          exact
          key="activateConfirm"
          path={paths.auth.activate.confirm}
        />
      )
    },
    {
      name: 'Activate',
      getComponent: () => (
        <Redirect
          exact
          key="activate"
          path={paths.auth.activate.base}
          to={paths.auth.activate.check}
        />
      )
    },
    // Admin
    {
      name: 'Admin',
      getComponent: () => (
        <SuperAdminRoute
          component={Admin}
          exact
          key="admin"
          path={paths.admin.base}
        />
      )
    },
    {
      name: 'Admin Impersonate User',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminImpersonate}
          exact
          key="adminImpersonate"
          path={paths.admin.impersonate}
        />
      )
    },
    {
      name: 'Admin Impersonate User Landing',
      getComponent: () => (
        <Route
          component={AdminImpersonateLanding}
          exact
          key="adminImpersonateUserLanding"
          path={paths.admin.setUser}
        />
      )
    },
    {
      name: 'Admin Blueprint Builder',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminBlueprintBuilderLanding}
          exact
          key="AdminBlueprintBuilderOverview"
          path={paths.admin.blueprintBuilderOverview}
        />
      )
    },
    {
      name: 'Admin Blueprint Builder',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminBlueprintBuilderLanding}
          exact
          key="AdminBlueprintBuilder"
          path={paths.admin.blueprintBuilder}
        />
      )
    },
    {
      name: 'Admin Create Organization',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminCreateOrganization}
          exact
          key="AdminCreateOrganization"
          path={paths.admin.createOrganization}
        />
      )
    },
    // Admin Settings
    {
      name: 'Admin Skin Settings',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminSkinSettings}
          exact
          key="AdminSkinSettings"
          path={paths.admin.settings.skin}
        />
      )
    },
    {
      name: 'Admin Architecture Settings',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminArchitectureSettings}
          exact
          key="AdminArchitectureSettings"
          path={paths.admin.settings.architecture}
        />
      )
    },
    {
      name: 'Admin Authentication Settings',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminAuthenticationSettings}
          exact
          key="AdminAuthenticationSettings"
          path={paths.admin.settings.authentication}
        />
      )
    },
    {
      name: 'Admin Billing Settings',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminBillingSettings}
          exact
          key="AdminBillingSettings"
          path={paths.admin.settings.billing}
        />
      )
    },
    {
      name: 'Admin Pricing Plan Settings',
      getComponent: () => (
        <SuperAdminRoute
          component={PricingSettings}
          exact
          key="PricingPlanSettings"
          path={paths.admin.settings.pricing}
        />
      )
    },
    {
      name: 'Admin Catalog & Content Settings Landing',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminCatalogContentSettingsLanding}
          exact
          key="AdminCatalogContentSettingsLanding"
          path={paths.admin.settings.catalogContentLanding}
        />
      )
    },
    {
      name: 'Admin Catalog & Content Settings',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminCatalogContentSettings}
          exact
          key="AdminCatalogContentSettings"
          path={paths.admin.settings.catalogContent}
        />
      )
    },
    {
      name: 'Admin Content Tables Landing',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminContentTablesLanding}
          exact
          key="AdminContentTablesLanding"
          path={paths.admin.settings.contentTablesLanding}
        />
      )
    },
    {
      name: 'Admin Content Tables Settings',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminContentTablesSettings}
          exact
          key="AdminContentTablesSettings"
          path={paths.admin.settings.contentTables}
        />
      )
    },
    {
      name: 'Admin Facebook Settings',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminFacebookSettings}
          exact
          key="AdminFacebookSettings"
          path={paths.admin.settings.facebook}
        />
      )
    },
    {
      name: 'Admin Webhook Settings',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminWebhookSettings}
          exact
          key="AdminWebhookSettings"
          path={paths.admin.settings.webhook}
        />
      )
    },
    {
      name: 'Admin Promotions',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminPromotions}
          exact
          key="promotions"
          path={paths.admin.settings.promotions}
        />
      )
    },
    {
      name: 'Admin Create Promotion',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminPromotionCreate}
          exact
          key="promotionCreate"
          path={paths.admin.settings.promotionCreate}
        />
      )
    },
    {
      name: 'Admin Promotion Details',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminPromotionDetails}
          exact
          key="promotionDetails"
          path={paths.admin.settings.promotionDetails}
        />
      )
    },
    {
      name: 'Admin Galleries',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminGalleries}
          exact
          key="AdminGalleries"
          path={paths.admin.settings.galleries}
        />
      )
    },
    {
      name: 'Admin Create Gallery',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminGalleryForm}
          exact
          key="AdminGalleryCreate"
          path={paths.admin.settings.galleryCreate}
        />
      )
    },
    {
      name: 'Admin Gallery Details',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminGalleryForm}
          exact
          key="AdminGalleryDetails"
          path={paths.admin.settings.galleryDetails}
        />
      )
    },
    {
      name: 'Admin Resource Library Manager',
      getComponent: () => (
        <SuperAdminRoute
          component={ResourceLibraryManager}
          exact
          key="ResourceLibraryManager"
          path={paths.admin.settings.resourceLibrary}
        />
      )
    },
    {
      name: 'Admin Google Search Settings',
      getComponent: () => (
        <SuperAdminRoute
          component={GoogleSearchSettings}
          exact
          key="GoogleSearchSettings"
          path={paths.admin.settings.googleSearch}
        />
      )
    },
    {
      name: 'Admin Content Data Manager',
      getComponent: () => (
        <SuperAdminRoute
          component={ContentDataManager}
          exact
          key="ContentDataManager"
          path={paths.admin.settings.contentDataManager}
        />
      )
    },
    {
      name: 'Channel Spend Allocation Settings',
      getComponent: () => (
        <SuperAdminRoute
          component={ChannelSpendAllocationSettings}
          exact
          key="ChannelSpendAllocationSettings"
          path={paths.admin.settings.channelSpendAllocation}
        />
      )
    },
    {
      name: 'Admin Create User',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminCreateUser}
          exact
          key="AdminCreateUser"
          path={paths.admin.createUser}
        />
      )
    },
    {
      name: 'Admin Cancel Order',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminCancelOrder}
          exact
          key="AdminCancelOrder"
          path={paths.admin.tools.cancelOrder}
        />
      )
    },
    {
      name: 'Admin Experiment Settings',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminExperimentSettings}
          exact
          key="AdminExperimentSettings"
          path={paths.admin.settings.experiments}
        />
      )
    },
    {
      name: 'Admin Manage Quick Starts Tool',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminManageQuickstartsTools}
          exact
          key="AdminManageQuickstartsSettings"
          path={paths.admin.settings.quickStarts}
        />
      )
    },
    {
      name: 'Admin Edit Quick Start Tool',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminEditQuickstartTool}
          exact
          key="AdminEditQuickstartTool"
          path={paths.admin.settings.quickStartEdit}
        />
      )
    },
    {
      name: 'Admin Notification Preferences',
      getComponent: () => (
        <SuperAdminRoute
          component={NotificationPreferences}
          exact
          key="notificationPreferences"
          path={paths.admin.settings.notificationPreferences}
        />
      )
    },

    {
      name: 'Admin Manually Link Orders',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminManuallyLinkOrders}
          exact
          key="AdminManuallyLinkOrders"
          path={paths.admin.tools.manuallyLinkOrders}
        />
      )
    },

    // Admin Tools
    {
      name: 'Admin Facebook Support Tools',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminFacebookSupportTools}
          exact
          key="AdminFacebookSupport"
          path={paths.admin.tools.facebook}
        />
      )
    },
    {
      name: 'Admin Partner Link Tool',
      getComponent: () => (
        <SuperAdminRoute
          component={PartnerPortalLink}
          exact
          key="PartnerPortalLink"
          path={paths.admin.tools.partner}
        />
      )
    },
    {
      name: 'Developer Client Keys Tool',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminClientKeys}
          exact
          key="AdminClientKeys"
          path={paths.admin.developer.clientKeys}
        />
      )
    },
    {
      name: 'Developer Handlebar Mapper',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminHandlebarMapper}
          exact
          key="HandlebarMapper"
          path={paths.admin.developer.handlebarMapper}
        />
      )
    },
    {
      name: 'Admin Audience Tool',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminAudience}
          exact
          key="AudienceTool"
          path={paths.admin.tools.audience}
        />
      )
    },
    // Admin Utilities
    {
      name: 'Admin Image Template Builder',
      getComponent: () => (
        <SuperAdminRoute
          component={AdminCloudinaryLanding}
          exact
          key="adminImageTemplateBuilder"
          path={paths.admin.utilities.imageTemplateBuilder}
        />
      )
    },
    // Public
    {
      name: 'SignUp',
      getComponent: () => (
        <PublicRoute
          component={SignUp}
          exact
          key="plgSignUp"
          path={paths.plg.signUp}
        />
      )
    },
    // Partner
    {
      name: 'Partner Landing',
      getComponent: () => (
        <PartnerRoute
          component={PartnerLanding}
          exact
          key="partnerLanding"
          path={paths.partner.base}
        />
      )
    },
    {
      name: 'Partner Onboarding Stripe',
      getComponent: () => (
        <PartnerRoute
          component={PartnerOnboardingStripe}
          key="partnerOnboardingStripe"
          path={paths.partner.onboarding.stripe}
        />
      )
    },
    {
      name: 'Partner Onboarding TikTok',
      getComponent: () => (
        <PartnerRoute
          component={PartnerOnboardingTikTok}
          exact
          key="partnerOnboardingTikTok"
          path={paths.partner.onboarding.tiktok}
        />
      )
    },
    {
      name: 'Partner Onboarding Okta',
      getComponent: () => (
        <PartnerRoute
          component={PartnerOnboardingOkta}
          exact
          key="partnerOnboardingOkta"
          path={paths.partner.onboarding.okta}
        />
      )
    },
    {
      name: 'Partner Onboarding Okta',
      getComponent: () => (
        <PartnerRoute
          component={PartnerTikTokSettings}
          exact
          key="partnerTikTokSettings"
          path={paths.partner.tiktok}
        />
      )
    },
    {
      name: 'Partner Onboarding SES',
      getComponent: () => (
        <PartnerRoute
          component={PartnerEmailNotificationSettings}
          exact
          key="partnerEmailNotificationSetup"
          path={paths.partner.emailNotificationSetup}
        />
      )
    },
    // Guest routes for co-marketing
    {
      name: 'Guest Auth Landing',
      getComponent: () => (
        <Route
          component={GuestAuthLanding}
          exact
          key="guestAuthLanding"
          path={paths.guest.auth.base}
        />
      )
    },
    {
      name: 'Guest Programs',
      getComponent: () => (
        <GuestRoute
          component={GuestPrograms}
          exact
          key="guestPrograms"
          path={paths.guest.programs.base}
        />
      )
    },
    {
      name: 'Guest Leads',
      getComponent: () => (
        <GuestRoute
          component={GuestLeads}
          exact
          key="guestLeads"
          path={paths.guest.leads.base}
        />
      )
    },
    {
      name: 'Guest Account',
      getComponent: () => (
        <GuestRoute
          component={GuestAccount}
          exact
          key="guestAccount"
          path={paths.guest.account.base}
        />
      )
    },
    // Site Component Playground
    {
      name: 'Playground',
      getComponent: () => (
        <Route
          component={Playground}
          exact
          key="componentPlayground"
          path={paths.playground.base}
        />
      )
    },
    {
      name: 'Testing',
      getComponent: () => (
        <Route
          component={TestingPlayground}
          exact
          key="testingPlayground"
          path={paths.playground.testing.base}
        />
      )
    },
    {
      name: 'Component Testing',
      getComponent: () => (
        <Route
          component={InputComponentTesting}
          exact
          key="inputComponentTesting"
          path={paths.playground.testing.inputs}
        />
      )
    },
    // Public Unsubscribe from email notifications route
    {
      name: 'Unsubscribe',
      getComponent: () => (
        <Route
          component={Unsubscribe}
          exact
          key="unsubscribe"
          path={paths.unsubscribe}
        />
      )
    },
    {
      name: 'Slash',
      // Redirect to dashboard but preserve any query parameters
      getComponent: () => (
        <Route
          exact
          key="slash"
          path={paths.home}
          render={({ history, location }) => {
            history.replace(`${paths.dashboard.base}${location.search || ''}`);
            return null;
          }}
        />
      )
    }
  ];
};
