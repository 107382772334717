import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { size, flow, isEmpty, map } from 'lodash';
import ErrorIcon from '@mui/icons-material/Error';
import Badge from '@mui/material/Badge';
import {
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Divider,
  TextField,
  Button,
  Grid
} from '@mui/material';
import Modal from 'src/components/Modal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  CopyToClipboardContainer,
  CopyToClipboardActionButton
} from 'src/components/CopyToClipboard/CopyToClipboard';
import { clearErrors } from './actions';

const PREFIX = 'ErrorConsole';

const classes = {
  content: `${PREFIX}-content`,
  copyButtonContainer: `${PREFIX}-copyButtonContainer`,
  footerButton: `${PREFIX}-footerButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.content}`]: {
    margin: theme.spacing(2)
  },

  [`& .${classes.copyButtonContainer}`]: {
    display: 'flex',
    justifyContent: 'end'
  },

  [`& .${classes.footerButton}`]: {
    marginTop: theme.spacing(2)
  }
}));

const ErrorConsole = props => {
  const [openModal, setOpenModal] = useState(false);
  const { errorConsole, clearErrors: clearErrorsAction } = props;

  const getCopyText = error => {
    let text = '';
    text += 'Query: \n';
    text += error.query;
    text += '\n';

    text += 'Variables: \n';
    text += JSON.stringify(error.variables);
    text += '\n\n';

    text += 'Error Messages: \n';
    text += JSON.stringify(error.errorMessages);
    text += '\n';
    return text;
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  if (isEmpty(errorConsole)) {
    return null;
  }

  return (
    <Root>
      <IconButton size="large" onClick={handleOpenModal}>
        <Badge badgeContent={size(errorConsole) || null} color="error">
          <ErrorIcon />
        </Badge>
      </IconButton>
      <Modal
        maxWidth="md"
        fullWidth
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        headerText="Graphql Errors"
      >
        <div className={classes.content}>
          {isEmpty(errorConsole) ? (
            <Grid item className={classes.quickStartItem}>
              There are no errors
            </Grid>
          ) : (
            <>
              {map(errorConsole, (value, key) => (
                <Accordion key={key}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">{key}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CopyToClipboardContainer
                      className={classes.copyButtonContainer}
                    >
                      <CopyToClipboardActionButton text={getCopyText(value)} />
                    </CopyToClipboardContainer>
                    <TextField
                      id="outlined-multiline-static"
                      label="Query"
                      fullWidth
                      multiline
                      variant="outlined"
                      value={value.query}
                      margin="normal"
                    />

                    <TextField
                      id="variables"
                      label="Variables"
                      fullWidth
                      multiline
                      variant="outlined"
                      value={JSON.stringify(value.variables)}
                      margin="normal"
                    />
                    <TextField
                      id="errorMessages"
                      label="Error Messages"
                      fullWidth
                      multiline
                      variant="outlined"
                      value={JSON.stringify(value.errorMessages)}
                      margin="normal"
                    />
                  </AccordionDetails>
                  <Divider />
                </Accordion>
              ))}
              <Button
                className={classes.footerButton}
                color="secondary"
                variant="outlined"
                onClick={clearErrorsAction}
              >
                Clear
              </Button>
            </>
          )}
        </div>
      </Modal>
    </Root>
  );
};

const mapStateToProps = state => ({
  errorConsole: state.errorConsole.globalGraphqlErrors
});

const mapDispatchToProps = {
  clearErrors
};

export default flow(connect(mapStateToProps, mapDispatchToProps))(ErrorConsole);
