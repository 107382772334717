import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { flow, some, isNull } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';

import { Button, CircularProgress } from '@mui/material';
import { dayjs } from 'src/common/dates';
import { useSnackbar } from 'notistack';
import { DynamicForm } from 'src/components/ReduxForm';
import ErrorMessage from 'src/components/Containers/ErrorMessage';
import Modal from 'src/components/Modal';

import {
  CREATE_FORM_NAME,
  SEARCH_FORM,
  createOrganizationPrePaymentInputs
} from './constants';
import { createPrePayAccount } from './mutations';

const PREFIX = 'PrePaymentConfiguration';

const classes = {
  cardHeading: `${PREFIX}-cardHeading`,
  cardSubheading: `${PREFIX}-cardSubheading`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  errorContainer: `${PREFIX}-errorContainer`,
  alertContainer: `${PREFIX}-alertContainer`
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.cardHeading}`]: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },

  [`& .${classes.cardSubheading}`]: {
    fontWeight: 'normal',
    fontSize: '14px',
    marginBottom: theme.spacing(4)
  },

  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),

    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  },

  [`& .${classes.errorContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },

  [`& .${classes.alertContainer}`]: {
    marginTop: theme.spacing(2)
  }
}));

const pageText = () => ({
  cardHeading: t('admin:prePaymentConfiguration.cardHeading'),
  subHeading: t('admin:prePaymentConfiguration.subHeading'),

  submitButton: t('admin:prePaymentConfiguration.submitButton'),
  clearButton: t('admin:prePaymentConfiguration.clearButton'),
  successSnackMessage: t('admin:prePaymentConfiguration.successSnackMessage'),
  errorSnackMessage: t('admin:prePaymentConfiguration.errorSnackMessage'),
  alertMessage: t('admin:prePaymentConfiguration.alertMessage'),
  overlappingDatesError: t(
    'admin:prePaymentConfiguration.overlappingDatesError'
  )
});

const PrePaymentConfiguration = props => {
  const {
    reset,
    submitFailed,
    submitting,
    handleSubmit,
    createPrePayAccount,
    dirty,
    invalid,
    open,
    close,
    selectedOrgValue,
    prepaymentAccounts
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const text = useMemo(() => pageText(), []);

  const onSubmit = async data => {
    const activePrepaymentAccounts = prepaymentAccounts.filter(account =>
      isNull(account.deletedAt)
    );
    const isOverlapping = some(activePrepaymentAccounts, account => {
      const isDateOverlapping = date => {
        return dayjs(date).isBetween(
          account.effectiveFrom,
          account.effectiveUntil
        );
      };
      return (
        isDateOverlapping(data.effectiveFrom) ||
        isDateOverlapping(data.effectiveUntil)
      );
    });

    if (isOverlapping) {
      return enqueueSnackbar(text.overlappingDatesError, {
        variant: 'error'
      });
    }
    try {
      await createPrePayAccount({
        variables: {
          input: {
            ...data,
            balanceAmount: data?.initialAmount,
            organizationId: selectedOrgValue
          }
        }
      });
      enqueueSnackbar(text.successSnackMessage, {
        variant: 'success'
      });
      reset();
      close();
    } catch (error) {
      enqueueSnackbar(text.errorSnackMessage, {
        variant: 'error'
      });
    }
  };

  return (
    <StyledModal
      fullWidth
      open={open}
      headerText={text.cardHeading}
      maxWidth="sm"
      onClose={close}
    >
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {submitFailed && (
          <div className={classes.errorContainer}>
            <ErrorMessage>{text.errorMessage}</ErrorMessage>
          </div>
        )}

        <DynamicForm inputs={createOrganizationPrePaymentInputs} />

        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={!dirty || invalid}
            endIcon={submitting && <CircularProgress size={15} />}
          >
            {text.submitButton}
          </Button>

          <Button onClick={reset}>{text.clearButton}</Button>
        </div>
      </form>
    </StyledModal>
  );
};

const mapStateToProps = state => {
  const formValues = getFormValues(SEARCH_FORM)(state);

  return {
    selectedOrgValue: formValues?.organizationId?.value
  };
};

export default flow(
  graphql(createPrePayAccount, {
    name: 'createPrePayAccount',
    options: {
      refetchQueries: ['getPrePayAccountsByOrganizationId'],
      awaitRefetchQueries: true
    }
  }),
  reduxForm({ form: CREATE_FORM_NAME, destroyOnUnmount: true }),
  connect(mapStateToProps)
)(PrePaymentConfiguration);
