import classNames from 'classnames';

import { styled } from '@mui/material/styles';

import { Divider, AccordionSummary, Typography } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';

const PREFIX = 'SkinSettingHeading';

const classes = {
  title: `${PREFIX}-title`,
  error: `${PREFIX}-error`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.title}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },

  [`& .${classes.error}`]: {
    color: theme.palette.error.main
  }
}));

const SkinSettingHeading = ({ title, error }) => {
  return (
    <Root>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="palette-skin-summary"
        id="palette-skin-summary"
      >
        <Typography
          variant="subtitle1"
          className={classNames(classes.title, {
            [classes.error]: error
          })}
        >
          {title}
          {error ? <ErrorIcon /> : null}
        </Typography>
      </AccordionSummary>
      <Divider />
    </Root>
  );
};

export default SkinSettingHeading;
