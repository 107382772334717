import { Card, Box, CardContent } from '@mui/material';

import { STYLES } from '../GoogleAdPreviewConstants';

const CardMedia = ({ headline, description, image, businessName }) => {
  return (
    <Card
      sx={{
        margin: '8px',
        minWidth: '300px',
        color: '#3c4043',
        fontSize: '0.685rem',
        paddingLeft: '6px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineClamp: '1'
      }}
    >
      {image && <Box sx={{ maxWidth: '300px' }}>{image}</Box>}
      <CardContent>
        <Box
          sx={{
            display: 'box',
            lineHeight: '110%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            boxOrient: 'vertical',
            lineClamp: '3'
          }}
        >
          <Box
            component="span"
            sx={{
              fontSize: '0.8rem',
              fontFamily: STYLES.fontFamily.googleSans,
              color: '#202124',
              letterSpacing: '0.008rem'
            }}
          >
            {headline} | {description}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: '12px',
            marginTop: '12px',
            marginBottom: '12px'
          }}
        >
          <Box sx={{ fontWeight: 'bold', fontSize: '0.685rem' }}>Ad • </Box>
          <Box
            sx={{
              color: '#3c4043',
              fontSize: '0.685rem',
              paddingLeft: '6px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineClamp: '1'
            }}
          >
            {businessName}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardMedia;
