import { useState, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { flow, remove, isEmpty } from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { touch } from 'redux-form';
import { t } from 'i18next';

import { Button, Divider, Typography } from '@mui/material';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

import { newBusinessObjectsFromArchitecture } from 'src/common/businessObjects';
import Modal from 'src/components/Modal';
import ContentTable from 'src/components/ContentTable/ContentTable';

import { getCatalogByArchitectureId } from './queries';
import BusinessObjectList from './BusinessObjectList';

const PREFIX = 'FormBusinessObjectSelector';

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  button: `${PREFIX}-button`,
  buttonIcon: `${PREFIX}-buttonIcon`,
  error: `${PREFIX}-error`,
  errorButton: `${PREFIX}-errorButton`,
  bottomError: `${PREFIX}-bottomError`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    position: 'relative',
    width: '100%'
  },

  [`& .${classes.title}`]: {
    alignItems: 'center',
    display: 'flex'
  },

  [`& .${classes.button}`]: {
    width: '100%'
  },

  [`& .${classes.buttonIcon}`]: {
    marginRight: theme.spacing(1)
  },

  [`& .${classes.error}`]: {
    color: theme.palette.error.main
  },

  [`& .${classes.errorButton}`]: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  },

  [`& .${classes.bottomError}`]: {
    position: 'relative',
    top: theme.spacing(2)
  }
}));

const pageText = ({ contentName }) => ({
  headerText: t('businessObjectSelector:modal.headerText', {
    contentName: contentName || 'Content'
  }),
  cancelButton: t('businessObjectSelector:modal.cancelButton'),
  doneButton: t('businessObjectSelector:modal.doneButton'),
  emptyBusinessObjectText: t(
    'businessObjectSelector:list.emptyBusinessObjectText',
    {
      contentName: contentName || 'content'
    }
  )
});

const FormBusinessObjectSelector = props => {
  const {
    architecture,
    architectureId,
    contentGroupKey,
    disabled,
    label,
    meta: { error, touched, form },
    minMaxSelection,
    onChange,
    selectedIds,
    touch,
    name,
    contentName,
    selectedBusinessObjects = {}
  } = props;
  const [rowSelectionModel, setRowSelectionModel] = useState(selectedIds || []);

  const text = useMemo(
    () => pageText({ contentName }),
    [architectureId, contentName]
  );

  const [modalOpen, setModalState] = useState(false);
  const toggleModal = useCallback(
    modalState => {
      // only need to update the selected rows if the modal is opening
      if (modalState === true) {
        setRowSelectionModel(selectedIds);
      }
      setModalState(modalState);
    },
    [modalOpen, selectedIds]
  );

  const handleAddBusinessObject = useCallback(() => {
    const getSelectedIds = rowSelectionModel;

    // don't close the modal if validation length is wrong
    if (
      getSelectedIds.length < minMaxSelection.min ||
      getSelectedIds.length > minMaxSelection.max
    ) {
      // touch this field to trigger the validation
      touch(form, name);
      return;
    }

    // update redux state
    onChange(getSelectedIds);

    // close modal
    toggleModal(false);
  }, [rowSelectionModel]);

  const handleRemoveBusinessObject = removeId => {
    const newSelectedIds = remove(selectedIds, id => {
      return id !== removeId;
    });

    onChange(newSelectedIds);
  };

  return (
    <Root className={classes.container}>
      <Modal
        fullWidth
        headerText={text.headerText}
        maxWidth="lg"
        onClose={() => toggleModal(false)}
        open={modalOpen}
        data-cy="businessObjects-modal"
        FooterComponent={() => (
          <>
            <div>
              {error && touched && (
                <Typography
                  className={classNames(classes.title, {
                    [classes.error]: error && touched
                  })}
                  variant="body2"
                >
                  {error}
                </Typography>
              )}
            </div>
            <Button onClick={() => toggleModal(false)}>
              {text.cancelButton}
            </Button>
            <Button color="primary" onClick={handleAddBusinessObject}>
              {text.doneButton}
            </Button>
          </>
        )}
      >
        <ContentTable
          minMaxSelection={minMaxSelection}
          architectureId={architectureId}
          displayCollapseKey={contentGroupKey}
          rowSelectionModel={rowSelectionModel}
          setRowSelectionModel={setRowSelectionModel}
          pageSize={25}
          showBorder={false}
        />
      </Modal>
      <Typography
        className={classNames(classes.title, {
          [classes.error]: error && touched
        })}
        variant="body2"
      >
        {label}
      </Typography>
      <Divider />
      <BusinessObjectList
        architecture={architecture}
        error={error && touched}
        emptyBusinessObjectText={text.emptyBusinessObjectText}
        onDelete={handleRemoveBusinessObject}
        selectedBusinessObjects={selectedBusinessObjects}
        selectedIds={selectedIds}
        contentName={contentName}
      />
      <Button
        className={classNames(classes.button, {
          [classes.errorButton]: error && touched
        })}
        onClick={() => toggleModal(true)}
        color="primary"
        variant="outlined"
        data-cy="businessObjects-btn"
        disabled={disabled}
      >
        <LibraryAddIcon className={classes.buttonIcon} />
        Add
      </Button>
      {error && touched && (
        <Typography
          className={classNames(classes.bottomError, classes.title, {
            [classes.error]: error && touched
          })}
          variant="body2"
        >
          {error}
        </Typography>
      )}
    </Root>
  );
};

export default flow(
  connect(null, {
    touch
  }),
  graphql(getCatalogByArchitectureId, {
    name: 'selectedBusinessObjects',
    skip: ({ architectureId, selectedIds }) =>
      !architectureId || isEmpty(selectedIds),
    options: ({ architectureId, selectedIds }) => {
      return {
        fetchPolicy: 'no-cache',
        variables: {
          architectureId,
          externalIds: selectedIds
        }
      };
    },
    props: ({ selectedBusinessObjects: { architecture, error, loading } }) => {
      const businessObjects = newBusinessObjectsFromArchitecture(architecture);

      return {
        selectedBusinessObjects: {
          selectedBusinessObjects: businessObjects,
          loading,
          error
        }
      };
    }
  })
)(FormBusinessObjectSelector);
