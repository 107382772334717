import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import v from 'validator';
import { isString } from 'lodash';

import {
  hasInvalidCloudinarySourceUrl,
  isUnsignedCloudinaryUrl
} from 'src/common/cloudinaryUtils';
import { isTemplate } from 'src/common/templateTranslator';

import CloudinaryImage from 'src/components/CloudinaryImage';
import CloudinaryVideo from 'src/components/CloudinaryVideo';
import Video from 'src/components/Video';
import { useGetVerticalPlaceholderImages } from 'src/common/verticals';

import S3Image from '../S3Image';

import PreviewCardButton from './PreviewCardButton';
import {
  NOT_SQUARE_IMAGE_RATIO,
  SQUARE_IMAGE_RATIO
} from './FacebookAdPreviewConstants';

export const getFacebookAdPreviewCardStyles = () => {
  return {
    previewCardContainer: {
      border: '1px solid lightgray',
      flexShrink: 0,

      marginRight: '10px',

      '&:last-child': {
        marginRight: 0
      }
    },

    previewImageWrap: {
      overflow: 'hidden',
      display: 'flex',
      '& > img': {
        width: '100%'
      }
    },

    previewImageWrapMulti: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden'
    },

    previewVideoWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      overflow: 'hidden'
    },

    previewImg: {
      objectFit: 'cover',
      flex: 'none'
    },

    previewCardFooter: {
      display: 'grid',
      padding: '10px'
    },

    previewCardTextContainer: {
      gridArea: '2 / 1'
    },

    previewCardLeadAdText: {
      color: '#999999',
      gridArea: '1 / 1',
      marginBottom: '5px',
      userSelect: 'text',
      textTransform: 'uppercase'
    },

    previewCardLeadAdTextMulti: {
      color: '#999999',
      gridArea: '3 / 1',
      marginTop: '5px',
      userSelect: 'text'
    },

    previewCardNameText: {
      fontWeight: 'bold',
      userSelect: 'text',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },

    previewCardDescriptionText: {
      userSelect: 'text',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      lineClamp: 2
    }
  };
};

const FacebookAdPreviewCard = ({
  requireBusinessObjects,
  card,
  callToAction = null,
  cardWidth,
  cardImageWidth,
  cardImageHeight,
  isSoloCard,
  isVideo,
  hasBusinessObjects,
  isLeadAd,
  isCarousel
}) => {
  const sxStyles = getFacebookAdPreviewCardStyles();
  // different facebook add types have different facet names :(
  const name = card?.name ?? card?.title;
  const picture = card?.picture;
  const video = isVideo && card?.url;
  const thumbnailUrl = card?.thumbnailUrl;
  const description = card?.description ?? card?.linkDescription;
  const cardCTA = card?.callToAction;

  const key = 'acd';

  const imageWrapWidth = isSoloCard ? '100%' : `${Number(cardWidth) - 1}px`;

  // IMAGES
  const [isSquareImage, setIsSquareImage] = useState(false);

  const getImageSizes = imageSizes => {
    const currentRatioDecimal =
      imageSizes.naturalWidth / imageSizes.naturalHeight;

    setIsSquareImage(currentRatioDecimal === 1);
  };

  const onLoad = img => {
    getImageSizes({
      naturalWidth: img.naturalWidth,
      naturalHeight: img.naturalHeight
    });
  };

  const placeholderImages = useGetVerticalPlaceholderImages();

  let imgContent = isVideo ? (
    <S3Image imageName={placeholderImages?.video} width={cardImageWidth} />
  ) : (
    <S3Image
      imageName={placeholderImages?.image}
      width={cardImageWidth}
      style={{ objectFit: 'cover' }}
    />
  );

  useEffect(() => {
    if (isCarousel) {
      setIsSquareImage(true);
    }
  }, [isCarousel]);

  if (
    (!requireBusinessObjects || hasBusinessObjects) &&
    picture &&
    isString(picture) &&
    !hasInvalidCloudinarySourceUrl(picture) &&
    !isVideo
  ) {
    if (isUnsignedCloudinaryUrl(picture)) {
      imgContent = (
        <CloudinaryImage
          unsignedUrl={picture}
          width="100%"
          onLoad={({ target: img }) => {
            onLoad(img);
          }}
          style={{
            objectFit: 'cover',
            aspectRatio: isSquareImage
              ? SQUARE_IMAGE_RATIO
              : NOT_SQUARE_IMAGE_RATIO
          }}
        />
      );
      // eslint-disable-next-line no-param-reassign
      cardImageHeight = 'auto';
    } else if (!isTemplate(picture) && v.isURL(picture)) {
      // We fall back to the default input value when user inputs are empty so
      // the ad preview renders something. (ex: Home for sale in {{city}})
      // For image default values we want to render the placeholder image
      // instead of a template string.
      imgContent = (
        <Box
          component="img"
          alt="preview"
          sx={sxStyles.previewImg}
          src={picture}
          onLoad={({ target: img }) => {
            onLoad(img);
          }}
          style={{
            // If square image, we will use the dimensions of the wrapping div to size the image
            width: isSoloCard || isSquareImage ? '100%' : `${cardImageWidth}px`,
            objectFit: 'cover',
            aspectRatio: isSquareImage
              ? SQUARE_IMAGE_RATIO
              : NOT_SQUARE_IMAGE_RATIO
          }}
        />
      );
    }
  }

  const imagePreviewAsset = isSoloCard ? (
    <Box
      sx={{
        ...sxStyles.previewImageWrap,
        width: '100%',
        aspectRatio: isSquareImage ? SQUARE_IMAGE_RATIO : NOT_SQUARE_IMAGE_RATIO
      }}
    >
      {imgContent}
    </Box>
  ) : (
    <Box
      sx={{
        ...sxStyles.previewImageWrapMulti,
        width: imageWrapWidth,
        // If square image, height and width of the wrapper are the same dimension
        height: isSquareImage ? imageWrapWidth : cardImageHeight
      }}
    >
      {imgContent}
    </Box>
  );

  // VIDEOS
  // default
  let videoContent = video ? (
    <Video src={video} style={{ width: '100%', maxHeight: '200px' }} controls />
  ) : null;

  if (isVideo && isUnsignedCloudinaryUrl(thumbnailUrl)) {
    videoContent = (
      <CloudinaryVideo
        videoUrl={video}
        unsignedUrl={thumbnailUrl}
        style={{ width: '100%' }}
      />
    );
  }

  return (
    <Box
      sx={sxStyles.previewCardContainer}
      style={{ width: isSoloCard ? '100%' : `${cardWidth}px` }}
      key={key}
      data-cy="facebook-ad-preview-card"
    >
      {isVideo && video ? (
        <Box sx={sxStyles.previewVideoWrap}>{videoContent}</Box>
      ) : (
        imagePreviewAsset
      )}
      <Box sx={sxStyles.previewCardFooter}>
        {isLeadAd && isSoloCard && (
          <Box sx={sxStyles.previewCardLeadAdText}>Form on Facebook</Box>
        )}

        <Box sx={sxStyles.previewCardTextContainer}>
          <Box
            sx={sxStyles.previewCardNameText}
            style={{ maxWidth: isSoloCard ? '280px' : '200px' }}
          >
            {name}
          </Box>
          <Box
            sx={sxStyles.previewCardDescriptionText}
            style={{ maxWidth: isSoloCard ? '280px' : '200px' }}
          >
            {description}
          </Box>
        </Box>
        <PreviewCardButton callToAction={callToAction || cardCTA} />

        {isLeadAd && !isSoloCard && (
          <Box sx={sxStyles.previewCardLeadAdTextMulti}>Form on Facebook</Box>
        )}
      </Box>
    </Box>
  );
};

export default FacebookAdPreviewCard;
