import { has } from 'lodash';

import { Button, Box } from '@mui/material';

import { GALLERY_TYPE, MEDIA_SOURCES } from '../constants';
import MediaAsset from './MediaAsset';

const TileWrapper = props => {
  const {
    allowSelect,
    onClick,
    isLightbox,
    galleryType,
    asset,
    type,
    showThumbnail
  } = props;
  const isGiphy = MEDIA_SOURCES.Giphy === asset?.source;
  const isMediaGallery = galleryType === GALLERY_TYPE.media;
  const shouldFetchAsset =
    isMediaGallery && has(asset, 'id') && !has(asset, 'link');

  const containerStyles = {
    position: 'relative',
    width: '100%',
    height: '100%',

    '& img': {
      objectFit: 'contain'
    }
  };

  const assetComponent = (
    <MediaAsset
      asset={asset}
      isGiphy={isGiphy}
      isLightbox={isLightbox}
      shouldFetchAsset={shouldFetchAsset}
      allowSelect={allowSelect}
      showThumbnail={showThumbnail}
      type={type}
    />
  );

  return (
    <>
      {allowSelect ? (
        <Button
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',

            '& img': {
              objectFit: 'contain'
            }
          }}
          onClick={onClick}
        >
          {assetComponent}
        </Button>
      ) : (
        <Box
          sx={{
            ...containerStyles,
            ...(isLightbox && {
              width: '90%',
              height: '90%',
              maxWidth: '700px',
              maxHeight: '700px'
            })
          }}
        >
          {assetComponent}
        </Box>
      )}
    </>
  );
};

export default TileWrapper;
