import { useEffect, useRef, useState } from 'react';
import { Box, keyframes } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { filter, find, forEach, map, omitBy, reduce, uniq } from 'lodash';
import { Trans } from 'react-i18next';
import { Button, Fade, Theme } from '@mui/material';
import { connect } from 'react-redux';
import { t } from 'i18next';
import {
  FieldValues,
  FormProvider,
  useForm,
  UseFormReturn
} from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';

import { showBusinessObjectSelectorModal } from 'src/components/BusinessObjectSelector/actions';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import { DRAWER_FULL_SCREEN_BREAKPOINT } from 'src/pages/Program/ProgramPreviewDrawer/constants';
import useProgram from 'src/pages/Program/utils/useProgram';
import { ThemeAugmentations } from 'src/AppThemeWrapper';

import AiChatFloatingButton from './AiChatFloatingButton';
import {
  AiChatMessageType,
  aiChatMessageTypes,
  AiChatType,
  aiChatTypes,
  aiChatWindowDimensions
} from './constants';
import AiChatHeader from './AiChatHeader';
import AiChatMessage from './AiChatMessage';
import AiChatFooter from './AiChatFooter';
import { InputField } from './helpers';
import { logClickAiCopy } from './amplitudeEventLoggers';
import { getAiTextSuggestions as getAiTextSuggestionsQuery } from './queries';
import useHandleError from './useHandleError';

interface SharedInputProps {
  blueprint: Record<string, any>;
  businessObjects: Record<string, any>[];
  formName: string;
  isContentSelectable: boolean;
  contentName: string;
  contentColumns: Record<string, any>[];
}

interface AiChatProps {
  showButton?: boolean;
  type?: AiChatType;
  inputs: InputField[];
  sharedInputProps: SharedInputProps;
  showBusinessObjectSelectorModal: () => void;
  parentFormMethods: UseFormReturn<FieldValues, any, undefined>;
  setHighlightedInputs: React.Dispatch<React.SetStateAction<string[]>>;
}

const AiChat = ({
  type = aiChatTypes.copywriter,
  inputs = [],
  sharedInputProps,
  showBusinessObjectSelectorModal,
  parentFormMethods,
  setHighlightedInputs
}: AiChatProps) => {
  const methods = useForm();
  const formValues = methods.watch();
  const selectedInputValues = omitBy(formValues, value => !value);
  const [inputsLoading, setInputsLoading] = useState<string[]>([]);
  const [inputsCurrentSuggestion, setInputsCurrentSuggestion] = useState<
    Record<string, any>
  >([]);
  const [isThinking, setIsThinking] = useState(false);

  const architecture = useArchitecture();

  const {
    trackingData: { productId },
    aiChatContext: { aiChatOpen, toggleAiChatWindow },
    showContentSelector: requiresSelectableContent
  } = useProgram();

  const hasMultipleBusinessObjects =
    sharedInputProps?.businessObjects?.length > 1;
  const businessObjectId = hasMultipleBusinessObjects
    ? ''
    : sharedInputProps?.businessObjects?.[0]?.id;

  const contentSelectionMissing =
    !hasMultipleBusinessObjects &&
    !businessObjectId &&
    requiresSelectableContent;

  const [getAiTextSuggestions, { loading, error }] = useLazyQuery(
    getAiTextSuggestionsQuery,
    { fetchPolicy: 'no-cache' }
  );

  const handleError = useHandleError();

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  useEffect(() => {
    methods.reset();
  }, [sharedInputProps.businessObjects?.[0]?.id]);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const highlightInputs = (blueprintVariableIds?: string[]) => {
    if (!blueprintVariableIds) {
      setHighlightedInputs(inputs.map(input => input.blueprintVariableId));
    } else {
      setHighlightedInputs(blueprintVariableIds);
    }
  };

  const buttons = inputs.map(input => {
    return {
      label: input.displayName,
      onMouseEnter: () => {
        highlightInputs([input.blueprintVariableId]);
      },
      onMouseLeave: () => {
        highlightInputs([]);
      },
      onClick: async () => {
        logClickAiCopy({
          input: input.displayName,
          productId,
          architectureId: architecture.id
        });

        setInputsLoading(prev => uniq([...prev, input.blueprintVariableId]));

        const result = await getAiTextSuggestions({
          variables: {
            input: {
              fields: [{ blueprintVariableId: input.blueprintVariableId }],
              catalogId: architecture.catalog?.id || '',
              catalogFilter: {
                id: {
                  in: businessObjectId ? [businessObjectId] : []
                }
              },
              productId,
              forceContentLessPrompt: hasMultipleBusinessObjects
            }
          }
        }).catch(e => {
          handleError(e);
        });

        const generatedSuggestion =
          result?.data?.aiTextSuggestions?.fields?.[0]?.suggestions?.[0];

        if (generatedSuggestion) {
          methods.setValue(input.blueprintVariableId, {
            suggestions: [{ text: generatedSuggestion }]
          });
        }

        setInputsLoading(prev =>
          prev.filter(id => id !== input.blueprintVariableId)
        );
      },
      disabled:
        formValues?.[input.blueprintVariableId]?.suggestions?.length > 0 ||
        inputsLoading.includes(input.blueprintVariableId)
    };
  });

  const adCopySuggestionMessages = reduce(
    formValues,
    (result, suggestionData, blueprintVariableId) => {
      if (suggestionData) {
        const input = find(inputs, { blueprintVariableId });

        if (input) {
          result.push({
            type: aiChatMessageTypes.adCopySuggestion,
            input
          });
        }
      }

      return result;
    },
    [] as {
      type: AiChatMessageType;
      input: InputField;
    }[]
  );

  const currentInputsWithSuggestions = filter(
    Object.keys(selectedInputValues),
    key => {
      return !!find(inputs, { blueprintVariableId: key });
    }
  );

  interface AiChatMessageConfig {
    type: AiChatMessageType;
    text?: string | JSX.Element;
    buttons?: {
      label: string;
      onMouseEnter: () => void;
      onMouseLeave: () => void;
      onClick: () => void;
      disabled?: boolean;
    }[];
    input?: InputField;
  }

  const { contentName } = sharedInputProps;

  const messages: AiChatMessageConfig[] = [
    {
      type: aiChatMessageTypes.text,
      text: contentSelectionMissing ? (
        <Trans
          i18nKey="aiSuggestion:chat.contentSelectionMissingMessage"
          values={{ contentName: contentName.toLocaleLowerCase() }}
          components={[
            <Button
              component="a"
              data-cy="ai-chat-content-selection-missing-button"
              onClick={showBusinessObjectSelectorModal}
              sx={{
                fontSize: 'inherit',
                backgroundColor: 'transparent',
                color: 'primary.main',
                textTransform: 'none',
                padding: 0,
                minWidth: 0,
                verticalAlign: 'baseline',
                lineHeight: 'inherit',
                display: 'inline',
                '&:hover': {
                  textDecoration: 'underline',
                  backgroundColor: 'transparent'
                }
              }}
            />
          ]}
        />
      ) : (
        t('aiSuggestion:chat.greetingMessage')
      )
    },
    ...(!contentSelectionMissing
      ? [
          {
            type: aiChatMessageTypes.buttonInput,
            buttons: [
              ...(inputs.length > 1
                ? [
                    {
                      label: t('aiSuggestion:chat.selectAllLabel'),
                      onClick: () => {
                        logClickAiCopy({
                          input: 'All',
                          productId,
                          architectureId: architecture.id
                        });
                        setInputsLoading(
                          inputs.map(input => input.blueprintVariableId)
                        );

                        // Get remaining inputs using the form values
                        const remainingInputs = filter(inputs, input => {
                          return !formValues?.[input.blueprintVariableId];
                        });

                        const fields = map(remainingInputs, input => {
                          return {
                            blueprintVariableId: input.blueprintVariableId
                          };
                        });

                        getAiTextSuggestions({
                          variables: {
                            input: {
                              fields,
                              catalogId: architecture.catalog?.id || '',
                              catalogFilter: {
                                id: {
                                  in: businessObjectId ? [businessObjectId] : []
                                }
                              },
                              productId,
                              forceContentLessPrompt: hasMultipleBusinessObjects
                            }
                          }
                        })
                          .then(result => {
                            const fields =
                              result?.data?.aiTextSuggestions?.fields;
                            if (fields) {
                              forEach(fields, field => {
                                const input = find(inputs, {
                                  blueprintVariableId:
                                    field?.blueprintVariableId
                                });

                                if (input) {
                                  const existingSuggestions =
                                    formValues?.[input.blueprintVariableId]
                                      ?.suggestions || [];

                                  methods.setValue(input.blueprintVariableId, {
                                    suggestions: [
                                      ...existingSuggestions,
                                      { text: field?.suggestions?.[0] }
                                    ]
                                  });
                                }
                              });
                            }
                          })
                          .catch(e => {
                            handleError(e);
                          })
                          .finally(() => {
                            setInputsLoading([]);
                          });
                      },
                      onMouseEnter: () => {
                        highlightInputs();
                      },
                      onMouseLeave: () => {
                        highlightInputs([]);
                      },
                      disabled:
                        currentInputsWithSuggestions.length === inputs.length ||
                        inputsLoading.length === inputs.length
                    }
                  ]
                : []),
              ...buttons
            ]
          },
          { type: aiChatMessageTypes.scrollToViewRef },
          ...adCopySuggestionMessages
        ]
      : [])
  ];

  const chatWindowRef = useRef<Node>(null);
  const lastAdCopyMessagesCount = useRef(0);

  // The inputs take a moment to render after ad copy has been generated which causes the scroll to not work
  // if we just detect changes in the ad copy message length
  // The mutation observer allows us to scroll to the bottom after the inputs have been rendered
  const scrollToBottomAfterRender = () => {
    const observer = new MutationObserver(() => {
      if (
        adCopySuggestionMessages.length > lastAdCopyMessagesCount.current &&
        messagesEndRef.current
      ) {
        messagesEndRef.current.scrollIntoView();
        lastAdCopyMessagesCount.current = adCopySuggestionMessages.length;
      }
    });

    if (!chatWindowRef.current) {
      return;
    }
    // Observe changes in the chat window container
    observer.observe(chatWindowRef.current, { childList: true, subtree: true });

    // Disconnect the observer after scrolling
    return () => observer.disconnect();
  };

  useEffect(() => {
    scrollToBottomAfterRender();
  }, [adCopySuggestionMessages.length]);

  const theme = useTheme<Theme & ThemeAugmentations>();

  const slideUp = keyframes`
    from {
      transform: translateY(15px);
    }
    to {
      transform: translateY(0);
    }
  `;

  const slideDown = keyframes`
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(15px);
    }
  `;

  return (
    <FormProvider {...methods}>
      <>
        <Fade in={aiChatOpen} timeout={250}>
          <Box
            data-cy="ai-chat-window-container"
            sx={{
              position: 'fixed',
              bottom: 80,
              right: aiChatWindowDimensions.xPosition,
              zIndex: theme?.zIndex?.appBar - 10,
              display: 'flex',
              flexDirection: 'column',
              width: aiChatWindowDimensions.width,
              height: aiChatWindowDimensions.height,
              minHeight: aiChatWindowDimensions.minHeight,
              bgcolor: 'background.default',
              boxShadow: 2,
              animation: `${aiChatOpen ? slideUp : slideDown} 0.25s ease-in-out`,
              [theme.breakpoints.down(DRAWER_FULL_SCREEN_BREAKPOINT)]: {
                zIndex: theme.zIndex.appBar - 20
              },
              [theme.breakpoints.down('sm')]: {
                right: 0,
                width: '100%',
                height: '100vh',
                bottom: 0,
                zIndex: theme.zIndex.tooltip
              }
            }}
          >
            <AiChatHeader onClose={toggleAiChatWindow} type={type} />
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                px: 2,
                py: 1,
                flexDirection: 'column',
                gap: 2,
                overflowY: 'auto'
              }}
              ref={chatWindowRef}
            >
              {messages.map(message => {
                if (message.type === aiChatMessageTypes.scrollToViewRef) {
                  return (
                    <Box
                      key={aiChatMessageTypes.scrollToViewRef}
                      ref={messagesEndRef}
                    />
                  );
                }

                let key = '';

                if (message.type === aiChatMessageTypes.text) {
                  key = message.text as string;
                }

                if (
                  message.type === aiChatMessageTypes.adCopySuggestion &&
                  message.input
                ) {
                  key = message.input.blueprintVariableId;
                }

                if (message.type === aiChatMessageTypes.buttonInput) {
                  key = 'adCopyButtons';
                }

                return (
                  <AiChatMessage
                    key={key}
                    type={message.type}
                    text={message.text}
                    buttons={message.buttons}
                    input={message.input}
                    sharedInputProps={sharedInputProps}
                    setIsThinking={setIsThinking}
                    parentFormMethods={parentFormMethods}
                    highlightInputs={highlightInputs}
                    inputsCurrentSuggestion={inputsCurrentSuggestion}
                    setInputsCurrentSuggestion={setInputsCurrentSuggestion}
                  />
                );
              })}
            </Box>
            <AiChatFooter
              loading={loading || isThinking}
              applyAllDisabled={!Object.keys(selectedInputValues)?.length}
              highlightInputs={highlightInputs}
              parentFormMethods={parentFormMethods}
              inputs={inputs}
              inputsCurrentSuggestion={inputsCurrentSuggestion}
            />
          </Box>
        </Fade>
        <AiChatFloatingButton
          isOpen={aiChatOpen}
          onClick={toggleAiChatWindow}
        />
      </>
    </FormProvider>
  );
};

export default connect(null, { showBusinessObjectSelectorModal })(AiChat);
