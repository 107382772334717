import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import numeral from 'numeral';

interface AnimatedCounterProps {
  end: number;
  duration?: number;
  format?: string;
  prefix?: string;
  suffix?: string;
  start?: number;
}

const AnimatedValue = styled.span`
  display: inline-block;
  text-decoration: inherit;
  transition:
    transform 0.3s ease-out,
    opacity 0.3s ease-out;
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
const AnimatedCounter = ({
  end,
  duration = 1000,
  format = '0,0',
  prefix = '',
  suffix = '',
  start = 0
}: AnimatedCounterProps) => {
  const [count, setCount] = useState(start);
  const countRef = useRef(start);
  const animationFrameRef = useRef<number | null>(null);

  useEffect(() => {
    const startValue = countRef.current;
    const change = end - startValue;
    let startTimestamp: number | null = null;

    // Don't animate if diff is 0
    if (change === 0) {
      return;
    }

    const step = (timestamp: number) => {
      // Set the start timestamp if it's not already set
      if (!startTimestamp) {
        startTimestamp = timestamp;
      }

      // Calculate the progress of the animation
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);

      // Update the count
      countRef.current = Math.floor(startValue + change * progress);
      setCount(countRef.current);

      // Request the next animation frame if the progress is less than 1
      if (progress < 1) {
        animationFrameRef.current = requestAnimationFrame(step);
      }
    };

    animationFrameRef.current = requestAnimationFrame(step);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [end, duration]);

  return (
    <AnimatedValue>
      {prefix}
      {numeral(count).format(format)}
      {suffix}
    </AnimatedValue>
  );
};
export default AnimatedCounter;
