import {
  Card as MuiCard,
  CardActionArea,
  CardContent,
  Typography,
  Box,
  styled
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { translateMaps } from 'src/common/templateTranslator';

import { CARD_WIDTH } from 'src/components/BusinessObjectSelector/ContentThemeSelector';

interface ContentThemeSelectorCardProps {
  contentData: {
    id: string;
    fields: any;
  };
  displayImageTemplate: string;
  displayNameTemplate: string;
  isSelected: boolean;
  onSelect: (id: string) => void;
  isListView?: boolean;
}

const backgroundImage =
  'https://ev-prod.s3.us-east-1.amazonaws.com/public/lithium-ui/contentSelector/googleAdBackground.png';

const CardWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: 8,
  border: `3px solid ${theme.palette.grey[300]}`,
  transition: 'transform 0.3s ease, border-color 0.3s ease',
  transform: 'translateY(0)',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    transform: 'translateY(-5px)',
    transition: 'transform 0.3s ease'
  }
}));

const StyledCard = styled(MuiCard)(() => ({
  display: 'flex',
  cursor: 'pointer',
  boxShadow: 'none',
  overflow: 'visible', // Ensures the icon can extend beyond the card
  backgroundColor: 'white',
  position: 'relative'
}));

const ContentThemeSelectorCard = ({
  contentData,
  displayImageTemplate,
  displayNameTemplate,
  isSelected,
  onSelect,
  isListView = false
}: ContentThemeSelectorCardProps) => {
  const imageUrl = translateMaps(displayImageTemplate, contentData.fields);
  const title = translateMaps(displayNameTemplate, contentData.fields);

  return (
    <CardWrapper
      sx={{
        borderColor: isSelected ? 'primary.main' : 'grey.300',
        width: '100%'
      }}
    >
      <StyledCard
        onClick={() => onSelect(contentData.id)}
        sx={{
          flexDirection: isListView ? 'row' : 'column',
          width: isListView ? '100%' : `calc(${CARD_WIDTH} - 16px)`,
          maxWidth: isListView ? '100%' : `calc(${CARD_WIDTH} - 16px)`,
          height: isListView ? 'auto' : 230
        }}
      >
        {/* icon for selected non list view */}
        {isSelected && !isListView && (
          <CheckCircleIcon
            sx={{
              backgroundColor: 'white',
              borderRadius: '50%',
              color: 'primary.main',
              height: 30,
              position: 'absolute',
              right: -15,
              top: -15,
              width: 30,
              zIndex: 2
            }}
          />
        )}
        {isListView ? (
          <Box
            sx={{ display: 'flex', alignItems: 'center', width: '100%', p: 1 }}
          >
            <Box
              sx={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                borderRadius: 2,
                flex: '0 0 60px',
                height: 30,
                mr: 2
              }}
            >
              <Box
                component="img"
                src={imageUrl}
                alt={title}
                sx={{
                  borderRadius: 2,
                  height: '100%',
                  width: '100%'
                }}
              />
            </Box>
            <Box sx={{ flex: 1, textAlign: 'left' }}>
              <Typography variant="h6" sx={{ fontSize: 14, lineHeight: 1.2 }}>
                {title}
              </Typography>
            </Box>
            <Box sx={{ ml: 2, mr: 2, width: '25px', height: '25px' }}>
              {isSelected ? (
                <RadioButtonCheckedIcon
                  sx={{
                    color: 'primary.main',
                    width: '25px',
                    height: '25px'
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  sx={{
                    color: 'primary.main',
                    width: '25px',
                    height: '25px'
                  }}
                />
              )}
            </Box>
          </Box>
        ) : (
          <CardActionArea
            sx={{
              boxShadow: 'none',
              height: '100%',
              position: 'relative'
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                borderRadius: '8px 8px 0 0',
                height: 175,
                position: 'relative',
                width: `calc(${CARD_WIDTH} - 16px)`
              }}
            >
              <Box
                component="img"
                src={imageUrl}
                alt={title}
                sx={{
                  borderRadius: '2px',
                  bottom: 22,
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                  maxWidth: '125px',
                  objectFit: 'contain',
                  position: 'absolute',
                  right: 1,
                  width: '91%'
                }}
              />
            </Box>
            <CardContent
              sx={{
                backgroundColor: 'white',
                padding: '8px',
                textAlign: 'center',
                borderRadius: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  mb: 1,
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  lineHeight: 1.3,
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                  margin: '0 !important'
                }}
              >
                {title}
              </Typography>
            </CardContent>
          </CardActionArea>
        )}
      </StyledCard>
    </CardWrapper>
  );
};

export default ContentThemeSelectorCard;
