import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { clone, isEmpty } from 'lodash';
import { t } from 'i18next';

import { TextField, Typography, IconButton, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/DeleteForever';

const PREFIX = 'RenderDefaultVariableValues';

const classes = {
  container: `${PREFIX}-container`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  emptyText: `${PREFIX}-emptyText`,
  innerContainer: `${PREFIX}-innerContainer`,
  colon: `${PREFIX}-colon`,
  valueInput: `${PREFIX}-valueInput`,
  keyInputContainer: `${PREFIX}-keyInputContainer`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    margin: '5px',
    height: '770px',
    width: '100%'
  },

  [`& .${classes.buttonContainer}`]: {
    margin: `${theme.spacing(2)} 0`
  },

  [`& .${classes.emptyText}`]: {
    margin: `${theme.spacing(1)} 0`
  },

  [`& .${classes.innerContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    margin: `${theme.spacing(1)} 0`
  },

  [`& .${classes.colon}`]: {
    margin: `0 ${theme.spacing(1)}`
  },

  [`& .${classes.valueInput}`]: {
    flexGrow: '1'
  },

  [`& .${classes.keyInputContainer}`]: {
    display: 'flex',
    width: '300px'
  }
}));

const pageText = () => ({
  heading: t('admin:blueprintBuilder.stepDefaultVariableValuesHeadline'),
  addButton: t('admin:blueprintBuilder.stepDefaultVariableValuesAddButton'),
  removeButton: t(
    'admin:blueprintBuilder.stepDefaultVariableValuesRemoveButton'
  ),
  emptyState: t('admin:blueprintBuilder.stepDefaultVariableValuesEmptyState')
});

const RenderDefaultVariableValues = props => {
  const { input } = props;
  const text = useMemo(() => pageText(), []);

  const defaultVariables = input?.value
    ? Object.keys(input?.value).map(key => {
        return {
          key,
          value: input?.value[key]
        };
      })
    : [];

  const updateInputValue = newParameterValues => {
    input.onChange(
      newParameterValues.reduce((accum, { key, value }) => {
        const newAccum = accum;
        newAccum[key] = value;
        return newAccum;
      }, {})
    );
  };

  const handleAddParameter = () => {
    const newParameterValues = clone(defaultVariables);

    newParameterValues.push({
      key: '',
      value: ''
    });

    updateInputValue(newParameterValues);
  };

  const handleRemoveParameter = index => {
    const newParameterValues = clone(defaultVariables);

    newParameterValues.splice(index, 1);

    updateInputValue(newParameterValues);
  };

  const onKeyChange = (index, value) => {
    const newParameterValues = clone(defaultVariables);

    newParameterValues[index].key = value;

    updateInputValue(newParameterValues);
  };

  const onValueChange = (index, value) => {
    const newParameterValues = clone(defaultVariables);

    newParameterValues[index].value = value;

    updateInputValue(newParameterValues);
  };

  return (
    <Root className={classes.container}>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddParameter}
          startIcon={<AddIcon />}
        >
          {text.addButton}
        </Button>
      </div>
      <div>
        {isEmpty(defaultVariables) ? (
          <Typography className={classes.emptyText}>
            {text.emptyState}
          </Typography>
        ) : (
          defaultVariables.map(({ key, value }, index) => {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`default-variable-${index}`}
                className={classes.innerContainer}
              >
                <TextField
                  className={classes.keyInputContainer}
                  variant="outlined"
                  value={key}
                  onChange={({ target: { value } }) =>
                    onKeyChange(index, value)
                  }
                />

                <span className={classes.colon}>{' : '}</span>

                <TextField
                  fullWidth
                  variant="outlined"
                  value={value}
                  onChange={({ target: { value } }) =>
                    onValueChange(index, value)
                  }
                />

                <IconButton
                  onClick={() => {
                    handleRemoveParameter(index);
                  }}
                  size="large"
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            );
          })
        )}
      </div>
    </Root>
  );
};

export default RenderDefaultVariableValues;
