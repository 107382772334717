import { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { t } from 'i18next';
import { useForm, FormProvider } from 'react-hook-form';

import { Paper, Tabs, Tab, Box } from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import BulkUploadResourceItems from './BulkUploadResourceItems';
import AddResourceItemForm from './AddResourceItemForm';

import {
  buildCreateOrUpdateInput,
  getResourceItemFormInputs
} from './constants';
import {
  createResourceItem,
  updateResourceItem,
  deleteResourceItem
} from './queries';

const pageText = () => ({
  errorMessage: t('adminResourceLibrary:items.form.errorMessage'),
  successMessage: t('adminResourceLibrary:items.form.successMessage'),
  updateButton: t('adminResourceLibrary:items.form.updateButton'),
  createButton: t('adminResourceLibrary:items.form.createButton'),
  cancelButton: t('adminResourceLibrary:items.form.cancelButton'),
  resetButton: t('adminResourceLibrary:items.form.resetButton'),
  deleteButton: t('adminResourceLibrary:items.form.deleteButton'),
  deleteWarningHeader: t('adminResourceLibrary:items.form.deleteWarningHeader'),
  deleteWarningBody: t('adminResourceLibrary:items.form.deleteWarningBody'),
  deleteSuccessMessage: t(
    'adminResourceLibrary:items.form.deleteSuccessMessage'
  ),
  deleteErrorMessage: t('adminResourceLibrary:items.form.deleteErrorMessage'),
  createNewTab: t('adminResourceLibrary:items.form.createNewTab'),
  bulkUploadTab: t('adminResourceLibrary:items.form.bulkUploadTab'),
  updateTab: t('adminResourceLibrary:items.form.updateTab')
});

const ResourceItemForm = ({
  isUpdate,
  isEditItemValuesOnly,
  initialValues,
  onAfterSuccess,
  closeForm
}) => {
  const text = useMemo(() => pageText(), []);
  const { enqueueSnackbar } = useSnackbar();

  const [createResourceItemQuery] = useMutation(createResourceItem);
  const [updateResourceItemQuery] = useMutation(updateResourceItem);
  const [deleteResourceItemQuery] = useMutation(deleteResourceItem);

  const inputs = useMemo(
    () => getResourceItemFormInputs(isUpdate, isEditItemValuesOnly),
    [isUpdate, isEditItemValuesOnly]
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const methods = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      ...initialValues
    },
    resetOptions: {
      keepDefaultValues: true,
      keepValues: true
    }
  });

  const {
    handleSubmit,
    reset: resetForm,
    formState: { isDirty, isValid }
  } = methods;

  const onSubmit = async data => {
    setIsSubmitting(true);
    const input = buildCreateOrUpdateInput(isUpdate, data, initialValues);

    const request = {
      variables: { input }
    };

    try {
      await (isUpdate
        ? updateResourceItemQuery(request)
        : createResourceItemQuery(request));

      enqueueSnackbar(text.successMessage, {
        variant: 'success'
      });

      resetForm();
      onAfterSuccess();
    } catch (error) {
      enqueueSnackbar(text.errorMessage, {
        variant: 'error'
      });
      resetForm();
    } finally {
      setIsSubmitting(false);
    }
  };

  const bulkUploadResourceItems = async items => {
    setIsSubmitting(true);

    try {
      await Promise.all(
        items.map(item => {
          const input = {
            resourceCollectionId: initialValues.resourceCollectionId,
            ...item
          };

          return createResourceItemQuery({
            variables: { input }
          });
        })
      );

      enqueueSnackbar(text.successMessage, {
        variant: 'success'
      });

      onAfterSuccess();
    } catch (error) {
      enqueueSnackbar(text.errorMessage, {
        variant: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onDelete = async () => {
    setIsSubmitting(true);
    const request = {
      variables: { id: initialValues.id }
    };

    try {
      await deleteResourceItemQuery(request);
      enqueueSnackbar(text.deleteSuccessMessage, {
        variant: 'success'
      });

      resetForm();
      onAfterSuccess();
    } catch (error) {
      enqueueSnackbar(text.deleteErrorMessage, {
        variant: 'error'
      });
      resetForm();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Tabs
        value={selectedTab}
        onChange={(e, newValue) => setSelectedTab(newValue)}
        sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}
      >
        <Tab
          label={isUpdate ? text.updateTab : text.createNewTab}
          id="resource-tab-0"
        />
        {!isUpdate && <Tab label={text.bulkUploadTab} id="resource-tab-1" />}
      </Tabs>
      <Box role="tabpanel" hidden={selectedTab !== 0}>
        {selectedTab === 0 && (
          <FormProvider {...methods}>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <AddResourceItemForm
                inputs={inputs}
                isUpdate={isUpdate}
                isEditItemValuesOnly={isEditItemValuesOnly}
                isSubmitting={isSubmitting}
                isDirty={isDirty}
                isValid={isValid}
                text={text}
                methods={methods}
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                resetForm={resetForm}
                closeForm={closeForm}
              />
              {isUpdate && (
                <ConfirmationModal
                  icon={<WarningIcon />}
                  cancelButtonText={text.cancelButton}
                  confirmButtonText={text.deleteButton}
                  title={text.deleteWarningHeader}
                  open={isDeleteModalOpen}
                  onClose={() => setIsDeleteModalOpen(false)}
                  onConfirm={onDelete}
                >
                  {text.deleteWarningBody}
                </ConfirmationModal>
              )}
            </form>
          </FormProvider>
        )}
      </Box>
      {!isUpdate && (
        <Box role="tabpanel" hidden={selectedTab !== 1}>
          {selectedTab === 1 && (
            <BulkUploadResourceItems
              bulkUploadResourceItems={bulkUploadResourceItems}
            />
          )}
        </Box>
      )}
    </Paper>
  );
};

export default ResourceItemForm;
