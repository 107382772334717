/* eslint-disable jsx-a11y/media-has-caption */
import { flow, get } from 'lodash';
import { styled } from '@mui/material/styles';
import { graphql } from '@apollo/client/react/hoc';
import { t } from 'i18next';

import { extractCloudinaryUrl } from 'src/common/cloudinaryUtils';

import { signCloudinaryUrl } from './mutations';

const PREFIX = 'CloudinaryVideo';

const classes = {
  error: `${PREFIX}-error`,
  loading: `${PREFIX}-loading`
};

const Root = styled('video')(({ theme }) => ({
  [`& .${classes.error}`]: {
    color: theme.palette.error.dark
  },

  [`& .${classes.loading}`]: {
    width: '10px',
    height: '10px'
  }
}));

const CloudinaryVideo = props => {
  const { className, error, loading, videoUrl, signedUrl, ...restProps } =
    props;

  if (loading) {
    return <span>Loading Video...</span>;
  }

  if (error || !signedUrl) {
    return <span className={classes.error}>Error getting Video</span>;
  }

  const fbImageAlt = t('adPreview:fbImageAlt');

  return (
    <Root
      src={videoUrl}
      className={className}
      caption={fbImageAlt}
      poster={signedUrl}
      controls
      {...restProps}
    >
      No support for this type of video
    </Root>
  );
};

export default flow(
  graphql(signCloudinaryUrl, {
    name: 'signCloudinaryUrl',
    options: props => {
      const { unsignedUrl } = props;
      const extractedUrl = extractCloudinaryUrl(unsignedUrl);

      return {
        variables: {
          unsignedCloudinaryUrl: extractedUrl
        }
      };
    },
    skip: ({ unsignedUrl }) => {
      if (!unsignedUrl) {
        return true;
      }
    },
    props: resp => {
      const error = get(resp, 'signCloudinaryUrl.error', null);
      const loading = get(resp, 'signCloudinaryUrl.loading', true);
      const signedUrl = get(
        resp,
        'signCloudinaryUrl.helpers.signCloudinaryUrl',
        null
      );

      return {
        error,
        loading,
        signedUrl
      };
    }
  })
)(CloudinaryVideo);
