import { isFunction, noop } from 'lodash';
import { styled } from '@mui/material/styles';
import { TableRow, TableCell, Checkbox, Radio } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { grey } from '@mui/material/colors';
import { keyByParams } from 'src/common/utilities';
import classnames from 'classnames';

import EvTableCell from './TableCell';
import { DATA_CY_TABLE_ROW } from './constants';

const PREFIX = 'TableRow';

const classes = {
  groupedRow: `${PREFIX}-groupedRow`,
  expanded: `${PREFIX}-expanded`,
  parentExpanded: `${PREFIX}-parentExpanded`
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${classes.groupedRow}`]: {
    display: 'none',
    '& td:first-child': {
      boxShadow: 'inset 7px 0 9px -7px rgba(0,0,0,0.2)'
    }
  },

  [`&.${classes.expanded}`]: {
    display: 'table-row'
  },

  [`&.${classes.parentExpanded}`]: {
    boxShadow: theme.shadows[2]
  }
}));

const BodyRow = ({
  columnSchema,
  row,
  index,
  rowHover = true,
  singleSelect,
  onClickBodyRow = null,
  onCheckboxClick = null,
  hasRowCheckbox = null,
  selectedRows = {},
  groupParent,
  grouped,
  expanded,
  childSelected,
  allChildrenSelected,
  highlightedColumns
}) => {
  let checkboxFunc = noop;

  if (isFunction(onCheckboxClick)) {
    checkboxFunc = event => {
      onCheckboxClick(row);

      // If onClickBodyRow is a function, we also want to prevent any
      // other actions from taking place (i.e. so a checkbox click doesn't
      // also register as a row click).
      if (isFunction(onClickBodyRow)) {
        event.preventDefault();
      }
    };
  }

  return (
    <StyledTableRow
      data-cy={DATA_CY_TABLE_ROW}
      hover={rowHover}
      onClick={() => {
        if (isFunction(onClickBodyRow)) {
          onClickBodyRow(row, index);
        }
      }}
      style={{
        cursor: isFunction(onClickBodyRow) ? 'pointer' : 'initial'
      }}
      className={classnames(
        { [classes.groupedRow]: grouped },
        { [classes.parentRow]: groupParent },
        {
          [classes.expanded]: grouped && expanded
        },
        {
          [classes.parentExpanded]: groupParent && expanded
        }
      )}
    >
      {groupParent && (
        <TableCell padding="checkbox">
          {expanded ? (
            <ExpandLessIcon htmlColor={grey[600]} style={{ fontSize: 40 }} />
          ) : (
            <ExpandMoreIcon htmlColor={grey[600]} style={{ fontSize: 40 }} />
          )}
        </TableCell>
      )}
      {hasRowCheckbox && isFunction(onCheckboxClick) && (
        <TableCell
          padding="checkbox"
          style={{
            ...(grouped && { background: 'rgb(0, 0, 0, 0.07)' })
          }}
        >
          {singleSelect ? (
            <Radio
              onClick={checkboxFunc}
              checked={!!selectedRows[row.id]}
              data-cy="table-row-checkbox"
            />
          ) : (
            <Checkbox
              onChange={checkboxFunc}
              checked={!!selectedRows[row.id]}
              data-cy="table-row-checkbox"
            />
          )}
        </TableCell>
      )}
      {columnSchema.map(column => {
        return (
          <EvTableCell
            {...column}
            key={keyByParams(column, column.accessor)}
            row={row}
            groupParent={groupParent}
            grouped={grouped}
            childSelected={childSelected}
            allChildrenSelected={allChildrenSelected}
            highlightedColumns={highlightedColumns}
          />
        );
      })}
    </StyledTableRow>
  );
};

export default BodyRow;
