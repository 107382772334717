import { clone, mapValues, chain } from 'lodash';
import { t } from 'i18next';

import { googleCreativeTypes } from 'src/common/adChannels';

import Message from 'src/components/Containers/Message';
import ClientHtml from 'src/components/ClientHtml';
import IncludedButton from 'src/components/AdPreview/IncludedButton/IncludedButton';
import { themeBasedCatalogIds } from 'src/components/BusinessObjectSelector/constants';

import { addChips, getMessageIconSize, getMessageMaxWidth } from '../helpers';
import GoogleDisplayAdPreview from './GoogleDisplayAdPreview';
import GoogleSearchAdPreview from './GoogleSearchAdPreview';
import GoogleDiscoveryAdPreview from './GoogleDiscoveryAdPreview';
import PhoneMockAdPreviewWrapper from '../PhoneMockAdPreviewWrapper';

const pageText = () => ({
  googleResponsiveFooterMessage: t(
    'adPreview:messages.GoogleResponsiveAdFooter'
  ),
  unsupportedMessage: t('adPreview:messages.unsupportedGoogleType')
});

const CreateGooglePreview = ({
  blueprint,
  creativeType,
  adPreviewSet, // expects one of the previews from getAdPreviewSet
  showMessaging = true,
  hideGoogleAdNavigationButtons,
  hasUniformPreviewWidth,
  displayAsPhoneMockUp,
  isResponsive,
  businessObjects,
  architecture,
  showIncludedButton
}) => {
  const googlePreviewProps = {
    blueprint,
    isResponsive,
    hasUniformPreviewWidth,
    adData: {}
  };
  let GoogleAdPreview = null;
  let blueprintTypeMessage = null;
  const footerMessage = blueprint?.messaging?.adPreviewFooterMessage || null;
  const text = pageText();

  switch (creativeType) {
    case googleCreativeTypes.googleResponsiveDisplay:
      GoogleAdPreview = GoogleDisplayAdPreview;

      googlePreviewProps.adData = mapValues(
        adPreviewSet?.googleResponsiveDisplayCreative?.permutations?.[0],
        item => {
          return addChips(item);
        }
      );

      blueprintTypeMessage = text.googleResponsiveFooterMessage;
      break;
    case googleCreativeTypes.googleSearch:
      GoogleAdPreview = GoogleSearchAdPreview;

      googlePreviewProps.adData =
        adPreviewSet?.googleSearchCreative?.permutations.reduce(
          (accum, current) => {
            const newAccum = clone(accum);
            newAccum.headlines.push(current?.headline);
            newAccum.descriptions.push(current?.description);
            newAccum.finalUrls.push(current?.finalUrl);
            newAccum.path1 = current?.path1;
            newAccum.path2 = current?.path2;
            return accum;
          },
          {
            descriptions: [],
            finalUrls: [],
            headlines: [],
            path1: '',
            path2: ''
          }
        );

      blueprintTypeMessage = text.googleResponsiveFooterMessage;
      break;
    case googleCreativeTypes.googleDiscoveryMultiAsset:
      GoogleAdPreview = GoogleDiscoveryAdPreview;

      googlePreviewProps.adData = chain(
        adPreviewSet?.googleDiscoveryMultiAssetCreative?.permutations
      )
        .map(permuation => mapValues(permuation, item => addChips(item)))
        .value();

      blueprintTypeMessage = text.googleResponsiveFooterMessage;
      break;
    default:
      return text.unsupportedMessage;
  }

  const googleAdPreview = (
    <GoogleAdPreview
      creativeType={creativeType}
      hideGoogleAdNavigationButtons={hideGoogleAdNavigationButtons}
      {...googlePreviewProps}
    />
  );

  const messageSx = !displayAsPhoneMockUp && {
    margin: '8px auto 8px auto',
    whiteSpace: 'normal !important',
    width: '100%'
  };

  const catalogId = architecture?.catalog?.id;
  const isRebelIqBlueprint = blueprint?.isRebelIqEnabled;

  return (
    <>
      {displayAsPhoneMockUp ? (
        <PhoneMockAdPreviewWrapper>{googleAdPreview}</PhoneMockAdPreviewWrapper>
      ) : (
        googleAdPreview
      )}

      {showIncludedButton && themeBasedCatalogIds.includes(catalogId) && (
        <IncludedButton
          selected={businessObjects}
          isRebelIqBlueprint={isRebelIqBlueprint}
        />
      )}

      {showMessaging && footerMessage && (
        <Message
          sx={messageSx}
          iconSize={getMessageIconSize(displayAsPhoneMockUp)}
          type="info"
          maxWidth={getMessageMaxWidth(displayAsPhoneMockUp)}
        >
          <ClientHtml html={footerMessage} />
        </Message>
      )}

      {showMessaging && blueprintTypeMessage && (
        <Message
          sx={messageSx}
          iconSize={getMessageIconSize(displayAsPhoneMockUp)}
          type="info"
          maxWidth={getMessageMaxWidth(displayAsPhoneMockUp)}
        >
          {blueprintTypeMessage}
        </Message>
      )}
    </>
  );
};

export default CreateGooglePreview;
