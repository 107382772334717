import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { clone, isEmpty } from 'lodash';

import {
  Avatar,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import HelpIcon from '@mui/icons-material/HelpOutline';

const PREFIX = 'RenderKeyBasedSettings';

const classes = {
  helpTip: `${PREFIX}-helpTip`,
  helpIcon: `${PREFIX}-helpIcon`,
  helperText: `${PREFIX}-helperText`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.helpTip}`]: {
    color: theme.palette.grey[500],
    position: 'absolute',
    top: '3px',
    right: '3px'
  },

  [`& .${classes.helpIcon}`]: {
    width: '20px'
  },

  [`& .${classes.helperText}`]: {
    marginTop: '0'
  }
}));

const RenderKeyBasedSettings = props => {
  const { input, label, tooltip, type } = props;
  const value = input?.value || {};

  const [inputValue, inputOnChange] = useState('');

  const handleOnChange = ({ target: { value } }) => {
    inputOnChange(value);
  };

  const handleAddId = () => {
    // don't add if empty
    if (isEmpty(inputValue)) {
      return;
    }
    const newValue = clone(value);
    newValue[inputValue] = true;
    // update redux input value
    input.onChange(newValue);
    // reset input
    inputOnChange('');
  };

  const handelKeyPress = ({ key }) => {
    if (key === 'Enter') {
      handleAddId();
    }
  };

  return (
    <Root>
      {!isEmpty(value) && (
        <List>
          {Object.keys(value).map(id => {
            return (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>ID</Avatar>
                </ListItemAvatar>
                <ListItemText primary={id} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      const newValue = clone(value);
                      delete newValue[id];
                      input.onChange(newValue);
                    }}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      )}
      <FormControl fullWidth>
        <TextField
          label={label}
          variant="outlined"
          onChange={handleOnChange}
          value={inputValue}
          onKeyPress={handelKeyPress}
          InputProps={{
            ...(type !== 'hidden' &&
              tooltip && {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={handleAddId}
                      size="large"
                    >
                      <AddIcon />
                    </IconButton>
                    <Tooltip className={classes.helpTip} arrow title={tooltip}>
                      <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                  </InputAdornment>
                )
              })
          }}
        />
      </FormControl>
    </Root>
  );
};

export default RenderKeyBasedSettings;
