import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';

import { Fab, Tooltip } from '@mui/material';

import SaveChangesIcon from '@mui/icons-material/Save';

const PREFIX = 'SaveBlueprintChangesFab';

const classes = {
  fab: `${PREFIX}-fab`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.fab}`]: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: theme.zIndex.modal
  }
}));

const pageText = () => ({
  tooltip: t('admin:blueprintBuilder.saveBlueprintChangesFabTooltip')
});

const SaveBlueprintChangesFab = props => {
  const { setSubmitModalOpen } = props;
  const text = useMemo(() => pageText(), []);

  return (
    <Root>
      <Tooltip arrow placement="left" title={text.tooltip}>
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="changeBlueprintChanges"
          onClick={() => {
            setSubmitModalOpen(true);
          }}
        >
          <SaveChangesIcon />
        </Fab>
      </Tooltip>
    </Root>
  );
};

export default SaveBlueprintChangesFab;
