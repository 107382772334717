import { useState } from 'react';
import { isEqual, isEmpty, xorWith } from 'lodash';
import { t } from 'i18next';
import { useWatch } from 'react-hook-form';

import { Box, Grid, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { contentColumnsFromMetadata } from 'src/common/dynamicUserInputs';
import { validateLength } from 'src/common/validations';
import { useGlobalContext } from 'src/GlobalContextProvider';
import HookFormWrapper from 'src/components/ReduxForm/DynamicForm/HookFormWrapper';

import {
  validateDynamic,
  getDynamicColumnValues
} from 'src/components/ReduxForm/DynamicForm/helpers';

import RenderTemplateStringTextField from 'src/components/ReduxForm/RenderTemplateStringTextField';
import RenderTextField from 'src/components/ReduxForm/RenderTextField';

import { getProgramNameTranslated } from './ProgramNameUtil';
import { PROGRAM_FORM_SECTION_SPEND_NAME } from '../Constants';
import InputValueTranslated from './InputValueTranslated';
import HelpText from './HelpText';

const PROGRAM_FIELD_NAME = 'programName';

const ProgramName = props => {
  const {
    disabled,
    selectedBlueprint,
    displayNameTemplate,
    contentColumns,
    businessObjects,
    isAutomated,
    showHelpTextAsTooltip,
    inputVariant,
    label,
    containerSx = {},
    isContentSelectable = false,
    contentName,
    isEditProgramModal = false
  } = props;

  const globalContext = useGlobalContext();
  const userMetadataFields = globalContext?.me?.metadata?.fields;
  const userFieldMetadata = globalContext?.me?.metadata?.fieldMetadata;
  const userContentColumns =
    contentColumnsFromMetadata(userFieldMetadata) || [];

  const programName = useWatch({
    name: `spendStep.${PROGRAM_FIELD_NAME}`,
    defaultValue: ''
  });

  const generateValidations = () => [
    validateLength(1, 1000),
    value =>
      validateDynamic(
        value,
        contentColumns,
        businessObjects,
        t('programCreate:programNameInput.validation.missing'),
        userContentColumns,
        [userMetadataFields] // needs to be an array
      )
  ];

  const [validations, setValidations] = useState({
    validations: generateValidations(),
    businessObjects
  });

  if (xorWith(validations.businessObjects, businessObjects, isEqual).length) {
    validations.validations = generateValidations();
    validations.businessObjects = businessObjects;
    setValidations(validations);
  }

  let inputValueTranslated = '';
  if (programName !== undefined && programName !== '') {
    inputValueTranslated = getProgramNameTranslated(
      programName,
      selectedBlueprint,
      businessObjects,
      displayNameTemplate,
      userMetadataFields
    );
  }

  const requiresContent = selectedBlueprint?.requiresContent;

  const InputComponent = requiresContent
    ? RenderTemplateStringTextField
    : RenderTextField;

  const { dynamicValues, dynamicValuesMap, missingColumns, validColumns } =
    getDynamicColumnValues([
      {
        shouldRenderChips: isContentSelectable,
        columns: contentColumns,
        content: businessObjects,
        type: 'content',
        friendlyName: contentName || 'Listing'
      },
      {
        shouldRenderChips: !isEmpty(userMetadataFields),
        columns: userContentColumns,
        content: [userMetadataFields],
        type: 'userMetadata',
        friendlyName: 'My Profile'
      }
    ]);

  // props for template string text field
  const extraProps = requiresContent
    ? {
        dynamicValues,
        dynamicValuesMap,
        validColumns,
        missingColumns,
        businessObjects
      }
    : {};

  const helpText = isAutomated
    ? t('programCreate:programNameInput.automatedHelpText')
    : t('programCreate:programNameInput.helpText');

  const sharedProps = {
    disabled,
    label,
    component: InputComponent,
    fullWidth: true,
    autoComplete: 'completely-off',
    // note: we don't seem to be validating anything here atm.
    // we probalby don't really care for the time being.
    // validate: validations.validations,
    name: PROGRAM_FIELD_NAME
  };

  const extra = {
    ...extraProps,
    blockEmojiPicker: true,
    multiline: false,
    variant: inputVariant,
    ...(showHelpTextAsTooltip ? { tooltip: helpText } : {})
  };

  // Don't render the formatted version if there's nothing to translate.
  const shouldRenderFormattedValue =
    Array.isArray(contentColumns) &&
    contentColumns.length &&
    selectedBlueprint?.requiresContent;

  const theme = useTheme();

  const input = (
    <HookFormWrapper
      {...sharedProps}
      extraProps={extra}
      formNamespace={PROGRAM_FORM_SECTION_SPEND_NAME}
    />
  );

  const helperTextWrapBreakpoint = 1360;

  if (isEditProgramModal) {
    return (
      <Box sx={{ ...containerSx }}>
        <Stack
          gap={2}
          sx={{
            alignItems: 'flex-start',
            flexDirection: 'column',
            [theme.breakpoints.up(helperTextWrapBreakpoint)]: {
              flexDirection: 'row'
            }
          }}
        >
          <Stack sx={{ flexShrink: 0 }}>
            <Box sx={{ flexShrink: 0 }}>{input}</Box>
            {shouldRenderFormattedValue && (
              <InputValueTranslated value={inputValueTranslated} />
            )}
          </Stack>
          <Box
            sx={{
              pt: 0,
              [theme.breakpoints.up(helperTextWrapBreakpoint)]: { pt: 1 }
            }}
          >
            {!showHelpTextAsTooltip && <HelpText helpText={helpText} />}
          </Box>
        </Stack>
      </Box>
    );
  }

  return (
    <Grid
      item
      xs={12}
      sx={{ padding: theme => theme.spacing(4, 0), ...containerSx }}
    >
      {!showHelpTextAsTooltip && <HelpText helpText={helpText} />}
      {input}
      {shouldRenderFormattedValue && (
        <InputValueTranslated value={inputValueTranslated} />
      )}
    </Grid>
  );
};

export default ProgramName;
