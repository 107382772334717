import { flow } from 'lodash';

import { useTheme } from '@mui/material/styles';

import { Typography } from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

import { Box } from '@mui/system';

const ICON_SIZES = {
  small: '20px',
  medium: '35px',
  large: '50px'
};
const ICON_TYPES = {
  error: ErrorIcon,
  info: InfoIcon,
  warn: ErrorIcon
} as const;

type MessageType = keyof typeof ICON_TYPES;

interface MessageProps {
  children: JSX.Element | string;
  iconSize: 'small' | 'medium' | 'large';
  type: MessageType;
  maxWidth?: string;
  sx?: Record<string, unknown>;
}

const Message = (props: MessageProps) => {
  const {
    children,
    iconSize = 'large',
    type = 'info',
    maxWidth = '100%',
    sx
  } = props;

  const theme = useTheme();

  const getMessageStyles = () =>
    ({
      info: { color: 'initial' },
      warn: { color: theme.palette.warning.main },
      error: { color: theme.palette.error.dark },
      large: { width: ICON_SIZES.large, height: ICON_SIZES.large },
      medium: { width: ICON_SIZES.medium, height: ICON_SIZES.medium },
      small: { width: ICON_SIZES.small, height: ICON_SIZES.small }
    }) as const;

  const sxStyles = getMessageStyles();

  const colorStyle = sxStyles[type] ? sxStyles[type] : sxStyles.info;

  const IconComponent = ICON_TYPES[type] ? ICON_TYPES[type] : ICON_TYPES.info;

  const iconSizeStyle = sxStyles[iconSize]
    ? sxStyles[iconSize]
    : sxStyles.large;

  const iconComponent = (
    <IconComponent sx={{ ...colorStyle, ...iconSizeStyle }} />
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1.5,
        whiteSpace: 'normal !important',
        maxWidth,
        ...sx
      }}
    >
      {iconComponent}
      <Typography sx={{ color: colorStyle }} variant="body2">
        {children}
      </Typography>
    </Box>
  );
};

export default flow()(Message);
