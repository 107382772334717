import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import ReactDiffViewer from 'react-diff-viewer';
import { t } from 'i18next';

import { Button, CircularProgress } from '@mui/material';

import Modal from 'src/components/Modal';

import { sortKeys, mergeColumnsData } from './helpers';

const PREFIX = 'ConfirmModal';

const classes = {
  publishButtonContainer: `${PREFIX}-publishButtonContainer`
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.publishButtonContainer}`]: {
    margin: `${theme.spacing(3)} 0`,
    display: 'flex',
    justifyContent: 'center',

    '& button:first-child': {
      marginRight: theme.spacing(3)
    }
  }
}));

const pageText = () => ({
  modalHeader: t('admin:blueprintBuilder.confirmModalHeader'),
  commit: t('admin:blueprintBuilder.confirmModalCommitButton'),
  saveDraft: t('admin:blueprintBuilder.confirmModalSaveDraftButton'),
  close: t('admin:blueprintBuilder.confirmModalCloseButton'),
  leftTitle: t('admin:blueprintBuilder.confirmModalLeftTitle'),
  rightTitle: t('admin:blueprintBuilder.confirmModalRightTitle')
});

const ConfirmModal = props => {
  const {
    submitModalOpen,
    currentlySelectedContentDoc = {},
    formValues = {},
    setSubmitModalClose,
    onPublish,
    submitting
  } = props;
  const text = useMemo(() => pageText(), []);

  return (
    <StyledModal
      open={submitModalOpen}
      headerText={text.modalHeader}
      fullWidth
      maxWidth="lg"
      onClose={setSubmitModalClose}
      FooterComponent={() => (
        <>
          <Button onClick={setSubmitModalClose}>{text.close}</Button>
        </>
      )}
    >
      <div>
        <ReactDiffViewer
          // Note: Sorting these keys so that the diff works correctly redux form and the
          //       object that the we compare can have properties out of order
          oldValue={JSON.stringify(
            sortKeys(mergeColumnsData(currentlySelectedContentDoc)),
            null,
            2
          )}
          newValue={JSON.stringify(
            sortKeys(mergeColumnsData(formValues)),
            null,
            2
          )}
          splitView
          disableWordDiff
          leftTitle={text.leftTitle}
          rightTitle={text.rightTitle}
        />

        <div className={classes.publishButtonContainer}>
          <Button
            color="primary"
            variant="contained"
            disabled={submitting}
            onClick={() => onPublish({ isDraft: false })}
            startIcon={<>{submitting && <CircularProgress />}</>}
          >
            {text.commit}
          </Button>

          <Button
            color="primary"
            variant="outlined"
            onClick={() => onPublish({ isDraft: true })}
          >
            {text.saveDraft}
          </Button>
        </div>
      </div>
    </StyledModal>
  );
};

export default ConfirmModal;
