import { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { flow, isEmpty } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';

import { Fab, Paper, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import TableEmptyState from 'src/components/EmptyStates/TableEmptyState';
import Loading from 'src/components/Loading';
import Table from 'src/components/Table';

import { getResourceItemTableColumnSchema } from './constants';
import { getResourceCollection } from './queries';
import ResourceItemForm from './ResourceItemForm';

const pageText = () => ({
  title: t('adminResourceLibrary:items.title'),
  emptyTableMessage: t('adminResourceLibrary:items.empty')
});

const ResourceItemsManager = ({
  isEditItemValuesOnly,
  selectedResourceCollection,
  resourceCollection,
  resourceCollectionMeta: {
    resourceCollectionLoading,
    resourceCollectionError,
    refetchResourceCollection
  }
}) => {
  const text = useMemo(() => pageText(), []);

  const [selectedItem, setSelectedItem] = useState();
  const [isFormOpen, setIsFormOpen] = useState(false);

  // anytime we have a new selectedResourceCollection, ensure that we don't
  // have anything selected and the form is not open.
  useEffect(() => {
    setSelectedItem();
    setIsFormOpen(false);
  }, [selectedResourceCollection]);

  if (resourceCollectionLoading || resourceCollectionError) {
    return <Loading error={resourceCollectionError} />;
  }

  return (
    <Box>
      <Box
        sx={{
          width: '50%',
          paddingBottom: theme => theme.spacing(1),
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography component="h2" variant="h5">
          {text.title}
        </Typography>
        {!isEditItemValuesOnly && (
          <Fab
            size="small"
            color="primary"
            onClick={() => {
              setSelectedItem();
              setIsFormOpen(true);
            }}
          >
            <AddIcon />
          </Fab>
        )}
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ width: '50%' }}>
          <Paper>
            {isEmpty(resourceCollection.resources) && (
              <TableEmptyState
                emptyMessage={text.emptyTableMessage}
                loading={resourceCollectionLoading}
              />
            )}
            {!isEmpty(resourceCollection.resources) && (
              <Table
                columnSchema={getResourceItemTableColumnSchema()}
                rows={resourceCollection.resources}
                onClickBodyRow={resourceItem => {
                  setSelectedItem(resourceItem);
                  setIsFormOpen(true);
                }}
                loading={resourceCollectionLoading}
                hasRowCheckbox
                // just need something here, work is done by onClickBodyRow
                onCheckboxClick={() => {}}
                singleSelect
                selectedRows={
                  selectedItem && {
                    [selectedItem.id]: selectedItem
                  }
                }
              />
            )}
          </Paper>
        </Box>
        <Box sx={{ width: '50%', paddingLeft: theme => theme.spacing(2) }}>
          {isFormOpen && (
            <ResourceItemForm
              key={selectedItem?.id}
              isEditItemValuesOnly={isEditItemValuesOnly}
              closeForm={() => {
                setSelectedItem();
                setIsFormOpen(false);
              }}
              initialValues={{
                resourceCollectionId: resourceCollection.id,
                ...selectedItem
              }}
              isUpdate={!!selectedItem}
              onAfterSuccess={() => {
                setSelectedItem();
                setIsFormOpen(false);
                refetchResourceCollection();
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default flow(
  graphql(getResourceCollection, {
    name: 'getResourceCollection',
    options: props => ({
      variables: {
        type: props.selectedResourceCollection.type,
        key: props.selectedResourceCollection.key
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }),
    props: ({
      getResourceCollection: { error, loading, refetch, resourceCollection }
    }) => {
      return {
        resourceCollection,
        resourceCollectionMeta: {
          resourceCollectionLoading: loading,
          resourceCollectionError: error,
          refetchResourceCollection: refetch
        }
      };
    }
  })
)(ResourceItemsManager);
