import BusinessObjectSelector from 'src/components/BusinessObjectSelector';
import FormBusinessObjectSelector from 'src/components/BusinessObjectSelector/FormBusinessObjectSelector';

const RenderBusinessObjectSelector = props => {
  const {
    input: { onChange, value, name },
    isFormInput = false,
    ...rest
  } = props;
  return isFormInput ? (
    <FormBusinessObjectSelector
      {...rest}
      name={name}
      onChange={onChange}
      selectedIds={value || []}
    />
  ) : (
    // this is the selector used in program create/edit
    <BusinessObjectSelector
      {...rest}
      name={name}
      onChange={onChange}
      selectedIds={value || []}
    />
  );
};

export default RenderBusinessObjectSelector;
