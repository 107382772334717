import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { Events } from './constants';

const AMPLITUDE_API_KEY = import.meta.env.EVOCALIZE_AMPLITUDE_API_KEY;

/**
 * A set of events to not send to Amplitude.
 */
const DISABLED_EVENTS = { [Events.GraphQLRequest]: true };

/**
 * A helper function to safely use the amplitude client instance.
 * @param {(amplitude: AmplitudeClient) => void} fn A function to invoke with the amplitude client.
 */

interface UserProperties {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  utmContent?: string;
  organizationId?: string;
  organizationSlug?: string;
  groupId?: string;
  groupName?: string;
}

const withAmplitude = <T>(fn: (client: typeof amplitude) => T): T | void => {
  const instance = AMPLITUDE_API_KEY && amplitude;

  if (instance) {
    return fn(instance);
  }
};

// const onExitPage = () =>
//   withAmplitude(amplitude => {
//     amplitude.track(Events.PageExit, {
//       // Normally we would be using react-router's useLocation hook
//       // but this needs to be usable in any location, so we've gotta
//       // fallback to the window property.
//       // Please don't copy this! Use useLocation instead!
//       path: window.location.pathname
//     });
//   });

const initialize = (settings: { userId: string }) =>
  withAmplitude(async amplitude => {
    amplitude.setUserId(settings.userId);

    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 0.25
    });
    // returns a promise
    await amplitude.add(sessionReplayTracking).promise;
  });

const setUserProperties = (
  userId: string | null,
  userProperties: UserProperties
) =>
  withAmplitude(amplitude => {
    const identify = new amplitude.Identify();

    // only set the property if it's defined
    const safeSet = (key: string, value: string | undefined, once = false) => {
      if (value) {
        identify[once ? 'setOnce' : 'set'](key, value);
      }
    };

    safeSet('initial_utm_source', userProperties.utmSource, true);
    safeSet('initial_utm_medium', userProperties.utmMedium, true);
    safeSet('initial_utm_campaign', userProperties.utmCampaign, true);
    safeSet('initial_utm_term', userProperties.utmTerm, true);
    safeSet('initial_utm_content', userProperties.utmContent, true);

    // must be snake case to match amplitude
    safeSet('utm_source', userProperties.utmSource);
    safeSet('utm_medium', userProperties.utmMedium);
    safeSet('utm_campaign', userProperties.utmCampaign);
    safeSet('utm_term', userProperties.utmTerm);
    safeSet('utm_content', userProperties.utmContent);

    safeSet('organizationId', userProperties.organizationId);
    safeSet('organizationSlug', userProperties.organizationSlug);
    safeSet('groupId', userProperties.groupId);
    safeSet('groupName', userProperties.groupName);

    return amplitude.identify(identify).promise;
  });

const setGroup = (groupType: string, groupName: string) => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  withAmplitude(amplitude => {
    return amplitude.setGroup(groupType, groupName).promise;
  });
};

const preInit = async (userProperties: UserProperties) =>
  withAmplitude(async amplitude => {
    await amplitude.init(AMPLITUDE_API_KEY, {
      defaultTracking: {
        sessions: true
      },
      autocapture: {
        attribution: false
      }
    }).promise;

    await setUserProperties(null, userProperties);
  });

const logEvent = (event: string, eventProperties: Record<string, any>) =>
  withAmplitude(amplitude => {
    if (!DISABLED_EVENTS[event as keyof typeof DISABLED_EVENTS]) {
      amplitude.track(event, eventProperties);
    }
  });

// Change these with care, they are consumed by the backend to correlate
// instrumentation events.
const AMPLITUDE_SESSION_ID_HEADER_VALUE_NAME = 'amplitudeSessionId';
const AMPLITUDE_DEVICE_ID_HEADER_VALUE_NAME = 'amplitudeDeviceId';

const getContextHeaderMap = () =>
  withAmplitude(amplitude => ({
    [AMPLITUDE_SESSION_ID_HEADER_VALUE_NAME]: amplitude.getSessionId(),
    [AMPLITUDE_DEVICE_ID_HEADER_VALUE_NAME]: amplitude.getDeviceId()
  }));

export default {
  preInit,
  initialize,
  setUserProperties,
  logEvent,
  getContextHeaderMap,
  setGroup
};
