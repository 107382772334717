import { isString } from 'lodash';
import { formatPriceWithCents } from 'src/common/numbers';

export const formatPromoPrice = (
  promoAmount: number | string,
  spendAmount: number
): string => {
  const promoPriceConverted = isString(promoAmount)
    ? parseInt(promoAmount, 10)
    : promoAmount;

  const remainingSpend = spendAmount - promoPriceConverted;

  const priceDisplay =
    remainingSpend < 0.5 && remainingSpend !== 0
      ? Math.ceil(promoPriceConverted)
      : promoPriceConverted;
  return formatPriceWithCents(priceDisplay);
};
