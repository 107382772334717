import { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { useSnackbar } from 'notistack';
import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import {
  Paper,
  Typography,
  Button,
  CircularProgress,
  Grid
} from '@mui/material';

import PageTitle from 'src/components/PageTitle/PageTitle';
import { DynamicForm } from 'src/components/ReduxForm';
import { formatDate, dayjs } from 'src/common/dates';

import { createPromotion } from './mutations';
import { isPromoCodeTaken } from './queries';
import { getPromoInputs } from './constants';

const PREFIX = 'PromotionCreate';

const classes = {
  content: `${PREFIX}-content`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.content}`]: {
    padding: theme.spacing(2)
  }
}));

const AdminPromotionCreate = ({
  client,
  handleSubmit,
  submitting,
  invalid,
  createPromotion,
  history
}) => {
  const title = t('adminPromotions:create.title');
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(async data => {
    const cleanData = {
      ...data,
      startDate: formatDate({
        date: dayjs.utc(data.startDate),
        format: 'YYYY-MM-DD'
      }),
      endDate: formatDate({
        date: dayjs.utc(data.endDate),
        format: 'YYYY-MM-DD'
      })
    };

    if (cleanData?.platformFeeCalculation) {
      cleanData.platformFeeCalculationType = cleanData?.platformFeeCalculation;
      delete cleanData.platformFeeCalculation;
    }

    try {
      const taken = await client.query({
        query: isPromoCodeTaken,
        variables: {
          promoCode: data.promoCode
        },
        fetchPolicy: 'no-cache'
      });

      if (taken?.data?.isPromoCodeTaken === true) {
        enqueueSnackbar(
          t('adminPromotions:create.errorTaken', {
            promoCode: data.promoCode
          }),
          {
            variant: 'error'
          }
        );
        return;
      }

      await createPromotion({
        variables: { createRequest: cleanData }
      });
    } catch (error) {
      enqueueSnackbar(
        t('adminPromotions:create.errorDescription', {
          promoCode: data.promoCode,
          error: error.toString()
        }),
        {
          variant: 'error'
        }
      );
      return;
    }

    enqueueSnackbar(
      t('adminPromotions:create.successDescription', {
        promoCode: data.promoCode
      }),
      {
        variant: 'success'
      }
    );

    history.push({
      pathname: generateLinkPath(paths.admin.settings.promotions)
    });
  }, []);

  const inputs = getPromoInputs();

  return (
    <Root>
      <PageTitle subPageTitle={title} />
      <Typography variant="h5">{title}</Typography>
      <Typography variant="subtitle2">
        <Trans i18nKey="adminPromotions:create.subtitle">
          Create a new promotion
        </Trans>
      </Typography>
      <Paper className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <form
              autoComplete="off"
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <DynamicForm disabled={false} inputs={inputs} />
              <br />
              <Button
                color="primary"
                disabled={submitting || invalid}
                endIcon={submitting && <CircularProgress size={15} />}
                variant="contained"
                type="submit"
              >
                <Trans i18nKey="adminPromotions:create.submit">
                  Create Promo
                </Trans>
              </Button>
            </form>
          </Grid>
        </Grid>
      </Paper>
    </Root>
  );
};

const getInitialValues = () => {
  const inputs = getPromoInputs();

  const initialValues = inputs.reduce((accum, current) => {
    const updated = accum;
    updated[current.name] = current.initialValue;
    return updated;
  }, {});

  return initialValues;
};

export default flow(
  reduxForm({
    enableReinitialize: true,
    form: 'promotionForm',
    initialValues: getInitialValues()
  }),
  graphql(createPromotion, {
    name: 'createPromotion'
  }),
  withRouter,
  withApollo
)(AdminPromotionCreate);
