import { useCallback } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { flow, pick } from 'lodash';
import { t } from 'i18next';

import { Grid, Button } from '@mui/material';

import { DynamicForm } from 'src/components/ReduxForm';
import { useSnackbar } from 'notistack';

import { updatePricingPlan } from '../mutations';
import { updatePricingConfigInputs, FORM_NAME_UPDATE_PLAN } from '../constants';

const UpdatePricingForm = props => {
  const { refetch, dirty, invalid, formValues, updatePricingPlanMutation } =
    props;

  const { enqueueSnackbar } = useSnackbar();

  async function updatePricingPlan(formValues) {
    const mutationParams = pick(formValues, [
      'id',
      'name',
      'description',
      'isActive'
    ]);

    try {
      await updatePricingPlanMutation({
        variables: {
          Input: {
            ...mutationParams
          }
        }
      });
      enqueueSnackbar(t('admin:updatePricingPlan.successSnack'), {
        variant: 'success'
      });

      refetch();
    } catch (error) {
      enqueueSnackbar(t('admin:updatePricingPlan.errorSnack'), {
        variant: 'error'
      });
    }
  }

  const onSubmit = useCallback(async formValues => {
    updatePricingPlan(formValues);
  }, []);

  return (
    <>
      <Grid item xs={12} md={6}>
        <form autoComplete="off" data-cy="edit-pricing-plan-form">
          <DynamicForm inputs={updatePricingConfigInputs} />
        </form>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mb: 3, display: 'flex', justifyContent: 'flex-start' }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={!dirty || invalid}
          onClick={() => {
            onSubmit(formValues);
          }}
          data-cy="edit-pricing-plan-button"
        >
          {t('admin:updatePricingPlan.update')}
        </Button>
      </Grid>
    </>
  );
};

function mapStateToProps(state, ownProps) {
  const { pricingPlan } = ownProps;

  const initialValues = pricingPlan;
  const formValues = getFormValues(FORM_NAME_UPDATE_PLAN)(state);

  return { initialValues, formValues };
}

export default flow(
  reduxForm({
    form: FORM_NAME_UPDATE_PLAN,
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  graphql(updatePricingPlan, {
    name: 'updatePricingPlanMutation'
  }),
  connect(mapStateToProps)
)(UpdatePricingForm);
