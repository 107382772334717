import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { isEmpty, flow } from 'lodash';
import { t } from 'i18next';

import { Paper, Typography } from '@mui/material';
import PageviewIcon from '@mui/icons-material/Pageview';

import withTheme from '@mui/styles/withTheme';
import { paths } from 'src/routes/paths';

import Table from 'src/components/Table';
import { generateLinkPath } from 'src/routes/RouteUtil';
import IconLink from 'src/components/Table/IconLink';

import {
  statusTextMap,
  getStatusColorMap,
  UNASSOCIATED_KEY
} from '../Constants';

const PREFIX = 'BlueprintTableView';

const classes = {
  architecture: `${PREFIX}-architecture`,
  architectureHeading: `${PREFIX}-architectureHeading`,
  emptyBlueprints: `${PREFIX}-emptyBlueprints`,
  cellContainer: `${PREFIX}-cellContainer`,
  statusDot: `${PREFIX}-statusDot`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.architecture}`]: {
    marginBottom: theme.spacing(2)
  },

  [`& .${classes.architectureHeading}`]: {
    fontSize: '18px',
    padding: `${theme.spacing(1)} 0`
  },

  [`& .${classes.emptyBlueprints}`]: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },

  [`&.${classes.cellContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  [`& .${classes.statusDot}`]: {
    height: '10px',
    width: '10px',
    borderRadius: '100%',
    background: theme.palette.error.main,
    marginLeft: '10px'
  }
}));

const getColumnSchema = (classes, statusColorMap) => {
  const text = {
    viewBlueprint: t('admin:blueprintTableView.view'),
    name: t('admin:blueprintTableView.name'),
    productId: t('admin:blueprintTableView.productId'),
    available: t('admin:blueprintTableView.available'),
    status: t('admin:blueprintTableView.status'),
    notAvailable: t('admin:blueprintTableView.notAvailable')
  };

  return [
    {
      key: 'view',
      CellComponent: ({ row }) => {
        const { productId } = row;
        const linkPath = generateLinkPath(paths.admin.blueprintBuilder, {
          productId
        });

        return (
          <IconLink
            title={text.viewBlueprint}
            icon={PageviewIcon}
            to={linkPath}
          />
        );
      }
    },
    {
      columnName: text.name,
      accessor: 'document.name',
      key: 'name',
      type: 'String'
    },
    {
      columnName: text.productId,
      accessor: 'productId',
      key: 'productId',
      type: 'String'
    },
    {
      columnName: text.available,
      accessor: 'document.available',
      key: 'available',
      CellComponent: ({ data }) => (
        <Root className={classes.cellContainer}>
          {data ? text.available : text.notAvailable}
        </Root>
      )
    },
    {
      columnName: text.status,
      accessor: 'status',
      key: 'status',
      type: 'String',
      CellComponent: ({ data }) => {
        const statusMap = statusTextMap;
        return (
          <div className={classes.cellContainer}>
            {statusMap[data] || statusMap.unknown}
            <div
              className={classes.statusDot}
              style={{ background: statusColorMap[data] }}
            />
          </div>
        );
      }
    }
  ];
};

const BlueprintTableView = props => {
  const { architectures, productsByArch, handleRowClick, theme } = props;
  const statusColorMap = useMemo(() => getStatusColorMap(theme), []);
  const columnSchema = useMemo(
    () => getColumnSchema(classes, statusColorMap),
    [classes, statusColorMap]
  );

  return (
    <div>
      {architectures.map(architecture => {
        const products = [
          ...(productsByArch[architecture.id] || []),
          ...(productsByArch[architecture.slug] || [])
        ];
        return (
          <div key={architecture.id}>
            <Typography variant="h6" className={classes.architectureHeading}>
              {architecture.name} - {architecture.id}
            </Typography>
            <Paper className={classes.architecture}>
              {isEmpty(products) ? (
                <div className={classes.emptyBlueprints}>
                  Architecture "{architecture.name}" has no Blueprints
                </div>
              ) : (
                <Table
                  rows={products}
                  columnSchema={columnSchema}
                  loading={false}
                  onClickBodyRow={handleRowClick}
                />
              )}
            </Paper>
          </div>
        );
      })}
      {/* if we have products with no architecture list them here: */}
      {productsByArch[UNASSOCIATED_KEY] && (
        <>
          <Typography variant="h6" className={classes.architectureHeading}>
            Unassociated Products (no Architecture)
          </Typography>
          <Paper className={classes.architecture}>
            <Table
              rows={productsByArch[UNASSOCIATED_KEY]}
              columnSchema={columnSchema}
              loading={false}
              onClickBodyRow={handleRowClick}
            />
          </Paper>
        </>
      )}
    </div>
  );
};

export default flow(withTheme)(BlueprintTableView);
