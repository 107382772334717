import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { Button, Box } from '@mui/material';

import { useAppSettings } from 'src/AppSettings';
import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';
import { GenericPlaceholder } from 'src/components/Placeholders';

import Authenticating from './Authenticating';

const authRegex = /access_token/;

const FourZeroFour = () => {
  const appSettings = useAppSettings();
  // Note: Auth0 sends the user back to /#access_token=xxxxxx and /#/access_toeken=xxxxxx
  //       so we have to catch those routes here and display authenticating.
  let isAuthRoute = false;
  const fullPath = `${window.location}`;

  if (fullPath.match(authRegex) !== null) {
    isAuthRoute = true;
  }

  if (isAuthRoute) {
    return <Authenticating />;
  }

  const dashboardName =
    appSettings?.app?.strings?.dashboardName ||
    t('errorPage:buttons.dashboard');

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1
      }}
    >
      <GenericPlaceholder
        title={t('errorPage:pageNotFound.title')}
        subtitle={t('errorPage:pageNotFound.subtitle', { dashboardName })}
        variant="pageNotFound"
        buttons={[
          <Button
            key="go-to-dashboard"
            color="primary"
            component={Link}
            to={generateLinkPath(paths.dashboard.base)}
            variant="outlined"
          >
            {t('errorPage:buttons.goToDashboard', { dashboardName })}
          </Button>,
          <Button
            key="contact-support"
            href="mailto:support@evocalize.com?subject=Site Error"
            variant="outlined"
          >
            {t('errorPage:buttons.contactSupport')}
          </Button>
        ]}
      />
    </Box>
  );
};

export default FourZeroFour;
