import { useTheme } from '@mui/material/styles';

import { getStatusColorMlp, getMlpParentStatusText } from './Constants';

import StatusDisplay from './StatusDisplay';

interface MlpStatusProps {
  status: string;
  hideHelpTooltip?: boolean;
  renderTextStatus?: boolean;
  tableView?: boolean;
  isAutomation?: boolean;
}

const MlpStatus = ({
  status,
  hideHelpTooltip = false,
  renderTextStatus = false,
  tableView = false
}: MlpStatusProps) => {
  const theme = useTheme();

  const text = getMlpParentStatusText(status);
  let statusColor = '#000000';

  statusColor = getStatusColorMlp({
    orderStatus: status,
    theme
  });

  return (
    <StatusDisplay
      text={text}
      statusColor={statusColor}
      hideHelpTooltip={hideHelpTooltip}
      renderTextStatus={renderTextStatus}
      tableView={tableView}
    />
  );
};

export default MlpStatus;
