import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import { t } from 'i18next';

import { Alert, AlertTitle } from '@mui/material';

import WarningIcon from '@mui/icons-material/Warning';

import { RESOURCE_LIB_REGEX, FB_ACCOUNT_REGEX } from './Constants';

const PREFIX = 'BlueprintBuilderWarnings';

const classes = {
  resourceWarning: `${PREFIX}-resourceWarning`,
  resourceWarningInline: `${PREFIX}-resourceWarningInline`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.resourceWarning}`]: {
    marginBottom: theme.spacing(2)
  },

  [`& .${classes.resourceWarningInline}`]: {
    marginBottom: theme.spacing(2)
  }
}));

const pageText = () => ({
  resourceLibraryWarning: t('admin:blueprintBuilder.warnings.resourceLibrary'),
  resourceLibraryFacebookWarning: t(
    'admin:blueprintBuilder.warnings.resourceLibraryFacebookAccount'
  ),
  example: t('admin:blueprintBuilder.warnings.example')
});

const BlueprintBuilderWarnings = props => {
  const { formValues } = props;

  const text = useMemo(() => pageText(), []);

  const stringFormValues = JSON.stringify(formValues || '');

  const facebookAccountMatches = stringFormValues.match(FB_ACCOUNT_REGEX) || [];
  const hasFacebookAccounts = !isEmpty(facebookAccountMatches);

  const resourceLibraryVariableMatches =
    stringFormValues.match(RESOURCE_LIB_REGEX) || [];

  return (
    <Root>
      {(isEmpty(resourceLibraryVariableMatches) || hasFacebookAccounts) && (
        <Alert
          severity="warning"
          icon={<WarningIcon />}
          className={classes.resourceWarning}
        >
          <AlertTitle>
            {hasFacebookAccounts
              ? text.resourceLibraryFacebookWarning
              : text.resourceLibraryWarning}
          </AlertTitle>
          <br />
          {text.example}:
          <ul>
            <li>
              <code>{`{{ RESOURCE_ITEM 'collection type' 'collection key' 'item key' }}`}</code>
            </li>
          </ul>
        </Alert>
      )}
    </Root>
  );
};

export default BlueprintBuilderWarnings;
