import { isEmpty, get, head } from 'lodash';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip
} from '@mui/material';

import HelpIcon from '@mui/icons-material/HelpOutline';

import { HelperTextFooter } from 'src/components/ReduxForm/HelperTextFooter';
import { ErrorFooter } from 'src/components/ReduxForm/ErrorFooter';
import { translateMaps } from 'src/common/templateTranslator';

const PREFIX = 'RenderDynamicRadioGroup';

const classes = {
  container: `${PREFIX}-container`,
  labelRoot: `${PREFIX}-labelRoot`,
  subLabel: `${PREFIX}-subLabel`,
  radio: `${PREFIX}-radio`,
  errorText: `${PREFIX}-errorText`,
  helperText: `${PREFIX}-helperText`,
  groupLabel: `${PREFIX}-groupLabel`,
  helpIcon: `${PREFIX}-helpIcon`
};

const getLabelRowStyles = theme => {
  return {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(4)
    },
    '&:last-of-type': {
      marginBottom: theme.spacing(1)
    }
  };
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${classes.container}`]: {
    width: '100%'
  },

  [`& .${classes.labelRoot}`]: props => ({
    display: 'flex',
    ...(props.displayAsRow && getLabelRowStyles(theme))
  }),

  [`& .${classes.subLabel}`]: {
    color: theme.palette.grey[700],
    fontSize: '0.8rem',
    overflow: 'hidden',
    width: '100%',
    wordBreak: 'break-word'
  },

  [`& .${classes.radio}`]: {
    alignSelf: 'start',
    paddingTop: '5px'
  },

  [`& .${classes.errorText}`]: {
    marginLeft: '0'
  },

  [`& .${classes.helperText}`]: {
    margin: '0'
  },

  [`& .${classes.groupLabel}`]: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '4px',
    width: '100%'
  },

  [`& .${classes.helpIcon}`]: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1),
    width: '20px'
  }
}));

const RenderDynamicRadioGroup = props => {
  const {
    businessObjects,
    displayName,
    input,
    meta: { error, touched },
    options,
    helperText,
    tooltip,
    userMetadataFields,
    readOnly = false,
    displayAsRow
  } = props;

  // console.log(rest, 'rest dynradgroup');
  const value = input.value.toString();

  const exampleString = t('common:radioGroup.messages.example');

  const inputInError = touched && !!error;

  return (
    <StyledFormControl className={classes.container}>
      <FormLabel error={touched && !!error} className={classes.groupLabel}>
        {displayName}
        {tooltip && (
          <Tooltip arrow title={tooltip}>
            <HelpIcon className={classes.helpIcon} />
          </Tooltip>
        )}
      </FormLabel>
      <RadioGroup
        name={input.name}
        onChange={(event, value) => input.onChange(value)}
        value={value}
        row={displayAsRow}
      >
        {options.map(option => {
          const value = get(option, 'value');
          const renderValue = get(option, 'renderValue');
          let label = get(option, 'label');

          // If fieldName exists && we have BOs that means we have extra
          // data to display on the label.
          if (renderValue && !isEmpty(businessObjects)) {
            // combine BOs and userMetadataFields so we can use userMetadata in the input
            let translatedValue = translateMaps(value, {
              ...head(businessObjects),
              ...userMetadataFields
            });

            // If there's more than one businessObject, add "ex: " so the user
            // knows it's an example of one translation.
            if (businessObjects.length > 1) {
              translatedValue = `${exampleString}: ${translatedValue}`;
            }

            label = (
              <>
                <span>{`${label}`}</span>
                <br />
                <span className={classes.subLabel}>{translatedValue}</span>
              </>
            );
          }

          return (
            <FormControlLabel
              classes={{
                root: classes.labelRoot
              }}
              control={<Radio className={classes.radio} disabled={readOnly} />}
              key={`${value}`}
              label={label}
              value={value}
            />
          );
        })}
      </RadioGroup>
      {inputInError && <ErrorFooter touched={touched} error={error} />}
      {helperText && (
        <HelperTextFooter helperText={helperText} stacked={inputInError} />
      )}
    </StyledFormControl>
  );
};

export default RenderDynamicRadioGroup;
