import React from 'react';
import { noop } from 'lodash';
import { AssetType } from 'src/generated/gql/graphql';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Grid } from '@mui/material';

import TileWrapper from './Tiles/TileWrapper';
import { GALLERY_TYPE } from './constants';

type GalleryModalMediaListItemProps = {
  id: string;
  item: AssetType;
  galleryType: string;
  selectionType: string;
};

const GalleryModalMediaListItem = ({
  item,
  galleryType,
  selectionType,
  id
}: GalleryModalMediaListItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: 'none',
    aspectRatio: '1/1',
    cursor: 'grab',
    '&:active:hover': {
      cursor: 'grabbing'
    }
  };

  return (
    <Grid
      item
      xs={4}
      md={3}
      ref={setNodeRef}
      sx={{
        ...style
      }}
      {...attributes}
      {...listeners}
    >
      <TileWrapper
        asset={item}
        galleryType={galleryType}
        onClick={noop}
        type={selectionType}
        showThumbnail={galleryType === GALLERY_TYPE.video}
      />
    </Grid>
  );
};

export default GalleryModalMediaListItem;
