import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { noop } from 'lodash';
import classNames from 'classnames';

import { t } from 'i18next';

import { IconButton, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const PREFIX = 'PaginationControls';

const classes = {
  paginationWrapper: `${PREFIX}-paginationWrapper`,
  paginationWrapperForIcons: `${PREFIX}-paginationWrapperForIcons`,
  nextPage: `${PREFIX}-nextPage`,
  bottomPadding: `${PREFIX}-bottomPadding`,
  topPadding: `${PREFIX}-topPadding`
};

const pageText = () => ({
  prevPage: t('common:pagination.previous'),
  nextPage: t('common:pagination.next')
});

const PaginationControlsIconMode = ({
  pageInfo,
  navigateNext,
  navigatePrev,
  nextText,
  prevText,
  isBottom,
  text
}) => {
  return (
    <div
      className={classNames(classes.paginationWrapperForIcons, {
        [classes.bottomPadding]: !isBottom,
        [classes.topPadding]: isBottom
      })}
    >
      <IconButton
        color="secondary"
        disabled={!pageInfo.hasPreviousPage}
        onClick={navigatePrev}
        aria-label={prevText || text.prevPage}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton
        color="secondary"
        className={classes.nextPage}
        disabled={!pageInfo.hasNextPage}
        onClick={navigateNext}
        aria-label={nextText || text.nextPage}
      >
        <NavigateNextIcon />
      </IconButton>
    </div>
  );
};

const StyledPaginationControlsIconMode = styled(PaginationControlsIconMode)(
  ({ theme }) => ({
    [`& .${classes.paginationWrapper}`]: {
      display: 'flex',
      justifyContent: 'center'
    },

    // Pagination wrapper specific for the icon mode
    [`& .${classes.paginationWrapperForIcons}`]: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },

    [`& .${classes.nextPage}`]: {
      marginLeft: theme.spacing(2)
    },

    [`& .${classes.bottomPadding}`]: {
      paddingBottom: theme.spacing(2)
    },

    [`& .${classes.topPadding}`]: {
      paddingTop: theme.spacing(2)
    }
  })
);

const PaginationControlsButtonMode = ({
  pageInfo,
  navigateNext,
  navigatePrev,
  nextText,
  prevText,
  isBottom,
  text
}) => {
  return (
    <div
      className={classNames(classes.paginationWrapper, {
        [classes.bottomPadding]: !isBottom,
        [classes.topPadding]: isBottom
      })}
    >
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<NavigateBeforeIcon />}
        disabled={!pageInfo.hasPreviousPage}
        onClick={navigatePrev}
      >
        {prevText || text.prevPage}
      </Button>

      <Button
        variant="outlined"
        color="secondary"
        endIcon={<NavigateNextIcon />}
        className={classes.nextPage}
        disabled={!pageInfo.hasNextPage}
        onClick={navigateNext}
      >
        {nextText || text.nextPage}
      </Button>
    </div>
  );
};

/**
 * Pagination controls for navigating paged content.
 *
 * Comes in two variants: button and icon
 *
 * Button is the default variant, which a lot of the old designs used.
 * Icon is the newer variant, which only has icons and no text.
 */
const PaginationControls = ({
  pageInfo = {},
  navigateNext = noop,
  navigatePrev = noop,
  nextText,
  prevText,
  isBottom = true,
  variant = 'button'
}) => {
  const text = useMemo(() => pageText(), []);

  if (variant === 'button') {
    return (
      <PaginationControlsButtonMode
        pageInfo={pageInfo}
        navigateNext={navigateNext}
        navigatePrev={navigatePrev}
        nextText={nextText || text.nextPage}
        prevText={prevText || text.prevPage}
        isBottom={isBottom}
        text={text}
      />
    );
  }

  if (variant === 'icon') {
    return (
      <StyledPaginationControlsIconMode
        pageInfo={pageInfo}
        navigateNext={navigateNext}
        navigatePrev={navigatePrev}
        nextText={nextText}
        prevText={prevText}
        isBottom={isBottom}
        text={text}
      />
    );
  }

  throw new Error(`Unknown pagination control variant: ${variant}`);
};

export default PaginationControls;
