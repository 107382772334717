import { useMemo } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import {
  Typography,
  Card,
  CardContent,
  Button,
  Tooltip,
  Divider
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

import { statusTextMap, getStatusColorMap } from '../Constants';

const PREFIX = 'GridViewCard';

const classes = {
  card: `${PREFIX}-card`,
  cardHeading: `${PREFIX}-cardHeading`,
  cardProductId: `${PREFIX}-cardProductId`,
  cardDescription: `${PREFIX}-cardDescription`,
  editButton: `${PREFIX}-editButton`,
  statusDot: `${PREFIX}-statusDot`
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.card}`]: {
    marginRight: theme.spacing(2),
    width: '275px',
    position: 'relative',
    minHeight: '275px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '&:last-child': {
      marginRight: '0px'
    }
  },

  [`& .${classes.cardHeading}`]: {
    fontSize: '18px',
    lineHeight: '25px',
    marginTop: theme.spacing(2)
  },

  [`& .${classes.cardProductId}`]: {
    fontSize: '14px',
    marginTop: theme.spacing(1)
  },

  [`& .${classes.cardDescription}`]: {
    fontSize: '14px',
    marginTop: theme.spacing(1)
  },

  [`& .${classes.editButton}`]: {
    width: '100%'
  },

  [`& .${classes.statusDot}`]: {
    height: '10px',
    width: '10px',
    borderRadius: '100%',
    background: theme.palette.error.main,
    marginLeft: '10px',
    display: 'block',
    position: 'absolute',
    right: '10px',
    top: '10px'
  }
}));

const GridViewCard = props => {
  const { product } = props;

  const theme = useTheme();

  const statusColorMap = useMemo(() => getStatusColorMap(theme), []);

  return (
    <StyledCard
      key={product.productId}
      className={classes.card}
      data-cy="grid-view-card"
    >
      <Tooltip
        title={
          statusTextMap[product.status]
            ? statusTextMap[product.status]
            : 'Unknown Status'
        }
      >
        <span
          className={classes.statusDot}
          style={{
            background: statusColorMap[product.status]
          }}
        />
      </Tooltip>
      <CardContent>
        <Typography component="h2" className={classes.cardHeading}>
          {product.document.name}
        </Typography>
        <Typography className={classes.cardProductId} color="textSecondary">
          {product.productId}
        </Typography>
        <Typography className={classes.cardDescription} component="p">
          {product.document.description}
        </Typography>
      </CardContent>
      <div>
        <Divider />
        <Button
          component={Link}
          to={generateLinkPath(paths.admin.blueprintBuilder, {
            productId: product.productId
          })}
          className={classes.editButton}
        >
          <EditIcon />
        </Button>
      </div>
    </StyledCard>
  );
};

export default GridViewCard;
