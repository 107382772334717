import { isString, isObject } from 'lodash';
import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';

const PREFIX = 'JsonCell';

const classes = {
  wrapper: `${PREFIX}-wrapper`
};

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .${classes.wrapper}`]: {
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '1rem',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}));

const objectToString = (obj, pretty = false) => {
  if (isObject(obj) && !isString(obj)) {
    try {
      return pretty ? JSON.stringify(obj, undefined, 2) : JSON.stringify(obj);
    } catch (error) {
      // it's not valid json, but we don't judge here
      return obj;
    }
  }
  // I'm not an object so hey why not try showing me
  return obj;
};

const JsonCell = ({ data, styleOverrides }) => {
  return (
    <StyledTooltip
      title={
        <>
          <pre>{objectToString(data, true)}</pre>
        </>
      }
    >
      <span className={classes.wrapper} style={{ ...styleOverrides }}>
        {objectToString(data)}
      </span>
    </StyledTooltip>
  );
};

export default JsonCell;
