import { t } from 'i18next';
import { pickBy } from 'lodash';

import {
  validateNotBlank,
  validateRequired,
  validateSlug
} from 'src/common/validations';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

/**
 * Builds a list of table column schema to display resource collections.
 * @returns A list of column schema information.
 */
export const getResourceCollectionTableColumnSchema = () => [
  {
    columnName: t('adminResourceLibrary:collections.table.key'),
    accessor: 'key',
    key: 'key',
    type: 'single_line_string'
  },
  {
    columnName: t('adminResourceLibrary:collections.table.description'),
    accessor: 'description',
    key: 'description',
    type: 'multi_line_string'
  }
];

/**
 * Builds a list of form inputs for creating or update a resource collection.
 * @param {boolean} isUpdate Indicates whether this is a create or update case.
 * @returns A list of resource collection create or update form inputs.
 */
export const getResourceCollectionFormInputs = isUpdate => [
  {
    name: 'id',
    displayName: 'ID',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: undefined,
    reduxValidations: [],
    isRequired: isUpdate,
    isHidden: true,
    disabled: true,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'type',
    displayName: 'Type',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: undefined,
    reduxValidations: [validateRequired, validateNotBlank(), validateSlug()],
    isRequired: true,
    isHidden: false,
    disabled: true,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'key',
    displayName: 'Key',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: undefined,
    reduxValidations: [validateRequired, validateNotBlank(), validateSlug()],
    isRequired: true,
    isHidden: false,
    disabled: isUpdate,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'description',
    displayName: 'Description',
    displayMethodId: INPUT_TYPES.MULTI_LINE_STRING,
    initialValue: undefined,
    reduxValidations: [validateRequired, validateNotBlank()],
    isRequired: true,
    isHidden: false,
    disabled: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  }
];

/**
 * Builds a list of table column schema to display resource items.
 * @returns A list of column schema information.
 */
export const getResourceItemTableColumnSchema = () => [
  {
    columnName: t('adminResourceLibrary:items.table.key'),
    accessor: 'key',
    key: 'key',
    type: 'single_line_string'
  },
  {
    columnName: t('adminResourceLibrary:items.table.value'),
    accessor: 'value',
    key: 'value',
    type: 'single_line_string'
  },
  {
    columnName: t('adminResourceLibrary:items.table.description'),
    accessor: 'description',
    key: 'description',
    type: 'multi_line_string'
  }
];

/**
 * Builds a list of form inputs for creating or update a resource item.
 * @param {boolean} isUpdate Indicates whether this is a create or update case.
 * @returns A list of resource item create or update form inputs.
 */
export const getResourceItemFormInputs = (isUpdate, isEditItemValuesOnly) => [
  {
    name: 'id',
    displayName: 'ID',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: undefined,
    reduxValidations: [],
    isRequired: isUpdate,
    isHidden: true,
    disabled: true,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'resourceCollectionId',
    displayName: 'Resource Collection Id',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: undefined,
    reduxValidations: [],
    isRequired: isUpdate,
    isHidden: true,
    disabled: true,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'key',
    displayName: 'Key',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: undefined,
    reduxValidations: [validateRequired, validateNotBlank(), validateSlug()],
    isRequired: true,
    isHidden: false,
    disabled: isUpdate,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'value',
    displayName: 'Value',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: undefined,
    reduxValidations: [validateRequired, validateNotBlank()],
    isRequired: true,
    isHidden: false,
    disabled: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'description',
    displayName: 'Description',
    displayMethodId: INPUT_TYPES.MULTI_LINE_STRING,
    initialValue: undefined,
    reduxValidations: [validateRequired, validateNotBlank()],
    isRequired: true,
    isHidden: false,
    disabled: isEditItemValuesOnly,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  }
];

/**
 * A helper to generate the input payload from a redux form for creating or
 * updating a resource item or collection.
 * @param {boolean} isUpdate Indicates whether this is a create or update case.
 * @param {object} formData The data from the form.
 * @param {object} initialValues The initial values for the form.
 * @returns An input payload for a create/update resource collection/item
 * request.
 */
export const buildCreateOrUpdateInput = (isUpdate, formData, initialValues) => {
  // For hidden/disabled fields, always use initialValues if formData has undefined
  const inputs = getResourceItemFormInputs(isUpdate);

  const processedData = {
    ...formData,
    // Ensure hidden/disabled fields use initialValues when undefined in formData
    ...inputs
      .filter(
        input =>
          (input.isHidden || input.disabled) &&
          formData[input.name] === undefined
      )
      .reduce(
        (acc, input) => ({
          ...acc,
          [input.name]: initialValues?.[input.name]
        }),
        {}
      )
  };

  if (isUpdate) {
    return {
      id: processedData.id,
      ...pickBy(processedData, (val, key) => val !== initialValues?.[key])
    };
  }

  return processedData;
};
