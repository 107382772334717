import { useState, useCallback, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { find, isObject, isString } from 'lodash';

import { TextField, IconButton, MenuItem } from '@mui/material';
import RemoveIcon from '@mui/icons-material/DeleteForever';

import RenderSubParameter from './RenderSubParameter';
import RenderSubParameterArray from './RenderSubParameterArray';

import {
  parametersWithObjectsMap,
  facetParametersWithArrayOfObjects,
  facetParameterTypes,
  facetParametersWithArrayOverrides,
  facetParametersWithJsonObject
} from '../../Constants';

const PREFIX = 'RenderParameterKeyValueInputs';

const classes = {
  container: `${PREFIX}-container`,
  leftHandInput: `${PREFIX}-leftHandInput`,
  colon: `${PREFIX}-colon`,
  valueInput: `${PREFIX}-valueInput`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    alignItems: 'center',
    display: 'flex',
    margin: `${theme.spacing(1)} 0`
  },

  [`& .${classes.leftHandInput}`]: {
    maxWidth: '250px',
    minWidth: '250px'
  },

  [`& .${classes.colon}`]: {
    margin: `0 ${theme.spacing(1)}`
  },

  [`& .${classes.valueInput}`]: {
    flexGrow: '1'
  }
}));

const getParameterType = ({ currentKey, facet, currentParameterMeta }) => {
  if (
    (currentParameterMeta?.isArray &&
      (currentParameterMeta?.type === facetParameterTypes.string ||
        currentParameterMeta?.type === facetParameterTypes.enum ||
        currentParameterMeta?.type === facetParameterTypes.enumUnderscore)) ||
    (facetParametersWithArrayOverrides[facet] &&
      facetParametersWithArrayOverrides[facet].keys.has(currentKey))
  ) {
    return 'array';
  }

  if (
    facetParametersWithArrayOfObjects[facet] &&
    facetParametersWithArrayOfObjects[facet].keys.has(currentKey)
  ) {
    return 'arrayOfObjects';
  }

  if (parametersWithObjectsMap.has(currentKey)) {
    return 'object';
  }

  if (
    facetParametersWithJsonObject[facet] &&
    facetParametersWithJsonObject[facet].keys.has(currentKey)
  ) {
    return 'jsonObject';
  }

  return 'string';
};

const stringToJson = val => {
  if (!isObject(val) && isString(val)) {
    try {
      return JSON.parse(val);
    } catch (error) {
      return val;
    }
  }
  return val;
};

const jsonToString = val => {
  if (isObject(val) && !isString(val)) {
    try {
      return JSON.stringify(val);
    } catch (error) {
      return val;
    }
  }
  return val;
};

const RenderParameterKeyValueInputs = ({
  onParameterValuesSet,
  parameterKey,
  parameterValue,
  parameterIndex,
  removeParameter,
  facet,
  disabled,
  parameterEnums,
  selectedParameters
}) => {
  const [currentKey, setCurrentKey] = useState(parameterKey);
  const [currentValue, setCurrentValue] = useState(parameterValue);

  useEffect(() => {
    setCurrentKey(parameterKey);
  }, [parameterKey]);

  useEffect(() => {
    setCurrentValue(parameterValue);
  }, [parameterValue]);

  const onKeyChange = useCallback(
    ({ target: { value } }) => {
      setCurrentKey(value);
    },
    [parameterKey, currentKey]
  );

  const onValueChange = useCallback(
    ({ target: { value } }) => {
      setCurrentValue(value);
    },
    [parameterValue, currentValue]
  );

  const onJsonValueChange = useCallback(
    ({ target: { value } }) => {
      setCurrentValue(stringToJson(value));
    },
    [parameterValue, currentValue]
  );

  const setParameters = () => {
    onParameterValuesSet({
      newKey: currentKey,
      newValue: currentValue,
      selectedIndex: parameterIndex
    });
  };

  const setSubParameters = newValue => {
    onParameterValuesSet({
      newKey: currentKey,
      newValue,
      selectedIndex: parameterIndex
    });
  };

  const currentParameterMeta = find(selectedParameters, { name: currentKey });
  const subParameterType = getParameterType({
    currentKey,
    facet,
    currentParameterMeta
  });

  const getSubParameterInput = () => {
    let input;

    switch (subParameterType) {
      case 'object':
      case 'arrayOfObjects':
        input = (
          <RenderSubParameter
            disabled={disabled}
            parameterValue={currentValue}
            parameterKey={currentKey}
            onSubValueChange={setSubParameters}
            type={subParameterType}
          />
        );
        break;
      case 'array':
        input = (
          <RenderSubParameterArray
            disabled={disabled}
            parameterValue={currentValue}
            onSubValueChange={setSubParameters}
            currentKey={currentKey}
            onBlur={setParameters}
          />
        );
        break;
      case 'jsonObject':
        input = (
          <TextField
            disabled={disabled}
            variant="outlined"
            value={jsonToString(currentValue)}
            onChange={onJsonValueChange}
            onBlur={setParameters}
            className={classes.valueInput}
          />
        );
        break;
      default:
        // string
        input = (
          <TextField
            disabled={disabled}
            variant="outlined"
            value={currentValue}
            onChange={onValueChange}
            onBlur={setParameters}
            className={classes.valueInput}
          />
        );
        break;
    }
    return input;
  };

  return (
    <Root className={classes.container}>
      <TextField
        className={classes.leftHandInput}
        disabled
        variant="outlined"
        value={currentKey}
        onChange={onKeyChange}
        onBlur={setParameters}
        select
      >
        {parameterEnums.map(option => {
          return (
            <MenuItem key={option?.value?.name} value={option?.value?.name}>
              {option.name}
            </MenuItem>
          );
        })}
      </TextField>
      <span className={classes.colon}>{' : '}</span>
      {getSubParameterInput()}
      <IconButton
        disabled={disabled}
        onClick={() => {
          removeParameter(parameterIndex);
        }}
        size="large"
      >
        <RemoveIcon />
      </IconButton>
    </Root>
  );
};

export default RenderParameterKeyValueInputs;
