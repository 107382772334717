import { forwardRef } from 'react';
import { isFunction } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import validator from 'validator';

import { Box, Link as MuiLink, useTheme } from '@mui/material';

// Note: We need this component b/c there are situations where we have
//       mixed internal and external links like in the case of menu items.
const Link = forwardRef(
  ({ to, refresh, children, sx, className, target }, ref) => {
    const theme = useTheme();
    let sxStyles = sx;

    if (isFunction(sx)) {
      sxStyles = sx(theme);
    }

    const sharedProps = {
      sx: sxStyles,
      className,
      target
    };

    if (!to) {
      // it's a simple element with nothing to link to
      return (
        <Box component="span" {...sharedProps}>
          {children}
        </Box>
      );
    }

    // Note: to url needs to have http:// || https://
    if (validator.isURL(to)) {
      // it's intended to be an external link
      // default for external links will be target="_blank"
      if (!sharedProps.target) {
        sharedProps.target = '_blank';
      }

      return (
        <Box
          component="a"
          href={to}
          rel={sharedProps.target === '_blank' ? 'noopener noreferrer' : ''}
          {...sharedProps}
        >
          {children}
        </Box>
      );
    }

    if (refresh) {
      // assuming the condition above filters out full urls
      const cleanTo = to[0] !== '/' ? `/${to}` : to;
      return (
        <MuiLink
          href={`#${cleanTo}`}
          onClick={e => {
            e.preventDefault();
            window.location.hash = cleanTo;
            window.location.reload();
          }}
          {...sharedProps}
          ref={ref}
        >
          {children}
        </MuiLink>
      );
    }

    // it's an internal link
    return (
      <RouterLink
        to={to}
        component={props => {
          const { navigate, ...other } = props; // Remove invalid props passed from RouterLink
          return <MuiLink {...other} ref={ref} />;
        }}
        {...sharedProps}
      >
        {children}
      </RouterLink>
    );
  }
);

export default Link;
