import { Chip, Typography } from '@mui/material';

type ChipTextProps = {
  text: string;
};

const ChipText = ({ text }: ChipTextProps) => {
  const renderTextWithChips = (inputText: string) => {
    const parts = inputText.split(/(\{.*?\})/); // Split by placeholders

    return parts.map(part => {
      if (part.startsWith('{') && part.endsWith('}')) {
        const content = part.slice(1, -1); // Remove surrounding {}
        const [label, chipText] = content.split(':'); // Split at `:` if it exists for things like {KeyWord:Secure Your VA Loan Today}
        const chipLabel = chipText?.trim() || label; // Use the part after `:` if available

        return (
          <Chip key={`chip-${chipLabel}`} label={chipLabel} size="small" />
        );
      }

      return (
        <Typography key={`text-${part}`} component="span">
          {part}
        </Typography>
      );
    });
  };

  return <div>{renderTextWithChips(text)}</div>;
};

export default ChipText;
