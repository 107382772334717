import { flow } from 'lodash';

import { styled } from '@mui/material/styles';

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const PREFIX = 'ResourceItemTypeSelector';

const classes = {
  formContainer: `${PREFIX}-formContainer`
};

const StyledFormControl = styled(FormControl)(() => ({
  [`&.${classes.formContainer}`]: {
    width: '455px'
  }
}));

const ResourceItemTypeSelector = ({
  setResourceItemType,
  selectedResourceItemType,
  resourceItemTypes
}) => {
  const handleChange = event => {
    setResourceItemType(event.target.value);
  };

  return (
    <StyledFormControl variant="outlined" className={classes.formContainer}>
      <InputLabel id="resource-item-type-selector-label">
        Resource Item Type
      </InputLabel>
      <Select
        labelId="resource-item-type-selector-label"
        id="resource-item-type-selector"
        value={selectedResourceItemType}
        label="Resource Item Type"
        onChange={handleChange}
      >
        {resourceItemTypes.map(resourceItemtype => (
          <MenuItem value={resourceItemtype.slug} key={resourceItemtype.slug}>
            {resourceItemtype.friendlyName}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export default flow()(ResourceItemTypeSelector);
