import { Box } from '@mui/system';

import PreviewTopBar from 'src/images/tiktok/tt-preview-topbar.svg';
import PreviewPlus from 'src/images/tiktok/tt-preview-plus.png';
import ShareIcon from 'src/images/tiktok/tt-share-icon.svg';
import CommentIcon from 'src/images/tiktok/vi-comment.svg';
import WhiteHeartIcon from 'src/images/tiktok/vi-white-heart.svg';
import MusicIcon from 'src/images/tiktok/tt-music-tag.svg';
import TiktokMusic from 'src/images/tiktok/tiktok-music.png';
import PreviewNavBar from 'src/images/tiktok/tt-preview-nav-bar.jpeg';
import TiktokVideoPlayer from './TiktokVideoPlayer';
import { getPreviewWidth } from '../helpers';

export const TIKTOK_PREVIEW_WIDTH = 260;

export const getTikTokAdPreviewStyles = ({ vars }) => ({
  container: {
    width: vars.isResponsive ? '100%' : '400px',
    display: 'flex',
    justifyContent: 'center'
  },
  previewContent: {
    width: getPreviewWidth(
      vars.hasUniformPreviewWidth,
      TIKTOK_PREVIEW_WIDTH,
      vars.isResponsive
    ),
    height: '460px',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '12px',
    boxSizing: 'content-box',
    backgroundColor: '#111213',
    fontSize: '10px',
    color: 'white'
  },

  videoContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '0 0'
  },
  topBanner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px'
  },
  rightBar: {
    position: 'absolute',
    top: '163px',
    right: '6px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  avatar: {
    width: '38px',
    height: '38px',
    overflow: 'hidden',
    border: '1px solid #fff',
    borderRadius: '100%'
  },
  avatarImg: {
    width: '100%',
    height: '100%'
  },
  plusIcon: {
    position: 'absolute',
    top: '32px',
    width: '16px',
    height: '16px'
  },
  engagementIcon: {
    height: '24px',
    width: '24px'
  },
  shareIconSize: {
    height: '16px',
    width: '24px'
  },
  infoContainer: {
    position: 'absolute',
    bottom: '42px',
    left: '12px',
    width: '190px',
    fontSize: '12px'
  },
  infoLabelContainer: {
    position: 'relative',
    display: 'box',
    margin: '6px 12px 6px 0',
    overflow: 'hidden',
    lineHeight: '14px',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    lineClamp: 2,
    boxOrient: 'vertical'
  },
  adIcon: {
    display: 'inline-block',
    marginLeft: '2px',
    padding: '2px 4px',
    color: 'rgba(0,0,0,.75)',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '14px',
    background: 'rgba(255,255,255,.75)',
    borderRadius: '3px',
    transform: 'scale(.5)',
    transformOrigin: 'left'
  },
  musicIcon: {
    width: '10px',
    height: '10px',
    marginRight: '4px'
  },
  callToAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '190px',
    height: '24px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    background: '#3a3a3a',
    borderRadius: '4px',
    opacity: '.5'
  },
  tiktokMusic: {
    position: 'absolute',
    right: '6px',
    bottom: '35px'
  },
  navBarContainer: {
    width: '100%',
    left: 0,
    bottom: 0,
    position: 'absolute',
    lineHeight: 0
  },
  navBarImage: {
    height: '100%',
    width: '100%'
  },
  infoOverlay: {
    position: 'absolute',
    inset: 0,
    zIndex: 100,
    pointerEvents: 'none'
  }
});

const TiktokAdPreview = props => {
  const { adData, hasUniformPreviewWidth, isResponsive } = props;

  const sxStyles = getTikTokAdPreviewStyles({
    vars: { hasUniformPreviewWidth, isResponsive }
  });

  return (
    <Box className="notranslate" sx={sxStyles.container}>
      <Box sx={sxStyles.previewContent}>
        <Box sx={sxStyles.videoContainer}>
          <TiktokVideoPlayer
            url={adData?.videoUrl}
            poster={adData?.coverImageUrl}
            width={getPreviewWidth(
              hasUniformPreviewWidth,
              TIKTOK_PREVIEW_WIDTH,
              isResponsive
            )}
            height={462}
          />
        </Box>
        <Box sx={sxStyles.infoOverlay}>
          <Box sx={sxStyles.topBanner}>
            <Box component="img" src={PreviewTopBar} alt="Preview Top Bar" />
          </Box>
          <Box sx={sxStyles.rightBar}>
            <Box sx={sxStyles.avatar}>
              <Box
                component="img"
                sx={sxStyles.avatarImg}
                src={adData?.identityImageUrl}
                alt="Identity Profile"
              />
            </Box>
            <Box
              component="img"
              src={PreviewPlus}
              alt="Follow"
              sx={sxStyles.plusIcon}
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2.5}
              alignItems="center"
            >
              <Box
                component="img"
                src={WhiteHeartIcon}
                alt="Like"
                sx={sxStyles.engagementIcon}
              />
              <span>71.1k</span>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2}
              alignItems="center"
            >
              <Box
                component="img"
                src={CommentIcon}
                alt="Comment"
                sx={sxStyles.engagementIcon}
              />
              <span>1281</span>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2}
              alignItems="center"
            >
              <Box
                component="img"
                src={ShareIcon}
                alt="Share"
                sx={sxStyles.shareIconSize}
              />
              <span>232</span>
            </Box>
          </Box>
          <Box sx={sxStyles.infoContainer}>
            <Box>{adData?.adDisplayName}</Box>
            <Box sx={sxStyles.infoLabelContainer}>
              <span>{adData?.adText} </span>
              <Box component="span" sx={sxStyles.adIcon}>
                Ad
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box
                component="img"
                src={MusicIcon}
                alt="Promotional Music"
                sx={sxStyles.musicIcon}
              />
              <span>Promotional Music</span>
            </Box>
            <Box sx={sxStyles.callToAction}>
              <span>{adData?.callToAction}</span>
            </Box>
          </Box>
          <Box sx={sxStyles.tiktokMusic}>
            <Box component="img" src={TiktokMusic} alt="Tiktok Music" />
          </Box>
          <Box sx={sxStyles.navBarContainer}>
            <Box
              component="img"
              src={PreviewNavBar}
              alt="Nav Bar"
              sx={sxStyles.navBarImage}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TiktokAdPreview;
