import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { Button } from '@mui/material';

const PREFIX = 'AdminQuickStartButton';

const classes = {
  quickStart: `${PREFIX}-quickStart`,
  quickStartLabel: `${PREFIX}-quickStartLabel`,
  quickStartIcon: `${PREFIX}-quickStartIcon`,
  quickStartName: `${PREFIX}-quickStartName`,
  quickStartDescription: `${PREFIX}-quickStartDescription`
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.quickStart}`]: {
    height: '100%',
    minWidth: '160px',
    width: '100%',
    color: theme.palette.text.primary,
    textAlign: 'center'
  },

  [`& .${classes.quickStartLabel}`]: {
    display: 'flex',
    flexDirection: 'column'
  },

  [`& .${classes.quickStartIcon}`]: {
    height: theme.spacing(10),
    width: theme.spacing(10)
  },

  [`& .${classes.quickStartName}`]: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },

  [`& .${classes.quickStartDescription}`]: {
    fontSize: '12px',
    textTransform: 'initial',
    textAlign: 'center'
  }
}));

const AdminQuickStartButton = ({ quickStart }) => {
  const QuickStartIcon = quickStart?.icon;

  return (
    <StyledButton
      component={Link}
      disabled={quickStart?.disabled}
      to={quickStart?.path}
      variant="outlined"
      className={classes.quickStart}
      classes={{
        label: classes.quickStartLabel
      }}
      color="inherit"
    >
      <span>
        {QuickStartIcon && (
          <QuickStartIcon className={classes.quickStartIcon} />
        )}
        <div className={classes.quickStartName}>{quickStart?.name}</div>
        <div className={classes.quickStartDescription}>
          {quickStart?.description}
        </div>
      </span>
    </StyledButton>
  );
};

export default AdminQuickStartButton;
