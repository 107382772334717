import { useMemo, useRef, useCallback } from 'react';
import { debounce, isArray, xorBy } from 'lodash';
import { t } from 'i18next';

import {
  Autocomplete,
  createFilterOptions,
  FormControl,
  TextField,
  Box,
  CircularProgress,
  Paper,
  Checkbox
} from '@mui/material';

import { formatValue, getSx } from 'src/components/ReduxForm/helpers';
import { ErrorFooter } from 'src/components/ReduxForm/ErrorFooter';
import { HelperTextFooter } from 'src/components/ReduxForm/HelperTextFooter';
import { InputTooltip } from 'src/components/ReduxForm/InputTooltip';
import { getOptionLabel as defaultGetOptionLabel } from 'src/components/ReduxForm/RenderAutocomplete/helpers';

/**
 * The default filter options func used in the autocomplete component.
 * We can configure this how we want but the default works fine.
 */
const defaultFilterOptions = createFilterOptions();

/**
 * Renders an autocomplete dropdown, similar to RenderSelect but with extra
 * functionality.
 *
 * See https://mui.com/material-ui/api/autocomplete/ for more info.
 *
 * Infinite scroll / pagination related description:
 * Infinite scroll in this component is quite complex due to the multiple
 * libraries working together, along with tying into our existing systems.
 * Please be careful modifying this component!
 * The general overview of this component's lifecycle is:
 * Setup:
 * 1. options are passed in via options, along with the selected item(s) via
 *    input.value
 * 2. The various data loading props are passed in (see notes below)
 *
 * Scrolling:
 * 1. If the user scrolls down far enough to reach the end of the currently
 *    loaded options, and hasNextPage is true, then we'll invoke loadNextPage.
 *    Nothing in this component will change after that. It is up to the parent
 *    component to respond to that loadNextPage call, load new options, and
 *    modify the options prop with new options.
 * 2. Now the user should have the next "page" of options to scroll through,
 *    which they can. If they reach the bottom again, and there's still another
 *    page, we repeat scrolling step 1 until hasNextPage is false.
 *
 * Filtering:
 * Filtering is a bit more tricky. We assume that loadNextPage is "slow" and
 * therefore do an optimization to speed up filtering locally. This aims to help
 * when we've loaded all pages, which is the only time local filtering is correct.
 * If you've got 1,000 options this won't help, but in the case of you only
 * having a hundred or so, this will totally help!
 *
 * If we have loaded all options:
 * 1. The user enters in filter text
 * 2. We pass that text into MUI's default autocomplete filter function. This
 *    modifies the children we pass into the InfiniteScrollListBox component
 *    (see props notes on that component)
 *
 * If we have not loaded all options:
 * 1. The user enters in filter text
 * 2. We invoke applyFilter with the filter text
 * 3. The parent component must apply that filter to their option loading call
 *    and load more options. This then flows the same as regular scrolling does
 *    where we expect new options to be passed in.
 *
 * Infinite scroll / pagination related props:
 * hasNextPage {boolean} - If there's a next page to load
 * isNextPageLoading {boolean} - If we're currently loading the next page. Used to
 *                               de-dupe load page calls
 * hasLoadedAllPages {boolean} - If we loaded EVERY page in the option set
 * loadNextPage {() => void} - Begin loading the next page. This expects the options
 *                             prop to be updated when completed. This function is
 *                             NOT called if hasNextPage is false
 * applyFilter {(filter: string) => void} - Applies a new text filter on subsequent
 *                                          queries. This expects a load operation
 *                                          to follow this call automatically.
 *                                          Note! This function IS NOT called if
 *                                          hasNextPage is false
 */
const RenderAutocomplete = props => {
  const {
    options,
    className,
    helperText,
    variant = 'outlined',
    input: { value, name, ...restInput },
    meta: { touched, error },
    label,
    placeholder,
    tooltip,
    isMultiSelect,
    loading,
    sx,
    hasNextPage,
    // Make sure we default this to true!
    // otherwise non-paginated components using autocomplete won't
    // get local searching. They'll be stuck trying to load the next page.
    hasLoadedAllPages = true,
    isNextPageLoading,
    loadNextPage,
    applyFilter,

    // If you want the  dropdown to close on button press
    // you must use the onMouseDown event on the button and preventCloseOnListButtonClick must be falsy
    // If you try to acheive this using onClick, the event handler will not be called

    // If you want the dropdown to stay open on button press
    // You can use either onMouseDown or onClick but preventCloseOnListButtonClick must also be true
    renderListButton,
    preventCloseOnListButtonClick,
    onInputChange,
    readOnly = false,
    renderOption,
    getOptionLabel,
    renderInput,
    customHelperText,
    enableSelectAll = false,
    enableCheckboxes = false,
    disabled
  } = props;

  // console.log(rest, 'auto comp rest');

  // The Autocomplete control is a little tricky to work with what we need.
  // We typically have options in a structured format like
  // {value: "a", name: "b"} but the redux state needs this in ["a"] with the
  // value extracted.
  // So, we'll do a little extra work to make sure the Autocomplete is happy
  // with that difference.
  // The main rule is:
  // ⭐️ Everything in Autocomplete uses options, outside does not ⭐️
  // Mainly this comes down to overriding a few props like getOptionLabel,
  // isOptionEqualToValue, and onChange to do that conversion

  // Cache our lookup map since that should ideally never change
  const optionLookupMap = useMemo(() => {
    const map = new Map();
    options.forEach(opt => map.set(opt.value, opt));
    return map;
  }, [options]);

  // Values that get transformed from ["1"] to [{value: "1"}]
  const formattedValues = formatValue(value, isMultiSelect);
  let mappedValues;
  if (isArray(formattedValues)) {
    mappedValues = formattedValues
      .map(val => optionLookupMap.get(val))
      // Remove any missing entries from the list
      .filter(val => val != null);
  } else {
    // Default to null here or else we'll get undefined and cause a
    // switch from uncontrolled -> controlled react component
    mappedValues = optionLookupMap.get(value) || null;
  }

  // Since applyFilter is called on user text input we need to debounce it
  // a bit, so we don't fire off dozens of network requests for search
  const applyFilterRef = useRef();
  applyFilterRef.current = applyFilter;
  const handleApplyFilter = useRef(
    debounce(filterValue => applyFilterRef.current(filterValue), 500)
  ).current;

  // This is to workaround a nasty "bug" in the useAutocomplete hook.
  // It isn't necessarily a bug but when combined with our usage it is!
  // There's a useEffect in useAutocomplete that will clear the search input
  // whenever the passed-in value changes. (see permalink, code may have changed)
  // https://github.com/mui/material-ui/blob/7e60a2aaab2202e05b944b31867b46b795bec888/packages/mui-base/src/useAutocomplete/useAutocomplete.js#L238
  // Since we're storing arrays of objects... referential equality kicks in and
  // clears our search input whenever we have values selected.
  // This mostly flows back to the fact we have to store extra options when
  // searching since if we don't autocomplete can't map a value to a label.
  // So, to workaround this, we'll setup a referentially stable array. This will
  // only change reference when the value actually changes.
  // Note: this problem is only present on multiselect but adding single just
  // to be consistent!
  const valueRef = useRef(mappedValues);
  if (valueRef.current !== mappedValues) {
    if (isMultiSelect) {
      if (xorBy(mappedValues, valueRef.current, 'value').length > 0) {
        valueRef.current = mappedValues;
      }
    } else if (mappedValues?.value !== valueRef.current?.value) {
      valueRef.current = mappedValues;
    }
  }

  // Trigger loadNextPage when this ref comes into view
  const observer = useRef();
  const loadMoreOptionElementRef = useCallback(
    node => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver(async entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          loadNextPage();
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [hasNextPage, loadNextPage]
  );

  const handleChange = (event, newValue) => {
    // Overwrite the onChange so that we pass the correct param
    // We need the first param to be the new value not the event since
    // redux-form doesn't think the event is a react event and never
    // updates state.

    let rawValue = newValue;
    // Also convert from the options shape to the raw value.
    if (newValue == null) {
      rawValue = newValue;
    } else if (isArray(newValue)) {
      rawValue = newValue.map(val => val.value);
    } else {
      rawValue = newValue.value;
    }

    if (
      enableSelectAll &&
      isMultiSelect &&
      rawValue.find(option => option === 'selectAllOption')
    ) {
      const selectAll = options.map(option => option.value);

      return restInput.onChange(
        value.length >= options.length ? [] : selectAll
      );
    }

    restInput.onChange(rawValue);
  };

  const handlePaperMouseDown = e => {
    // Prevents dropdown from closing when the list button is clicked
    e.preventDefault();
  };

  const defaultRenderOption = (props, option, state) => {
    // threshold is how many options from the end we should load more
    const threshold = 10;
    // determines what option we should load more on
    const isLoadMoreOption = state.index === options.length - threshold;
    // determines where to put the loading icon
    const isLastOption = state.index === options.length - 1;

    let isChecked = state.selected;
    if (
      enableSelectAll &&
      isMultiSelect &&
      value.length >= options.length // using greater than or equal to handle the select all option
    ) {
      isChecked = true;
    }

    return (
      <>
        <Box
          {...props}
          data-cy="autocomplete-listbox-item"
          component="li"
          key={props?.id}
          ref={isLoadMoreOption ? loadMoreOptionElementRef : null}
        >
          {enableCheckboxes && (
            <Checkbox style={{ marginRight: 8 }} checked={isChecked} />
          )}
          {option.name}
        </Box>
        {isLastOption && !hasLoadedAllPages && (
          <Box
            data-cy="autocomplete-loading-spinner"
            className={props.className}
            component="li"
            key="loadingOption"
            sx={{
              display: 'flex',
              justifyContent: 'center !important'
            }}
          >
            {isNextPageLoading && <CircularProgress size={30} />}
          </Box>
        )}
      </>
    );
  };

  const defaultRenderInput = params => (
    <TextField
      sx={{
        '& .MuiAutocomplete-input': {
          // setting this b/c it was causing the internal input to add an extra line when many options had been
          // selected. I've tested several things and this seems to solve the issue.
          // To reproduce the issues this fixes:
          //    comment out these -> select all options -> change the browser width to cause the +14 (number selected) to push the internal input to a new line. Workato input is a good place to test this.
          minWidth: '0px !important', // default is 30px
          padding: '7.5px 0 !important' // need to keep top and bottom padding to ensure empty input looks correct
        }
      }}
      {...params}
      error={touched && error}
      InputProps={{
        // Make sure to compose the original input props and end adornment
        // with our custom one for tooltips. Otherwise the input will break!
        ...params.InputProps,
        endAdornment: (
          <>
            {tooltip && <InputTooltip tooltipText={tooltip} />}
            {params.InputProps.endAdornment}
          </>
        )
      }}
      data-cy={name}
      label={label || placeholder}
    />
  );

  const renderOptionToUse = renderOption || defaultRenderOption;
  const renderInputToUse = renderInput || defaultRenderInput;

  return (
    <FormControl
      className={className}
      error={touched && !!error}
      fullWidth
      variant={variant}
      disabled={disabled}
    >
      <Autocomplete
        {...restInput}
        disabled={readOnly}
        filterOptions={(options, state) => {
          // If we loaded everything, just do the default filter operation
          if (hasLoadedAllPages) {
            if (enableSelectAll) {
              const filtered = options.filter(option =>
                option.value
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );

              return [
                { name: t('common:selectAll'), value: 'selectAllOption' },
                ...filtered
              ];
            }
            return defaultFilterOptions(options, state);
          }

          // otherwise, if we need to call the server for filters we'll
          // just return what was passed in and wait for the server response.
          // If we don't do this we'll incorrectly filter out options before
          // they pop in moments later!
          return options;
        }}
        // Careful, take a look at the docs for the difference of onInputChange
        // (the search input changing) and onChange (selecting an option)
        // https://mui.com/material-ui/react-autocomplete/#controlled-states
        onInputChange={(event, value, reason) => {
          if (onInputChange) {
            onInputChange(value);
          }

          // If we loaded everything in this set, there's no need to apply a custom
          // filter via a server query anymore.
          if (hasLoadedAllPages) {
            return;
          }

          if (reason === 'reset') {
            handleApplyFilter('');
          } else {
            handleApplyFilter(value);
          }
        }}
        onChange={handleChange}
        onBlur={() => {
          // The default onBlur for Autocomplete does not play nicely with redux-form
          // for some reason the event passed to this onBlur does not work
          // and if called via ...restInput it will clear the input.
          restInput.onBlur();
        }}
        // Overwrite the value of autocomplete in the rest obj or else it errors
        autoComplete={false}
        value={valueRef.current}
        loading={loading}
        sx={getSx(sx, props)}
        // we want to always display the loading indicator when we're loading
        // so that users know we're fetching their new filtered options.
        options={loading ? [] : options}
        ChipProps={{
          // Roughly match our chips in template text fields
          variant: 'outlined'
        }}
        // only close the dropdown when we're selecting a single item
        disableCloseOnSelect={isMultiSelect}
        getOptionLabel={getOptionLabel || defaultGetOptionLabel}
        multiple={isMultiSelect}
        slotProps={{
          popper: {
            'data-cy': 'autocomplete-listbox-root',
            sx: {
              boxShadow: theme => theme.shadows[3]
            }
          }
        }}
        renderOption={renderOptionToUse}
        renderInput={renderInputToUse}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        PaperComponent={
          renderListButton
            ? ({ children }) => (
                <Paper
                  onMouseDown={
                    preventCloseOnListButtonClick && handlePaperMouseDown
                  }
                >
                  {children}
                  {renderListButton({
                    selectedOptions: value,
                    onChange: restInput.onChange
                  })}
                </Paper>
              )
            : undefined
        }
      />
      {customHelperText && customHelperText}
      {error && touched && (
        <ErrorFooter touched={touched} error={error} variant={variant} />
      )}
      <HelperTextFooter
        helperText={helperText}
        variant={variant}
        stacked={customHelperText || (error && touched)}
      />
    </FormControl>
  );
};

export default RenderAutocomplete;
