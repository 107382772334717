import { Trans } from 'react-i18next';

import { styled } from '@mui/material/styles';

import { Paper, Typography } from '@mui/material';

import PageTitle from 'src/components/PageTitle';

import CloudinaryTemplateBuilder from './CloudinaryTemplateBuilder';

const PREFIX = 'CloudinaryLanding';

const classes = {
  paper: `${PREFIX}-paper`,
  pageHeading: `${PREFIX}-pageHeading`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  },

  [`& .${classes.pageHeading}`]: {
    marginBottom: theme.spacing(2)
  }
}));

const CloudinaryLanding = () => {
  return (
    <Root>
      <PageTitle subPageTitle="Admin Image Template Builder" />
      <Typography variant="h5" className={classes.pageHeading}>
        <Trans i18nKey="admin:headings.imageTemplateBuilder">
          Image Template Builder
        </Trans>
      </Typography>
      <Paper className={classes.paper}>
        <CloudinaryTemplateBuilder />
      </Paper>
    </Root>
  );
};

export default CloudinaryLanding;
