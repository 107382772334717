import { Route } from 'react-router-dom';
import qs from 'qs';
import Auth from 'src/Auth/Auth';
import { AUTH0_KEYS, isGuest } from 'src/Auth/common';

interface GuestRouteProps {
  component: React.ComponentType<any>;
  componentProps?: any;
  [key: string]: any;
}

const GuestRoute = ({
  component: Component,
  componentProps,
  ...restProps
}: GuestRouteProps) => {
  return (
    <Route
      {...restProps}
      render={props => {
        const { location, history } = props;
        const {
          accessToken,
          // TODO: do something with these later on
          // invitationId,
          // orderId,
          ...rest
        } = qs.parse(location.search, {
          ignoreQueryPrefix: true
        });
        let isAuthenticated = Auth.isAuthenticated();

        // TODO: How long should their token last? Is 1 day enough?
        if (accessToken && typeof accessToken === 'string') {
          const date = new Date();
          // add a day
          date.setDate(date.getDate() + 1);
          // do soemthing
          localStorage.setItem(AUTH0_KEYS.EV_ACCESS_TOKEN, accessToken);
          localStorage.setItem(
            AUTH0_KEYS.EXPIRES_AT,
            date.getTime().toString()
          );

          // remove token from url
          let search = '';
          if (rest && Object.keys(rest).length) {
            search = `?${qs.stringify(rest)}`;
          }
          history.replace({ pathname: location.pathname, search });
          // we're authenticated
          isAuthenticated = true;
        }

        // TODO: What do we do if the invitationId or orderId is present?
        // * Do we want to direct them somewhere specific if the invitationId or orderId is present?
        // * This is from the PRD:
        // * https://evocalize.atlassian.net/wiki/spaces/ENG/pages/2313355281/RFC-125+Co-Marketing+-+Guest+Relations#Guest-Creation-Flow

        if (isAuthenticated && isGuest()) {
          return <Component {...componentProps} {...props} />;
        }

        // TODO: If not authed there are a few options:
        // * If Auth0 they hit the login modal
        // * If not Auth0 they hit their partner portal page
        // * Either way, they will need to be redirected to a guest auth page where they can enter their email
        // * and submit to get access via a magic link
        // https://evocalize.atlassian.net/wiki/spaces/ENG/pages/2313355281/RFC-125+Co-Marketing+-+Guest+Relations#Guest-Auth-Flow

        return (
          <div>
            <h1>You are un-authed and/or your role is not guest </h1>
            <p>
              We need to replace this page with something that makes sense or a
              redirect!
            </p>
          </div>
        );
      }}
    />
  );
};

export default GuestRoute;
