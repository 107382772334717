import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Auth from 'src/Auth/Auth';

import Lock from '@mui/icons-material/Lock';

import PageTitle from 'src/components/PageTitle';

import { Button, Grid, Typography } from '@mui/material';

const PREFIX = 'Login';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  button: `${PREFIX}-button`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%'
  },

  [`& .${classes.icon}`]: {
    color: theme.palette.primary.light,
    height: '300px',
    width: '300px'
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1)
  }
}));

const Login = () => {
  useEffect(() => {
    Auth.login();
  }, []);

  return (
    <StyledGrid className={classes.root} container spacing={3}>
      <PageTitle subPageTitle="Login" />
      <Grid item xs>
        <Lock className={classes.icon} />
        <Typography component="h1" variant="h2">
          <Trans i18nKey="login:messages.hello">Hello</Trans>
        </Typography>
        <Typography component="h2" variant="h5">
          <Trans i18nKey="login:messages.notLoggedIn">
            It does not look like you are logged in.
          </Trans>
        </Typography>
      </Grid>
      <Grid item xs>
        <Button
          className={classes.button}
          color="primary"
          size="large"
          variant="outlined"
        >
          Login
        </Button>
        {/* TODO: Make support email go to correct place / be dynamic */}
        <Button
          className={classes.button}
          href="mailto:support@evocalize.com?subject=Login Issue"
          size="large"
          variant="outlined"
        >
          <Trans i18nKey="login:messages.contactSupport">Contact Support</Trans>
        </Button>
      </Grid>
    </StyledGrid>
  );
};

export default flow(withRouter)(Login);
