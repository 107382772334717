import { t } from 'i18next';

import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import { programOrderStatusCode, getStatusColor } from './Constants';

const BillingStatus = ({ order, renderTextStatus }) => {
  const theme = useTheme();

  const orderStatus = order?.orderItem?.status;
  const isProgramActive = order?.isProgramActive;

  const statusColor = getStatusColor({
    isProgramActive,
    orderStatus,
    theme
  });

  let text = t('programs:messages.billingPending');

  switch (orderStatus) {
    case programOrderStatusCode.fulfilled:
      if (isProgramActive) {
        text = t('programs:messages.billingSuccess');
      } else {
        // fulfilled but not active
        text = t('programs:messages.billingStopped');
      }
      break;
    case programOrderStatusCode.cancelled:
      text = t('programs:messages.billingCancelled');
      break;
    case programOrderStatusCode.billingFailed:
      text = t('programs:messages.billingFailed');
      break;
    case programOrderStatusCode.error:
      text = t('programs:messages.billingError');
      break;
    case programOrderStatusCode.cancelling:
      text = t('programs:messages.billingCancelling');
      break;
    default:
      // keep default above Pending
      break;
  }

  return (
    <Tooltip title={renderTextStatus ? '' : text}>
      <Box
        component="span"
        sx={{ fontSize: '20px', cursor: 'default', color: statusColor }}
      >
        ●{' '}
        {renderTextStatus && (
          <Box component="span" sx={{ fontSize: '14px' }}>
            ({text})
          </Box>
        )}
      </Box>
    </Tooltip>
  );
};

export default BillingStatus;
