import { Button, CircularProgress } from '@mui/material';

import { DynamicForm } from 'src/components/ReduxForm';

interface AddResourceItemFormProps {
  inputs: any;
  isUpdate: boolean;
  isEditItemValuesOnly: boolean;
  isSubmitting: boolean;
  isDirty: boolean;
  isValid: boolean;
  text: any;
  methods: any;
  setIsDeleteModalOpen: (open: boolean) => void;
  resetForm: () => void;
  closeForm: () => void;
}

const AddResourceItemForm = ({
  inputs,
  isUpdate,
  isEditItemValuesOnly,
  isSubmitting,
  isDirty,
  isValid,
  text,
  methods,
  setIsDeleteModalOpen,
  resetForm,
  closeForm
}: AddResourceItemFormProps) => {
  return (
    <>
      <DynamicForm
        key={isUpdate}
        inputs={inputs}
        isHookForm
        hookFormContext={methods}
        formName="resourceItemForm"
      />
      <br />
      <Button
        color="primary"
        disabled={isSubmitting || !isDirty || !isValid}
        endIcon={isSubmitting && <CircularProgress size={15} />}
        variant="contained"
        type="submit"
      >
        {isUpdate ? text.updateButton : text.createButton}
      </Button>{' '}
      {isUpdate && !isEditItemValuesOnly && (
        <>
          <Button
            sx={theme => ({
              background: theme.palette.error.main,
              color: '#fff',
              '&:hover': {
                background: theme.palette.error.dark
              }
            })}
            variant="outlined"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            {text.deleteButton}
          </Button>{' '}
        </>
      )}
      <Button color="secondary" variant="outlined" onClick={() => resetForm()}>
        {text.resetButton}
      </Button>{' '}
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => {
          resetForm();
          closeForm();
        }}
      >
        {text.cancelButton}
      </Button>
    </>
  );
};

export default AddResourceItemForm;
