import { gql } from 'src/generated/gql';

// hard coding the integrationType to total_expert for now until we have more integrations
export const fetchEmbeddedLink = gql(`
  query fetchEmbeddedLink {
    fetchEmbeddedLink(integrationType: total_expert) {
      link
      errorMessage
    }
  }
`);
