import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { flow, get, find, sortBy } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { graphql } from '@apollo/client/react/hoc';
import classnames from 'classnames';

import {
  Button,
  Typography,
  Paper,
  Toolbar,
  FormControl,
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { useModalState } from 'src/components/Modal';

import ErrorMessage from 'src/components/Containers/ErrorMessage';
import PageTitle from 'src/components/PageTitle';
import { withFeatures } from 'src/components/Feature';

import ProgramsTable from './ProgramsTableLegacy';
import { getArchitectureById, getBlueprints } from './queries';
import SelectQuickStartModal from './SelectQuickStartModal';

const PREFIX = 'ProgramsByArchitecture';

const classes = {
  containerWithMargin: `${PREFIX}-containerWithMargin`,
  rightIcon: `${PREFIX}-rightIcon`,
  headerContainer: `${PREFIX}-headerContainer`,
  button: `${PREFIX}-button`,
  errorContainer: `${PREFIX}-errorContainer`,
  formControl: `${PREFIX}-formControl`,
  tableTitle: `${PREFIX}-tableTitle`,
  inputLabel: `${PREFIX}-inputLabel`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.containerWithMargin}`]: {
    marginTop: theme.spacing(2)
  },

  [`& .${classes.rightIcon}`]: {
    marginLeft: theme.spacing(1)
  },

  [`& .${classes.headerContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'initial',
      marginBottom: 'initial'
    }
  },

  [`& .${classes.button}`]: {
    [theme.breakpoints.down('md')]: {
      margin: `${theme.spacing(3)} 0`
    }
  },

  [`& .${classes.errorContainer}`]: {
    margin: '0 auto'
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 500
  },

  [`& .${classes.tableTitle}`]: {
    flexGrow: 1
  },

  [`& .${classes.inputLabel}`]: {
    marginLeft: theme.spacing(2),
    background: '#fff',
    zIndex: 2,
    pointerEvents: 'none'
  }
}));

function formatName(name) {
  return name.replace(/\s/g, '').replaceAll('-', '').toLowerCase();
}

const ProgramsByArchitecture = props => {
  const {
    match,
    architecture,
    error,
    blueprints,
    features: { addProgramButton }
  } = props;
  const [selectedFilter, setSelectedFilter] = useState([]);
  const { open, openModal, closeModal } = useModalState();

  const handleFilterChange = event => {
    setSelectedFilter(event.target.value);
  };

  const renderValue = value => {
    return value.reduce(
      (str, val) =>
        `${str}(${get(find(blueprints.products, ['id', val]), 'name')})`,
      ''
    );
  };

  const products = get(blueprints, 'products');

  const architectureId = get(match, 'params.architectureId');

  let architectureName = get(architecture, 'name') || '';
  architectureName = architectureName ? `${architectureName}: ` : '';

  const title = t('programsByArchitecture:headers.title', {
    architectureName
  });

  const sortedProducts = sortBy(products, [
    product => formatName(product.name)
  ]);

  return (
    <Root>
      <SelectQuickStartModal
        open={open}
        onClose={closeModal}
        isSupervisable={false}
      />
      <PageTitle subPageTitle={title} />
      <div className={classes.headerContainer}>
        <div className={classes.headerLeft}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle2">
            <Trans i18nKey="programsByArchitecture:subTitle">
              Manage all of your programs
            </Trans>
          </Typography>
        </div>

        <div className={classes.headerRight}>
          {!error && addProgramButton && (
            <Button
              data-amp-click-add-new-program={JSON.stringify({
                architectureId,
                type: 'program'
              })}
              className={classes.button}
              color="primary"
              size="large"
              onClick={openModal}
              variant="contained"
            >
              <Trans i18nKey="programsByArchitecture:buttons.addNew">
                Create Program
              </Trans>
              <AddIcon className={classes.rightIcon} />
            </Button>
          )}
        </div>
      </div>
      {error ? (
        <>
          <ErrorMessage className={classes.errorContainer}>
            <Trans i18nKey="programsByArchitecture:errorGettingPrograms">
              There was an error getting your program. Please refresh. If the
              problem persists, please contact support.
            </Trans>
          </ErrorMessage>
        </>
      ) : (
        <>
          <Paper
            className={classnames({
              [classes.containerWithMargin]: !addProgramButton
            })}
          >
            <Toolbar>
              <Typography
                className={classes.tableTitle}
                component="h2"
                variant="h6"
              />
              <FormControl className={classes.formControl}>
                <InputLabel
                  htmlFor="select-multiple-checkbox"
                  className={classes.inputLabel}
                >
                  {t(
                    'programsByArchitecture:inputLabel.filterByBlueprint',
                    'Filter by Blueprint'
                  )}
                </InputLabel>
                {!get(blueprints, 'loading') && products && (
                  <Select
                    multiple
                    value={selectedFilter}
                    onChange={handleFilterChange}
                    input={<OutlinedInput id="select-multiple-checkbox" />}
                    renderValue={renderValue}
                  >
                    {sortedProducts.map(({ name, id }) => (
                      <MenuItem key={id} value={id}>
                        <Checkbox checked={selectedFilter.indexOf(id) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Toolbar>
            <ProgramsTable
              architectureId={architectureId}
              productIds={selectedFilter}
            />
          </Paper>
        </>
      )}
    </Root>
  );
};

export default flow(
  graphql(getArchitectureById, {
    name: 'getArchitectureById',
    props: ({ getArchitectureById: { error, loading, architecture } }) => {
      return {
        loading,
        error,
        architecture
      };
    },
    options: props => ({
      variables: {
        architectureId: get(props, 'match.params.architectureId')
      }
    })
  }),
  graphql(getBlueprints, {
    name: 'blueprints',
    options: props => ({
      variables: {
        architectureId: get(props, 'match.params.architectureId'),
        ...(props?.features?.showInactiveBlueprints && {
          showInactiveBlueprints: true
        }),
        ...(props?.features?.showInactiveOffers && {
          showInactiveOffers: true
        })
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    })
  }),
  withRouter,

  withFeatures
)(ProgramsByArchitecture);
