import { useState } from 'react';
import { isEmpty } from 'lodash';
import { t } from 'i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';

import {
  Grid,
  Button,
  Tooltip,
  IconButton,
  Typography,
  Box,
  Stack,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Edit as EditIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { useGlobalContext } from 'src/GlobalContextProvider';
import { dayjs, formatDate } from 'src/common/dates';
import { getConditionalInputVisibilityFromBlueprint } from 'src/common/conditionals';

import Instrumentation from 'src/instrumentation';
import Logger from 'src/common/Logger';
import { businessObjectsFromOrder } from 'src/common/businessObjects';
import {
  formatBlueprints,
  hasCatalog,
  contentSelectable,
  getOneOfEachChannelKeys
} from 'src/common/blueprints';
import { contentColumnsFromArchitecture } from 'src/common/dynamicUserInputs';
import { useInterval } from 'src/hooks/useInterval';
import FooterValidationErrors from 'src/pages/Program/FooterValidationErrors';

import ProgramDynamicUserInputs from 'src/pages/Program/ProgramDynamicUserInputs';
import { getBudgetSectionTitle } from 'src/pages/Program/ProgramSteps/ProgramStepSpend';
import Modal from 'src/components/Modal';

import AdPreview from 'src/components/AdPreview';
import SpendSelector, { useMinSpendState } from 'src/components/SpendSelector';
import ScheduleSelector from 'src/components/ScheduleSelector';
import { PROGRAM_FORM_SECTION_SPEND_NAME } from 'src/pages/Program/Constants';
import useCreativeValidationsHandler from 'src/pages/Program/useCreativeValidationsHandler';
import { OFFER_TYPES } from 'src/common/offers';
import ProgramName from 'src/pages/Program/ProgramName/ProgramName';
import { getSelectedLocations } from 'src/pages/Program/queries';
import { FormSection } from 'src/components/ReduxForm';
import PreviewDrawerHeader from 'src/pages/Program/ProgramPreviewDrawer/PreviewDrawerHeader';
import { DrawerDivider } from 'src/pages/Program/ProgramPreviewDrawer/ProgramPreviewDrawer';
import PreviewDrawerFooter from 'src/pages/Program/ProgramPreviewDrawer/PreviewDrawerFooter';
import { useAppSettings } from 'src/AppSettings';
import { BACKGROUND_GREY } from 'src/AppThemeWrapper';
import { DrawerToggleButton } from 'src/pages/Program/ProgramPreviewDrawer';

import {
  EDIT_PROGRAM_FORM_NAME,
  ORDER_PROCESSING_STATUS,
  SUBSCRIPTION_TIER_CHANGE_METHODS,
  mostRecentBudgetAdjustmentOutcomes
} from '../Constants';
import { editOrder as editOrderMutation } from '../mutations';

import {
  getProgramNameTranslated,
  getProgramNameLabel
} from '../../Program/ProgramName/ProgramNameUtil';
import {
  canMakeScheduleUpdates,
  getProgramStatuses,
  getEditPermissionsFromOrder
} from '../helpers';
import { getEditButtonStatus } from './helpers';

const EditProgram = props => {
  const {
    architecture,
    refetch,
    order,
    disabled = false,
    hasWritePermission,
    contentName
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [editOrder] = useMutation(editOrderMutation);
  const globalContext = useGlobalContext();
  const userMetadataFields = globalContext?.me?.metadata?.fields;

  const hookFormMethods = useFormContext();

  const {
    reset,
    submitting,
    handleSubmit,
    formState: { errors: formErrors },
    watch,
    trigger
  } = hookFormMethods;

  const {
    orderItem: { status },
    billingDetails,
    offer: { subscriptionTierChangeMethod }
  } = order;

  const { startDate, endDate, amount, renewsOn } = billingDetails;

  const { isSubscription } = getProgramStatuses(order);

  const {
    canEditSubscriptionTier,
    canEditPurchaseOrderAmount,
    canEditPurchaseSchedule
  } = getEditPermissionsFromOrder(order);

  const selectedLocations = order?.multiLocationChildProgramDetails?.locationId
    ? [order?.multiLocationChildProgramDetails?.locationId]
    : [];

  const { data: allLocations } = useQuery(getSelectedLocations, {
    skip: !selectedLocations.length,
    variables: {
      filter: {
        locationIds: selectedLocations
      }
    }
  });

  const selectedLocationsMetadata = [allLocations?.locations?.edges?.[0]?.node];

  const formValues = watch();
  const hasFormErrors = !isEmpty(formErrors);
  const programStartDate = startDate;
  const programEndDate = endDate;
  const orderStatus = status;
  const currentEndDate = dayjs(endDate);
  const currentStartDate = dayjs(startDate);
  const currentSpend = amount;
  const isScheduledSubscriptionTierChange =
    subscriptionTierChangeMethod === SUBSCRIPTION_TIER_CHANGE_METHODS.scheduled;

  const canEditBudgetOrSchedule =
    canEditPurchaseOrderAmount ||
    canEditPurchaseSchedule ||
    canEditSubscriptionTier;

  const mostRecentBudgetAdjustment =
    order?.billingDetails?.mostRecentBudgetAdjustment;

  const {
    handleAdContentChannelValidation,
    isValidatingCreative,
    isPollingPreview,
    setIsPollingPreview,
    setIsValidatingCreative,
    creativeValidationErrors,
    clearUpdatedInputCreativeErrors,
    inputValidators: channelInputValidators
  } = useCreativeValidationsHandler({
    product: order?.orderItem?.product,
    isAutomated: false,
    trigger
  });

  const [modal, setModal] = useState(null);
  const {
    minSpendLoading,
    setMinSpendLoading,
    hasMinSpendError,
    setHasMinSpendError
  } = useMinSpendState();

  const appSettings = useAppSettings();
  const supportEmail = appSettings?.app?.support?.email;
  const supportPhone = appSettings?.app?.support?.phone;

  const [isUpdating, setIsUpdating] = useState(
    mostRecentBudgetAdjustment?.outcome ===
      mostRecentBudgetAdjustmentOutcomes.pending
  );
  const [count, setCount] = useState(0);

  const checkForUpdates = () => {
    if (
      mostRecentBudgetAdjustment?.outcome ===
        mostRecentBudgetAdjustmentOutcomes.success &&
      count > 3 && // ensure we are updated. if a successful update has already happed the outcome starts out as a success
      order?.executionStatus?.outcome ===
        mostRecentBudgetAdjustmentOutcomes.success
    ) {
      setIsUpdating(false);
      setCount(0);
      return;
    }

    // stop after 30 tries / seconds
    if (count > 30) {
      setIsUpdating(false);
      setCount(0);
      return;
    }

    if (
      mostRecentBudgetAdjustment?.outcome ===
      mostRecentBudgetAdjustmentOutcomes.error
    ) {
      enqueueSnackbar(t('programPerf:editModal.editError'), {
        variant: 'error'
      });
      Logger.error(
        `mostRecentBudgetAdjustment error: ${mostRecentBudgetAdjustment.errorMessage}`
      );
      setIsUpdating(false);
      setCount(0);
      return;
    }

    refetch();
    setCount(count + 1);
  };

  useInterval(checkForUpdates, isUpdating ? 1000 : null);

  const handleClose = () => {
    setModal(null);
  };

  const handleOpen = () => {
    Instrumentation.logEvent(Instrumentation.Events.EditProgramClicked, {
      orderId: order?.orderItem?.id,
      architectureId: order?.architectureId,
      productId: order?.orderItem?.product?.id,
      paymentAmount: order?.billingDetails?.amount,
      channel: getOneOfEachChannelKeys(order?.channels?.[0]?.channels)
    });
    setModal(true);
  };

  const contentColumns = contentColumnsFromArchitecture(architecture);
  const product = {
    ...order?.orderItem?.product,
    offers: [{ ...order?.offer, isActive: true }]
  };
  const blueprint = formatBlueprints([product] || []);
  const selectedBlueprint = blueprint?.[0];

  const dynamicUserInputSections = blueprint[0].inputSections
    // disable editing legacy audiences
    .map(section => {
      return {
        ...section,
        inputFields: section.inputFields.map(input => {
          if (input.displayMethodId === 'facebook_audience_id') {
            return { ...input, isEditable: false, disabled: true };
          }
          // when input is not editable we want it in read only mode
          if (!input.isEditable) {
            return {
              ...input,
              readOnly: true
            };
          }
          return input;
        })
      };
    });

  const paymentPlans = selectedBlueprint?.paymentPlans;

  const businessObjects = businessObjectsFromOrder(order);
  const hasBusinessObjects = !isEmpty(businessObjects);

  const previewData = {
    blueprint: selectedBlueprint,
    dynamicUserInputs: formValues?.dynamicUserInputs || {},
    businessObjects
  };

  const architectureHasCatalog = hasCatalog(architecture, selectedBlueprint);
  const displayNameTemplate = architecture?.catalog?.displayNameTemplate;
  const isContentSelectable = contentSelectable(architecture, product);
  const orderItemId = order?.orderItem?.id;
  const offerId = order?.orderItem?.offerId;
  const orderIsPending = orderStatus === ORDER_PROCESSING_STATUS.pending;

  const { startDateEditable, endDateEditable } = canMakeScheduleUpdates({
    isEdit: true,
    orderStatus,
    programStartDate,
    programEndDate,
    isSubscription
  });

  const conditionalInputsVisibility =
    getConditionalInputVisibilityFromBlueprint(
      selectedBlueprint,
      formValues,
      userMetadataFields,
      businessObjects,
      selectedLocationsMetadata
    );

  const handleSave = data => {
    const businessObjects = businessObjectsFromOrder(order);

    if (hasFormErrors) {
      enqueueSnackbar(t('programPerf:editModal.editInvalid'), {
        variant: 'error'
      });
      return;
    }

    const programName = getProgramNameTranslated(
      data?.spendStep?.programName,
      product,
      businessObjects,
      displayNameTemplate,
      userMetadataFields
    );

    const resultsEstimation = data?.spendStep?.resultsEstimation;

    return editOrder({
      variables: {
        orderItemId,
        programName,
        variableValues: data?.dynamicUserInputs,
        ...(canEditPurchaseSchedule &&
          startDateEditable && {
            scheduleStartEpochSeconds: dayjs(data?.spendStep?.startDate).unix()
          }),
        ...(canEditPurchaseSchedule &&
          endDateEditable && {
            scheduleEndEpochSeconds: dayjs(data?.spendStep?.endDate).unix()
          }),
        ...((canEditSubscriptionTier || canEditPurchaseOrderAmount) &&
          !orderIsPending && {
            updatedBudgetAmount:
              OFFER_TYPES.subscription === data?.spendStep?.scheduleType
                ? data?.spendStep?.subscriptionSpend
                : data?.spendStep?.oneTimeSpend,
            ...(resultsEstimation && { resultsEstimation })
          }),
        ...(canEditSubscriptionTier && {
          updatedTierId: data?.spendStep?.subscription?.split('tier:')[1]
        })
      }
    })
      .then(() => {
        enqueueSnackbar(t('programPerf:editModal.successfulUpdate'), {
          variant: 'success'
        });
        refetch();
        Instrumentation.logEvent(
          Instrumentation.Events.EditProgramActionsClicked,
          { action: 'save' }
        );
        handleClose();
        setIsUpdating(true);
        return null;
      })
      .catch(() => {
        enqueueSnackbar(t('programPerf:editModal.editError'), {
          variant: 'error'
        });
        handleClose();
        return null;
      });
  };

  const editButtonStatus = getEditButtonStatus({
    disabled,
    hasOrder: !!order,
    architectureHasCatalog,
    hasBusinessObjects,
    hasWritePermission
  });

  const getScheduleSelectorHelpText = () => {
    if (orderIsPending) {
      return isSubscription
        ? t('programEdit:scheduleSelector.subscriptionPendingChangeDisabled')
        : t('programEdit:scheduleSelector.purchasePendingChangeDisabled');
    }

    return '';
  };

  const getSpendSelectorHelpText = () => {
    if (orderIsPending) {
      return isSubscription
        ? t('programEdit:spend.subscriptionPendingTip')
        : t('programEdit:spend.purchasePendingTip');
    }

    return '';
  };

  const previewDrawerBreakpoint = 1190;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(previewDrawerBreakpoint)
  );
  const [isPreviewDrawerOpen, setIsPreviewDrawerOpen] = useState(false);

  const previewDrawerOpen =
    !isSmallScreen || (isSmallScreen && isPreviewDrawerOpen);

  const subscriptionTierChangeRenewalDate =
    isScheduledSubscriptionTierChange &&
    renewsOn &&
    formatDate({
      date: renewsOn,
      format: 'MM/DD/YYYY'
    });

  return (
    <>
      <Tooltip title={editButtonStatus.tooltip}>
        <span>
          <IconButton
            color="primary"
            onClick={handleOpen}
            data-cy="edit-program-button"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 0.5
            }}
            disabled={editButtonStatus.isDisabled}
          >
            <EditIcon fontSize="small" />
            <Typography component="span" variant="body2">
              {t('programPerf:editModal.edit')}
            </Typography>
          </IconButton>
        </span>
      </Tooltip>
      {modal && (
        <Box sx={{ position: 'relative' }}>
          <Modal
            open={!!modal}
            headerText={t('programPerf:editModal.title')}
            fullWidth
            fullScreen={isSmallScreen}
            maxWidth="xl"
            dialogContentSx={{
              pb: 3,
              backgroundColor: BACKGROUND_GREY.light,
              overflowX: 'hidden'
            }}
            onClose={(e, reason) => {
              if (reason === 'backdropClick') {
                return;
              }
              handleClose();
              reset();
            }}
            FooterComponent={() => (
              <>
                <Button
                  onClick={() => {
                    // putting Tracker here b/c handleClose is called from multiple places
                    Instrumentation.logEvent(
                      Instrumentation.Events.EditProgramActionsClicked,
                      {
                        action: 'cancel'
                      }
                    );
                    handleClose();
                    reset();
                  }}
                >
                  {t('programPerf:editModal.cancel')}
                </Button>
                <LoadingButton
                  variant="contained"
                  onClick={handleSubmit(handleSave)}
                  disabled={
                    submitting ||
                    isValidatingCreative ||
                    creativeValidationErrors ||
                    minSpendLoading ||
                    hasMinSpendError
                  }
                  loading={
                    submitting || isValidatingCreative || minSpendLoading
                  }
                >
                  {t('programPerf:editModal.save')}
                </LoadingButton>
              </>
            )}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: 'flex-start',
                position: 'relative',
                justifyContent: 'flex-start',
                gap: 3,
                // Drawer Closed Styles
                ...(!previewDrawerOpen && { gap: 0 })
              }}
            >
              {((isSmallScreen && !previewDrawerOpen) || !isSmallScreen) && (
                <Box component="form">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormSection title="Program Name" isEditProgramModal>
                        <ProgramName
                          isEditProgramModal
                          contentColumns={contentColumns}
                          businessObjects={previewData.businessObjects}
                          displayNameTemplate={displayNameTemplate}
                          selectedBlueprint={product}
                          formName={EDIT_PROGRAM_FORM_NAME}
                          label={getProgramNameLabel()}
                          formValues={formValues}
                          isContentSelectable={isContentSelectable}
                          contentName={contentName}
                        />
                      </FormSection>
                    </Grid>
                    <Grid item xs={12}>
                      <ProgramDynamicUserInputs
                        isEditProgramModal
                        blueprint={blueprint[0]}
                        dynamicUserInputSections={dynamicUserInputSections}
                        contentColumns={contentColumns}
                        businessObjects={previewData.businessObjects}
                        formName={EDIT_PROGRAM_FORM_NAME}
                        isContentSelectable={isContentSelectable}
                        contentName={contentName}
                        channelInputValidators={channelInputValidators}
                        isHookForm
                        hookFormMethods={hookFormMethods}
                        conditionalInputsVisibility={
                          conditionalInputsVisibility
                        }
                      />
                    </Grid>
                    {canEditBudgetOrSchedule && (
                      <>
                        {canEditPurchaseSchedule && (
                          <Grid item xs={12}>
                            <FormSection
                              isEditProgramModal
                              title={t('programCreate:spend.offerTypeTitle')}
                              description={t(
                                'programPerf:editModal.offerTypeDescription'
                              )}
                              helpText={getScheduleSelectorHelpText()}
                            >
                              <Box sx={{ pb: 1 }}>
                                <ScheduleSelector
                                  paymentPlans={paymentPlans}
                                  selectedBlueprint={selectedBlueprint}
                                  programStartDate={programStartDate}
                                  programEndDate={programEndDate}
                                  orderStatus={orderStatus}
                                  isEdit
                                  orderIsPending={orderIsPending}
                                  formSectionName={
                                    PROGRAM_FORM_SECTION_SPEND_NAME
                                  }
                                />
                              </Box>
                            </FormSection>
                          </Grid>
                        )}
                        {(canEditPurchaseOrderAmount ||
                          canEditSubscriptionTier) && (
                          <Grid item xs={12}>
                            <FormSection
                              isEditProgramModal
                              title={getBudgetSectionTitle({ isSubscription })}
                              description={
                                subscriptionTierChangeRenewalDate
                                  ? t('programEdit:spend.editHeaderScheduled', {
                                      renewsOn:
                                        subscriptionTierChangeRenewalDate
                                    })
                                  : t('programPerf:editModal.budgetDescription')
                              }
                              helpText={getSpendSelectorHelpText()}
                            >
                              <SpendSelector
                                architecture={architecture}
                                paymentPlans={paymentPlans}
                                selectedBlueprint={selectedBlueprint}
                                isAutomated={false}
                                isEdit
                                orderItemId={orderItemId}
                                offerId={offerId}
                                currentEndDate={currentEndDate}
                                currentStartDate={currentStartDate}
                                currentSpend={currentSpend}
                                orderIsPending={orderIsPending}
                                // formSectionName={formSectionName}
                                setMinSpendLoading={setMinSpendLoading}
                                setHasMinSpendError={setHasMinSpendError}
                                {...(isScheduledSubscriptionTierChange && {})}
                                formSectionName={
                                  PROGRAM_FORM_SECTION_SPEND_NAME
                                }
                              />
                            </FormSection>
                          </Grid>
                        )}
                      </>
                    )}

                    {!isEmpty(creativeValidationErrors) && (
                      <Grid item xs={12}>
                        <FooterValidationErrors
                          channelValidationErrors={creativeValidationErrors}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}

              {/* Ad Preview */}
              <Stack
                sx={{
                  minWidth: !isSmallScreen ? 450 : '100%',
                  flex: 1,
                  position: 'sticky',
                  top: 0,
                  pt: 1,
                  alignItems: 'center',
                  // Drawer Closed Styles
                  ...(!previewDrawerOpen && {
                    position: 'sticky',
                    right: -24,
                    mr: -3,
                    width: 0,
                    minWidth: 0
                  })
                }}
              >
                <Stack sx={{ width: '100%' }}>
                  <PreviewDrawerHeader
                    channels={order?.channels
                      ?.map(channel => channel.channels)
                      .flat()
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      )}
                  />
                  <DrawerDivider />
                </Stack>
                <Stack
                  sx={{ overflow: 'scroll', maxWidth: 350, width: '100%' }}
                >
                  <AdPreview
                    displayAsPhoneMockUp
                    isResponsive
                    disableResponsiveStyles
                    showLoadingSkeleton
                    architecture={architecture}
                    previewData={previewData}
                    handleAdContentChannelValidation={
                      handleAdContentChannelValidation
                    }
                    setIsPollingPreview={setIsPollingPreview}
                    isPollingPreview={isPollingPreview}
                    setIsValidatingCreative={setIsValidatingCreative}
                    clearUpdatedInputCreativeErrors={
                      clearUpdatedInputCreativeErrors
                    }
                    showIncludedButton
                  />
                  {(supportPhone || supportEmail) && (
                    <>
                      <DrawerDivider />
                      <PreviewDrawerFooter
                        supportPhone={supportPhone}
                        supportEmail={supportEmail}
                      />
                    </>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Modal>
          {isSmallScreen && (
            <DrawerToggleButton
              isOpen={previewDrawerOpen}
              onClick={() => setIsPreviewDrawerOpen(isOpen => !isOpen)}
              text="AD PREVIEW"
              isDrawerFullScreenOverride={isSmallScreen}
              sxOverrides={({ theme, isOpen }) => ({
                zIndex: theme.zIndex.tooltip + 1000,
                position: 'fixed',
                top: '61.5%',
                left: isOpen ? 0 : '100%',
                transform: isOpen
                  ? `translate(0, 0) rotate(-90deg)`
                  : `translate(-23%, 0) rotate(-90deg)`
              })}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default EditProgram;
