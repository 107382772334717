import { Fragment, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { t } from 'i18next';

import Heading from 'src/components/PageElements/Heading';

import AdminGalleriesTable from './AdminGalleriesTable';

const PREFIX = 'AdminGalleries';

const classes = {
  content: `${PREFIX}-content`
};

const Root = styled(Fragment)(({ theme }) => ({
  [`& .${classes.content}`]: {
    maxWidth: '700px',
    margin: `${theme.spacing(3)} 0`
  }
}));

const pageText = () => ({
  title: t('adminGalleries:title'),
  subTitle: t('adminGalleries:subTitle')
});

const AdminGalleries = () => {
  const text = useMemo(() => pageText(), []);

  return (
    <Root>
      <Heading
        title={text.title}
        subTitle={text.subTitle}
        pageTitle={text.title}
        divider
      />
      <div className={classes.content}>
        <AdminGalleriesTable />
      </div>
    </Root>
  );
};

export default flow()(AdminGalleries);
