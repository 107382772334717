import { useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { clone } from 'lodash';

import { TextField, IconButton, Button } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Close';

const PREFIX = 'RenderSubParameter';

const classes = {
  container: `${PREFIX}-container`,
  colon: `${PREFIX}-colon`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    alignItems: 'center',
    display: 'flex',
    margin: `${theme.spacing(1)} 0`,
    flexGrow: '1'
  },

  [`& .${classes.colon}`]: {
    margin: `0 ${theme.spacing(1)}`
  }
}));

const SubParameter = ({
  parameterKey,
  parameterValue,
  onSubValueChange,
  parameterIndex,
  onRemoveSubParameter
}) => {
  const [currentKey, setCurrentKey] = useState(parameterKey);
  const [currentValue, setCurrentValue] = useState(parameterValue);

  const onKeyChange = useCallback(
    ({ target: { value } }) => {
      setCurrentKey(value);
    },
    [parameterKey, currentKey, currentValue]
  );

  const onValueChange = useCallback(
    ({ target: { value } }) => {
      setCurrentValue(value);
    },
    [parameterValue, currentValue, currentValue]
  );

  const setParameters = () => {
    onSubValueChange({ key: currentKey, value: currentValue }, parameterIndex);
  };

  return (
    <Root className={classes.container}>
      <TextField
        variant="outlined"
        value={currentKey}
        onChange={onKeyChange}
        onBlur={setParameters}
      />
      <span className={classes.colon}>{' : '}</span>
      <TextField
        variant="outlined"
        value={currentValue}
        onChange={onValueChange}
        onBlur={setParameters}
      />
      <IconButton
        onClick={() => {
          onRemoveSubParameter(parameterIndex);
        }}
        size="large"
      >
        <RemoveIcon />
      </IconButton>
    </Root>
  );
};

const RenderSubParameter = ({ parameterValue, onSubValueChange, type }) => {
  let parameterValues;

  if (type === 'object') {
    parameterValues = parameterValue
      ? Object.keys(parameterValue).map(key => {
          return {
            key,
            value: parameterValue[key]
          };
        })
      : [];
  }

  if (type === 'arrayOfObjects') {
    parameterValues = clone(parameterValue) || [];
  }

  const setNewValue = updatedParameterValues => {
    if (type === 'arrayOfObjects') {
      return onSubValueChange(updatedParameterValues);
    }
    // if type === 'object'
    onSubValueChange(
      updatedParameterValues.reduce((accum, parameter) => {
        // eslint-disable-next-line no-param-reassign
        accum[parameter.key] = parameter.value;
        return accum;
      }, {})
    );
  };

  const subValueChange = (value, parameterIndex) => {
    const updatedParameterValues = parameterValues;

    updatedParameterValues[parameterIndex] = value;

    setNewValue(updatedParameterValues);
  };

  const removeSubParameter = parameterIndex => {
    const updatedParameterValues = parameterValues;
    updatedParameterValues.splice(parameterIndex, 1);

    setNewValue(updatedParameterValues);
  };

  const addSubParameter = () => {
    if (type === 'arrayOfObjects') {
      return onSubValueChange([...parameterValue, { key: '', value: '' }]);
    }
    // if type === 'object'
    onSubValueChange({
      ...parameterValue,
      '': ''
    });
  };

  return (
    <div>
      {parameterValues.map(({ key, value }, index) => {
        return (
          <SubParameter
            key={`${key}-${value}`}
            parameterKey={key}
            parameterValue={value}
            parameterIndex={index}
            onSubValueChange={subValueChange}
            onRemoveSubParameter={removeSubParameter}
          />
        );
      })}
      <Button onClick={addSubParameter}>Add</Button>
    </div>
  );
};

export default RenderSubParameter;
