import { flow, get } from 'lodash';
import { styled } from '@mui/material/styles';
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { graphql } from '@apollo/client/react/hoc';
import { Button, Typography, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import classNames from 'classnames';

import { useModalState } from 'src/components/Modal';

import ErrorMessage from 'src/components/Containers/ErrorMessage';
import PageTitle from 'src/components/PageTitle';
import { withFeatures } from 'src/components/Feature';

import AutomatedProgramsTable from './AutomatedProgramsTable';
import { getArchitectureById } from './queries';
import SelectQuickStartModal from './SelectQuickStartModal';

const PREFIX = 'AutomatedPrograms';

const classes = {
  containerWithMargin: `${PREFIX}-containerWithMargin`,
  rightIcon: `${PREFIX}-rightIcon`,
  headerContainer: `${PREFIX}-headerContainer`,
  button: `${PREFIX}-button`,
  errorContainer: `${PREFIX}-errorContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.containerWithMargin}`]: {
    marginTop: theme.spacing(2)
  },

  [`& .${classes.rightIcon}`]: {
    marginLeft: theme.spacing(1)
  },

  [`& .${classes.headerContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'initial',
      marginBottom: 'initial'
    }
  },

  [`& .${classes.button}`]: {
    [theme.breakpoints.down('md')]: {
      margin: `${theme.spacing(3)} 0`
    }
  },

  [`& .${classes.errorContainer}`]: {
    margin: '0 auto'
  }
}));

const Programs = props => {
  const {
    match,
    architecture,
    error,
    features: { addProgramButton }
  } = props;
  const architectureId = get(match, 'params.architectureId');
  const { open, openModal, closeModal } = useModalState();

  const architectureName = get(architecture, 'name') || '';
  const niceName = architectureName ? `${architectureName}: ` : '';

  const title = t('automatedPrograms:headers.title', {
    niceName
  });

  return (
    <Root>
      <SelectQuickStartModal isSupervisable open={open} onClose={closeModal} />
      <PageTitle subPageTitle={title} />
      <div className={classes.headerContainer}>
        <div className={classes.headerLeft}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle2">
            <Trans i18nKey="automatedPrograms:subTitle">
              Manage your automations
            </Trans>
          </Typography>
        </div>
        <div className={classes.headerRight}>
          {!error && addProgramButton && (
            <Button
              data-amp-click-add-new-program={JSON.stringify({
                architectureId,
                type: 'automated'
              })}
              className={classes.button}
              color="primary"
              size="large"
              onClick={openModal}
              variant="contained"
            >
              <Trans i18nKey="automatedPrograms:buttons.addNew">
                Create Automation
              </Trans>
              <AddIcon className={classes.rightIcon} />
            </Button>
          )}
        </div>
      </div>
      {error ? (
        <>
          <ErrorMessage className={classes.errorContainer}>
            <Trans i18nKey="automatedPrograms:errorGettingPrograms">
              There was an error getting your programs. Please refresh. If the
              problem persists, please contact support.
            </Trans>
          </ErrorMessage>
        </>
      ) : (
        <>
          <Paper
            className={classNames({
              [classes.containerWithMargin]: !addProgramButton
            })}
          >
            <AutomatedProgramsTable
              architectureId={architectureId}
              architectureName={architectureName}
              productIds={[]}
            />
          </Paper>
        </>
      )}
    </Root>
  );
};

export default flow(
  graphql(getArchitectureById, {
    name: 'getArchitectureById',
    props: ({ getArchitectureById: { error, loading, architecture } }) => {
      return {
        loading,
        error,
        architecture
      };
    },
    options: props => ({
      variables: {
        architectureId: get(props, 'match.params.architectureId')
      }
    })
  }),
  withRouter,

  withFeatures
)(Programs);
