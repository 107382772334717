import { useCallback, useState } from 'react';
import { getFormValues, reduxForm, SubmitHandler } from 'redux-form';
import { flow } from 'lodash';
import { useMutation } from '@apollo/client';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { connect, DefaultRootState } from 'react-redux';

import {
  Box,
  Button,
  Link,
  List,
  ListItem,
  Paper,
  Typography
} from '@mui/material';
import { Cancel as CancelIcon } from '@mui/icons-material';

import { DynamicForm, FormAlert } from 'src/components/ReduxForm';
import Modal from 'src/components/Modal';
import Heading from 'src/components/PageElements/Heading';

import { cancelOrder as cancelOrderMutation } from './mutations';
import { cancelOrderInputs } from './utilities';

const FORM_NAME = 'adminCancelOrder';

interface FormValues {
  orderId?: string;
  forceFullRefund?: boolean;
}

interface AdminCancelOrderProps {
  handleSubmit: SubmitHandler;
  formValues: FormValues;
  reset: () => void;
}

const pageText = () => ({
  modalHeader: t('admin:cancelOrder.modalHeader'),
  modalThisWill: t('admin:cancelOrder.modalThisWill'),
  modalCancelOrderBulletPoint: t(
    'admin:cancelOrder.modalCancelOrderBulletPoint'
  ),
  modalForceFullRefundBulletPoint: t(
    'admin:cancelOrder.modalForceFullRefundBulletPoint'
  ),
  modalAreYouSure: t('admin:cancelOrder.modalAreYouSure'),
  closeButton: t('admin:cancelOrder.cancelButton'),
  cancelOrderButton: t('admin:cancelOrder.cancelOrderButton'),
  pageHeading: t('admin:headings.cancelOrder'),
  pageSubtitle: t('admin:subtitles.cancelOrder'),
  cancellationError: t('admin:cancelOrder.cancellationError'),
  cancelAnotherOrderButton: t('admin:cancelOrder.cancelAnotherOrderButton')
});

const AdminCancelOrder = ({
  handleSubmit,
  formValues,
  reset
}: AdminCancelOrderProps) => {
  const [open, setOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [cancelOrder, { loading, error }] = useMutation(cancelOrderMutation);

  const text = pageText();

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  const onSubmit = useCallback(
    async data => {
      const { orderId, forceFullRefund } = data;

      const result = await cancelOrder({
        variables: {
          orderId,
          forceFullRefund
        }
      }).catch(() => {
        closeModal();
      });

      if (result) {
        setIsSuccess(true);
      }

      closeModal();
    },
    [cancelOrder]
  );

  const submitForm = () => {
    handleSubmit(onSubmit)(formValues);
  };

  const resetForm = () => {
    reset();
    setIsSuccess(false);
  };

  const isCancelDisabled = !formValues?.orderId?.trim() || loading;

  return (
    <>
      <Heading
        title={text.pageHeading}
        subTitle={text.pageSubtitle}
        pageTitle={text.pageHeading}
      />
      <form autoComplete="off">
        <Paper sx={theme => ({ mt: theme.spacing(2), p: theme.spacing(2) })}>
          {isSuccess ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme => theme.spacing(4),
                alignItems: 'flex-start'
              }}
            >
              <FormAlert severity="success">
                <Trans
                  i18nKey="admin:cancelOrder.successDescription"
                  values={{ orderId: formValues?.orderId }}
                />
                <br />
                <br />
                <Button
                  sx={{ marginBottom: theme => theme.spacing(2) }}
                  color="primary"
                  component={Link}
                  size="large"
                  href={`https://evocalize.tryretool.com/apps/af5dc552-43cf-11ee-b4a7-4f23c997a992/Office/OrderDetail#id=${formValues?.orderId}`}
                  variant="outlined"
                  target="_blank"
                >
                  {t('admin:cancelOrder.checkStatusButton')}
                </Button>
              </FormAlert>
              <Box
                sx={{
                  display: 'flex',
                  gap: theme => theme.spacing(1)
                }}
              >
                <Button
                  data-cy="cancel-another-order-button"
                  variant="contained"
                  onClick={resetForm}
                >
                  {text.cancelAnotherOrderButton}
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              {error && (
                <Box sx={{ mb: theme => theme.spacing(2) }}>
                  <FormAlert severity="error">
                    <>
                      <Typography variant="body1">
                        {text.cancellationError}
                      </Typography>
                    </>
                  </FormAlert>
                </Box>
              )}
              <Box
                sx={{
                  '& [data-cy="forceFullRefund-root-ele"]': {
                    mt: 1
                  }
                }}
                data-cy="admin-create-user-form"
              >
                <DynamicForm inputs={cancelOrderInputs} />
              </Box>
              <div>
                <Button
                  color="primary"
                  disabled={isCancelDisabled}
                  variant="contained"
                  data-cy="admin-cancel-order-button"
                  sx={{ mt: theme => theme.spacing(2) }}
                  onClick={openModal}
                  loading={loading}
                >
                  {text.cancelOrderButton}
                </Button>
              </div>
            </>
          )}
        </Paper>
      </form>
      <Modal
        showClose
        open={open}
        onClose={closeModal}
        HeaderComponent={() => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: -1 }}>
            <CancelIcon />
            <Typography component="h1" variant="h6" sx={{ fontWeight: 600 }}>
              {text.modalHeader}
            </Typography>
          </Box>
        )}
        FooterComponent={() => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2
            }}
          >
            <Button onClick={closeModal}>{text.closeButton}</Button>
            <Button
              disabled={isCancelDisabled}
              variant="contained"
              onClick={submitForm}
              data-cy="admin-cancel-order-confirmation-button"
            >
              {text.cancelOrderButton}
            </Button>
          </Box>
        )}
      >
        <Box
          sx={{
            display: 'flex',
            listStylePosition: 'inside',
            flexDirection: 'column',
            mt: -0.5
          }}
        >
          <Typography variant="body1">
            {text.modalThisWill}

            <List sx={{ listStyleType: 'disc', mt: 1, ml: 2, mb: 0, pl: 2 }}>
              <ListItem sx={{ display: 'list-item', pl: 0, py: 0 }}>
                {text.modalCancelOrderBulletPoint}
              </ListItem>
              {formValues?.forceFullRefund && (
                <ListItem sx={{ display: 'list-item', pl: 0, py: 0 }}>
                  {text.modalForceFullRefundBulletPoint}
                </ListItem>
              )}
            </List>
          </Typography>

          <br />
          <Typography variant="body1">{text.modalAreYouSure}</Typography>
        </Box>
      </Modal>
    </>
  );
};

interface StateProps {
  formValues: FormValues;
}

interface OwnProps {
  handleSubmit: SubmitHandler;
}

const mapStateToProps = (state: DefaultRootState) => {
  const formValues = getFormValues(FORM_NAME)(state);

  return { formValues };
};

export default flow(
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: true,
    initialValues: {
      orderId: '',
      forceFullRefund: false
    }
  }),
  connect<StateProps, null, OwnProps>(mapStateToProps, null)
)(AdminCancelOrder);
