import { useState } from 'react';
import { keys, values } from 'lodash';
import { t } from 'i18next';

import { Box, Button, Grid, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { translateMaps } from 'src/common/templateTranslator';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal/ConfirmationModal';
import TextCarousel from 'src/components/AdPreview/IncludedButton/TextCarousel';
import ImageCarousel from 'src/components/AdPreview/IncludedButton/ImageCarousel';
import Instrumentation from 'src/instrumentation';

interface IncludedButtonProps {
  selected: { [key: string]: any }[];
  isRebelIqBlueprint: boolean;
}

export interface MetaField {
  body: string;
  headline: string;
  description: string;
  identifier: number;
}

export interface GoogleField {
  headline: string;
  description: string;
  identifier: number;
}

const groupFields = (
  data: Record<string, string>
): { meta: MetaField[]; google: GoogleField[] } => {
  const groupedMeta: Record<string, MetaField> = {};
  const groupedGoogle: Record<string, GoogleField> = {};

  const metaRegex = /^meta_(body_text|headline|description)_(\d+)$/;
  const googleRegex = /^google_(headline|description)_(\d+)$/;

  keys(data).forEach(key => {
    let match: RegExpMatchArray | null;

    // Handle meta fields
    match = key.match(metaRegex);
    if (match !== null) {
      const [, type, identifier] = match;
      if (!groupedMeta[identifier]) {
        groupedMeta[identifier] = {
          body: '',
          headline: '',
          description: '',
          identifier: parseInt(identifier, 10)
        };
      }

      if (type === 'body_text') {
        groupedMeta[identifier].body = data[key];
      } else if (type === 'headline') {
        groupedMeta[identifier].headline = data[key];
      } else if (type === 'description') {
        groupedMeta[identifier].description = data[key];
      }
      return;
    }

    // Handle google fields
    match = key.match(googleRegex);
    if (match !== null) {
      const [, type, identifier] = match;
      if (!groupedGoogle[identifier]) {
        groupedGoogle[identifier] = {
          headline: '',
          description: '',
          identifier: parseInt(identifier, 10)
        };
      }

      // Ensure that type is either 'headline' or 'description'
      if (type === 'headline' || type === 'description') {
        groupedGoogle[identifier][type] = data[key];
      }
    }
  });

  // Convert grouped objects to arrays and filter out incomplete entries
  const metaArray = values(groupedMeta).filter(
    item => item.body && item.headline && item.description
  );
  const googleArray = values(groupedGoogle).filter(
    item => item.headline && item.description
  );

  return { meta: metaArray, google: googleArray };
};

const IncludedButton = ({
  selected,
  isRebelIqBlueprint
}: IncludedButtonProps) => {
  const architecture = useArchitecture();
  const [modalOpen, setModalState] = useState(false);

  const setModalOpen = () => {
    setModalState(true);
    Instrumentation.logEvent('click-whats-included--content-details', {
      contentIds: selected.map(item => item?.id || '').filter(id => id !== '')
    });
  };

  const setModalClosed = () => setModalState(false);

  const catalogFieldMetadata = architecture?.catalog?.fieldMetadata || [];

  const imageFieldNames = catalogFieldMetadata
    ?.filter(item => item.displayMethodId === 'image_url')
    ?.map(item => item.fieldName)
    ?.sort();

  const images =
    imageFieldNames?.map(imageFieldName => selected[0]?.[imageFieldName]) || [];
  const textData = groupFields(selected[0]);

  const fallbackNameTemplate = 'Theme';
  const displayNameTemplate =
    architecture?.catalog?.displayNameTemplate || fallbackNameTemplate;
  const displayName = translateMaps(displayNameTemplate, selected[0]);

  return (
    <>
      <Button
        onClick={setModalOpen}
        sx={{
          width: '100%',
          backgroundColor: theme => theme.palette.grey[300],
          color: 'primary.main'
        }}
        endIcon={<OpenInNewIcon />}
      >
        {t('common:plg.whatsIncluded.button')}
      </Button>
      <ConfirmationModal
        open={modalOpen}
        title={t('common:plg.whatsIncluded.modal.title', {
          displayName
        })}
        onClose={setModalClosed}
        onConfirm={setModalClosed}
        confirmButtonText="Done"
      >
        <>
          <Box>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {isRebelIqBlueprint
                ? t('common:plg.whatsIncluded.modal.description')
                : t('common:plg.whatsIncluded.modal.descriptionNoLandingPage')}
              <br />
              <strong>
                {t('common:plg.whatsIncluded.modal.exploreContent')}
              </strong>
            </Typography>
          </Box>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <ImageCarousel images={images} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextCarousel textData={textData} />
            </Grid>
          </Grid>
        </>
      </ConfirmationModal>
    </>
  );
};

export default IncludedButton;
