import { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { graphql } from '@apollo/client/react/hoc';
import { flow, isEmpty } from 'lodash';
import { Switch, FormControlLabel, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/HelpOutline';

import { getWebhookSlug } from './queries';

const PREFIX = 'RenderOrgWebhookSwitch';

const classes = {
  helpIcon: `${PREFIX}-helpIcon`,
  labelWithTipContainer: `${PREFIX}-labelWithTipContainer`
};

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  [`& .${classes.helpIcon}`]: {
    color: theme.palette.grey[500],
    marginLeft: '14px',
    width: '20px'
  },

  [`& .${classes.labelWithTipContainer}`]: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const RenderOrgWebhookSwitch = props => {
  const { getWebhookSlug, input, className, color, disabled, label, tooltip } =
    props;

  const [checked, setChecked] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      setChecked(!isEmpty(input.value));
    }
    return () => {
      isMounted.current = false;
    };
  }, [input.value]);

  const onChange = value => {
    setChecked(value.target.checked);
    if (value.target.checked) {
      return input.onChange([
        getWebhookSlug?.myOrganization?.webhookSubscription?.slug
      ]);
    }
    return input.onChange([]);
  };

  const webhookSlug = getWebhookSlug?.myOrganization?.webhookSubscription?.slug;

  const webhookLabel = `Include ${webhookSlug} in Webhooks`;

  const control = (
    <Switch
      className={className}
      color={color}
      value={input.value}
      checked={checked}
      disabled={disabled || !webhookSlug}
      onChange={onChange}
    />
  );

  const labelWithToolTip =
    tooltip || !webhookSlug ? (
      <span className={classes.labelWithTipContainer}>
        {label}
        <Tooltip arrow title="Create a webhook first">
          <HelpIcon className={classes.helpIcon} />
        </Tooltip>
      </span>
    ) : (
      webhookLabel
    );

  return <StyledFormControlLabel control={control} label={labelWithToolTip} />;
};

export default flow(
  graphql(getWebhookSlug, {
    name: 'getWebhookSlug',
    options: () => ({
      fetchPolicy: 'no-cache'
    })
  })
)(RenderOrgWebhookSwitch);
