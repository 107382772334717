import React from 'react';
import { toNumber, isNaN, get } from 'lodash';
import { useWatch, useFieldArray } from 'react-hook-form';
import { Box, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';

import RenderFilterOperator from './RenderFilterOperator';
import {
  getInputByColumn,
  getValidationByColumn,
  getDefaultFilterValueByColumn,
  ContentSetFieldMetadata
} from './helpers';
import { CONTENT_COL_TYPES, AutomatedProgramFilterType } from './constants';

const validateNumberRange = (
  value: string,
  allValues: any,
  props: any,
  fieldName: string
) => {
  const values = get(allValues, fieldName.slice(0, -3)); // { gt:0, lt:100 }
  const operator = fieldName.slice(-2); // 'gt' or 'lt';

  if (operator === 'gt' && value >= values?.lt) {
    return 'must be less than high value';
  }
  if (operator === 'lt' && value <= values?.gt) {
    return 'must be greater than low value';
  }
};

const memoizedValidations: { [key: string]: object } = {};
const getMemoizedValidationByColumn = (
  column: ContentSetFieldMetadata,
  operator: string = ''
) => {
  // some operators need extra validation (eg range)
  const key = `${column?.displayMethodId}-${operator}`;
  if (!memoizedValidations[key]) {
    memoizedValidations[key] = getValidationByColumn(column);
    // if there is more than range we can add a get validation by operator func
    if (operator === 'range') {
      memoizedValidations[key] = {
        ...memoizedValidations[key],
        validateNumberRange
      };
    }
  }
  return memoizedValidations[key];
};

type RulesFilterProps = {
  contentMeta: ContentSetFieldMetadata[];
  columnValue: AutomatedProgramFilterType;
  columnDisplayName: string;
  deleteColumn: () => void;
  name: string;
  filterIndex: number;
};

const Filter = ({
  contentMeta,
  columnValue,
  columnDisplayName,
  deleteColumn,
  name,
  filterIndex
}: RulesFilterProps) => {
  const { fields: rules, ...rulesArrayMethods } = useFieldArray({
    name
  });

  const allRules = useWatch({ name, defaultValue: [] });

  const selectedColumn = contentMeta.find(
    c => c.fieldName === columnValue?.column
  ) as ContentSetFieldMetadata;

  return (
    <>
      {rules.map((rule, index) => {
        const value = allRules[index];
        const selectedOperator = value?.operator;
        const isRange = selectedOperator === 'range';

        const ValueComponent = getInputByColumn(selectedColumn);

        const onChangeOperator = (e: React.BaseSyntheticEvent) => {
          const value = getDefaultFilterValueByColumn(
            selectedColumn,
            e.target.value
          );
          if (e.target.value === 'range') {
            rulesArrayMethods.update(index, {
              operator: e.target.value,
              value
            });
          } else {
            rulesArrayMethods.update(index, {
              operator: e.target.value,
              value
            });
          }
        };

        const normalize = (value: any) => {
          if (selectedColumn.contentColumnType === CONTENT_COL_TYPES.NUMBER) {
            const number = toNumber(value);
            if (!value || isNaN(number)) {
              return value;
            }

            return number;
          }
          return value;
        };

        return (
          <tr
            data-cy="filter-rule"
            data-cy-key={`filters[${filterIndex}].rules[${index}]`}
            key={rule.id}
          >
            <Box
              component="th"
              sx={{
                position: 'relative',
                borderRight: `1px solid grey.400}`,
                verticalAlign: 'top',
                fontSize: '1rem',
                textAlign: 'right',
                background: 'grey.100',
                whiteSpace: 'nowrap',
                '& div:first-child': {
                  paddingTop: '7px'
                }
              }}
            >
              <span style={{ position: 'relative' }}>
                {index > 0 && (
                  <Box
                    component="span"
                    sx={{
                      display: 'inline-block',
                      width: theme => theme.spacing(4),
                      height: theme => theme.spacing(4),
                      lineHeight: theme => theme.spacing(4),
                      borderRadius: theme => theme.spacing(2),
                      textAlign: 'center',
                      fontWeight: 'bold',
                      background: 'grey[100]',
                      padding: '0',
                      position: 'absolute',
                      top: '0',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      mt: -2
                    }}
                  >
                    or
                  </Box>
                )}
                <Box
                  component="span"
                  sx={{
                    position: 'relative',
                    top: '6px'
                  }}
                >
                  {columnDisplayName}
                </Box>
              </span>
            </Box>
            <Box
              component="td"
              sx={{
                position: 'relative',
                '& > div': {
                  pr: 2
                },
                ...(index === rules.length - 1 && { borderBottom: 'none' })
              }}
            >
              <RenderFilterOperator
                name={`${name}.${index}.operator`}
                onChangeOperator={onChangeOperator}
                selectedColumn={selectedColumn}
              />
              {isRange ? (
                <>
                  <ValueComponent
                    name={`${name}.${index}.value.gt`}
                    selectedColumn={selectedColumn}
                    validate={getMemoizedValidationByColumn(
                      selectedColumn,
                      selectedOperator
                    )}
                    normalize={normalize}
                  />
                  <Box
                    component="span"
                    sx={{
                      position: 'relative',
                      top: '5px',
                      mr: 2,
                      fontWeight: 'bold',
                      padding: theme => `0 ${theme.spacing(1)}`
                    }}
                  >
                    AND
                  </Box>
                  <ValueComponent
                    name={`${name}.${index}.value.lt`}
                    selectedColumn={selectedColumn}
                    validate={getMemoizedValidationByColumn(
                      selectedColumn,
                      selectedOperator
                    )}
                    normalize={normalize}
                  />
                </>
              ) : (
                <ValueComponent
                  name={`${name}.${index}.value`}
                  selectedColumn={selectedColumn}
                  validate={getMemoizedValidationByColumn(
                    selectedColumn,
                    selectedOperator
                  )}
                  normalize={normalize}
                />
              )}
            </Box>
            <td>
              <IconButton
                size="small"
                data-cy="delete-rule"
                onClick={() => {
                  rulesArrayMethods.remove(index);
                  // remove the whole column if this is the last rule
                  if (rules.length <= 1) {
                    deleteColumn();
                  }
                }}
              >
                <CancelIcon />
              </IconButton>
            </td>
          </tr>
        );
      })}
      <tr>
        <Box
          component="td"
          sx={{
            p: 1,
            position: 'relative',
            textAlign: 'center',
            cursor: 'pointer',
            color: 'primary.main',
            fontWeight: 'bold'
          }}
          colSpan={3}
          onClick={() => {
            rulesArrayMethods.append({
              operator: 'eq',
              value: getDefaultFilterValueByColumn(selectedColumn, 'eq')
            });
          }}
        >
          <AddCircleIcon
            color="primary"
            fontSize="small"
            style={{ verticalAlign: 'middle' }}
          />{' '}
          Add {columnDisplayName} Rule
        </Box>
      </tr>
    </>
  );
};

export default Filter;
