import React, { useState } from 'react';
import {
  Chip,
  Divider,
  Fade,
  Paper,
  Popper,
  Tooltip,
  Typography,
  Tabs,
  Tab,
  Box
} from '@mui/material';
import { noop, sortBy } from 'lodash';
import {
  DynamicValueColumns,
  DynamicValue
} from 'src/components/ReduxForm/commonTypes';
import AddIcon from '@mui/icons-material/Add';
import { t } from 'i18next';
import { preventDefault } from 'src/common/functionUtil';

const pageText = () => ({
  chipHeader: t('renderTemplateStringTextField:chipSelector.header'),
  missingChipTooltip: t('renderTemplateStringTextField:missingTooltipChip'),
  scrollForMore: t('renderTemplateStringTextField:chipSelector.scrollMore')
});

export interface DynamicValueSelectorProps {
  missingColumns: Set<string>;
  dynamicValues: DynamicValueColumns[] | undefined;
  anchorElement: HTMLElement | null;
  handleAddTag: (tagName: string) => void;
  active: boolean | undefined;
}

export const DynamicValueSelector = ({
  dynamicValues = [],
  anchorElement,
  active,
  missingColumns,
  handleAddTag
}: DynamicValueSelectorProps) => {
  const text = pageText();
  const [tabValue, setTabValue] = useState(0);
  const allColumns = dynamicValues?.reduce((acc, type) => {
    return [...acc, ...type.columns];
  }, [] as DynamicValue[]);

  // Only render the column picker if we have dynamic values.
  if (
    !Array.isArray(dynamicValues) ||
    !dynamicValues.length ||
    !allColumns?.length
  ) {
    return <span />;
  }

  return (
    <Popper
      sx={{
        zIndex: theme => theme.zIndex.tooltip + 1
      }}
      anchorEl={anchorElement}
      id="temp-string-popper"
      open={Boolean(active)}
      placement="top"
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            sx={{
              mb: 2,
              maxWidth: '350px' // TODO: We should probably figure out how to make this more dynamic
            }}
            data-cy="dynamic-field-chips"
          >
            <Typography
              sx={{
                p: 1,
                textAlign: 'center',
                fontWeight: 'bold'
              }}
              variant="body2"
            >
              {text.chipHeader}
            </Typography>
            <Divider />
            <Tabs
              value={tabValue}
              sx={{ paddingLeft: 2, paddingRight: 2 }}
              data-cy="dynamic-value-selector-tabs"
            >
              {dynamicValues.map((columnType, index) => (
                <Tab
                  key={columnType.type}
                  label={columnType?.friendlyName || ''}
                  onMouseDown={preventDefault}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTabValue(index);
                  }}
                />
              ))}
            </Tabs>
            {dynamicValues.map((columnType, index) => (
              <>
                {tabValue === index && (
                  <Box sx={{ pt: 0, pr: 1, pb: 1, pl: 1 }}>
                    <Box
                      component="div"
                      sx={{
                        maxHeight: '200px',
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                          '-webkit-appearance': 'none',
                          width: '7px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                          borderRadius: '4px',
                          backgroundColor: 'rgba(0,0,0,.5)',
                          '-webkit-box-shadow': '0 0 1px rgba(255,255,255,.5)'
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'grey.100'
                        }
                      }}
                    >
                      {sortBy(columnType.columns, o => o.displaySortOrder).map(
                        item => {
                          const isMissing = missingColumns.has(item.fieldName);

                          let clickHandler = () => handleAddTag(item.value);
                          if (isMissing) {
                            clickHandler = noop;
                          }
                          const chip = (
                            <Chip
                              key={`rtstf-${item.value}`}
                              sx={{
                                m: 1,
                                ...(isMissing && {
                                  color: '#bbb',
                                  borderColor: '#ddd',
                                  backgroundColor: '#f9f9f9'
                                })
                              }}
                              icon={isMissing ? undefined : <AddIcon />}
                              label={item.label}
                              // Prevent default so we don't lose focus
                              onMouseDown={preventDefault}
                              clickable={!isMissing}
                              onClick={clickHandler}
                              variant={isMissing ? 'outlined' : undefined}
                            />
                          );
                          if (!isMissing) {
                            return chip;
                          }
                          return (
                            <Tooltip
                              title={text.missingChipTooltip}
                              key={`rtstf-${item.value}`}
                            >
                              {chip}
                            </Tooltip>
                          );
                        }
                      )}
                    </Box>
                  </Box>
                )}
              </>
            ))}
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};
