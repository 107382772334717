import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { FormSection, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';

import { Typography, Divider } from '@mui/material';

import { DynamicForm } from 'src/components/ReduxForm';

import {
  BLUEPRINT_BUILDER_FORM_NAME,
  getMessagingInputs,
  getContentAwareDashboardInputs,
  getSellingPointsInputs
} from '../Constants';
import BlueprintBuilderStepFooter from './BlueprintBuilderStepFooter';

const PREFIX = 'StepFinalize';

const classes = {
  subHeading: `${PREFIX}-subHeading`,
  sectionHeading: `${PREFIX}-sectionHeading`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.subHeading}`]: {
    marginBottom: theme.spacing(2)
  },

  [`& .${classes.sectionHeading}`]: {
    margin: `${theme.spacing(2)} 0 ${theme.spacing(1)} 0`
  }
}));

const pageText = () => ({
  stepHeading: t('admin:blueprintBuilder.stepFinalizeStepHeading'),
  quickstartSection: t(
    'admin:blueprintBuilder.stepFinalizeStepQuickstartSection'
  ),
  contentAwareDashboardSection: t(
    'admin:blueprintBuilder.stepFinalizeStepContentAwareDashboard'
  ),
  messagingSection: t(
    'admin:blueprintBuilder.stepFinalizeStepMessagingSection'
  ),
  sellingPoints: t('admin:blueprintBuilder.stepFinalizeStepSellingPoints')
});

const StepFinalize = props => {
  const { formValues } = props;
  const contentAwareDashboardEnabled =
    !!formValues?.document?.contentDrivenPreviewConfig?.enabled;

  const text = useMemo(() => pageText(), []);

  return (
    <Root>
      <Typography variant="h6">{text.stepHeading}</Typography>
      <Typography className={classes.sectionHeading} variant="h6">
        {text.contentAwareDashboardSection}
      </Typography>
      <Divider sx={{ mt: 0.5, mb: 2 }} />
      <FormSection name="document.contentDrivenPreviewConfig">
        <DynamicForm
          inputs={getContentAwareDashboardInputs(contentAwareDashboardEnabled)}
        />
      </FormSection>
      <Typography className={classes.sectionHeading} variant="h6">
        {text.messagingSection}
      </Typography>
      <Divider sx={{ mt: 0.5, mb: 2 }} />
      <FormSection name="document.messaging">
        <DynamicForm inputs={getMessagingInputs()} />
        <DynamicForm inputs={getSellingPointsInputs()} />
      </FormSection>
      <BlueprintBuilderStepFooter />
    </Root>
  );
};

export default flow(
  connect(state => {
    return { formValues: getFormValues(BLUEPRINT_BUILDER_FORM_NAME)(state) };
  })
)(StepFinalize);
