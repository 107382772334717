import { useState, useEffect, useRef } from 'react';
import {
  Box,
  Stack,
  Typography,
  CircularProgress,
  Tooltip
} from '@mui/material';
import {
  ShowChart as ChartIcon,
  Warning as WarningIcon,
  InfoOutlined as InfoIcon
} from '@mui/icons-material';
import { t } from 'i18next';

import AnimatedCounter from 'src/components/AnimatedCounter';

interface ResultsEstimationProps {
  isSubscription?: boolean;
  hasApiError?: boolean;
  hasValidationError?: boolean;
  lowerBoundResultCount?: number;
  upperBoundResultCount?: number;
  resultKpi?: string;
  isLoading?: boolean;
  isAutomated?: boolean;
  isMlp?: boolean;
}

const ResultsEstimation = ({
  isSubscription,
  hasValidationError,
  hasApiError,
  lowerBoundResultCount,
  upperBoundResultCount,
  resultKpi,
  isLoading,
  isAutomated,
  isMlp
}: ResultsEstimationProps) => {
  const [showLoading, setShowLoading] = useState(false);
  const timeoutRef = useRef<number>();

  // Only show loading state if loading takes longer than 0.5 seconds
  // this prevents the loading state from flickering when loading times are fast
  useEffect(() => {
    if (isLoading) {
      timeoutRef.current = window.setTimeout(() => setShowLoading(true), 500);
    } else {
      setShowLoading(false);
    }

    return () => {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, [isLoading]);

  const isInitialLoad = !resultKpi && isLoading;
  // validationError only happens when offer type is one time payment
  const hasError = (hasValidationError && !isSubscription) || hasApiError;

  const getIcon = () => {
    if ((isInitialLoad || showLoading) && !hasError)
      return <CircularProgress size={16} color="primary" />;
    if (hasError) return <WarningIcon sx={{ color: 'error.main' }} />;
    return <ChartIcon sx={{ color: 'success.main' }} />;
  };

  const getNumberFormatter = (number: number) => {
    // If less than 10,000
    if (number < 10000) return '0,0';
    // If less than 1,000,000 but greater than 1,000
    if (number < 1000000) return '0.0a';
    // If greater than or equal to 1,000,000
    if (number >= 1000000) return '0.00a';
    return '0,0b';
  };

  return (
    <Stack
      display="inline-flex"
      spacing={1}
      flexShrink={1}
      alignItems="flex-start"
      data-cy="results-estimation"
    >
      <Stack
        direction="row"
        spacing={isInitialLoad || showLoading ? 2 : 1}
        alignItems="center"
        sx={{
          p: 2.5,
          backgroundColor: 'grey.200',
          pr: 4,
          borderRadius: 1
        }}
      >
        <>
          {getIcon()}
          <Typography
            component="p"
            fontWeight="regular"
            sx={{ color: hasError ? 'error.main' : 'initial' }}
          >
            {/* API Error UI */}
            {hasApiError && t('programCreate:spend.resultsEstimationApiError')}
            {/* Validation Error UI */}
            {hasValidationError &&
              t('programCreate:spend.resultsEstimationValidationError')}
            {/* initial loading UI for when we don't have numbers or objective type */}
            {!hasError &&
              !resultKpi &&
              t('programCreate:spend.resultsEstimationLoading')}
            {/* Results UI */}
            {!hasError && resultKpi && (
              <>
                {`${t('programCreate:spend.estimated')}: `}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  <AnimatedCounter
                    end={lowerBoundResultCount || 0}
                    format={getNumberFormatter(lowerBoundResultCount || 0)}
                  />
                  {` `}-{` `}
                  <AnimatedCounter
                    end={upperBoundResultCount || 0}
                    format={getNumberFormatter(upperBoundResultCount || 0)}
                  />
                  {` ${resultKpi}`}
                </Box>
                {isSubscription &&
                  ` /${t('programCreate:spend.resultsEstimateSubDurationUnit')}`}
              </>
            )}
            {!hasError && (
              <>
                {` `}
                <Tooltip
                  data-cy="results-estimation-tooltip"
                  title={t('programCreate:spend.resultsEstimationTooltip')}
                >
                  <InfoIcon
                    sx={{
                      color: 'grey.600',
                      width: 16,
                      height: 16,
                      position: 'relative',
                      top: '3px'
                    }}
                  />
                </Tooltip>
              </>
            )}
            {(isAutomated || isMlp) && !hasError && (
              <>
                {` `}
                <Box
                  component="span"
                  sx={{ fontSize: theme => theme.typography.caption.fontSize }}
                >
                  (
                  {isAutomated &&
                    t('programCreate:spend.resultsEstimatePerProgram')}
                  {isMlp && t('programCreate:spend.resultsEstimatePerLocation')}
                  )
                </Box>
              </>
            )}
          </Typography>
        </>
      </Stack>
    </Stack>
  );
};

export default ResultsEstimation;
