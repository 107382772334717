import { t } from 'i18next';

import Message from 'src/components/Containers/Message';
import ClientHtml from 'src/components/ClientHtml';
import IncludedButton from 'src/components/AdPreview/IncludedButton/IncludedButton';
import { themeBasedCatalogIds } from 'src/components/BusinessObjectSelector/constants';
import { tiktokCreativeType } from 'src/common/adChannels';

import TiktokAdPreview from './TiktokAdPreview';
import PhoneMockAdPreviewWrapper from '../PhoneMockAdPreviewWrapper';
import { getMessageIconSize, getMessageMaxWidth } from '../helpers';

const pageText = () => ({
  unsupportedMessage: t('adPreview:messages.unsupportedGoogleType')
});

const CreateTiktokAdPreview = ({
  blueprint,
  creativeType,
  adPreviewSet, // expects one of the previews from getAdPreviewSet
  showMessaging = true,
  hasUniformPreviewWidth,
  displayAsPhoneMockUp,
  isResponsive,
  businessObjects,
  architecture,
  showIncludedButton
}) => {
  const footerMessage = blueprint?.messaging?.adPreviewFooterMessage || null;
  const blueprintTypeMessage = null;
  const tiktokPreviewProps = {
    blueprint,
    hasUniformPreviewWidth,
    isResponsive,
    adData: {}
  };
  const text = pageText();

  switch (creativeType) {
    case tiktokCreativeType.tiktokSingleVideo:
      tiktokPreviewProps.adData = adPreviewSet?.tikTokSingleVideoCreative;
      break;
    default:
      return text.unsupportedMessage;
  }

  const catalogId = architecture?.catalog?.id;
  const isRebelIqBlueprint = blueprint?.isRebelIqEnabled;
  const adPreview = <TiktokAdPreview {...tiktokPreviewProps} />;

  return (
    <>
      {displayAsPhoneMockUp ? (
        <PhoneMockAdPreviewWrapper>{adPreview}</PhoneMockAdPreviewWrapper>
      ) : (
        adPreview
      )}

      {showIncludedButton && themeBasedCatalogIds.includes(catalogId) && (
        <IncludedButton
          selected={businessObjects}
          isRebelIqBlueprint={isRebelIqBlueprint}
        />
      )}

      {showMessaging && footerMessage && (
        <Message
          sx={{
            margin: '8px auto 8px auto',
            whiteSpace: 'normal !important',
            width: '100%'
          }}
          iconSize={getMessageIconSize(displayAsPhoneMockUp)}
          type="info"
          maxWidth={getMessageMaxWidth(displayAsPhoneMockUp)}
        >
          <ClientHtml html={footerMessage} />
        </Message>
      )}

      {showMessaging && blueprintTypeMessage && (
        <Message
          sx={{
            margin: '8px auto 8px auto',
            whiteSpace: 'normal !important',
            width: '100%'
          }}
          iconSize={getMessageIconSize(displayAsPhoneMockUp)}
          type="info"
          maxWidth={getMessageMaxWidth(displayAsPhoneMockUp)}
        >
          {blueprintTypeMessage}
        </Message>
      )}
    </>
  );
};

export default CreateTiktokAdPreview;
