import { Box, Skeleton, Stack } from '@mui/material';

import { CARD_WIDTH } from 'src/components/BusinessObjectSelector/ContentThemeSelector';

const ContentThemeSelectorSkeleton = ({ cardCount = 3 }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      margin: 'auto',
      maxWidth: '100%',
      width: 'calc(100% - 60px)'
    }}
  >
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      sx={{
        position: 'relative',
        width: '100%',
        justifyContent: 'center'
      }}
    >
      {Array.from({ length: cardCount }, (_, index) => (
        <Box
          key={index}
          sx={{ flex: '1 1 auto', width: CARD_WIDTH, maxWidth: CARD_WIDTH }}
        >
          <Skeleton
            variant="rectangular"
            height={170}
            sx={{ borderRadius: 2 }}
          />
          <Skeleton variant="text" height={40} sx={{ mt: 1 }} />
        </Box>
      ))}
    </Stack>
  </Box>
);

export default ContentThemeSelectorSkeleton;
