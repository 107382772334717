import {
  SupervisorOrder,
  Offer,
  BillingDetail
} from 'src/generated/gql/graphql';
import { OFFER_TYPES } from 'src/common/offers';
import { getValidProductOffers } from 'src/pages/Program/utils/offers';
import { getInitialValuesProgramName } from 'src/pages/Program/ProgramName/ProgramNameUtil';

import { AUTOMATED_DEFAULT_DAYS, scheduleTypes } from '../../Constants';

const orderOverrides = ({
  billingDetails,
  offer
}: {
  billingDetails: BillingDetail;
  offer: Partial<Offer>;
}) => {
  return {
    ...(offer?.type === OFFER_TYPES.purchase && {
      oneTimeSpend: billingDetails?.amount
    }),
    ...(offer?.type === OFFER_TYPES.subscription && {
      subscription: `tier:${billingDetails?.tierId}`,
      subscriptionSpend: billingDetails?.amount
    })
  };
};

const supervisorOrderOverrides = (automation: SupervisorOrder) => {
  return {
    oneTimeSpend: automation?.paymentAmount,
    paymentMethodId: automation?.paymentMethodId,
    stripeSourceId: automation?.paymentMethod?.stripeSourceId,
    ...(automation?.tier?.id && {
      subscription: `tier:${automation?.tier?.id}`,
      subscriptionSpend: automation?.tier?.amount
    }),
    scheduleDays: automation?.childOrderDurationDays,
    // Note: This puts the promo code in but does not validate it. Validation happens in the spend
    //       step and that will fill out the rest of the promo with discount etc ...
    promoCodes: automation?.promoCode
      ? [{ promoCode: automation?.promoCode }]
      : [],
    programName: automation?.childOrderNameTemplate
  };
};

type DraftOrder = {
  billingDetails: {
    amount: number;
    tierId: string;
  };
  childOrderDurationDays: number;
  childOrderNameTemplate: string;
};

const draftOverrides = (order: DraftOrder) => {
  return {
    oneTimeSpend: order?.billingDetails?.amount,
    ...(order?.billingDetails?.tierId && {
      subscription: `tier:${order?.billingDetails?.tierId}`,
      subscriptionSpend: order?.billingDetails?.amount
    }),
    ...(order?.childOrderDurationDays && {
      scheduleDays: order?.childOrderDurationDays
    }),
    ...(order?.childOrderNameTemplate && {
      programName: order?.childOrderNameTemplate
    })
  };
};

interface GetInitialSpendStepValuesArgs {
  startDate: string;
  endDate: string;
  offersChanged: boolean;
  // order is too complex atm
  orderToClone: any | undefined;
  automation?: SupervisorOrder;
  preselectedPromoCodes: { promoCode: string }[];
  selectedBlueprint: any;
  architecture: any;
  isAutomated: boolean;
  isDraft: boolean;
}

// Get spend step initial values
export const getInitialSpendStepValues = ({
  selectedBlueprint,
  architecture,
  isAutomated,
  startDate,
  endDate,
  offersChanged,
  orderToClone,
  automation,
  preselectedPromoCodes,
  isDraft
}: GetInitialSpendStepValuesArgs) => {
  const programName = getInitialValuesProgramName({
    selectedBlueprint,
    displayNameTemplate: architecture?.catalog?.displayNameTemplate,
    orderToClone,
    isDraft,
    automation
  });

  // Only grab valid offers, filters out innactive offers and subscriptions with no tiers (since we don't display them in the UI)
  const validOffers = getValidProductOffers(selectedBlueprint, isAutomated);

  const initialOffer = validOffers?.[0];

  let offer = initialOffer;

  if (orderToClone && !offersChanged && !isDraft) {
    offer = orderToClone?.offer;
  }

  if (automation && !offersChanged && !isDraft) {
    offer = automation?.offer;
  }

  return {
    programName,
    startDate,
    endDate,
    offerId: offer?.id,
    scheduleType: offer?.type || scheduleTypes.purchase,
    billingMethod: offer?.billingMethod,
    scheduleDays: AUTOMATED_DEFAULT_DAYS,
    ...(offer?.type === OFFER_TYPES.purchase && {
      oneTimeSpend: offer?.purchasePriceAmount
    }),
    promoCodes: preselectedPromoCodes,
    ...(automation && !offersChanged && supervisorOrderOverrides(automation)),
    ...(orderToClone &&
      !offersChanged &&
      !isDraft &&
      orderOverrides({ billingDetails: orderToClone?.billingDetails, offer })),
    ...(isDraft && draftOverrides(orderToClone))
  };
};
