import { Trans } from 'react-i18next';

import { styled } from '@mui/material/styles';

import PageTitle from 'src/components/PageTitle';

import { CircularProgress, Grid, Typography } from '@mui/material';

const PREFIX = 'Authenticating';

const classes = {
  root: `${PREFIX}-root`,
  progress: `${PREFIX}-progress`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%'
  },

  [`& .${classes.progress}`]: {
    margin: theme.spacing(2)
  }
}));

const Authenticating = () => {
  return (
    <StyledGrid className={classes.root} container spacing={3}>
      <PageTitle subPageTitle="Authenticating" />
      <Grid item xs>
        <CircularProgress className={classes.progress} />
        <Typography component="h1" variant="h4">
          <Trans i18nKey="errorPage:auth.authenticatingHeader">
            Authenticating
          </Trans>
        </Typography>
        <Typography className={classes.errorCode} variant="subtitle1">
          <Trans i18nKey="errorPage:auth.pleaseStandby">Please standby</Trans>
        </Typography>
      </Grid>
    </StyledGrid>
  );
};

export default Authenticating;
