import { gql } from 'src/generated/gql';

export const getLeads = gql(`
  query userLeadsByProgramId($programId: ID, $endCursor: ID, $first: Int!) {
    userLeads(programId: $programId, after: $endCursor, first: $first) {
      edges {
        cursor
        node {
          id
          facebookId
          architectureId
          programId
          orderId
          programName
          submittedAt
          data
          fields {
            name
            values
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`);
