import { useMemo } from 'react';
import { flow, values } from 'lodash';
import { FormSection } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { t } from 'i18next';

import { Box, Typography } from '@mui/material';

import { isDeveloper } from 'src/Auth/common';

import { DynamicForm } from 'src/components/ReduxForm';
import { configureInputs } from 'src/components/ReduxForm/helpers';

import {
  getBlueprintDetailInputs,
  getPublisherDetailInputs
} from '../Constants';
import { mapEnumeratedValues } from '../helpers';
import { getEnumeratedValues } from '../queries';

import BlueprintBuilderStepFooter from './BlueprintBuilderStepFooter';

const pageText = () => ({
  stepHeading: t('admin:blueprintBuilder.stepConfigureStepHeading'),
  stepSubHeading: t('admin:blueprintBuilder.stepConfigureStepSubHeading')
});

const isDev = isDeveloper();
const devOnlyInputs = new Set([]);

const enumInputs = {
  objectiveSlug: 'OBJECTIVE_SLUGS'
};

const StepConfigure = props => {
  const { enumerationValues } = props;
  const text = useMemo(() => pageText(), []);
  const blueprintDetailInputs = getBlueprintDetailInputs();
  const publisherDetailInputs = getPublisherDetailInputs();

  const updatedBlueprintDetailInputsUpdated = useMemo(() => {
    const disabledInputs = isDev ? new Set() : devOnlyInputs;

    return configureInputs({
      inputs: blueprintDetailInputs,
      disabledInputs,
      enumInputs,
      enumerationValues
    });
  }, [blueprintDetailInputs, enumerationValues]);

  const updatedPublisherDetailInputs = useMemo(() => {
    const disabledInputs = isDev ? new Set() : devOnlyInputs;

    return configureInputs({
      inputs: publisherDetailInputs,
      disabledInputs
    }).map(input => {
      // These were already clone-deeped, so we can safely modify them in-place
      const updatedInput = input;
      // Make grantees collapsable
      if (input?.name === 'grantees') {
        updatedInput.displayParameters.inputData.collapseConfig = {
          numFieldsTillCollapse: 3,
          headerItemText: index =>
            t('blueprintBuilder:grantee.itemHeader', { index })
        };
      }
      return updatedInput;
    });
  }, [publisherDetailInputs]);

  return (
    <Box>
      <Typography variant="h6">{text.stepHeading}</Typography>
      <Typography variant="subtitle2">{text.stepSubHeading}</Typography>
      <br />
      <FormSection name="document.blueprint">
        <DynamicForm inputs={updatedBlueprintDetailInputsUpdated} />
      </FormSection>
      <br />
      <FormSection name="document">
        <DynamicForm inputs={updatedPublisherDetailInputs} />
      </FormSection>
      <BlueprintBuilderStepFooter />
    </Box>
  );
};

export default flow(
  graphql(getEnumeratedValues, {
    name: 'getEnumeratedValues',
    options: () => {
      return {
        variables: {
          request: { enumerations: values(enumInputs) }
        }
      };
    },
    props: mapEnumeratedValues
  })
)(StepConfigure);
