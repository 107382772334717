import { useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';
import { flow } from 'lodash';

import { Button } from '@mui/material';

const PREFIX = 'StepDownloadTemplate';

const classes = {
  downloadButton: `${PREFIX}-downloadButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.downloadButton}`]: {
    width: '100%',
    textAlign: 'center'
  }
}));

const pageText = () => ({
  download: t('adminContentDataManager:uploadModal.downloadTemplate'),
  continue: t('adminContentDataManager:uploadModal.continue')
});

const StepDownloadTemplate = ({
  handleNext,
  contentSetSlug,
  contentSetFields
}) => {
  const text = useMemo(() => pageText(), []);

  // https://stackoverflow.com/a/14966131
  const onDownload = useCallback(() => {
    // create the csv file content
    const fileMetadata = 'data:text/csv;charset=utf-8';
    const headerRow = contentSetFields.map(f => f.name).join(',');
    const csvContent = `${fileMetadata},${headerRow}\n`;
    const encodedCsvContent = encodeURI(csvContent);

    // create a download link with our encoded content
    const downloadLinkElement = document.createElement('a');
    downloadLinkElement.setAttribute('href', encodedCsvContent);
    downloadLinkElement.setAttribute(
      'download',
      `${contentSetSlug}-template.csv`
    );

    // append it to the dom and start the download
    document.body.appendChild(downloadLinkElement);
    downloadLinkElement.click();
  }, [contentSetFields]);

  return (
    <Root>
      <div className={classes.downloadButton}>
        <Button variant="contained" onClick={onDownload}>
          {text.download}
        </Button>
      </div>
      <div>
        <Button
          variant="outlined"
          onClick={handleNext}
          data-cy="content-data-manager-template-continue-button"
        >
          {text.continue}
        </Button>
      </div>
    </Root>
  );
};

export default flow()(StepDownloadTemplate);
