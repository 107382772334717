import { useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';

import {
  Button,
  ButtonGroup,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';

import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import TableChartIcon from '@mui/icons-material/TableChart';

import CloneBlueprintModal from 'src/pages/Admin/CloneBlueprint/CloneBlueprintModal';

import { landingViewStateOptions } from '../Constants';

const PREFIX = 'LandingHeader';

const classes = {
  container: `${PREFIX}-container`,
  actionContainer: `${PREFIX}-actionContainer`,
  selectContainer: `${PREFIX}-selectContainer`,
  buttonGroup: `${PREFIX}-buttonGroup`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    margin: `${theme.spacing(2)} 0 ${theme.spacing(4)} 0`
  },

  [`& .${classes.actionContainer}`]: {
    display: 'flex',
    margin: `${theme.spacing(2)} 0 ${theme.spacing(4)} 0`
  },

  [`& .${classes.selectContainer}`]: {
    width: '400px'
  },

  [`& .${classes.buttonGroup}`]: {
    marginLeft: theme.spacing(2)
  }
}));

const pageText = () => ({
  cloneButton: t('admin:blueprintBuilder.cloneButton'),
  selectArchitecture: t('admin:blueprintBuilder.selectArchitecture'),
  selectArchitectureAllMenuItem: t(
    'admin:blueprintBuilder.selectArchitectureAllMenuItem'
  )
});

const LandingHeader = props => {
  const {
    setViewState,
    architectures,
    productsByArch,
    refetchProducts,
    viewState,
    selectedArchitecture,
    setSelectedArchitecture
  } = props;

  const text = useMemo(() => pageText(), []);

  const [cloneModalOpen, setCloneModalState] = useState(false);
  const handleSetCloneModalState = useCallback(state => {
    setCloneModalState(state);
  });

  const [selectOpen, setSelectOpen] = useState(false);
  const handleSetSelectOpen = useCallback(state => {
    setSelectOpen(state);
  });

  return (
    <Root className={classes.container}>
      <div className={classes.actionContainer}>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => handleSetCloneModalState(true)}
          startIcon={<SyncAltIcon />}
          data-cy="clone-button"
        >
          {text.cloneButton}
        </Button>

        <ButtonGroup className={classes.buttonGroup} color="primary">
          <Button
            disabled={viewState === landingViewStateOptions.grid}
            onClick={() => {
              setViewState(landingViewStateOptions.grid);
            }}
          >
            <ViewComfyIcon />
          </Button>
          <Button
            disabled={viewState === landingViewStateOptions.table}
            onClick={() => {
              setViewState(landingViewStateOptions.table);
            }}
          >
            <TableChartIcon />
          </Button>
        </ButtonGroup>
      </div>
      <div className={classes.selectContainer}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="selectArch">{text.selectArchitecture}</InputLabel>

          <Select
            label={text.selectArchitecture}
            labelId="selectArch"
            value={selectedArchitecture}
            onChange={({ target: { value } }) => {
              setSelectedArchitecture(value);
            }}
            onClose={() => handleSetSelectOpen(false)}
            onOpen={() => handleSetSelectOpen(true)}
            open={selectOpen}
          >
            <MenuItem value="all" key="menuItem-all">
              {text.selectArchitectureAllMenuItem}
            </MenuItem>
            {architectures.map(architecture => (
              <MenuItem
                value={architecture.id}
                key={`menuItem-${architecture.id}`}
              >
                {architecture.name} | {architecture.id} (
                {productsByArch[architecture.id]
                  ? productsByArch[architecture.id].length + 1
                  : '0'}
                )
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {cloneModalOpen && (
        <CloneBlueprintModal
          architectures={architectures}
          onClose={() => handleSetCloneModalState(false)}
          refetchData={refetchProducts}
        />
      )}
    </Root>
  );
};

export default LandingHeader;
