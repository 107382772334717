import { memo } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { isFunction, noop, isArray, some, every } from 'lodash';
import classNames from 'classnames';

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material';

import { keyByParams } from '../../common/utilities';
import EvTableRow from './TableRow';
import GroupedRow from './GroupedRow';

const PREFIX = 'TableWrapper';

const classes = {
  scrollContainer: `${PREFIX}-scrollContainer`,
  image: `${PREFIX}-image`,
  loadingCell: `${PREFIX}-loadingCell`,
  overallCell: `${PREFIX}-overallCell`,
  highlight: `${PREFIX}-highlight`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.scrollContainer}`]: {
    width: '100%',
    overflowX: 'auto'
  },

  [`& .${classes.image}`]: {
    width: '50px'
  },

  [`& .${classes.loadingCell}`]: {
    height: '200px',
    textAlign: 'center'
  },

  [`& .${classes.overallCell}`]: {
    padding: '5px'
  },

  [`& .${classes.highlight}`]: {
    background: alpha(theme.palette.warning[100], 0.5)
  }
}));

const SubRow = memo(props => <EvTableRow {...props} />);

const TableWrapper = props => {
  const {
    actionColumnToolTip = '',
    columnSchema,
    hasRowCheckbox = false,
    onCheckboxClick,
    singleSelect,
    onClickActionColumn = null,
    onClickBodyRow = null,
    onClickHeaderRow = null,
    rowHover = true,
    rows = [],
    selectAll = null,
    selectedRows = {},
    loading = true,
    highlightedColumns = [],
    CustomRowComponent,
    customRowProps,
    dataCy
  } = props;

  const totalNumberOfRowsSelected = Object.keys(selectedRows).length;

  const numColumns =
    columnSchema.length +
    (isFunction(selectAll) ? 1 : 0) +
    (isFunction(onClickActionColumn) ? 1 : 0);

  return (
    <Root className={classes.scrollContainer}>
      <Table data-loading={loading ? 'true' : 'false'} data-cy={dataCy}>
        {numColumns > 0 && (
          <TableHead>
            <TableRow
              onClick={
                hasRowCheckbox && isFunction(onClickHeaderRow)
                  ? () => onClickHeaderRow()
                  : noop
              }
            >
              {hasRowCheckbox && (
                <TableCell padding="checkbox">
                  {isFunction(selectAll) && (
                    <Checkbox
                      indeterminate
                      checked={totalNumberOfRowsSelected === rows.length}
                      onChange={() => selectAll(rows)}
                    />
                  )}
                </TableCell>
              )}

              {!loading &&
                columnSchema &&
                columnSchema.map(column => {
                  return (
                    <TableCell
                      align="center"
                      key={keyByParams(column, column.accessor)}
                      className={classNames(classes.overallCell, {
                        [classes.highlight]: highlightedColumns.includes(
                          column.accessor
                        )
                      })}
                    >
                      {column.columnName}
                    </TableCell>
                  );
                })}
              {isFunction(onClickActionColumn) && (
                <TableCell align="center" className={classes.overallCell} />
              )}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell
                colSpan={numColumns > 0 ? numColumns : 1}
                className={classes.loadingCell}
              >
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            rows &&
            rows.map((row, i) => {
              const subRowProps = {
                columnSchema,
                row,
                rowHover,
                onCheckboxClick,
                onClickBodyRow,
                singleSelect,
                hasRowCheckbox,
                selectedRows,
                onClickActionColumn,
                actionColumnToolTip,
                highlightedColumns,
                key: keyByParams(row),
                index: i
              };

              // if you pass a custom row and selected this row
              if (CustomRowComponent && selectedRows[keyByParams(row)]) {
                return (
                  <CustomRowComponent
                    key={keyByParams(row)}
                    row={row}
                    index={i}
                    columnSchema={columnSchema}
                    {...customRowProps}
                  />
                );
              }

              if (isArray(row)) {
                const [first] = row;
                const childSelected = some(row, data => selectedRows[data.id]);
                const allChildrenSelected = every(
                  row,
                  data => selectedRows[data.id]
                );
                return (
                  <GroupedRow key={`${keyByParams(first)}_parent`}>
                    {[
                      <EvTableRow
                        columnSchema={columnSchema}
                        row={first}
                        rowHover={rowHover}
                        selectedRows={selectedRows}
                        singleSelect={singleSelect}
                        onClickActionColumn={onClickActionColumn}
                        actionColumnToolTip={actionColumnToolTip}
                        groupParent
                        childSelected={childSelected}
                        allChildrenSelected={allChildrenSelected}
                        highlightedColumns={highlightedColumns}
                      />,
                      ...row.map(groupedRow => {
                        const groupedSuRowProps = {
                          ...subRowProps,
                          row: groupedRow
                        };
                        return (
                          <SubRow
                            {...groupedSuRowProps}
                            key={keyByParams(groupedRow)}
                            grouped
                          />
                        );
                      })
                    ]}
                  </GroupedRow>
                );
              }

              return <SubRow {...subRowProps} />;
            })}
        </TableBody>
      </Table>
    </Root>
  );
};

export default TableWrapper;
