import { useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm, SubmissionError } from 'redux-form';
import { t } from 'i18next';

import { Button, Typography } from '@mui/material';

import { useSnackbar } from 'notistack';
import { DynamicForm } from 'src/components/ReduxForm';
import ErrorMessage from 'src/components/Containers/ErrorMessage';

import {
  ACTIVATE_FORM_NAME,
  getActivateFacebookConfigurationInputs
} from './Constants';
import { setActiveFacebookConfiguration } from './mutations';
import { getFacebookConfiguration } from './queries';

const PREFIX = 'ActiveFacebookConfiguration';

const classes = {
  cardHeading: `${PREFIX}-cardHeading`,
  cardSubheading: `${PREFIX}-cardSubheading`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  errorContainer: `${PREFIX}-errorContainer`
};

const Root = styled('form')(({ theme }) => ({
  [`& .${classes.cardHeading}`]: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },

  [`& .${classes.cardSubheading}`]: {
    fontWeight: 'normal',
    fontSize: '14px',
    marginBottom: theme.spacing(4)
  },

  [`& .${classes.buttonContainer}`]: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  },

  [`& .${classes.errorContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
}));

const pageText = () => ({
  cardHeading: t('admin:activeFacebookConfiguration.cardHeading'),
  subHeading: t('admin:activeFacebookConfiguration.subHeading'),
  errorMessage: t('admin:activeFacebookConfiguration.errorMessage'),
  submitButton: t('admin:activeFacebookConfiguration.submitButton'),
  successSnackMessage: t('admin:activeFacebookConfiguration.successSnack'),
  errorSnackMessage: t('admin:activeFacebookConfiguration.errorSnack')
});

const ActiveFacebookConfiguration = props => {
  const {
    dirty,
    facebookConfigurationOptions,
    handleSubmit,
    loading,
    setActiveFacebookConfiguration,
    submitFailed
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const text = useMemo(() => pageText(), []);

  const onSubmit = useCallback(async data => {
    try {
      await setActiveFacebookConfiguration({
        variables: {
          ...data
        }
      });

      enqueueSnackbar(text.successSnackMessage, {
        variant: 'success'
      });
    } catch (error) {
      const message = text.errorSnackMessage;

      enqueueSnackbar(message, {
        variant: 'error'
      });

      throw new SubmissionError(error);
    }
  }, []);

  return (
    <Root autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Typography component="h2" className={classes.cardHeading}>
        {text.cardHeading}
      </Typography>
      <Typography variant="subtitle2" className={classes.cardSubheading}>
        {text.subHeading}
      </Typography>
      {submitFailed && (
        <div className={classes.errorContainer}>
          <ErrorMessage>{text.errorMessage}</ErrorMessage>
        </div>
      )}
      <DynamicForm
        inputs={getActivateFacebookConfigurationInputs(
          facebookConfigurationOptions
        )}
      />
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          disabled={!dirty || loading}
          type="submit"
          variant="contained"
        >
          {text.submitButton}
        </Button>
      </div>
    </Root>
  );
};

const mapFacebookConfigurationToProps = ({ facebookConfiguration }) => {
  const facebookConfigurations =
    facebookConfiguration?.myOrganization?.facebookConfiguration || [];

  const facebookConfigurationOptions = facebookConfigurations.map(option => {
    return {
      name: `Bot User ID: ${option.botUserId}`,
      value: option.id
    };
  });

  const activeFacebookConfigurations = facebookConfigurations.find(
    configuration => {
      return configuration?.isActive;
    }
  );

  return {
    loading: facebookConfiguration?.loading,
    facebookConfigurationOptions,
    initialValues: {
      facebookConfigId: activeFacebookConfigurations?.id
    }
  };
};

export default flow(
  reduxForm({
    form: ACTIVATE_FORM_NAME,
    destroyOnUnmount: true,
    enableReinitialize: true
  }),
  graphql(setActiveFacebookConfiguration, {
    name: 'setActiveFacebookConfiguration'
  }),
  graphql(getFacebookConfiguration, {
    name: 'facebookConfiguration',
    props: mapFacebookConfigurationToProps
  })
)(ActiveFacebookConfiguration);
