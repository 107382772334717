import { flow, pick, isString, pickBy } from 'lodash';
import { styled } from '@mui/material/styles';
import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { reduxForm } from 'redux-form';
import { CircularProgress, Button } from '@mui/material';

import { DynamicForm } from 'src/components/ReduxForm';
import { useSnackbar } from 'notistack';
import { createOrUpdateCatalogCollapseKey } from './mutations';
import { getCollapseKeyInputs } from './constants';

const PREFIX = 'CollapseKeyForm';

const classes = {
  updateButton: `${PREFIX}-updateButton`
};

const Root = styled('form')(({ theme }) => ({
  [`& .${classes.updateButton}`]: { marginTop: theme.spacing(2) }
}));

const CollapseKeyForm = ({
  handleSubmit,
  submitting,
  invalid,
  initialValues,
  catalog,
  refetch,
  createOrUpdateCatalogCollapseKey
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async data => {
    const onlyAPIFields = pick(data, [
      ...getCollapseKeyInputs.filter(f => !f.readOnly).map(f => f.name)
    ]);
    const onlyChanged = pickBy(onlyAPIFields, (val, key) => {
      if (isString(val)) {
        return val !== initialValues?.[key];
      }
      return true;
    });
    try {
      await createOrUpdateCatalogCollapseKey({
        variables: {
          catalogId: initialValues.id,
          input: onlyChanged
        }
      });

      enqueueSnackbar(t('admin:catalogContent.collapseKeyFormSuccess'), {
        variant: 'success'
      });
      refetch();
    } catch (e) {
      enqueueSnackbar(t('admin:catalogContent.collapseKeyFormError'), {
        variant: 'error'
      });
      refetch();
    }
  };

  const fieldMetaData =
    catalog.fieldMetadata?.map(field => ({
      name: field.fieldName,
      value: field.id
    })) || [];

  return (
    <Root autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
      <DynamicForm
        inputs={getCollapseKeyInputs({
          enums: { fieldMetaData }
        })}
      />
      <Button
        color="primary"
        variant="outlined"
        type="submit"
        disabled={submitting || invalid}
        endIcon={submitting && <CircularProgress size={15} />}
        className={classes.updateButton}
      >
        <Trans i18nKey="admin:catalogContent.updateCollapseKey">
          Update Collapse Key
        </Trans>
      </Button>
    </Root>
  );
};

export default flow(
  reduxForm({
    // formName from parent
    // initialValues from parent
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  graphql(createOrUpdateCatalogCollapseKey, {
    name: 'createOrUpdateCatalogCollapseKey'
  })
)(CollapseKeyForm);
