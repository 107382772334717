import { ReactElement } from 'react';
import { useFeatures } from 'src/components/Feature';
import { useGlobalContext } from 'src/GlobalContextProvider';
// import { SuprSendProvider } from '@suprsend/react-inbox';

export interface NotificationGlobalWrapperProps {
  children: ReactElement;
}

/**
 * Wraps a top part of our app to allow for connection to our notification
 * store (SuprSend)
 */
export const NotificationGlobalWrapper = ({
  children
}: NotificationGlobalWrapperProps) => {
  const { showNotificationPanel } = useFeatures();
  const { loading, me, isAllowListPath } = useGlobalContext();

  const subscriberId = me?.suprsendSubscriberDetails?.subscriberId;
  const workspaceKey = me?.suprsendSubscriberDetails?.workspaceKey;
  const tenantId = me?.suprsendSubscriberDetails?.tenantId;

  if (
    !showNotificationPanel ||
    isAllowListPath ||
    loading ||
    !subscriberId ||
    !workspaceKey ||
    !tenantId
  ) {
    return <>{children}</>;
  }

  // TODO: Clean this up
  return <>{children}</>;

  // return (
  //   <SuprSendProvider
  //     subscriberId={subscriberId}
  //     workspaceKey={workspaceKey || ''}
  //     tenantId={tenantId}
  //     distinctId={me?.id}
  //   >
  //     {children}
  //   </SuprSendProvider>
  // );
};
