import { useRef } from 'react';
import { flow } from 'lodash';
import { Box, Tooltip } from '@mui/material';

import Video from 'src/components/Video';

import { MEDIA_TYPE } from '../constants';

const ThirdPartySearch = ({ media, mediaType, tooltip, clipBottom }) => {
  const videoRef = useRef(null);
  return (
    <Tooltip
      arrow
      placement="top"
      slotProps={{
        popper: {
          sx: {
            '&[data-popper-placement*="top"]': {
              top: '13px'
            }
          }
        }
      }}
      title={tooltip || ''}
    >
      <Box
        data-cy="media-search-result"
        onMouseEnter={() => {
          if (mediaType === MEDIA_TYPE.video) {
            videoRef.current.play();
          }
        }}
        onMouseLeave={() => {
          if (mediaType === MEDIA_TYPE.video) {
            videoRef.current.pause();
          }
        }}
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          cursor: 'pointer'
        }}
      >
        {mediaType === MEDIA_TYPE.video && (
          <Video
            videoRef={videoRef}
            alt={media.name || media.id}
            src={media.previewUrl}
            preload="metadata"
            sx={{ height: '100%', width: '100%', objectFit: 'contain' }}
            loop
          />
        )}
        {mediaType === MEDIA_TYPE.image && (
          <>
            <Box
              component="img"
              src={media.previewUrl}
              sx={{ height: '100%', width: '100%', objectFit: 'contain' }}
              alt={media.name || media.id}
              style={{
                clipPath: `inset(0px 0px ${clipBottom || '0px'} 0px)`
              }}
              onContextMenu={e => {
                // Prevent the context menu from showing
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </>
        )}
      </Box>
    </Tooltip>
  );
};

export default flow()(ThirdPartySearch);
