import { Box, TypographyVariant, Typography } from '@mui/material';

interface ModalHeaderProps {
  icon?: JSX.Element;
  children: string | JSX.Element;
  typographyVariant?: TypographyVariant;
  subHeaderText?: string;
}

const ModalHeader = ({
  icon,
  children,
  typographyVariant = 'h5',
  subHeaderText
}: ModalHeaderProps) => {
  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
        gap: 1,
        fontSize: theme => theme?.typography?.[typographyVariant].fontSize,
        fontWeight: 'bold',
        alignItems: 'center'
      }}
    >
      {icon}
      <Box sx={{ alignSelf: 'flex-end' }}>
        {children}
        {subHeaderText && (
          <Typography variant="subtitle1">{subHeaderText}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default ModalHeader;
