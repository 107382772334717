import { useState, useMemo, ReactElement } from 'react';
import { flow, isArray } from 'lodash';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import {
  Button,
  Paper,
  Typography,
  Tooltip,
  Box,
  InputLabel,
  FormControl
} from '@mui/material';

import HelpIcon from '@mui/icons-material/HelpOutline';
import ImageIcon from '@mui/icons-material/Image';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import Modal from 'src/components/Modal';
import { withFeatures } from 'src/components/Feature/Feature';
import Instrumentation from 'src/instrumentation';
import { HelperTextFooter } from 'src/components/ReduxForm/HelperTextFooter';
import { ErrorFooter } from 'src/components/ReduxForm/ErrorFooter';

import { AssetType } from 'src/generated/gql/graphql';
import { InjectedFeatures } from 'src/components/Feature/constants';
import { WrappedFieldMetaProps } from 'redux-form/lib/Field';
import { ContentMediaItem } from 'src/components/ReduxForm/RenderGallerySelector';

import { getDefaultMediaSources } from './constants';
import GalleryModalMediaList from './GalleryModalMediaList';
import GalleryModalMediaSingleSelect from './GalleryModalMediaSingleSelect';
import Gallery from './Gallery';

const getText = () => ({
  close: t('gallery:modal.close'),
  confirm: t('gallery:modal.confirm'),
  remove: t('gallery:buttons.remove')
});

export type MediaAssetSelection =
  | {
      id: string;
      type: AssetType;
    }
  | string;

export interface GalleryModalProps {
  setSelectedGalleryItem: (selection: any) => void;
  onChange: (items: any) => void;
  reset: () => void;
  galleryType: string;
  label: string;
  selection: MediaAssetSelection[];
  tooltip?: string;
  helperText?: string;
  internalOnly?: boolean;
  sizeConstraint?: boolean;
  name: string;
  mediaType?: string;
  showFilter?: boolean;
  meta: WrappedFieldMetaProps;
  disabled?: boolean;
  internalOnlyAllowSvg?: boolean;
  isMultiSelect?: boolean;
  isHookForm?: boolean;
  allowContentSelection?: boolean;
  businessObjects?: any;
  contentName?: string;
  contentItems?: ContentMediaItem[];
  arrayMinMax?: { min?: number; max?: number };
  isRequired?: boolean;
}

const GalleryModal = (props: GalleryModalProps & InjectedFeatures) => {
  const {
    setSelectedGalleryItem,
    onChange,
    reset,
    features: { giphySearch, galleryAdminImages, shutterstockSearch },
    galleryType,
    label,
    selection,
    meta,
    tooltip,
    helperText,
    internalOnly,
    internalOnlyAllowSvg,
    sizeConstraint,
    name,
    mediaType,
    showFilter = false,
    disabled = false,
    isMultiSelect = false,
    isHookForm,
    allowContentSelection,
    businessObjects,
    contentName,
    contentItems,
    arrayMinMax,
    isRequired
  } = props;
  const text = useMemo(() => getText(), []);
  const hasSelection = isArray(selection) && selection.length > 0;

  // get feature flagged media sources:
  const defaultMediaSources = getDefaultMediaSources({
    giphySearch,
    galleryType,
    shutterstockSearch,
    galleryAdminImages
  });

  const [selectedMediaSources, setSelectedMediaSources] = useState([]);

  const { touched, error } = meta;
  const [modalOpen, setModalOpen] = useState(false);

  const [modalMediaType, setModalMediaType] = useState(mediaType);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const triggerLogEvent = () => {
    const eventMeta = {
      hasContent: !!selection.length,
      contentCount: selection.length
    };

    Instrumentation.logEvent(
      Instrumentation.Events.MediaLibrarySelectFromGallery,
      eventMeta
    );
  };

  const inputInError = error && touched;

  const validNumberSelected =
    selection.length >= (arrayMinMax?.min || 0) &&
    selection.length <= (arrayMinMax?.max || Infinity); // lol

  const waitingOnBusinessObjects =
    allowContentSelection && businessObjects.length <= 0;

  const helpTip = tooltip && (
    <Tooltip data-cy="gallery-modal-tooltip" title={tooltip}>
      <HelpIcon
        sx={{
          color: 'grey.500',
          ml: 1,
          width: '20px'
        }}
      />
    </Tooltip>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Modal
        sx={{ p: 0 }}
        open={modalOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        showClose={false}
        FooterComponent={() => (
          <>
            {arrayMinMax && (arrayMinMax?.min || arrayMinMax?.max) && (
              <Box
                sx={{
                  paddingStart: 2,
                  fontWeight: 'bold',
                  flexGrow: 2,
                  color: t => t.palette.success.main,
                  ...(!validNumberSelected
                    ? { color: t => t.palette.warning.main }
                    : {})
                }}
              >
                {validNumberSelected ? (
                  <DoneAllIcon
                    style={{
                      verticalAlign: 'bottom'
                    }}
                  />
                ) : (
                  <InfoIcon
                    style={{
                      verticalAlign: 'bottom'
                    }}
                  />
                )}{' '}
                {selection.length} {t('gallery:modal.selectedFooter')}{' '}
                <Box component="span" sx={{ fontWeight: 'normal' }}>
                  ({arrayMinMax?.min || 0}{' '}
                  {arrayMinMax?.max &&
                    t('gallery:modal.maxFooter', { max: arrayMinMax.max })}
                  )
                </Box>
              </Box>
            )}
            <Button
              variant={hasSelection ? 'contained' : 'text'}
              color="primary"
              onClick={handleClose}
            >
              {hasSelection ? text.confirm : text.close}
            </Button>
          </>
        )}
      >
        <Box sx={{ pl: 4, pr: 4 }}>
          <Gallery
            allowSelect
            internalOnly={internalOnly}
            internalOnlyAllowSvg={internalOnlyAllowSvg}
            selectedGalleryItems={selection}
            setSelectedGalleryItem={setSelectedGalleryItem}
            isMultiSelect={isMultiSelect}
            sizeConstraint={sizeConstraint}
            selectedMediaSources={selectedMediaSources}
            defaultMediaSources={defaultMediaSources}
            setSelectedMediaSources={setSelectedMediaSources}
            isModal
            showFilter={showFilter}
            mediaType={modalMediaType}
            setMediaType={setModalMediaType}
            galleryType={galleryType}
            contentItems={contentItems}
            allowContentSelection={allowContentSelection}
            arrayMinMax={arrayMinMax}
          />
        </Box>
      </Modal>

      {isMultiSelect && (
        <Paper
          sx={{
            width: '100%',
            position: 'relative',
            padding: 2
          }}
        >
          {label && (
            <Typography
              variant="body1"
              sx={{
                alignItems: 'center',
                display: 'flex',
                paddingLeft: 1,
                paddingRight: 1,
                background: '#FFFFFF',
                ...(inputInError && {
                  color: 'error.main',
                  borderColor: 'error.main'
                }),
                ...(isMultiSelect && { fontWeight: 'medium' })
              }}
            >
              {isMultiSelect && <ImageIcon sx={{ pr: 1 }} />}
              {label}
              {isMultiSelect && arrayMinMax?.max && (
                <Box component="span" sx={{ pl: 0.5, pr: 0.5 }}>
                  {selection.length}/{arrayMinMax?.max}
                </Box>
              )}
              {isRequired && (
                <span
                  style={{
                    fontStyle: 'italic',
                    fontSize: '14px'
                  }}
                >
                  {' '}
                  ({t('gallery:modal.required', { min: arrayMinMax?.min || 1 })}
                  )
                </span>
              )}
              {helpTip}
            </Typography>
          )}
          {waitingOnBusinessObjects ? (
            <Typography sx={{ textAlign: 'center', pt: 1 }}>
              {t('gallery:modal.businessObjects', { contentName })}
            </Typography>
          ) : (
            <>
              <GalleryModalMediaList
                name={name}
                // @ts-expect-error This doesn't exist on GalleryModalButton
                onClearSelection={event => {
                  event.stopPropagation();

                  reset();
                }}
                onChange={onChange}
                onClick={handleOpen}
                selectedGalleryItems={selection}
                contentItems={contentItems}
                galleryType={galleryType}
                meta={meta}
                disabled={disabled}
                isHookForm={isHookForm}
              />
              <Button
                disabled={disabled}
                data-cy={`gallery-modal-button-${name.replace('.', '-')}`}
                color="primary"
                variant="text"
                sx={{
                  ...(inputInError && {
                    borderColor: 'error.main',
                    color: 'error.main'
                  }),
                  ...(selection?.length <= 0 && {
                    width: '100%',
                    pt: 7,
                    pb: 7
                  }),
                  '& .MuiButton-label': {
                    alignItems: 'center',
                    display: 'flex',
                    pl: 1,
                    pr: 1,
                    background: '#FFFFFF'
                  }
                }}
                onClick={() => {
                  triggerLogEvent();
                  handleOpen();
                }}
                startIcon={selection?.length <= 0 ? <AddIcon /> : <EditIcon />}
              >
                {selection?.length <= 0 ? (
                  <Trans i18nKey="gallery:button.label" />
                ) : (
                  <Trans i18nKey="gallery:button.editLabel" />
                )}
              </Button>
            </>
          )}
        </Paper>
      )}
      {!isMultiSelect && (
        <FormControl fullWidth>
          {label && (
            <InputLabel
              shrink
              sx={{
                alignItems: 'center',
                display: 'flex',
                pl: 1,
                pr: 1,
                background: '#FFFFFF'
              }}
              error={inputInError}
            >
              {label}
              {helpTip}
            </InputLabel>
          )}

          <Box
            {...(inputInError && {
              sx: { color: 'error.main', borderColor: 'error.main' }
            })}
          >
            <GalleryModalMediaSingleSelect
              name={name}
              onClick={handleOpen}
              selection={selection[0]}
              galleryType={galleryType}
              meta={meta}
              disabled={disabled}
            />
          </Box>
        </FormControl>
      )}

      {inputInError && <ErrorFooter touched={touched} error={error} />}
      {helperText && (
        <HelperTextFooter helperText={helperText} stacked={inputInError} />
      )}
    </Box>
  );
};

export default flow(withFeatures)(GalleryModal) as (
  props: GalleryModalProps
) => ReactElement;
