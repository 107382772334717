import { useState } from 'react';
import { flow } from 'lodash';
import { withRouter } from 'react-router-dom';
import { t } from 'i18next';
import { Button, Box } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import Heading from 'src/components/PageElements/Heading';
import SelectQuickStartModal from 'src/pages/Programs/SelectQuickStartModal';
import { GenericPlaceholder } from 'src/components/Placeholders';
import { usePageSizeBasedOnWindow } from 'src/components/DataTable/helpers';

import LeadsTable from './LeadsTable';

const defaultPageSize = 25;
const offsetHeight = 230;
const standardRowHeight = 40;

const Leads = () => {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const { pageSize, windowDimensions } = usePageSizeBasedOnWindow(
    offsetHeight,
    defaultPageSize,
    standardRowHeight
  );

  return (
    <>
      <Heading
        title={t('leads:header.title')}
        subTitle={t('leads:header.subtitle')}
        pageTitle={t('leads:header.title')}
      />
      <Box
        sx={{
          height: windowDimensions.height,
          marginTop: '20px',
          minHeight: '400px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <LeadsTable
          pageSize={pageSize}
          noRowsPlaceholderComponent={
            <GenericPlaceholder
              variant="noLeads"
              title={t('leads:placeholders.noLeads.title')}
              subtitle={t('leads:placeholders.noLeads.subtitle')}
              buttons={[
                <Button
                  key="create-leads-program"
                  variant="contained"
                  color="primary"
                  endIcon={<AddIcon />}
                  onClick={openModal}
                >
                  {t('leads:placeholders.noLeads.ctaButton')}
                </Button>
              ]}
            />
          }
        />
      </Box>
      <SelectQuickStartModal
        open={open}
        onClose={closeModal}
        isSupervisable={false}
        selectedObjective="generate_contacts"
      />
    </>
  );
};

export default flow(withRouter)(Leads);
