import { useState, useEffect, useRef } from 'react';
import { t } from 'i18next';
import { get, isString, fill } from 'lodash';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { facebookCreativeTypes } from 'src/common/adChannels';

// import LoadingCover from 'src/components/Loaders/LoadingCover';
import S3Image from '../S3Image';
import { getPreviewWidth } from '../helpers';

import FacebookAdPreviewCard from './FacebookAdPreviewCard';
import {
  FB_BLUE,
  PREVIEW_CARD_WIDTH,
  SOLO_CARD_WIDTH,
  SOLO_IMAGE_HEIGHT,
  SOLO_IMAGE_WIDTH,
  FACEBOOK_PREVIEW_WIDTH,
  SOLO_CARD_WIDTH_LARGE
} from './FacebookAdPreviewConstants';
import { Comment, Like, Share } from '../../Icons/FacebookSocialButtons';

export const getFacebookAdPreviewStyles = ({ theme, vars }) => {
  return {
    preview: {
      margin: '0 auto',
      backgroundColor: 'white',
      borderRadius: '0.25rem',
      border: '1px solid #e5e5e5',
      color: 'black',
      display: 'grid',
      flexBasis: 'calc(100% - 1rem)',
      gridTemplateColumns: '100%',
      gridTemplateTows: 'auto',
      padding: '10px 10px 0 10px',
      position: 'relative',
      userSelect: 'none',
      width: getPreviewWidth(
        vars.hasUniformPreviewWidth,
        FACEBOOK_PREVIEW_WIDTH,
        vars.isResponsive
      ),
      '& > *': {
        fontFamily: 'Arial, sans-serif !important',
        fontSize: '13px',
        lineHeight: '14px',
        textAlign: 'left'
      },

      [theme.breakpoints.down('sm')]: vars.disableResponsiveStyles
        ? {}
        : {
            width: 'initial',
            maxWidth: getPreviewWidth(
              vars.hasUniformPreviewWidth,
              FACEBOOK_PREVIEW_WIDTH,
              vars.isResponsive
            )
          }
    },

    previewHeader: {
      fontSize: '12px',
      color: '#999999',
      borderBottom: '1px solid #e5e5e5',
      paddingBottom: '4px'
    },

    companyRow: {
      padding: '5px 0 10px 0',
      display: 'grid',
      alignContent: 'stretch'
    },

    previewFooter: {
      display: 'grid',
      borderTop: '1px solid #e5e5e5',
      alignContent: 'stretch',
      padding: '10px 0',
      justifyContent: 'space-around',
      alignItems: 'center'
    },

    fBImage: {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    companyNameContainer: {
      position: 'relative'
    },

    companyName: {
      color: `${FB_BLUE}`,
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '14px',
      width: 'auto'
    },

    sponsored: {
      color: '#999999',
      fontSize: '12px',
      lineHeight: '13px'
    },

    likePage: {
      textAlign: 'right'
    },

    bodyText: {
      paddingBottom: '4px',
      whiteSpace: 'pre-wrap',
      margin: '0',
      display: 'grid',
      textAlign: 'left',
      userSelect: 'text',
      '& > p': {
        margin: '0'
      }
    },

    // Note: We set the position of the avatar block and the image absolutely so
    //       they will always be in the top-left container. This is to handle the
    //       case when the pageName is long enough to cause a wrap in the text to
    //       keep the image in its proper position.
    sharedAvatar: {
      backgroundColor: `${FB_BLUE}`,
      display: 'inline-block',
      height: '25px',
      left: '0',
      position: 'absolute',
      top: '0',
      verticalAlign: 'top',
      width: '25px'
    },

    companyNameInline: {
      display: 'inline-block',
      marginLeft: '30px'
    },

    pagination: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '100%',
      zIndex: '7'
    },

    leftPageImage: {
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      left: 0
    },

    rightPageImage: {
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      transform: 'scaleX(-1)',
      right: 0,
      left: 'auto'
    },

    previewCardsWrap: {
      overflowX: 'hidden',
      width: '100%',
      paddingBottom: '4px'
    },

    previewCards: {
      display: 'flex',
      transition: '0.5s'
    },
    showMoreButton: {
      color: theme.palette.facebook.main,
      outline: 'none',
      cursor: 'pointer',
      display: 'inline-block',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      hyphens: 'auto'
    }
  };
};

const CAROUSEL_TYPES = {
  [facebookCreativeTypes.multiProductCarousel]: true,
  [facebookCreativeTypes.dynamicAdCreative]: true,
  [facebookCreativeTypes.singleProductCarousel]: true,
  [facebookCreativeTypes.fbCarousel]: true,
  [facebookCreativeTypes.fbSingleProductCarousel]: true,
  [facebookCreativeTypes.fbDareCarousel]: true
};

const truncateWithEllipses = (text = '', max, ellipses = ' ...') => {
  return (
    <span>
      {isString(text) ? (
        <>
          {text.substr(0, max - 1)}
          {text.length > max ? ellipses : ''}
        </>
      ) : (
        <>{text}</>
      )}
    </span>
  );
};

const FacebookAdPreview = props => {
  const {
    cards: initialCards = [],
    creativeType,
    profileImgUrl = null,
    /* loading, */
    callToAction = null,
    pageName = null,
    requireBusinessObjects,
    hasBusinessObjects,
    isLeadAd
  } = props;

  const [currentPage, setCurrentPage] = useState(0);
  const [showMoreBodyText, setShowMoreBodyText] = useState(false);
  const lastCardsRef = useRef(initialCards);

  const isCarousel = !!CAROUSEL_TYPES[creativeType];

  const theme = useTheme();

  const sxStyles = getFacebookAdPreviewStyles({
    theme,
    vars: {
      hasUniformPreviewWidth: props.hasUniformPreviewWidth,
      isResponsive: props.isResponsive,
      disableResponsiveStyles: props.disableResponsiveStyles
    }
  });

  useEffect(() => {
    const currentCards = lastCardsRef.current;

    // If the cards prop has changed length and is less than the currentPage
    // we reset the currentPage to the very last card.
    if (
      initialCards.length !== currentCards.length &&
      initialCards.length < currentPage + 1
    ) {
      let newPage = initialCards.length - 1;

      if (newPage < 0) {
        newPage = 0;
      }
      setCurrentPage(newPage);
    }
  }, [currentPage, initialCards]);

  let cards = [...initialCards];
  if (isCarousel && cards.length < 3) {
    // For carousel previews, ensure we have 3 dummy cards.
    // fill the array wih empty objects
    cards = [...cards, ...fill(Array(3 - cards.length), {})];
  }

  const toggleShowMoreText = () => {
    setShowMoreBodyText(!showMoreBodyText);
  };

  const paginateLeft = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const paginateRight = () => {
    if (currentPage !== cards.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const isVideo =
    creativeType === facebookCreativeTypes.video ||
    creativeType === facebookCreativeTypes.videoUpload ||
    creativeType === facebookCreativeTypes.fbSingleVideo;

  const isSoloCard = cards.length === 1;
  let cardWidth = PREVIEW_CARD_WIDTH;
  let cardImageWidth = SOLO_IMAGE_WIDTH;
  let cardImageHeight = SOLO_IMAGE_HEIGHT;

  if (isSoloCard) {
    cardWidth = SOLO_CARD_WIDTH;
    cardImageWidth = SOLO_IMAGE_WIDTH;
    cardImageHeight = SOLO_IMAGE_HEIGHT;
  }

  if (isCarousel) {
    // Larger default preview image width because the wrapping div is taller in order to preserve 1:1 ratio
    cardImageWidth = SOLO_CARD_WIDTH_LARGE;
  }

  const getOffsetStyle = () => {
    const offset = 0 - (Number(PREVIEW_CARD_WIDTH) - 15) * currentPage;

    return { transform: `translateX(${offset}px)` };
  };

  const getBody = () => {
    for (let i = 0; i < cards.length; i++) {
      const { message } = cards[i];
      if (message) {
        return showMoreBodyText ? (
          <span>
            {message}{' '}
            <Box
              component="span"
              sx={sxStyles.showMoreButton}
              onClick={toggleShowMoreText}
              role="button"
              tabIndex="0"
            >
              See Less
            </Box>
          </span>
        ) : (
          truncateWithEllipses(
            message,
            175,
            <>
              {' ... '}
              <Box
                component="span"
                sx={sxStyles.showMoreButton}
                onClick={toggleShowMoreText}
                role="button"
                tabIndex="0"
              >
                See More
              </Box>
            </>
          )
        );
      }
    }

    return '';
  };

  return (
    <Box
      className="notranslate"
      sx={sxStyles.preview}
      data-cy="facebook-ad-preview"
    >
      {isCarousel && (
        <Box sx={sxStyles.pagination}>
          {currentPage !== 0 && (
            <S3Image
              imageName="fb/pagination-left.png"
              onClick={paginateLeft}
              style={sxStyles.leftPageImage}
            />
          )}

          {currentPage !== cards.length - 1 && (
            <S3Image
              imageName="fb/pagination-left.png"
              onClick={paginateRight}
              style={sxStyles.rightPageImage}
            />
          )}
        </Box>
      )}
      <Box sx={sxStyles.previewHeader} style={{ gridArea: '1 / 1' }}>
        Suggested Post
      </Box>
      <Box sx={sxStyles.companyRow} style={{ gridArea: '2 / 1' }}>
        <Box sx={sxStyles.companyNameContainer} style={{ gridArea: '1 / 1' }}>
          {profileImgUrl ? (
            <Box
              component="img"
              alt="Profile"
              sx={sxStyles.sharedAvatar}
              src={profileImgUrl}
            />
          ) : (
            <Box sx={sxStyles.sharedAvatar}>&nbsp;</Box>
          )}

          <Box sx={sxStyles.companyNameInline}>
            <Box sx={sxStyles.companyName}>
              {pageName || t('adPreview:facebookAdPreview.genericPageName')}
            </Box>
            <Box sx={sxStyles.sponsored}>Sponsored</Box>
          </Box>
        </Box>
        <Box sx={sxStyles.likePage} style={{ gridArea: '1 / 3' }}>
          <S3Image imageName="fb/like_page.png" height="24" />
        </Box>
      </Box>
      <Box sx={sxStyles.bodyText} style={{ gridArea: '3 / 1' }}>
        {getBody()}
      </Box>
      <Box sx={sxStyles.previewCardsWrap}>
        <Box sx={sxStyles.previewCards} style={getOffsetStyle()}>
          {cards.map((card, index) => (
            <FacebookAdPreviewCard
              // eslint-disable-next-line react/no-array-index-key
              key={`${get(card, 'image.id')}-${index}`}
              isVideo={isVideo}
              card={card}
              callToAction={callToAction}
              cardWidth={cardWidth}
              cardImageWidth={cardImageWidth}
              cardImageHeight={cardImageHeight}
              isSoloCard={isSoloCard}
              requireBusinessObjects={requireBusinessObjects}
              hasBusinessObjects={hasBusinessObjects}
              isLeadAd={isLeadAd}
              isCarousel={isCarousel}
            />
          ))}
        </Box>
      </Box>
      <Box sx={sxStyles.previewFooter}>
        <Box className={sxStyles.fBImage} style={{ gridArea: '1 / 1' }}>
          <Like width="100%" />
        </Box>
        <Box className={sxStyles.fBImage} style={{ gridArea: '1 / 2' }}>
          <Comment width="100%" />
        </Box>
        <Box className={sxStyles.fBImage} style={{ gridArea: '1 / 3' }}>
          <Share width="100%" />
        </Box>
      </Box>
    </Box>
  );
};

export default FacebookAdPreview;
