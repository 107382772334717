import { Trans } from 'react-i18next';
import { isFunction } from 'lodash';
import { useEffect } from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { CheckCircle, Warning } from '@mui/icons-material';

import { formatNumber } from 'src/common/numbers';
import { dayjs } from 'src/common/dates';

import { SPEND_VALIDATION_MESSAGES } from './constants';

const ICON_MARGIN = 1;
const TEXT_MARGIN = 2;
const TEXT_DISPLAY = 'flex';
const TEXT_ALIGN_ITEMS = 'center';

const WarningIcon = styled(Warning)(({ theme }) => ({
  marginRight: theme.spacing(ICON_MARGIN)
}));

const SuccessIcon = styled(CheckCircle)(({ theme }) => ({
  marginRight: theme.spacing(ICON_MARGIN)
}));

const SpendErrorText = styled(Typography)(({ theme }) => ({
  alignItems: TEXT_ALIGN_ITEMS,
  color: theme.palette.error.main,
  display: TEXT_DISPLAY,
  marginTop: theme.spacing(TEXT_MARGIN)
}));

const SpendSuccessText = styled(Typography)(({ theme }) => ({
  alignItems: TEXT_ALIGN_ITEMS,
  color: theme.palette.success[500],
  display: TEXT_DISPLAY,
  marginTop: theme.spacing(TEXT_MARGIN)
}));

const OneTimeSpendMessage = ({
  message,
  performancePredictions,
  minDays,
  min,
  max,
  currentEndDate,
  setHasMinSpendError
}) => {
  const predictions = performancePredictions?.map(
    prediction =>
      `${prediction?.lowerBound} - ${prediction?.upperBound} ${prediction?.kpi}`
  );

  const handleSetMinSpendError = hasError => {
    if (isFunction(setHasMinSpendError)) {
      setHasMinSpendError(hasError);
    }
  };

  // state setter needs to be wrapped in a useEffect so that we don't set state during render
  useEffect(() => {
    if (
      message === SPEND_VALIDATION_MESSAGES.success ||
      message === SPEND_VALIDATION_MESSAGES.noPredictionSuccess
    ) {
      handleSetMinSpendError(false);
    } else if (
      message === SPEND_VALIDATION_MESSAGES.dailySpend ||
      message === SPEND_VALIDATION_MESSAGES.dateRange ||
      message === SPEND_VALIDATION_MESSAGES.minSpend ||
      message === SPEND_VALIDATION_MESSAGES.maxSpend ||
      message === SPEND_VALIDATION_MESSAGES.increaseEndDate
    ) {
      handleSetMinSpendError(true);
    } else {
      handleSetMinSpendError(false);
    }
  }, [message, handleSetMinSpendError]);

  switch (message) {
    case SPEND_VALIDATION_MESSAGES.success:
      return (
        <SpendSuccessText variant="body1">
          <SuccessIcon />
          <Trans
            i18nKey="programCreate:spend.successDailySpend"
            values={{ predictions }}
          />
        </SpendSuccessText>
      );
    case SPEND_VALIDATION_MESSAGES.dailySpend:
      return (
        <SpendErrorText variant="body1">
          <WarningIcon />
          <Trans i18nKey="programCreate:spend.errorDailySpend" />
        </SpendErrorText>
      );
    case SPEND_VALIDATION_MESSAGES.dateRange:
      return (
        <SpendErrorText variant="body1">
          <WarningIcon />
          <Trans
            i18nKey="programCreate:spend.errorDateRange"
            values={{ minDays }}
          />
        </SpendErrorText>
      );
    case SPEND_VALIDATION_MESSAGES.minSpend:
      return (
        <SpendErrorText variant="body1">
          <WarningIcon />
          <Trans
            i18nKey="programCreate:spend.errorMinDailySpend"
            values={{ min: formatNumber(min) }}
          />
        </SpendErrorText>
      );
    case SPEND_VALIDATION_MESSAGES.maxSpend:
      return (
        <SpendErrorText variant="body1">
          <WarningIcon />
          <Trans
            i18nKey="programCreate:spend.errorMaxDailySpend"
            values={{ max: formatNumber(max) }}
          />
        </SpendErrorText>
      );
    case SPEND_VALIDATION_MESSAGES.noPredictionSuccess:
      return (
        <SpendSuccessText variant="body1">
          <SuccessIcon />
          <Trans i18nKey="programCreate:spend.defaultDailySpend" />
        </SpendSuccessText>
      );
    case SPEND_VALIDATION_MESSAGES.increaseEndDate:
      return (
        <SpendErrorText variant="body1">
          <WarningIcon />
          <Trans
            i18nKey="programEdit:spend.increaseEndDate"
            values={{
              minBudgetUpdateEndDate: dayjs(currentEndDate)
                .add(3, 'days')
                .format('MM/DD/YYYY')
            }}
          />
        </SpendErrorText>
      );
    default:
      handleSetMinSpendError(false);
      return null;
  }
};

export default OneTimeSpendMessage;
