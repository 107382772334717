import { flow, get } from 'lodash';
import { styled } from '@mui/material/styles';
import { graphql } from '@apollo/client/react/hoc';
import { t } from 'i18next';

import { extractCloudinaryUrl } from 'src/common/cloudinaryUtils';

import { signCloudinaryUrl } from './mutations';

const PREFIX = 'CloudinaryImage';

const classes = {
  error: `${PREFIX}-error`,
  loading: `${PREFIX}-loading`
};

const Root = styled('img')(({ theme }) => ({
  [`& .${classes.error}`]: {
    color: theme.palette.error.dark
  },

  [`& .${classes.loading}`]: {
    width: '10px',
    height: '10px'
  }
}));

const CloudinaryImage = props => {
  const {
    className,
    error,
    loading,
    signedUrl,
    showError = true,
    ...restProps
  } = props;

  if (loading) {
    return <span>Loading Image...</span>;
  }

  if (error || !signedUrl) {
    if (showError) {
      return <span className={classes.error}>Error getting Image</span>;
    }
    return '';
  }

  const imageAlt = t('adPreview:fbImageAlt');

  return (
    <Root alt={imageAlt} className={className} src={signedUrl} {...restProps} />
  );
};

export default flow(
  graphql(signCloudinaryUrl, {
    name: 'signCloudinaryUrl',
    options: props => {
      const { unsignedUrl } = props;
      const extractedUrl = extractCloudinaryUrl(unsignedUrl);

      return {
        variables: {
          unsignedCloudinaryUrl: extractedUrl
        }
      };
    },
    skip: ({ unsignedUrl }) => {
      if (!unsignedUrl) {
        return true;
      }
    },
    props: resp => {
      const error = get(resp, 'signCloudinaryUrl.error', null);
      const loading = get(resp, 'signCloudinaryUrl.loading', true);
      const signedUrl = get(
        resp,
        'signCloudinaryUrl.helpers.signCloudinaryUrl',
        null
      );

      return {
        error,
        loading,
        signedUrl
      };
    }
  })
)(CloudinaryImage);
