import { useContext } from 'react';
import { flow } from 'lodash';
import {
  ImageListItem,
  ImageList,
  Button,
  Typography,
  Box
} from '@mui/material';
import { SaveAlt as SaveAltIcon } from '@mui/icons-material';

import { graphql } from '@apollo/client/react/hoc';

import { t } from 'i18next';

import { GlobalContext } from 'src/GlobalContextProvider';
import { usePagination } from 'src/components/Pagination/hooks';

import Loading from 'src/components/Loading';
import PaginationControls from 'src/components/Pagination/PaginationControls';

import { /* shutterstockQuery, */ giphyQuery } from './queries';
import { importGiphyAsset } from './mutations';
import {
  LIBRARY_SCOPES,
  getGrantTypesForMe,
  MEDIA_TYPE,
  IMAGE_GALLERY_HEIGHT
} from './constants';
import ThirdPartyMediaTile from './Tiles/ThirdPartyMediaTile';

const GIPHY_RESULTS_PER_PAGE = 12;

const GalleryGiphy = ({
  giphySearchResult = {},
  importGiphyAsset,
  returnToGallery,
  galleryRefetch,
  searchQuery,
  setIsAddingMedia,
  isAddingMedia
}) => {
  const { giphySearchVideos } = giphySearchResult;

  const videos = giphySearchVideos?.media?.edges.map(edge => edge.node) || [];

  const { me } = useContext(GlobalContext);
  const grantTypesByScope = getGrantTypesForMe(me);

  const { navigateNext, navigatePrev } = usePagination({
    edges: giphySearchVideos?.media?.edges,
    resultsPerPage: GIPHY_RESULTS_PER_PAGE,
    refetchCallback: giphySearchResult.refetch,
    refetchOptions: {
      last: GIPHY_RESULTS_PER_PAGE,
      input: {
        query: searchQuery
      }
    }
  });

  const saveAsset = async asset => {
    setIsAddingMedia(true);
    await importGiphyAsset({
      variables: {
        input: {
          giphyId: asset.id,
          name: asset.id,
          // all 3rd party scopes are USER scopes?
          grants: grantTypesByScope[LIBRARY_SCOPES.User]
        }
      }
    });
    setIsAddingMedia(false);
    returnToGallery();
    galleryRefetch();
  };

  if (giphySearchResult?.loading || giphySearchResult?.error) {
    return (
      <Loading
        error={giphySearchResult?.error && t('gallery:giphy.loadingError')}
      />
    );
  }

  return (
    <Box>
      <Box
        sx={{ position: 'relative', minHeight: `${IMAGE_GALLERY_HEIGHT}px` }}
      >
        {videos.length === 0 && searchQuery && searchQuery?.length > 0 && (
          <Typography
            variant="h4"
            sx={{ textAlign: 'center', marginTop: '20px' }}
          >
            {t('gallery:giphy.noResults')}
          </Typography>
        )}
        {videos.length > 0 && (
          <ImageList rowHeight={180} cols={4}>
            {videos.map(video => (
              <ImageListItem key={video.id} cols={1}>
                <ThirdPartyMediaTile
                  media={video}
                  mediaType={MEDIA_TYPE.video}
                  tooltip={
                    <Button
                      onClick={() => saveAsset(video)}
                      variant="outlined"
                      size="small"
                      sx={{ color: '#fff', borderColor: '#fff' }}
                      startIcon={<SaveAltIcon />}
                      disabled={isAddingMedia}
                    >
                      {t('gallery:giphy.saveToLibrary')}
                    </Button>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </Box>
      <PaginationControls
        pageInfo={giphySearchVideos?.media?.pageInfo}
        navigateNext={navigateNext}
        navigatePrev={navigatePrev}
        variant="icon"
      />
      <br />
    </Box>
  );
};

export default flow(
  graphql(importGiphyAsset, { name: 'importGiphyAsset' }),
  graphql(giphyQuery, {
    name: 'giphySearchResult',
    skip: props => !props.searchQuery,
    options: ({ searchQuery }) => {
      return {
        variables: {
          input: {
            query: searchQuery
          },
          first: GIPHY_RESULTS_PER_PAGE,
          last: GIPHY_RESULTS_PER_PAGE, // what does this mean?
          after: null
        }
      };
    }
  })
)(GalleryGiphy);
